import { Stack, Avatar, VStack, Heading, Text, Box } from '@chakra-ui/react';
import { ISocialNetwork } from '..';

interface IInstructorProp {
  instructor: {
    id: number;
    fullName: string;
    biography: string;
    photo: string;
    totalProducts: number;
    totalStudents: number;
    socialNetwork: ISocialNetwork[];
  };
  isVorticePlatform: boolean;
}

export default function HeaderMobile({ instructor, isVorticePlatform }: IInstructorProp) {
  return (
    <Stack position="relative" direction="row" spacing={0}>
      <Box
        display="flex"
        width="full"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        height="150px"
      >
        <Avatar
          boxSize="80px"
          border="5px solid #FFF"
          bgColor="#202123"
          color="#FFF"
          name={instructor?.fullName}
          src={instructor?.photo}
        />
      </Box>

      <Stack width="full" justifyContent="center" marginTop="135px">
        <VStack alignItems="center" fontSize="14px" lineHeight="20px" zIndex={9}>
          <Text fontWeight="600">Instrutor(a)</Text>
          <Heading
            width="full"
            paddingX="15px"
            textAlign="center"
            fontSize="30px"
            fontWeight="700"
            isTruncated
          >
            {instructor?.fullName}
          </Heading>
        </VStack>

        {!isVorticePlatform && (
          <Stack direction="row" width="full" justifyContent="center" spacing="30px">
            <VStack alignItems="flex-start" lineHeight="20px" zIndex={9}>
              <Text fontSize="14px" fontWeight="700">
                Total de alunos
              </Text>
              <Text fontSize="24px" fontWeight="600">
                {instructor?.totalStudents}
              </Text>
            </VStack>

            <VStack alignItems="flex-start" lineHeight="20px" zIndex={9}>
              <Text fontSize="14px" fontWeight="700">
                Total de produtos
              </Text>
              <Text fontSize="24px" fontWeight="600">
                {instructor?.totalProducts}
              </Text>
            </VStack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
