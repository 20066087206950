import { Box, Button, Link, Text, Heading, AspectRatio, Flex, keyframes } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { FiAlertTriangle as Alert } from 'react-icons/fi';
import ContentAPI from '../../../../../api/Content';
import CourseAPI from '../../../../../api/Course';
import { HiDownload as DownloadIcon } from 'react-icons/hi';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import Player from '@vimeo/player';
import { ROLE_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import { TOKEN_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import Toast from '../../../../../components/Toast';
import { baseURLBackend } from '../../../../../api/Configuration';
import parse from 'html-react-parser';
import path from 'path';
import { useParams } from 'react-router-dom';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import { scrollbarStyle } from '..';

const targets = [20, 40, 60, 80, 100];

const isBetween = (start, end, value) => value > start && value < end;

const alertAnimation = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
  `;

function ViewVideo() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { studentCourse, getContent } = useStudentCourse();
  const [content, setContent] = useState();
  const [hasChange, setHasChange] = useState(false);

  const { courseId, moduleId, contentId } = useParams();

  const [lastRange, setLastRange] = useState(0);
  const [shouldDisplayVideo, setShouldDisplayVideo] = useState(true);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [iframe, setIframe] = useState(null);
  const [duration, setDuration] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      setHasChange(true);
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, 'VIDEO');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setHasChange(false);
    }
  }, [contentId, courseId, moduleId]);

  useEffect(() => {
    if (role === 'ALUNO' && studentCourse && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, role, studentCourse]);

  useEffect(() => {
    if (mounted) {
      setIframe(document.getElementById(`iframe${courseId}`));

      try {
        if (content && iframe) {
          setVideoPlayer(new Player(iframe));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }, [content, courseId, iframe, mounted]);

  async function getStatistics(contentId, courseId) {
    const statistics = await ContentAPI.getStatistics(parseInt(contentId), parseInt(courseId));
    return statistics;
  }

  useEffect(() => {
    getStatistics(contentId, courseId).then(statistics => {
      setIsBlocked(statistics?.blocked);
      setShouldDisplayVideo(!statistics?.blocked);
    });
  }, [contentId, courseId]);

  useEffect(() => {
    if (videoPlayer) {
      videoPlayer
        .getDuration()
        .then(function (seconds) {
          setDuration(seconds);
        })
        .catch(function (error) {
          Toast(ErrorResponse(error), 'error');
        });
    }
  }, [contentId, courseId, videoPlayer]);

  useEffect(() => {
    if (duration) {
      getStatistics(contentId, courseId).then(statistics => {
        if (statistics) {
          const currentTime = Math.round(duration * (statistics.progress / 100));

          videoPlayer.setCurrentTime(currentTime).catch(function (error) {
            Toast(ErrorResponse(error), 'error');
          });
        }
      });
    }
  }, [contentId, courseId, duration, videoPlayer]);

  const sendVideoStatistics = useCallback(
    async data => {
      const { percent } = data;
      const progress = Math.ceil(percent * 100);

      if (progress === 100 && isBlocked) {
        setShouldDisplayVideo(false);
      }

      for (let index = 0; index < targets.length; index++) {
        const start = index > 0 ? targets[index - 1] : 0;
        const end = targets[index];

        if (isBetween(start, end, progress) && lastRange !== end) {
          setLastRange(targets[index]);
          break;
        }
      }
    },
    [isBlocked, lastRange]
  );

  useEffect(() => {
    if (content && iframe && videoPlayer) {
      videoPlayer.on('timeupdate', sendVideoStatistics);
    }
  }, [content, iframe, sendVideoStatistics, videoPlayer]);

  useEffect(() => {
    async function sendProgress() {
      const response = await ContentAPI.sendStatistics(contentId, parseInt(courseId), lastRange);
      setIsBlocked(response.blocked);
    }

    if (lastRange > 0) {
      sendProgress();
    }
  }, [contentId, courseId, lastRange]);

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <div className="d-flex justify-content-md-center h-100">
        <div className="col-sm-12 col-md-10 col-lg-10 p-0">
          <Box mt={{ base: 4, lg: 0 }} px={{ base: 6, lg: 0 }}>
            <Heading as="h4" fontSize={{ base: 'md', md: '2xl' }} noOfLines={2} fontWeight="medium">
              {content?.title || 'Conteúdo sem título'}
            </Heading>

            <AspectRatio ratio={16 / 9} mt={4} mb={4}>
              {shouldDisplayVideo ? (
                <iframe
                  id={`iframe${courseId}`}
                  src={content?.link ? content?.link : content?.thumbnail}
                  title={content?.title}
                  border="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              ) : (
                <Box bg="blackAlpha.800">
                  <Flex justify="center" align="center" bg="white" p={{ base: 6, md: 16 }}>
                    <Flex align="center">
                      <Box animation={`${alertAnimation} 1.1s ease-out alternate infinite running`}>
                        <Alert color="#ef233c" size={40} />
                      </Box>
                      <Text
                        ml={4}
                        fontSize={{ base: 'xl', lg: '3xl' }}
                        lineHeight="base"
                        color="dark.500"
                      >
                        Seu acesso foi limitado!
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              )}
            </AspectRatio>

            {content?.content && (
              <Box m="2.5rem 1rem 1rem 1rem" p="0.5rem" className="ql-editor">
                <>{parse(String(content.content))}</>
              </Box>
            )}

            {content?.download && (
              <Flex
                direction={{ base: 'column', lg: 'row' }}
                my={5}
                px={{ base: 3, lg: 2 }}
                py={{ base: 2, lg: 2 }}
                gap={2}
                h="auto"
                border="2px"
                borderColor="#8091A7"
                borderRadius={10}
                alignItems={{ base: 'center' }}
                justifyContent="space-between"
              >
                <Box>
                  <Text fontSize={{ base: 'smaller', sm: 'md' }} noOfLines={1}>
                    {path.basename(decodeURI(content?.download)).slice(0, 56)}
                  </Text>
                </Box>

                <Flex>
                  <Button
                    rightIcon={<DownloadIcon />}
                    colorScheme="primary"
                    size="sm"
                    variant="outline"
                    isDisabled={hasChange}
                    width="100%"
                  >
                    <Link
                      _hover={{ textDecoration: 'none' }}
                      href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                        TOKEN_LOCAL_STORAGE
                      )}`}
                      download={true}
                    >
                      Fazer o Download
                    </Link>
                  </Button>
                </Flex>
              </Flex>
            )}
          </Box>
        </div>
      </div>
    </Box>
  );
}

export default ViewVideo;
