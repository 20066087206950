import {
  Button,
  CSSObject,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  ModalBody,
  ModalFooter,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';

import { MdLock as PadlockIcon } from 'react-icons/md';
import amex from '../../../../assets/card-brands/v2/amex.svg';
import dinersClub from '../../../../assets/card-brands/v2/diners-club.svg';
import elo from '../../../../assets/card-brands/v2/elo.svg';
import masterCard from '../../../../assets/card-brands/v2/master-card.svg';
import visa from '../../../../assets/card-brands/v2/visa.svg';

import { Link } from 'react-router-dom';

import { FormEvent, useEffect, useState } from 'react';
import { capitalizePlanName } from '../..';
import PlansAPI from '../../../../api/Plan';
import { useSubscription } from '../../../../contexts/SubscriptionContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useHandleChange from '../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import Toast from '../../../Toast';
import CreditCardInput from './CreditCardInput';
import { SubscriptionSchemaType, subscriptionSchemaValidator } from './subscriptionSchemaValidator';
import YearSelect from './YearSelect';
import LoadingOverlay from './LoadingOverlay';

type RecurrenceType = 'MÊS' | 'ANO';

type StatusTypes =
  | 'ATIVO'
  | 'INATIVO'
  | 'BLOQUEADO'
  | 'EM_EDICAO'
  | 'EXCLUIDO'
  | 'NAO_LISTADO'
  | 'CONVIDADO';

export interface IPlan {
  id: number;
  name: string;
  value: number;
  recurrence: RecurrenceType;
  description?: string;
  customerPercentage: string;
  marketplacePercentage: number;
  marketplaceIntegration: boolean;
  trial: number;
  homologatedBy?: any;
  homologation: boolean;
  status: StatusTypes;
}

function getMonth(value: number) {
  return `${value + 1}`.padStart(2, '0');
}

const SxFormProps: CSSObject = {
  label: {
    fontSize: 'sm',
    fontWeight: 'medium',
    color: '#202123',
  },
  input: {
    _focus: { borderColor: 'orange.400', boxShadow: 'none' },
  },
  select: {
    _focus: { borderColor: 'orange.400', boxShadow: 'none' },
  },
};

export default function ModalContentSubscription({ onActionButtonClick }) {
  const [plan, setPlan] = useState<IPlan>();
  const [isLoading, setIsLoading] = useState(true);

  const { form, handleChange } = useHandleChange<SubscriptionSchemaType>({
    cardNumber: '',
    cardCVV: '',
    month: '',
    year: '',
    cardExpirationDate: '',
  });

  const payload = {
    ...form,
    cardNumber: form.cardNumber.replace(/[\D+]/g, ''),
    cardExpirationDate: `${form.month}${form.year}`,
  };

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: subscriptionSchemaValidator,
    url: '/pay-subscription-platform',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Assinatura renovada com sucesso!',
      callback: onActionButtonClick,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  const { subscriptionStatus } = useSubscription();

  const planName = capitalizePlanName(subscriptionStatus?.plan);
  const planIdentifier = subscriptionStatus?.planIdentifier;

  useEffect(() => {
    (async () => {
      try {
        const response = await PlansAPI.showByIdentifier(planIdentifier);

        setPlan(response);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [planIdentifier]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <ModalBody>
          <Flex align="center" direction="column" mt={4}>
            <Heading
              as="h6"
              fontSize="2xl"
              fontWeight="medium"
              color="orange.400"
              maxW="16ch"
              textAlign="center"
            >
              Plano {planName}
            </Heading>

            <Text mt={2} fontSize="lg" color="#202123">
              R${plan?.value}/mês
            </Text>

            <VStack as="form" mt={6} spacing={4} w="full" sx={SxFormProps}>
              <FormControl isInvalid={formValidation?.cardNumber?.isInvalid}>
                <FormLabel>Número do cartão</FormLabel>
                <CreditCardInput
                  name="cardNumber"
                  onChange={handleChange}
                  value={form.cardNumber}
                />
                <FormErrorMessage>{formValidation?.cardNumber?.message}</FormErrorMessage>
              </FormControl>

              <Flex gap={4} w="full">
                <Flex direction="column" align="flex-start" flex={1}>
                  <FormLabel>Data de validade</FormLabel>
                  <Flex w="full" gap={2}>
                    <FormControl isInvalid={formValidation?.month.isInvalid}>
                      <Select
                        value={form.month}
                        name="month"
                        placeholder="MM"
                        onChange={handleChange}
                      >
                        {Array.from({ length: 12 }, (_, i) => +i).map(month => (
                          <option key={month}>{getMonth(month)}</option>
                        ))}
                      </Select>
                      <FormErrorMessage>{formValidation?.month.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formValidation?.year.isInvalid}>
                      <YearSelect value={form.year} name="year" onChange={handleChange} />
                      <FormErrorMessage>{formValidation?.year.message}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Flex>

                <FormControl flex={1} isInvalid={formValidation?.cardCVV.isInvalid}>
                  <FormLabel>CVV</FormLabel>
                  <Input
                    name="cardCVV"
                    value={form.cardCVV}
                    placeholder="XXX"
                    type="text"
                    inputMode="numeric"
                    maxLength={4}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{formValidation?.cardCVV.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <VStack align="flex-start" w="full">
                <Text fontSize="xs" color="#202123">
                  Bandeiras aceitas
                </Text>

                <Flex
                  align="center"
                  w="full"
                  justify="space-between"
                  sx={{ img: { maxW: '2.5rem' } }}
                >
                  <Image src={visa} />
                  <Image src={masterCard} />
                  <Image src={elo} />
                  <Image src={amex} />
                  <Image src={dinersClub} />
                </Flex>
              </VStack>

              <Flex gap="2">
                <PadlockIcon color="#eb7129" fontSize="1rem" />

                <Flex textAlign="start" direction="column">
                  <Text fontSize="0.625rem" fontWeight="medium" color="orange.400">
                    Suas informações estão seguras
                  </Text>

                  <Text fontSize="0.625rem" fontWeight="light" color={'black'}>
                    Todos os dados deste site são criptografados com protocolo SSL ao trafegarem
                    pela internet.
                  </Text>
                </Flex>
              </Flex>

              <Text
                textAlign="start"
                fontSize="0.625rem"
                color="#202123"
                lineHeight="12.19px"
                sx={{ a: { fontWeight: 'bold', textDecoration: 'underline' } }}
              >
                Ao clicar no botão “Iniciar minha assinatura” você concorda com os{' '}
                <Link to="/term-of-use" target="_blank">
                  termo de uso.
                </Link>{' '}
                Você pode cancelar sua assinatura a qualquer momento para evitar cobranças futuras;
                seu cancelamento entrará em vigor no final do período de cobrança atual. Para
                cancelar, envie um e-mail para suporte@proluno.com.br.
              </Text>
            </VStack>
          </Flex>
        </ModalBody>

        <ModalFooter pb={6}>
          <Button
            type="submit"
            w="full"
            colorScheme="orange"
            fontSize="sm"
            _focus={{ outline: 'none', boxShadow: 'none' }}
            isLoading={isSubmitting}
          >
            Iniciar minha assinatura
          </Button>
        </ModalFooter>
        <LoadingOverlay isLoading={isLoading} />
      </form>
    </>
  );
}
