export function translatePurchaseStatus(status: string) {
  switch (status) {
    case 'paid':
      return 'Paga';
    case 'waiting_payment':
      return 'Aguardando pagamento';
    case 'refused':
      return 'Compra recusada';
    case 'refunded':
      return 'Compra estornada';
    case 'chargedback':
      return 'Chargedback';
    case 'canceled':
      return 'Cancelada';
    case 'pending_refund':
      return 'Estorno Pendente';
    case 'manual':
      return 'Inserido';
    default:
      return '-';
  }
}
