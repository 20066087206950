import { Box } from '@chakra-ui/react';
import { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideoLibraryProvider from '../../contexts/VideoLibraryContext';
import EditorToolbar, { formats, modules } from './EditorToolbar';
import './styles.css';

interface EditorProps {
  value: string;
  hasVideoLibrary?: boolean;
  onChange?: (value: string) => void;
}

export default function Editor({ value, hasVideoLibrary, onChange }: EditorProps) {
  function handleChange(value: string) {
    onChange(value);
  }

  const quillRef = useRef(null);

  return (
    <VideoLibraryProvider>
      <Box className="text-editor">
        <EditorToolbar quillRef={quillRef} hasVideoLibrary={hasVideoLibrary} />
        <ReactQuill
          theme="snow"
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          ref={quillRef}
        />
      </Box>
    </VideoLibraryProvider>
  );
}
