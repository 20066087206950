import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BsChevronDown } from 'react-icons/bs';
import { FaExclamationCircle } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { IS_REACT_APP_HOSTNAME } from '../../../../../App';
import AdminAPI from '../../../../../api/Admin';
import PurchaseAPI from '../../../../../api/Purchase';
import TransactionAPI from '../../../../../api/Transaction';
import DotsFalling from '../../../../../components/Loadings/DotsFalling';
import Pagination from '../../../../../components/Pagination';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import NoData from '../../NoData';
import styles from '../styles.module.css';
import TransactionItem from './TransactionItem';

import {
  Box,
  Button,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Text,
} from '@chakra-ui/react';

const initialStateTransactions = {
  filteredTransactions: [],
};

export default function TableList() {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const today = dayjs().format('YYYY-MM-DD');
  const lastMonth = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
  const startDateQuery = urlSearchParams.get('startDate');
  const endDateQuery = urlSearchParams.get('endDate');
  const pageQuery = urlSearchParams.get('page');
  const searchQuery = urlSearchParams.get('search');

  function getQueryValue(queryName) {
    return (
      urlSearchParams
        .get(queryName)
        ?.split(',')
        .map(query => query.trim()) ?? []
    );
  }

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [transactionTemp, setTransactionTemp] = useState(null);
  const [currentPage, setCurrentPage] = useState(pageQuery ?? 1);
  const [filter, setFilter] = useState(initialStateTransactions);
  const [searchTransaction, setSearchTransaction] = useState(searchQuery ?? '');
  const [filterPaymentMethod, setFilterPaymentMethod] = useState(getQueryValue('paymentMethod'));
  const [filterPurchaseStatus, setFilterPurchaseStatus] = useState(getQueryValue('status'));
  const [filterEndDate, setFilterEndDate] = useState(endDateQuery ?? today);
  const [filterStartDate, setFilterStartDate] = useState(startDateQuery ?? lastMonth);

  const getTransactions = useCallback(
    async ({ page, search, startDate, endDate, filterPaymentMethod, filterPurchaseStatus }) => {
      try {
        const { data: transactionData } = await TransactionAPI.index({
          page,
          search,
          startDate,
          endDate,
          filterPaymentMethod,
          filterPurchaseStatus,
        });

        if (transactionData) {
          setTransactions(transactionData.transactions);
          setPageCount(transactionData.total / transactionData.limit);
          setFilter({ ...filter, filteredTransactions: transactionData.transactions });
        } else {
          setFilter({ ...filter, filteredTransactions: [] });
          setPageCount(0);
        }

        setLoading(false);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function getExportTransactionExcel() {
    try {
      await PurchaseAPI.purchaseExportTransaction({
        typeFile: 'Excel',
        search: searchTransaction,
        endDate: filterEndDate,
        startDate: filterStartDate,
        filterPaymentMethod: filterPaymentMethod,
        filterPurchaseStatus: filterPurchaseStatus,
      });
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function getExportTransactionCsv() {
    try {
      await PurchaseAPI.purchaseExportTransaction({
        typeFile: 'CSV',
        search: searchTransaction,
        startDate: filterStartDate,
        endDate: filterEndDate,
        filterPaymentMethod: filterPaymentMethod,
        filterPurchaseStatus: filterPurchaseStatus,
      });
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  useEffect(() => {
    history.push({
      pathname: '/financial',
      search: urlSearchParams.toString(),
    });

    const timer = setTimeout(() => {
      if (searchTransaction || transactions) {
        getTransactions({
          page: currentPage,
          search: searchTransaction,
          startDate: filterStartDate,
          endDate: filterEndDate,
          filterPaymentMethod: filterPaymentMethod,
          filterPurchaseStatus: filterPurchaseStatus,
        });
      } else {
        setFilter({ ...filter, filteredTransactions: transactions.sort() });
      }
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    searchTransaction,
    filterStartDate,
    filterEndDate,
    filterPaymentMethod,
    filterPurchaseStatus,
    getTransactions,
  ]);

  function onChangeFilter(event) {
    const { value } = event.target;

    setCurrentPage(1);

    urlSearchParams.set('search', value);
    urlSearchParams.set('page', '1');

    setSearchTransaction(value);
  }

  function onChangeFilterStartDate(event) {
    const { value } = event.target;

    setCurrentPage(1);

    if (value) {
      const formattedDate = dayjs(value).format('YYYY-MM-DD');

      setFilterStartDate(formattedDate);
      urlSearchParams.set('startDate', formattedDate);
    } else {
      const formattedDate = dayjs().format('YYYY-MM-DD');

      setFilterStartDate(formattedDate);
      urlSearchParams.set('startDate', formattedDate);
    }
  }

  function onChangeFilterEndDate(event) {
    const { value } = event.target;

    setCurrentPage(1);

    if (value) {
      const formattedDate = dayjs(value).format('YYYY-MM-DD');

      setFilterEndDate(formattedDate);
      urlSearchParams.set('endDate', formattedDate);
    } else {
      const formattedDate = dayjs().format('YYYY-MM-DD');

      setFilterEndDate(formattedDate);
      urlSearchParams.set('endDate', formattedDate);
    }
  }

  function onChangeFilterPaymentMethod(paymentsType) {
    setCurrentPage(1);

    setFilterPaymentMethod(paymentsType);

    urlSearchParams.set('paymentMethod', paymentsType);
  }

  function onChangeFilterPurchaseStatus(status) {
    setCurrentPage(1);

    setFilterPurchaseStatus(status);

    urlSearchParams.set('status', status);
  }

  function handlePageChange({ selected: selectedPage }) {
    const currentPage = selectedPage + 1;

    setCurrentPage(currentPage);
    urlSearchParams.set('page', currentPage);
  }

  function modalReleaseAccess(transactionId) {
    setIsShowModal(true);

    const transaction = transactions.find(transaction => transaction.id === transactionId);

    if (transaction) {
      setTransactionTemp(transaction);
    }
  }

  async function handleReleaseAccess() {
    try {
      setDisable(true);

      await AdminAPI.releaseAccess(transactionTemp.id);

      const updatedTransaction = transactions.map(transaction => {
        if (transaction.id === transactionTemp.id) {
          return {
            ...transactionTemp,
            purchaseStatus: 'paid',
          };
        }

        return transaction;
      });

      setFilter({ ...filter, filteredTransactions: updatedTransaction.sort() });

      setDisable(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsShowModal(false);
    }
  }

  const hostname = window.location.hostname;
  const activePage = Number(currentPage) ? Number(currentPage) - 1 : 0;

  if (loading) {
    return (
      <>
        <div className="container d-flex justify-content-center mt-5">
          <DotsFalling className="white" />
        </div>
      </>
    );
  }

  return (
    <>
      <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} my={2}>
        <Input
          type="text"
          value={searchTransaction}
          focusBorderColor="#EB7129"
          onChange={onChangeFilter}
          size="sm"
          borderRadius={6}
          placeholder="Pesquise por comprador, CPF, e-mail ou produto"
        />

        <Menu>
          <MenuButton
            w={{ base: '100%', md: 200, lg: 200 }}
            size="sm"
            color="white"
            bgColor="#EB7129"
            _active={{
              background: '#EB712980',
            }}
            _hover={{
              background: '#EB712980',
            }}
            as={Button}
            rightIcon={<BsChevronDown />}
            style={{ boxShadow: 'none', outline: 'none' }}
          >
            Exportar
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={getExportTransactionExcel}
              style={{ boxShadow: 'none', outline: 'none' }}
            >
              Download Excel
            </MenuItem>
            <MenuItem
              onClick={getExportTransactionCsv}
              style={{ boxShadow: 'none', outline: 'none' }}
            >
              Download CSV
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>

      <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} my={2}>
        <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} spacing={3} width="full">
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              bg="transparent"
              size="sm"
              width="full"
              border="1px solid #20212330"
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text>Status da Transação</Text>
                <BsChevronDown size={15} />
              </Stack>
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                title="Transações"
                type="checkbox"
                onChange={onChangeFilterPurchaseStatus}
                value={filterPurchaseStatus}
              >
                <MenuItemOption value="paid">Pagas</MenuItemOption>
                <MenuItemOption value="waiting_payment">Aguardando Pagamento</MenuItemOption>
                <MenuItemOption value="refused">Recusadas</MenuItemOption>
                <MenuItemOption value="refunded">Estornadas</MenuItemOption>
                <MenuItemOption value="chargedback">Chargedback</MenuItemOption>
                <MenuItemOption value="canceled">Canceladas</MenuItemOption>
                <MenuItemOption value="pending_refund">Estorno Pendente</MenuItemOption>
                <MenuItemOption value="manual">Inserido</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>

          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              bg="transparent"
              size="sm"
              width="full"
              border="1px solid #20212330"
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text> Forma de pagamento</Text>
                <BsChevronDown size={15} />
              </Stack>
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                title="Pagamentos"
                type="checkbox"
                onChange={onChangeFilterPaymentMethod}
                value={filterPaymentMethod}
              >
                <MenuItemOption value="credit_card">Cartão de Crédito</MenuItemOption>
                <MenuItemOption value="boleto">Boleto</MenuItemOption>
                <MenuItemOption value="pix">Pix</MenuItemOption>
                <MenuItemOption value="free">Grátis</MenuItemOption>
                <MenuItemOption value="external">Externo</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Stack>

        <Stack direction="row" spacing={3} width="full">
          <Input
            type="date"
            onChange={onChangeFilterStartDate}
            value={filterStartDate}
            focusBorderColor="#EB7129"
            size="sm"
            borderRadius={6}
          />
          <Input
            type="date"
            onChange={onChangeFilterEndDate}
            value={filterEndDate}
            focusBorderColor="#EB7129"
            size="sm"
            borderRadius={6}
          />
        </Stack>
      </Stack>
      <Box overflowX="auto" overflowY="hidden">
        <table className={`table ${styles.customTable}`}>
          <thead className={styles.tableTHeader}>
            <tr>
              <th>Transação</th>
              <th>Itens</th>
              <th>Data/Hora</th>
              <th>Comprador</th>
              <th>CPF</th>
              <th>E-mail</th>
              <th>Pagamento</th>
              <th>Valor</th>
              <th>Status</th>
              {IS_REACT_APP_HOSTNAME(hostname) && <th>Ação</th>}
            </tr>
          </thead>

          <tbody className={styles.tableTBody}>
            {filter.filteredTransactions.map(transaction => (
              <TransactionItem
                key={transaction.id}
                transaction={transaction}
                modalReleaseAccess={modalReleaseAccess}
              />
            ))}
          </tbody>
        </table>
      </Box>

      {!transactions?.length && <NoData />}

      {!filter.filteredTransactions.length && !!transactions?.length && (
        <Box w="100%" textAlign="center">
          <Heading size="sm">Nenhuma transação foi encontrada.</Heading>
        </Box>
      )}

      {(!!filter.filteredTransactions.length || !!transactions?.length) && (
        <Box>
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePageChange}
            onPageActive={activePage}
          />
        </Box>
      )}

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex flex-column align-items-center justify-content-center mb-1 px-2">
            <FaExclamationCircle size="4rem" color="#EB7129" />
            <h3 className="text-center mt-2">Liberar acesso ao produto?</h3>
            <p className="text-center mt-2">
              Deseja liberar o acesso do produto{' '}
              <b style={{ fontWeight: 600 }}>{transactionTemp?.product}</b> antes da confirmação do
              pagamento?
            </p>
          </div>
          <div className="row d-flex mb-1 mt-4 px-2" style={{ gap: '1rem' }}>
            <button
              onClick={() => setIsShowModal(false)}
              disabled={disable}
              className="btn btn-primary col d-flex justify-content-center"
            >
              Cancelar
            </button>
            <button
              onClick={handleReleaseAccess}
              disabled={disable}
              className="btn btn-outline-danger col d-flex justify-content-center"
              style={{
                backgroundColor: 'transparent',
              }}
            >
              Sim
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
