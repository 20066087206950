import CopyToClipboard from 'react-copy-to-clipboard';
import { MdContentCopy as CopyIcon } from 'react-icons/md';
import BoletoPreviewWidget from './BoletoPreviewWidget';
import PixPreviewWidget from './PixPreviewWidget';
import { CheckoutSettingsEditPageThanksProps } from './types';
import useCheckoutSettingsEditPageThanks from './useCheckoutSettingsEditPageThanks';

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';

export default function CheckoutSettingsEditPageThanks({
  onIsLoadingChange,
}: CheckoutSettingsEditPageThanksProps) {
  const {
    form,
    isSubmitting,
    formValidation,
    hasChange,
    boletoScript,
    pixScript,
    handleThankPageTypeChange,
    handleCopy,
    handleChange,
    handleCancelButtonClick,
    onSubmit,
  } = useCheckoutSettingsEditPageThanks({
    onIsLoadingChange,
  });

  return (
    <Box mt={10}>
      <Heading fontSize="xl" fontWeight="medium" color="#202123">
        Página de obrigado
      </Heading>

      <VStack
        as="form"
        align="start"
        mt="1.125rem"
        spacing={8}
        sx={{
          '.chakra-form__label': {
            fontSize: 'sm',
            color: '#202123',
            fontWeight: 'medium',
          },
          '.chakra-form__helper-text': {
            color: '#20212380',
            mb: 2,
          },
          '.chakra-input, .chakra-input::placeholder': {
            color: '#0000005C',
          },
        }}
        noValidate
        onSubmit={onSubmit}
      >
        <FormControl isInvalid={formValidation?.thankPageType.isInvalid}>
          <FormLabel>Escolha uma opção</FormLabel>

          <Select
            value={form?.thankPageType}
            name="thankPageType"
            onChange={handleThankPageTypeChange}
            focusBorderColor="gray.200"
          >
            <option value="DEFAULT">Página de obrigado padrão</option>
            <option value="EXTERNAL">Página de obrigado externa</option>
          </Select>

          <FormErrorMessage>{formValidation?.thankPageType?.message}</FormErrorMessage>
        </FormControl>

        {form.thankPageType === 'EXTERNAL' && (
          <>
            <FormControl isInvalid={formValidation?.thankPageForApprovedPurchases?.isInvalid}>
              <FormLabel>Compras aprovadas</FormLabel>

              <FormHelperText>
                Esta página é exibida quando a compra é feita com cartão de crédito e a aprovação é
                confirmada.
              </FormHelperText>

              <Input
                name="thankPageForApprovedPurchases"
                value={form.thankPageForApprovedPurchases}
                onChange={handleChange}
                placeholder="URL"
                focusBorderColor="gray.200"
              />

              <FormErrorMessage>
                {formValidation?.thankPageForApprovedPurchases?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formValidation?.thankPageForPurchasesAwaitingPayment?.isInvalid}
            >
              <FormLabel>Compras aguardando pagamento</FormLabel>

              <FormHelperText>
                Esta página é exibida quando a compra é feita por boleto bancário e o pagamento está
                pendente.
              </FormHelperText>

              <Input
                name="thankPageForPurchasesAwaitingPayment"
                value={form.thankPageForPurchasesAwaitingPayment}
                onChange={handleChange}
                placeholder="URL"
                focusBorderColor="gray.200"
              />

              <FormErrorMessage>
                {formValidation?.thankPageForPurchasesAwaitingPayment?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formValidation?.thankPageForPurchasesAwaitingAnalysis?.isInvalid}
            >
              <FormLabel>Compras aguardando análise</FormLabel>

              <FormHelperText>
                Esta página é exibida quando a compra é feita por Pix, e servirá para o usuário
                visualizar QR Code/Código.
              </FormHelperText>

              <Input
                name="thankPageForPurchasesAwaitingAnalysis"
                value={form.thankPageForPurchasesAwaitingAnalysis}
                onChange={handleChange}
                placeholder="URL"
                focusBorderColor="gray.200"
              />

              <FormErrorMessage>
                {formValidation?.thankPageForPurchasesAwaitingAnalysis?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>Script para widget de boleto</FormLabel>

              <InputGroup bg="#ffffff" borderRadius="base">
                <Input value={boletoScript} focusBorderColor="gray.200" />
                <InputRightAddon maxWidth="107px" p={0} overflow="hidden">
                  <CopyToClipboard text={boletoScript} onCopy={handleCopy}>
                    <Button leftIcon={<CopyIcon size={14} />} color="#20212380" w="full">
                      <Text>Copiar</Text>
                    </Button>
                  </CopyToClipboard>
                </InputRightAddon>
              </InputGroup>
            </FormControl>

            <VStack align="start" w="full">
              <Text className="chakra-form__label">Script para widget de boleto(Preview)</Text>
              <BoletoPreviewWidget />
            </VStack>

            <FormControl>
              <FormLabel>Script para widget de PIX</FormLabel>

              <InputGroup bg="#ffffff" borderRadius="base">
                <Input value={pixScript} focusBorderColor="gray.200" />
                <InputRightAddon maxWidth="107px" p={0} overflow="hidden">
                  <CopyToClipboard text={pixScript} onCopy={handleCopy}>
                    <Button leftIcon={<CopyIcon size={14} />} color="#20212380" w="full">
                      <Text>Copiar</Text>
                    </Button>
                  </CopyToClipboard>
                </InputRightAddon>
              </InputGroup>
            </FormControl>

            <VStack align="start" w="full">
              <Text className="chakra-form__label">Script para widget de PIX(Preview)</Text>
              <PixPreviewWidget />
            </VStack>
          </>
        )}

        <HStack as="footer" w="full" justify="end" spacing={1}>
          <Button
            onClick={handleCancelButtonClick}
            variant="ghost"
            mr={3}
            background="gray.200"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isDisabled={isSubmitting}
          >
            Voltar
          </Button>

          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isDisabled={!hasChange}
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
