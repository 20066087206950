import { BankDetails } from '..';

interface BiometricsStatus {
  name: 'approved' | 'denied' | 'pending';
  label: string;
  color: string;
}

export function getBiometricsStatus(
  status: BankDetails['status'],
  kycDetailsStatus: BankDetails['kycDetailsStatus']
): BiometricsStatus {
  if (status === 'active' && kycDetailsStatus === 'approved') {
    return { name: 'approved', label: 'Validação aprovada', color: '#22BB33' };
  }

  if (status === 'affiliation' && kycDetailsStatus === 'partially_denied') {
    return { name: 'pending', label: 'Aguardando biometria', color: '#202123' };
  }

  return { name: 'denied', label: 'Validação não aprovada', color: '#BB2124' };
}
