import dayjs from 'dayjs';
import { IPayment } from '../../..';
import convertToBrazilianCurrency from '../../../../../../../helpers/convertToBrazilianCurrency';
import { IItemType } from '../../../../../../DashboardStudent/Payments';
import getPaymentMethod from '../../../utils/getPaymentMethod';
import Status from './Status';

import {
  Table,
  TableContainer,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';

interface ProductsTableProps extends TableProps {
  paymentList: IPayment[];
  toggleModal: (pixQrCode: string) => void;
  setTransactionFiltered: (products: IItemType[]) => void;
  onOpen: () => void;
}

export default function ProductsTable({
  paymentList,
  toggleModal,
  setTransactionFiltered,
  onOpen,
  ...rest
}: ProductsTableProps) {
  function filterTransactionId(products: IItemType[]) {
    setTransactionFiltered(products);
    onOpen();
  }

  const tableBackground = useColorModeValue('#FFFFFF', '#37393D');
  const tableColor = useColorModeValue('#202123', '#FFFFFF');
  const oddRowColor = useColorModeValue('#2021230D', '#00000040');

  return (
    <TableContainer
      border="1px solid #2021230D"
      p={5}
      borderRadius="md"
      bg={tableBackground}
      {...rest}
    >
      <Table>
        <Thead fontWeight="bold">
          <Tr sx={{ th: { color: tableColor } }}>
            <Th>N° do Pedido</Th>
            <Th>Valor</Th>
            <Th>Forma de Pagamento</Th>
            <Th>Parcelas</Th>
            <Th>Data</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>

        <Tbody
          sx={{
            'tr:nth-of-type(odd)': {
              background: oddRowColor,
            },
          }}
        >
          {paymentList.map(payment => (
            <Tr key={payment.id}>
              <Td onClick={() => filterTransactionId(payment.products)} cursor="pointer">
                #{payment.id}
              </Td>
              <Td onClick={() => filterTransactionId(payment.products)} cursor="pointer">
                {convertToBrazilianCurrency(payment.value)}
              </Td>
              <Td onClick={() => filterTransactionId(payment.products)} cursor="pointer">
                {getPaymentMethod(payment.paymentMethod)}
              </Td>
              <Td onClick={() => filterTransactionId(payment.products)} cursor="pointer">
                {payment.installments}
              </Td>
              <Td onClick={() => filterTransactionId(payment.products)} cursor="pointer">
                {dayjs(payment.date).format('DD/MM/YYYY')}
              </Td>
              <Td>
                <Status
                  paymentMethod={payment.paymentMethod}
                  paymentStatus={payment.status}
                  link={payment.link}
                  onClick={() => toggleModal(payment.pixQrCode)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
