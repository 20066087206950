import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Hide,
  Input,
  Select,
  VStack,
  useOutsideClick,
} from '@chakra-ui/react';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';
import { onlyAlphaAndSpace, onlyNumber } from '../../../../../hooks/useHandleChange';
import { FormValidation } from '../../../../../hooks/useHandleSubmit';
import { IPayment as IInstallment } from '../../../../../hooks/useShoppingCart';
import { INPUT_STYLES } from '../../../constants';
import { CreditCardType, UserCard } from '../../types';
import CreditCardInput from './CreditCardInput';
import YearSelect from './YearSelect';
import CreditCardIllustration from './CreditCardIllustration';
import { useRef, useState } from 'react';
// import CreditCardIllustration from './CreditCardIllustration';

function getMonth(value: number) {
  return `${value + 1}`.padStart(2, '0');
}

interface CreditCardProps {
  creditCard: CreditCardType;
  formValidation?: FormValidation<Partial<CreditCardType>>;
  payments: IInstallment[];
  installments: number;
  saveCreditCardForFuturePurchases: boolean;
  userCreditCardsList: UserCard[];
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onInstallmentChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default function CreditCard({
  creditCard,
  payments,
  formValidation,
  installments,
  saveCreditCardForFuturePurchases,
  userCreditCardsList,
  onChange,
  onInstallmentChange,
}: CreditCardProps) {
  const [isShowBackIllustrationCard, setIsShowBackIllustrationCard] = useState(false);

  const inputRef = useRef();

  function showBackIllustrationCard() {
    setIsShowBackIllustrationCard(true);
  }

  function hideBackIllustrationCard() {
    setIsShowBackIllustrationCard(false);
  }

  useOutsideClick({
    ref: inputRef,
    handler: hideBackIllustrationCard,
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    if (name === 'creditCard.cardNumber') {
      const unmaskedValue = value.replace(/\D/g, '');
      event.target.value = unmaskedValue;
      return onChange(event);
    }

    onChange(event);
  }

  function handleInstallmentChange(event: React.ChangeEvent<HTMLSelectElement>) {
    onInstallmentChange(event);
  }

  return (
    <Flex gap={5} align="start">
      <VStack flex={1} spacing={8}>
        {!!userCreditCardsList?.length && (
          <FormControl>
            <FormLabel>Cartões salvos</FormLabel>
            <Select
              name="creditCard.cardId"
              value={creditCard?.cardId}
              onChange={handleChange}
              {...INPUT_STYLES}
            >
              <option value="" hidden>
                Selecione um cartão
              </option>
              <option value="" hidden={!creditCard?.cardId}>
                Usar um novo cartão
              </option>
              {userCreditCardsList?.map(creditCard => {
                return (
                  <option key={`${creditCard.id}-${creditCard.lastDigits}`} value={creditCard.id}>
                    {creditCard.cardHolderName.toUpperCase()} - {creditCard.lastDigits}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        )}

        {!creditCard?.cardId && (
          <>
            <FormControl isInvalid={formValidation?.cardNumber?.isInvalid}>
              <FormLabel>Número do cartão</FormLabel>
              <CreditCardInput
                name="creditCard.cardNumber"
                value={creditCard?.cardNumber}
                onChange={handleChange}
                placeholder="Digite somente números"
              />
              <FormErrorMessage>{formValidation?.cardNumber?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.cardHolderName?.isInvalid}>
              <FormLabel>Titular do cartão</FormLabel>
              <Input
                name="creditCard.cardHolderName"
                value={creditCard?.cardHolderName}
                onChange={handleChange}
                placeholder="Como no cartão"
                onInput={onlyAlphaAndSpace}
                maxLength={45}
                textTransform="uppercase"
                _placeholder={{ textTransform: 'none' }}
                {...INPUT_STYLES}
              />
              <FormErrorMessage>{formValidation?.cardHolderName?.message}</FormErrorMessage>
            </FormControl>

            <HStack align="start" w="full" spacing={5}>
              <Flex direction="column" align="flex-start" w="64%" flexShrink={0}>
                <FormLabel>Data de validade</FormLabel>
                <Flex w="full" gap={2}>
                  <FormControl isInvalid={formValidation?.month?.isInvalid}>
                    <Select
                      name="creditCard.month"
                      value={creditCard?.month}
                      onChange={handleChange}
                      {...INPUT_STYLES}
                    >
                      <option value="" hidden>
                        MM
                      </option>
                      {Array.from({ length: 12 }, (_, i) => +i).map(month => (
                        <option key={month}>{getMonth(month)}</option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formValidation?.month?.message}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={formValidation?.year?.isInvalid}>
                    <YearSelect
                      name="creditCard.year"
                      value={creditCard?.year}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formValidation?.year?.message}</FormErrorMessage>
                  </FormControl>
                </Flex>

                <FormControl
                  isInvalid={
                    !formValidation?.year?.isInvalid &&
                    !formValidation?.month?.isInvalid &&
                    formValidation?.cardExpirationDate?.isInvalid
                  }
                >
                  <FormErrorMessage>{formValidation?.cardExpirationDate?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <FormControl isInvalid={formValidation?.cardCVV?.isInvalid}>
                <FormLabel>CVV</FormLabel>
                <Input
                  ref={inputRef}
                  name="creditCard.cardCVV"
                  value={creditCard?.cardCVV}
                  onChange={handleChange}
                  onFocus={showBackIllustrationCard}
                  onBlur={hideBackIllustrationCard}
                  placeholder="XXX"
                  onInput={onlyNumber}
                  maxLength={4}
                  {...INPUT_STYLES}
                />
                <FormErrorMessage>{formValidation?.cardCVV?.message}</FormErrorMessage>
              </FormControl>
            </HStack>
          </>
        )}

        <FormControl>
          <FormLabel>Opções de parcelamento</FormLabel>
          <Select
            name="installments"
            value={installments}
            onChange={handleInstallmentChange}
            {...INPUT_STYLES}
          >
            <option value="" hidden>
              Selecione o número de parcelas
            </option>
            {payments?.map(payment => (
              <option value={payment.installment} key={payment.installment}>
                {payment.installment}x de {convertToBrazilianCurrency(payment.installmentPrice)}
              </option>
            ))}
          </Select>

          {!creditCard?.cardId && (
            <FormControl>
              <HStack mt={3} spacing={1}>
                <input
                  name="saveCreditCardForFuturePurchases"
                  checked={saveCreditCardForFuturePurchases}
                  onChange={handleChange}
                  id="saveCreditCardForFuturePurchases"
                  type="checkbox"
                />
                <FormLabel
                  htmlFor="saveCreditCardForFuturePurchases"
                  fontWeight="normal"
                  fontSize="xs !important"
                >
                  Salvar dados para futuras compras.
                </FormLabel>
              </HStack>
            </FormControl>
          )}
        </FormControl>
      </VStack>

      <Hide below="xl">
        <Flex flex={1} pr={3}>
          <CreditCardIllustration
            cardCVV={creditCard?.cardCVV}
            isShowBack={isShowBackIllustrationCard}
            cardHolderName={creditCard?.cardHolderName}
            cardNumber={creditCard?.cardNumber}
            cardExpirationDate={{ month: creditCard?.month, year: creditCard?.year }}
          />
        </Flex>
      </Hide>
    </Flex>
  );
}
