import {
  Box,
  Button,
  chakra,
  Heading,
  Image,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import RegisterBankDetails from '../../../assets/icons/redirectionIcons/Bank.svg';
import InviteUsers from '../../../assets/icons/redirectionIcons/Bust-in-silhouette.svg';
import buildYourWebsite from '../../../assets/icons/redirectionIcons/Desktop-computer.svg';
import RevenueSplit from '../../../assets/icons/redirectionIcons/Dollar-banknote.svg';
import ExploreProluno from '../../../assets/icons/redirectionIcons/Magnifying-glass-tilted-right.png';
import CreateBlog from '../../../assets/icons/redirectionIcons/Memo.svg';
import createProduct from '../../../assets/icons/redirectionIcons/Open-book.svg';
import UpMyClasses from '../../../assets/icons/redirectionIcons/Outbox-tray.svg';

const options = [
  {
    text: 'Criar um Produto',
    image: createProduct,
    path: '/courses/new',
  },
  {
    text: 'Construir o Seu Site',
    image: buildYourWebsite,
    path: '/page-builder',
  },
  {
    text: 'Subir minhas aulas',
    image: UpMyClasses,
    path: '/video-library',
  },
  {
    text: 'Convidar Usuários',
    image: InviteUsers,
    path: '/user-base',
  },
  {
    text: 'Cadastrar Dados Bancários',
    image: RegisterBankDetails,
    path: '/profile/bank-accounts',
  },
  {
    text: 'Compartilhar Receita',
    image: RevenueSplit,
    path: '/revenue-split/default-type',
  },
  {
    text: 'Criar blog',
    image: CreateBlog,
    path: '/internal-blog',
  },
  {
    text: 'Explorar a Proluno',
    image: ExploreProluno,
    path: '/home',
  },
];
function CustomRadio(props) {
  const { image, text, ...radioProps } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps } = useRadio(radioProps);

  return (
    <chakra.label {...htmlProps} cursor="pointer" p={1}>
      <input {...getInputProps({})} hidden />
      <Stack
        {...getCheckboxProps()}
        direction="row"
        justifyContent="center"
        alignItems="center"
        border="2px"
        borderColor={state.isChecked ? '#EB7129' : 'gray.200'}
        w={{ base: 155, md: 200, lg: 200 }}
        h={{ base: 120, md: 100, lg: 100 }}
        rounded={6}
      >
        <Stack direction="column" justifyContent="center" alignItems="center" p={1}>
          <Image src={image} alt={`icon-${text}`} />
          <Text fontWeight={700} textAlign="center">
            {text}
          </Text>
        </Stack>
      </Stack>
    </chakra.label>
  );
}

export function Redirection() {
  const [path, setPath] = useState(null);

  const handleChange = (value: string) => {
    setPath(value);
  };

  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: '',
    onChange: handleChange,
  });

  return (
    <Stack direction="column" spacing={5} {...getRootProps()}>
      <Stack
        direction="column"
        textAlign={{ base: 'center', md: 'start', lg: 'start' }}
        spacing={0}
        my={2}
      >
        <Heading fontSize="3xl" fontWeight={700} color="#EB7129">
          Bem-vindo a Proluno!
        </Heading>

        <Stack
          direction="column"
          spacing={5}
          alignItems={{ base: 'center', md: 'flex-start', lg: 'flex-start' }}
          textAlign={{ base: 'center', md: 'start', lg: 'start' }}
        >
          <Text fontSize="md" fontWeight={400} color="#20212380">
            Seu site foi criado com sucesso.{' '}
            <Text as="span" color="#202123">
              👏🏼
            </Text>
          </Text>
          <Text fontSize="md" fontWeight={600} color="#20212380">
            Selecione por onde você deseja começar:
          </Text>
        </Stack>
      </Stack>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={{ base: 'center', md: 'flex-start', lg: 'flex-start' }}
        alignItems="center"
        w={{ base: '100%', md: 630, lg: 630 }}
      >
        {options.map(option => {
          return (
            <CustomRadio
              key={option.text}
              image={option.image}
              text={option.text}
              {...getRadioProps({ value: option.path })}
            />
          );
        })}
      </Box>

      <Box paddingTop={{ base: 15, md: 15, lg: 50 }} paddingBottom={{ base: 5, md: 5, lg: 10 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ base: 'center', md: 'end', lg: 'end' }}
        >
          <Link
            to={path}
            style={{
              display: 'flex',
              margin: 0,
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              isDisabled={!path}
              colorScheme="orange"
              w={{ base: '100%', md: 'auto', lg: 'auto' }}
              rightIcon={<AiOutlineRight size="12px" />}
              size="sm"
            >
              Vamos lá
            </Button>
          </Link>
        </Stack>
      </Box>
    </Stack>
  );
}
