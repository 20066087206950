import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Hide,
  Input,
  Link,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import dayjs from 'dayjs';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IUserResponse, UserFormForPurchaseType } from '..';
import ZipCodeAPI from '../../../api/ZipCode';
import { getDDI } from '../../../components/CountrySelect';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../../helpers/ga-tag-custom';
import useHandleChange, { TargetType, onlyNumber } from '../../../hooks/useHandleChange';
import useHandleSubmit from '../../../hooks/useHandleSubmit';
import { ResponseJSON } from '../../../utils/CreateToastAlert';
import scrollToInvalidInput from '../../../utils/scrollToInvalidInput';
import DocumentInput from '../Components/DocumentInput';
import Loading from '../Components/Loading';
import ZipCodeInput from '../Components/ZipcodeInput';
import { IPayment } from '../Payment/types';
import useLocales from '../Payment/useLocales';
import findState from '../Payment/utils/findState';
import getDocumentType from '../Payment/utils/getDocumentType';
import { FORM_STYLES, INPUT_STYLES } from '../constants';
import schemaValidator, { RegisterSchemaType } from './schemaValidator';

import {
  FILLED_PERSONAL_INFORMATION_TIME,
  SHOPPING_CART,
  TOKEN_PURCHASE,
} from '../../../helpers/LocalStorageHelper';

import useQuery from '../../../hooks/useQuery';
import useShoppingCart, {
  IPayment as IInstallment,
  IProductCart,
  IShoppingCart,
} from '../../../hooks/useShoppingCart';

interface IProps {
  ddi: string;
  token: string;
  country: string;
  isInternational: boolean;
  userData: IUserResponse;
  userFormForPurchase: UserFormForPurchaseType[];
  products: IProductCart[];
  payments: IInstallment[];
  shoppingCartData: IShoppingCart;
  setPersonalData: (form: Partial<RegisterSchemaType>) => void;
}
interface ICartAbandonment {
  name: string;
  phone: string;
  email: string;
  doc: string;
  totalPrice: number;
  abandonedCheckoutUrl: string;
  paymentMethodType: string;
  lineItems: {
    product_name: string;
    quantity: number;
    price: number;
  }[];
}

type CallbackResponseType = {
  data: {
    data: { fullName: string; phoneNumber: string; role: string; token: string };
  };
};

function saveUserInfoToLocalStorage(response, tokenUser) {
  if (!tokenUser) {
    const authenticationData = response?.data?.data;
    const { token } = authenticationData;
    localStorage.setItem(TOKEN_PURCHASE, token);
  }
}

export default function Register({
  ddi,
  token,
  country,
  isInternational,
  userData,
  userFormForPurchase,
  products,
  payments,
  shoppingCartData,
  setPersonalData,
}: IProps) {
  const toast = useToast();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { productId }: { productId: string } = useParams();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const addressVisible = userFormForPurchase?.includes('END');
  const instagramVisible = userFormForPurchase?.includes('INS');
  const phoneVisible = userFormForPurchase?.includes('TEL');

  const [isFetchingZipCode, setIsLoadingZipCode] = useState(false);
  const [isAddressFetchedByAPI, setIsAddressFetchedByAPI] = useState(false);

  const { getShoppingCartFromLocalStorage } = useShoppingCart();
  const shoppingCartInLocalStorage = getShoppingCartFromLocalStorage();
  const methodPaymentTypeInLocalStorage =
    shoppingCartInLocalStorage?.methodPaymentType as IPayment['methodPaymentType'];

  const totalOnePayment: IInstallment = payments?.find(payment => payment.installment === 1);

  function unmask(value: string) {
    return value.replace(/\D/g, '');
  }

  const redirectToPaymentPage = useCallback(async () => {
    urlSearchParams.set('added-to-cart-authentication', 'true');

    history.push({
      pathname: `/launch-checkout/${productId}/payment`,
      search: urlSearchParams.toString(),
    });
  }, [history, productId, urlSearchParams]);

  const { form, setForm, handleChange } = useHandleChange<Partial<RegisterSchemaType>>({
    fullName: query.get('name') ?? '',
    document: {
      type: getDocumentType(country, ''),
      number: '',
    },
    email: query.get('email') ?? '',
    confirmEmail: '',
    instagram: query.get('instagram') ?? '',
    ddi: query.get('ddi') ?? '55',
    ddd: query.get('ddd') ?? '',
    phone: query.get('phone') ?? '',
    country: 'br',
    address: {
      country: 'br',
      zipCode: '',
      streetAddress: '',
      streetNumber: '',
      neighborhood: '',
      state: '',
      city: '',
      complementary: '',
      alternateAddress: '',
    },
  });

  const movedToPaymentEvent = useCallback(async () => {
    const idsGTM = googleTagManagerIds();

    let cartProducts = [];

    if (shoppingCartData?.productCart?.length) {
      cartProducts = shoppingCartData?.productCart;

      for (const id of idsGTM) {
        newEventGTM({
          id,
          data: {
            currency: 'BRL',
            event: 'moved_to_payment',
            name: form.fullName,
            email: form.email,
            phone: form.phone ?? null,
            zip_code: form.address?.zipCode ?? null,
            items: cartProducts.map(product => ({
              item_id: product.productId,
              item_name: product.productName,
              value: product.productPrice,
              quantity: 1,
            })),
            total_value: cartProducts.reduce((total, product) => total + product.productPrice, 0),
          },
        });

        removeEventGTM(id, 'moved_to_payment');
      }
    }
  }, [shoppingCartData?.productCart, form.fullName, form.email, form.phone, form.address?.zipCode]);

  const sendInfoCartAbandonmentPayload: ICartAbandonment = {
    name: form.fullName,
    phone: form.phone,
    email: form.email,
    abandonedCheckoutUrl: window.location.hostname + window.location.pathname,
    doc: form.document.number,
    totalPrice: totalOnePayment?.total,
    paymentMethodType: methodPaymentTypeInLocalStorage,
    lineItems: products?.map(product => {
      return {
        product_name: product.productName,
        quantity: product.productAmount,
        price: product.productPrice,
      };
    }),
  };

  const { handleSubmit: sendInfoCartAbandonment } = useHandleSubmit({
    data: sendInfoCartAbandonmentPayload,
    method: 'post',
    url: '/purchases/shopping-cart-abandonment',
    onSuccess: {
      callback() {
        localStorage.removeItem(FILLED_PERSONAL_INFORMATION_TIME);
      },
    },
  });

  function filledInformation() {
    localStorage.setItem(
      FILLED_PERSONAL_INFORMATION_TIME,
      dayjs().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    );
  }

  const cartAbandonment = useCallback(async () => {
    const informationTime = localStorage.getItem(FILLED_PERSONAL_INFORMATION_TIME);

    const existInformationTimeValue = informationTime !== '';
    const existFullNameValue = form.fullName !== '';
    const existEmailValue = form.email !== '';
    const existPhoneNumberValue = form.phone !== '';

    const shouldSendInfo =
      existInformationTimeValue && existFullNameValue && (existEmailValue || existPhoneNumberValue);

    if (shouldSendInfo) {
      const sendInformation = dayjs(informationTime).isBefore(
        dayjs().format('YYYY-MM-DD HH:mm:ss')
      );

      if (sendInformation) {
        sendInfoCartAbandonment();
      }
    }
  }, [form.email, form.fullName, form.phone, sendInfoCartAbandonment]);

  useEffect(() => {
    const handleBeforeUnload = async () => {
      // Faça a requisição ao backend aqui
      try {
        if ((form.fullName && form.email) || (form.fullName && form.phone)) {
          sendInfoCartAbandonment();
          localStorage.removeItem(FILLED_PERSONAL_INFORMATION_TIME);
        }
        localStorage.removeItem(SHOPPING_CART);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Erro ao fazer a requisição', error);
      }
    };

    // Adiciona o listener do evento beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove o listener ao desmontar o componente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [form.email, form.fullName, form.phone, sendInfoCartAbandonment]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      cartAbandonment();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [cartAbandonment]);

  useEffect(() => {
    if ((form.fullName && form.email) || (form.fullName && form.phone)) {
      filledInformation();
    }
  }, [form.email, form.fullName, form.phone]);

  useEffect(() => {
    if (form) {
      setPersonalData(form);
    }
  }, [form, setPersonalData]);

  useEffect(() => {
    if (userData) {
      setForm(prevForm => ({
        ...prevForm,
        fullName: userData?.fullName || '',
        document: {
          type: country ? getDocumentType(country, userData?.documentNumber) : 'other',
          number: userData?.documentNumber || '',
        },
        email: userData?.email || '',
        confirmEmail: '',
        ddi: userData?.ddi ?? ddi,
        ddd: userData?.ddd || '',
        phone: userData?.phone || '',
        instagram: userData?.instagram || '',
        country: userData?.address?.country || 'br',
        address: {
          country: userData?.address?.country || 'br',
          zipCode: userData?.address?.zipCode || '',
          streetAddress: userData?.address?.streetAddress || '',
          streetNumber: userData?.address?.streetNumber || '',
          neighborhood: userData?.address?.neighborhood || '',
          state: userData?.address?.state || '',
          city: userData?.address?.city || '',
          complementary: userData?.address?.complementary || '',
          alternateAddress: userData?.address?.alternateAddress || '',
        },
      }));
    }
  }, [ddi, country, setForm, userData]);

  const authenticated = token ? true : false;

  const payload = {
    ...form,
    document: {
      type: getDocumentType(country, form.document.number),
      number: form.document.number,
    },
    phone: unmask(form.phone),
    country: country,
    ddi: getDDI(country),
    address: {
      ...form.address,
      country: country,
      state: form?.address?.state.toLowerCase(),
    },
  };

  if (isInternational) {
    delete payload.ddd;
  }

  if (!addressVisible) {
    delete payload.address;
  }

  if (!phoneVisible) {
    delete payload.phone;
    delete payload.ddd;
    delete payload.ddi;
  }

  if (!instagramVisible) {
    delete payload.instagram;
  }

  const { handleSubmit, formValidation, isLoading } = useHandleSubmit({
    data: payload,
    schemaValidator: schemaValidator,
    method: 'post',
    url: '/users/checkout-launch-user-registration',
    authenticated,
    onSuccess: {
      callback(response: CallbackResponseType) {
        movedToPaymentEvent();
        redirectToPaymentPage();
        saveUserInfoToLocalStorage(response, token);
      },
    },
  });

  useEffect(() => {
    if (country && addressVisible) {
      setForm(prevForm => ({
        ...prevForm,
        address: {
          ...prevForm.address,
          country: country,
        },
      }));
    }
  }, [addressVisible, country, setForm]);

  async function getAddressByZipCode(zipCode: string) {
    try {
      setIsLoadingZipCode(true);
      const { data: response } = await ZipCodeAPI.index(zipCode);

      if (response) {
        setForm({
          ...form,
          address: {
            ...form.address,
            state: response.state,
            city: response.city,
            neighborhood: response.neighborhood,
            streetAddress: response.street,
            zipCode,
            country: 'br',
          },
        });
      }

      if (formValidation?.address?.zipCode) {
        formValidation.address.zipCode.isInvalid = false;
        formValidation.address.zipCode.message = '';
      }
    } catch (error) {
      toast({
        title: 'Não foi possível localizar seu endereço...',
        description: 'Por favor, preencha todos os campos manualmente.',
        status: 'info',
        position: 'bottom-right',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAddressFetchedByAPI(true);
      setIsLoadingZipCode(false);
    }
  }

  const updateField = useCallback(
    (name: string, value: string) => {
      const event = {
        target: {
          name,
          value,
        },
      };

      handleChange(event as unknown as ChangeEvent<TargetType>);
    },
    [handleChange]
  );

  function handleZipCodeChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value.length === 8) getAddressByZipCode(value);
    updateField('address.zipCode', value);
  }

  function handleStateChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      address: {
        ...prevForm.address,
        state: value,
      },
    }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function handleInstagramChange(event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;

    const valueWithoutSpaces = inputValue.replace(/\s/g, '');

    setForm({
      ...form,
      instagram: valueWithoutSpaces,
    });
  }

  const handleDocumentNumberChange = useCallback(
    (value: string) => {
      updateField('document.number', value);

      const type = getDocumentType(country, value);

      setForm(prevForm => ({
        ...prevForm,
        document: {
          type,
          number: value,
        },
      }));
    },
    [country, setForm, updateField]
  );

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      scrollToInvalidInput();
      await handleSubmit<ResponseJSON<RegisterSchemaType>>();
    },
    [handleSubmit]
  );

  const { cities, states } = useLocales(form?.address?.state);
  const hasZipCodeRegistered = userData?.address?.zipCode;
  const hasZipCodeValidLength = form?.address?.zipCode?.length === 8;
  const isAddressFormShow =
    !isInternational && hasZipCodeValidLength && (hasZipCodeRegistered || isAddressFetchedByAPI);

  return (
    <Flex
      align="center"
      direction="column"
      w="full"
      as="form"
      onSubmit={onSubmit}
      autoComplete="off"
      noValidate
    >
      <VStack w="full" sx={FORM_STYLES} spacing={{ base: 8, xl: 7 }} mt={4}>
        <Stack w="full" direction="row">
          <FormControl isInvalid={formValidation?.fullName.isInvalid}>
            <FormLabel>Nome</FormLabel>
            <Input
              name="fullName"
              value={form.fullName}
              onChange={handleChange}
              placeholder="Digite seu nome completo"
              autoComplete="new-name"
              maxLength={50}
              {...INPUT_STYLES}
            />
            <FormErrorMessage>{formValidation?.fullName.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.document?.number.isInvalid}>
            <FormLabel>{isInternational ? 'Documento' : 'CPF/CNPJ'}</FormLabel>
            <DocumentInput
              value={form?.document?.number}
              onChange={handleDocumentNumberChange}
              isInternational={isInternational}
              {...INPUT_STYLES}
            />

            <FormErrorMessage>{formValidation?.document?.number.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack w="full" direction="row">
          <FormControl isInvalid={formValidation?.email.isInvalid}>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="Digite seu melhor email"
              type="email"
              isDisabled={!!token}
              autoComplete="new-email"
              maxLength={66}
              {...INPUT_STYLES}
            />
            <FormErrorMessage>{formValidation?.email.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.confirmEmail.isInvalid}>
            <FormLabel>Confirmar e-mail</FormLabel>
            <Input
              name="confirmEmail"
              value={form.confirmEmail}
              onChange={handleChange}
              placeholder="Digite seu email novamente"
              type="confirmEmail"
              maxLength={256}
              {...INPUT_STYLES}
            />
            <FormErrorMessage>{formValidation?.confirmEmail.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack w="full" direction="row">
          <VStack w="150px" align="start" hidden={!phoneVisible || isInternational}>
            <FormControl isInvalid={formValidation?.ddd?.isInvalid && phoneVisible}>
              <FormLabel>DDD</FormLabel>
              <Input
                name="ddd"
                value={form.ddd}
                onChange={handleChange}
                placeholder="Digite seu DDD"
                maxLength={2}
                onInput={onlyNumber}
                {...INPUT_STYLES}
              />

              <FormErrorMessage>{formValidation?.ddd?.message}</FormErrorMessage>
            </FormControl>
          </VStack>

          <VStack w="full" align="start" hidden={!phoneVisible}>
            <FormControl isInvalid={formValidation?.phone?.isInvalid && phoneVisible}>
              <FormLabel>Telefone</FormLabel>

              <Input
                name="phone"
                type="tel"
                value={form.phone}
                onChange={handleChange}
                placeholder="Digite seu telefone"
                autoComplete="new-telephone"
                maxLength={20}
                onInput={onlyNumber}
                {...INPUT_STYLES}
              />

              <FormErrorMessage>{formValidation?.phone?.message}</FormErrorMessage>
            </FormControl>
          </VStack>

          <FormControl
            hidden={!instagramVisible}
            isInvalid={formValidation?.instagram?.isInvalid && instagramVisible}
          >
            <FormLabel>Instagram</FormLabel>
            <Input
              name="instagram"
              value={form.instagram}
              onChange={handleInstagramChange}
              placeholder="Digite seu instagram"
              type="instagram"
              maxLength={30}
              {...INPUT_STYLES}
            />
            <FormErrorMessage>{formValidation?.instagram?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        {!isInternational && addressVisible ? (
          <>
            <Stack width="full">
              <FormControl
                isInvalid={formValidation?.address?.zipCode?.isInvalid && addressVisible}
              >
                <FormLabel>CEP</FormLabel>
                <ZipCodeInput
                  name="address.zipCode"
                  value={form?.address?.zipCode}
                  onChange={handleZipCodeChange}
                  {...INPUT_STYLES}
                />

                <FormErrorMessage>{formValidation?.address?.zipCode?.message}</FormErrorMessage>
              </FormControl>

              {!isAddressFormShow && (
                <Link
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  color=" #759AED"
                  textDecoration="underline"
                  isExternal
                >
                  Não sabe o CEP e deseja preencher o endereço?
                </Link>
              )}
            </Stack>

            {isAddressFormShow && (
              <VStack spacing={8}>
                <Stack spacing={5} direction={{ base: 'column', md: 'row', xl: 'row' }}>
                  <FormControl
                    isInvalid={formValidation?.address?.streetAddress?.isInvalid && addressVisible}
                    w="full"
                  >
                    <FormLabel>Rua</FormLabel>
                    <Input
                      name="address.streetAddress"
                      value={form?.address?.streetAddress}
                      onChange={handleChange}
                      {...INPUT_STYLES}
                    />
                    <FormErrorMessage>
                      {formValidation?.address?.streetAddress?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <HStack spacing={5} w="full" align="start">
                    <FormControl
                      isInvalid={formValidation?.address?.streetNumber?.isInvalid && addressVisible}
                      maxWidth="31%"
                    >
                      <FormLabel>Nº</FormLabel>
                      <Input
                        name="address.streetNumber"
                        value={form.address.streetNumber}
                        onChange={handleChange}
                        onInput={onlyNumber}
                        inputMode="numeric"
                        placeholder="XXX"
                        {...INPUT_STYLES}
                      />
                      <FormErrorMessage>
                        {formValidation?.address?.streetNumber?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <Hide above="xl">
                      <FormControl flex={1}>
                        <FormLabel>Complemento</FormLabel>
                        <Input
                          name="address.complementary"
                          value={form.address.complementary}
                          onChange={handleChange}
                          placeholder="XXX"
                          {...INPUT_STYLES}
                        />
                      </FormControl>
                    </Hide>

                    <Hide below="xl">
                      <FormControl
                        isInvalid={
                          formValidation?.address?.neighborhood?.isInvalid && addressVisible
                        }
                      >
                        <FormLabel>Bairro</FormLabel>
                        <Input
                          name="address.neighborhood"
                          value={form.address.neighborhood}
                          onChange={handleChange}
                          {...INPUT_STYLES}
                        />
                        <FormErrorMessage>
                          {formValidation?.address?.neighborhood?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Hide>
                  </HStack>
                </Stack>

                <Stack spacing={5} direction={{ base: 'column', md: 'row', xl: 'row' }} w="full">
                  <Hide below="xl">
                    <FormControl flex={1.125}>
                      <FormLabel>Complemento</FormLabel>
                      <Input placeholder="XXX" {...INPUT_STYLES} />
                    </FormControl>
                  </Hide>

                  <Hide above="xl">
                    <FormControl
                      isInvalid={formValidation?.address?.neighborhood?.isInvalid && addressVisible}
                      flex={1}
                    >
                      <FormLabel>Bairro</FormLabel>
                      <Input
                        name="address.neighborhood"
                        value={form.address.neighborhood}
                        onChange={handleChange}
                        {...INPUT_STYLES}
                      />
                      <FormErrorMessage>
                        {formValidation?.address?.neighborhood?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Hide>

                  <FormControl
                    isInvalid={formValidation?.address?.city?.isInvalid && addressVisible}
                    flex={1}
                  >
                    <FormLabel>Cidade</FormLabel>
                    <Select
                      name="address.city"
                      value={form?.address?.city}
                      onChange={handleChange}
                      {...INPUT_STYLES}
                    >
                      <option value="" hidden>
                        Selecione
                      </option>
                      {cities.map(city => (
                        <option key={city.id}>{city.nome}</option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formValidation?.address?.city?.message}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={formValidation?.address?.state?.isInvalid && addressVisible}
                    flex={1}
                  >
                    <FormLabel>Estado</FormLabel>
                    <Select
                      name="address.state"
                      value={findState(form?.address?.state, states)}
                      onChange={handleStateChange}
                      {...INPUT_STYLES}
                    >
                      <option value="" hidden>
                        Selecione
                      </option>
                      {states.map(state => (
                        <option key={state.id} value={state.sigla}>
                          {state.nome}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formValidation?.address?.state?.message}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </VStack>
            )}
          </>
        ) : (
          <VStack w="full" spacing={8}>
            <FormControl
              isInvalid={formValidation?.address?.alternateAddress?.isInvalid && addressVisible}
              hidden={!addressVisible}
            >
              <FormControl isInvalid={formValidation?.document?.number.isInvalid}>
                <FormLabel>Endereço</FormLabel>

                <Input
                  name="address.alternateAddress"
                  value={form.address?.alternateAddress}
                  onChange={handleChange}
                  {...INPUT_STYLES}
                />

                <FormErrorMessage>
                  {formValidation?.address?.alternateAddress.message}
                </FormErrorMessage>
              </FormControl>

              {!form.address?.alternateAddress &&
                !formValidation?.address?.alternateAddress?.isInvalid && (
                  <FormHelperText>
                    Você pode digitar letras, número e caracteres especiais.
                  </FormHelperText>
                )}
            </FormControl>
          </VStack>
        )}
      </VStack>

      <VStack w="full" spacing={5} mt={8}>
        <Button
          w="full"
          type="submit"
          colorScheme="green"
          px="4.5rem"
          fontSize="md"
          isLoading={isLoading}
          fontWeight="bold"
          bg="#7fcf69"
          _hover={{
            bgColor: '#66c64c',
            borderColor: '#5ec342',
          }}
        >
          Próximo passo
        </Button>

        <Text
          width="full"
          textAlign="start"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="normal"
        >
          Ao continuar, você concorda com estes{' '}
          <Text as="span" color="#EB7129">
            Termos de Serviços
          </Text>{' '}
          e{' '}
          <Text as="span" color="#EB7129">
            Políticas de Privacidade.
          </Text>{' '}
        </Text>
      </VStack>

      <Loading isLoading={isFetchingZipCode} />
    </Flex>
  );
}
