import './styles.css';

import { Box, Button, Flex, FormControl, Select, Stack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom';

import { nanoid } from 'nanoid';
import { platformsWithCopyModules } from '../../../../..';
import ContentsAPI from '../../../../../api/Content';
import CourseAPI from '../../../../../api/Course';
import { default as Module, default as ModuleAPI } from '../../../../../api/Module';
import Toast from '../../../../../components/Toast';
import { useCourse } from '../../../../../contexts/CourseContext';
import VideoLibraryProvider from '../../../../../contexts/VideoLibraryContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
// import ModulesList from '../ModulesList';
import { lazy } from 'react';
import SuspenseDefault from '../../../../../components/SuspenseDefault';
import AudioForm from './AudioForm';
import DownloadForm from './DownloadForm';
import ModuleForm from './ModuleForm';
import ModuleNoteSelected from './ModuleNotSelected';
import MultimediaAddForm from './MultimediaAddForm';
import PdfForm from './PdfForm';
import { AddOrEditQuestionLibrary } from './QuestionLibrary/AddOrEditQuestionLibrary';
import QuestionsForm from './QuestionsForm';
import TaskForm from './TasksForm';
import TextForm from './TextForm';
import reorder from './utils/reorder';
import VideoForm from './VideoForm';
import YouTubeLiveForm from './YouTubeLiveForm';
import ZoomLiveForm from './ZoomLiveForm';
import { InsertQuestions } from './QuestionLibrary/InsertQuestions';

const ModulesList = lazy(() => import('../ModulesList'));

const ADD_WITH_CURRENT_MODULE_ID = 1;

const hostname = window.location.hostname;

export default function ModulesManager() {
  const { id: courseId } = useParams();

  const history = useHistory();

  const {
    course,
    setCourse,
    isAddingModule,
    setIsAddingModule,
    loading,
    onChangeCourse,
    onReorderModules,
  } = useCourse();

  const [courses, setCourses] = useState([]);

  const [selectModules, setSelectModules] = useState([]);

  const [reorderedModules, setReorderedModules] = useState([]);
  const [reorderedContents, setReorderedContents] = useState([]);

  const [reorderType, setReorderType] = useState('');
  const [moduleToUpdate, setModuleToUpdate] = useState('');

  const [copyProductId, setCopyProductId] = useState(null);
  const [copyModuleId, setCopyModuleId] = useState(null);

  const [isCopingModule, setIsCopingModule] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCourse = useCallback(async () => {
    try {
      const { data: response } = await CourseAPI.getAllCourses();

      if (response) {
        setCourses(response);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, []);

  function toggleCopyModule() {
    setIsCopingModule(prevState => !prevState);
  }

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  async function getModulesByCourseFiltered(courseId) {
    try {
      const { data: response } = await Module.getModules(courseId);
      if (response) {
        setSelectModules(response);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  const handleSubmit = useCallback(async () => {
    if (reorderType === 'MODULES') {
      if (!reorderedModules.length) return;

      const payload = reorderedModules.map(({ id, order }) => ({
        moduleId: id,
        order,
      }));
      const { updateModulesOrder } = ModuleAPI;

      try {
        await updateModulesOrder(courseId, payload);
        Toast('Módulos ordenados com sucesso', 'success');
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setReorderType('');
      }

      return;
    }

    if (reorderType === 'CONTENTS') {
      if (!reorderedContents.length) return;

      const payload = reorderedContents.map(({ id, order }) => ({
        contentId: id,
        order,
      }));

      const { updateContentsOrder } = ContentsAPI;

      try {
        await updateContentsOrder(courseId, moduleToUpdate, payload);
        Toast('Conteúdos ordenados com sucesso', 'success');
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setReorderType('');
      }
    }
  }, [courseId, moduleToUpdate, reorderType, reorderedContents, reorderedModules]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSubmit(course.modules);
    }, 1000);

    return () => clearTimeout(timer);
  }, [course.modules, handleSubmit]);

  function addModule() {
    if (course.modules?.length) {
      const ids = course.modules.map(module => module.id);
      const maxId = course.modules.length && Math.max(...ids);
      const tempId = maxId + ADD_WITH_CURRENT_MODULE_ID;

      onChangeCourse({
        ...course,
        modules: [...course.modules, { id: tempId, name: 'Novo Módulo', contents: [] }],
      });
    } else {
      onChangeCourse({
        ...course,
        modules: [{ id: ADD_WITH_CURRENT_MODULE_ID, name: 'Novo Módulo', contents: [] }],
      });
    }

    setIsAddingModule(true);
  }

  function handleDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    if (result.type === 'MODULES') {
      setReorderType('MODULES');
      const updatedModules = reorder(course.modules, result.source.index, result.destination.index);
      onReorderModules(updatedModules);
      setReorderedModules(updatedModules);
      return;
    }

    setReorderType('CONTENTS');
    setModuleToUpdate(course.modules[Number(result.type)].id);

    const updatedContents = reorder(
      course.modules[Number(result.type)].contents,
      result.source.index,
      result.destination.index
    );

    const updatedModules = course.modules.map((module, index) => {
      if (index === Number(result.type)) {
        return (module = { ...module, contents: updatedContents });
      }

      return module;
    });

    setReorderedContents(updatedContents);
    onReorderModules(updatedModules);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === 'product') {
      setCopyProductId(value);
      getModulesByCourseFiltered(parseInt(value));
    }

    if (name === 'module') {
      setCopyModuleId(value);
    }
  }

  async function handleCopyModule() {
    try {
      setIsLoading(true);

      const payload = { courseOrigin: copyProductId, moduleOrigin: copyModuleId };

      const { data: moduleCopyData } = await ModuleAPI.copy(courseId, payload);

      if (moduleCopyData) {
        const newModules = {
          ...course,
          modules: course?.modules ? [...course?.modules, moduleCopyData] : [moduleCopyData],
        };

        setCourse(newModules);

        Toast('Módulo copiado com sucesso!', 'success');

        setIsCopingModule(false);

        history.push(
          `/courses/${courseId}/course-manager/course/modules/${moduleCopyData?.id}/edit`
        );
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box display="flex" flexWrap={{ base: 'wrap', md: 'nowrap' }} h="full" bg="white">
      <div className="col-lg-4 border-right border-gray h-100 px-0 px-lg-5 mb-5">
        <SuspenseDefault element={<ModulesList onDragEnd={handleDragEnd} products={courses} />} />

        {isCopingModule && (
          <Stack
            marginTop="1.25rem"
            paddingX="5"
            paddingY="6"
            border="1px solid #202123"
            borderRadius="0.625rem"
          >
            <Flex justifyContent="center">
              <Text fontSize="sm" fontWeight="medium" color="dark.500">
                Copiar módulo de:
              </Text>
            </Flex>

            <FormControl paddingY="2.5" display="grid" gap="2.5">
              <Select
                id="product"
                name="product"
                size="md"
                onChange={handleChange}
                value={copyProductId}
                color="rgba(0, 0, 0, 0.49)"
                background="#F6F5F5"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="rgba(174, 173, 173, 1)"
                borderRadius="0"
                focusBorderColor="primary.500"
                _hover={{
                  borderColor: 'rgba(174, 173, 173, 1)',
                }}
              >
                <option value="" hidden>
                  Nome do curso
                </option>
                {courses &&
                  courses.map(product => (
                    <option key={nanoid()} value={product.id}>
                      {product.name}
                    </option>
                  ))}
              </Select>

              <Select
                id="module"
                name="module"
                size="md"
                placeholder="Nome do módulo"
                onChange={handleChange}
                value={copyModuleId}
                color="rgba(0, 0, 0, 0.49)"
                background="#F6F5F5"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="rgba(174, 173, 173, 1)"
                borderRadius="0"
                focusBorderColor="primary.500"
                _hover={{
                  borderColor: 'rgba(174, 173, 173, 1)',
                }}
              >
                {selectModules &&
                  selectModules.map(module => (
                    <option key={nanoid()} value={module.id}>
                      {module.name}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <Flex justifyContent="flex-end" gap="3">
              <Button
                size="xs"
                variant="solid"
                border="1px solid rgba(32, 33, 35, 0.25)"
                onClick={() => {
                  toggleCopyModule();
                }}
              >
                Cancelar
              </Button>
              <Button
                size="xs"
                background="#eb7129"
                color="white"
                _hover={{
                  background: '#eb712980',
                }}
                onClick={() => {
                  handleCopyModule();
                }}
                isDisabled={isLoading}
                isLoading={isLoading}
              >
                Copiar
              </Button>
            </Flex>
          </Stack>
        )}

        {!isAddingModule && !loading && (
          <Box display="flex" alignItems="center" gap="4" marginTop="10">
            <Button
              as={Link}
              size="sm"
              background="#eb7129"
              color="white"
              _hover={{
                background: '#eb712980',
              }}
              width="100%"
              to={`/courses/${courseId}/course-manager/course/modules/new`}
              onClick={addModule}
            >
              Adicionar módulo
            </Button>
            <Button
              hidden={!platformsWithCopyModules.includes(hostname)}
              size="sm"
              width="100%"
              background="rgba(15, 16, 17, 0.75)"
              color="white"
              onClick={toggleCopyModule}
              _hover={{
                background: 'rgba(2, 2, 2, 0.75)',
              }}
            >
              Copiar módulo de
            </Button>
          </Box>
        )}
      </div>

      <div id="sectionContent" className="col-lg pt-4 animate-bottom">
        <Switch>
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/new"
            component={ModuleForm}
          />
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/edit"
            component={() => <ModuleForm onGetModules={getCourse}></ModuleForm>}
          />
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-download"
            component={DownloadForm}
          />
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-download"
            component={DownloadForm}
          />
          <Route
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-pdf"
            component={PdfForm}
          />
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-pdf"
            component={PdfForm}
          />

          <Route
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-audio"
            component={AudioForm}
          />
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-audio"
            component={AudioForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-multimedia"
            component={MultimediaAddForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-multimedia"
            component={MultimediaAddForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-text"
            component={TextForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-text"
            component={TextForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-question"
            component={QuestionsForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-question"
            component={QuestionsForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-video"
            component={() => (
              <VideoLibraryProvider>
                <VideoForm />
              </VideoLibraryProvider>
            )}
          />
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-video"
            component={() => (
              <VideoLibraryProvider>
                <VideoForm />
              </VideoLibraryProvider>
            )}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-live-zoom-content"
            component={ZoomLiveForm}
          />
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-live-zoom-content"
            component={ZoomLiveForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-live-youtube-content"
            component={YouTubeLiveForm}
          />
          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-live-youtube-content"
            component={YouTubeLiveForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-task"
            component={TaskForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-task"
            component={TaskForm}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/new-question-library"
            component={AddOrEditQuestionLibrary}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/edit-question-library"
            component={AddOrEditQuestionLibrary}
          />

          <Route
            exact
            path="/courses/:id/course-manager/course/modules/:moduleId/contents/:contentId/insert-questions"
            component={InsertQuestions}
          />

          <Route path="*" component={ModuleNoteSelected} />
        </Switch>
      </div>
    </Box>
  );
}
