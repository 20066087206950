import { useToast } from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { axiosBackend } from '../../../../../api/Configuration';
import useHandleChange from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../utils/scrollToInvalidInput';
import { JoiSchemasType } from '../../../../../validation/EntitySchema';
import { Webhook, WebhookView } from '../types';

interface useInstructorFormProps {
  url?: string;
  method?: 'post' | 'patch';
  data?: WebhookView;
  successMessage?: string;
  schemaValidator: JoiSchemasType<Partial<any>>;
}

interface SelectedProduct {
  value: number;
  label: string;
}

export default function useWebhookForm({
  url,
  method,
  data,
  successMessage,
  schemaValidator,
}: useInstructorFormProps) {
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct | null>({
    value: data?.product.id ?? 0,
    label: data?.product.name ?? 'TODOS',
  });

  const [products, setProducts] = useState([]);

  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    async function getProducts() {
      const { data: productsResponse } = await axiosBackend().get(
        '/courses/list?status=ATIVO_E_NLISTADO'
      );

      if (!productsResponse) {
        toast({
          status: 'error',
          title: 'Erro ao tentar cadastrar Webhook',
          description: 'Você não possui produtos com os status: Ativo ou Não listado',
        });

        return history.push('/integrations/webhook');
      }

      const mappedProducts = [
        {
          value: 0,
          label: 'TODOS',
        },
        ...(productsResponse?.data?.map(product => ({
          value: product.id,
          label: product.name,
        })) || []),
      ];

      setProducts(mappedProducts);
    }

    getProducts();
  }, [history, toast]);

  const {
    form,
    setForm,
    onChanged: hasChange,
    handleChange,
    setOnChanged: setHasChange,
    handleCancel,
  } = useHandleChange<Webhook>(
    method === 'patch'
      ? {
          title: data.webhookName,
          events: data.events,
          courseId: data?.product.id,
          webhookUrl: data?.webhookUrl,
        }
      : { courseId: 0, events: [], title: '', webhookUrl: '' }
  );

  function handleEventsSelect(events: string[]) {
    setHasChange(true);

    setForm(prevForm => ({
      ...prevForm,
      events,
    }));
  }

  function handleSelectProduct({ value, label }) {
    setHasChange(true);
    setSelectedProduct({ value, label });

    setForm(prevForm => ({
      ...prevForm,
      courseId: value,
    }));
  }

  function onCancel() {
    if (!hasChange) {
      return history.push('/integrations/webhook');
    }

    handleCancel();
  }

  const payload = {
    ...form,
    courseId: !form.courseId ? null : form.courseId,
    ...(method === 'patch' && { status: data.status }),
  };

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    schemaValidator,
    url,
    method,
    authenticated: true,
    onSuccess: {
      message: successMessage,
      callback: () => history.push('/integrations/webhook'),
    },
    removeNullProps: false,
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    formValidation,
    isSubmitting,
    hasChange,
    products,
    selectedProduct,
    handleChange,
    onSubmit,
    handleSelectProduct,
    handleCancel: onCancel,
    setSelectedProduct,
    handleEventsSelect,
  };
}
