import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  VStack,
  Text,
} from '@chakra-ui/react';
import { BiDotsHorizontal } from 'react-icons/bi';
import useHandleSubmit from '../../../../../../../../hooks/useHandleSubmit';
import {
  FormOfPaymentType,
  CoursePriceType,
} from '../../../../../../../../validation/CoursePriceSchema';
import { Link } from 'react-router-dom';
import convertToBrazilianCurrency from '../../../../../../../../helpers/convertToBrazilianCurrency';

function parseFormOfPayment(formOfPayment: FormOfPaymentType, installments: number) {
  if (formOfPayment === 'FREE') {
    return 'Grátis';
  }

  if (formOfPayment === 'INSTALLMENTS_WITH_FEE' && installments > 1) {
    return 'parcelado com juros';
  }

  if (formOfPayment === 'INSTALLMENTS_WITHOUT_FEE' && installments > 1) {
    return 'parcelado sem juros';
  }

  return 'À vista';
}

interface CardCoursePricesProps {
  courseId: string;
  data: CoursePriceType;
  onCorsePriceChange: (data: CoursePriceType) => void;
  onCoursePriceDelete: (id: number) => void;
}

export default function CardCoursePrices({
  courseId,
  data,
  onCorsePriceChange,
  onCoursePriceDelete,
}: CardCoursePricesProps) {
  const {
    isLoading: setDefaultCoursePriceIsLoading,
    handleSubmit: setDefaultCoursePriceHandleSubmit,
  } = useHandleSubmit({
    method: 'patch',
    url: `/courses/${data.id}/course-price`,
    authenticated: true,
    data: {
      default: true,
    },
    onSuccess: {
      message: 'Preço do curso alterado com sucesso!',
      callback: () => {
        onCorsePriceChange({ ...data, default: true });
      },
    },
  });

  const { isLoading: deleteCoursePriceIsLoading, handleSubmit: deleteCoursePriceHandleSubmit } =
    useHandleSubmit({
      method: 'delete',
      url: `/courses/${data.id}/course-price`,
      authenticated: true,
      onSuccess: {
        message: 'Preço do curso deletado com sucesso!',
        callback: () => {
          onCoursePriceDelete(data.id);
        },
      },
    });

  return (
    <Card
      marginBottom="15px"
      shadow="lg"
      border={data.default ? '2px solid #ee6924' : '1px solid #cccccc50'}
    >
      <CardHeader paddingY="12px">
        <HStack justifyContent="space-between">
          <Text fontWeight="medium" fontSize="lg">
            {data.name}{' '}
            <Text hidden={!data.default} as="span" color="default.500" fontSize="md">
              (Preço padrão)
            </Text>
          </Text>
          <Menu>
            <Box>
              <Spinner
                hidden={!setDefaultCoursePriceIsLoading || !setDefaultCoursePriceIsLoading}
                color="default.500"
                marginRight={2}
              />
              <MenuButton
                style={{ boxShadow: 'none', outline: 'none' }}
                bgColor="#20212312"
                borderRadius={5}
                paddingX={1}
                paddingY={0.5}
              >
                <BiDotsHorizontal size="20px" color="#20212380" />
              </MenuButton>
            </Box>
            <MenuList>
              <MenuItem
                as={Link}
                to={`/courses/${courseId}/course-manager/price/primary-prices/${data.id}/edit`}
                style={{ boxShadow: 'none', outline: 'none' }}
              >
                Editar
              </MenuItem>

              <MenuItem
                onClick={setDefaultCoursePriceHandleSubmit}
                hidden={data.default}
                isDisabled={setDefaultCoursePriceIsLoading}
                style={{ boxShadow: 'none', outline: 'none' }}
              >
                Tornar preço principal
              </MenuItem>

              <MenuItem
                onClick={deleteCoursePriceHandleSubmit}
                isDisabled={deleteCoursePriceIsLoading}
                style={{ boxShadow: 'none', outline: 'none' }}
                color="red"
              >
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </CardHeader>
      <CardBody width="full" paddingY="12px">
        <VStack alignItems="flex-start" width="full">
          {data.formOfPayment !== 'FREE' ? (
            <Text fontWeight="medium">
              Parcelas:{' '}
              <Text as="span" fontWeight="normal">
                {data.installments}x
              </Text>
            </Text>
          ) : (
            ''
          )}
          <HStack width="full" justifyContent="space-between">
            <Text fontWeight="medium">
              Forma de pagamento:{' '}
              <Text as="span" fontWeight="normal">
                {parseFormOfPayment(data.formOfPayment, data.installments)}
              </Text>
            </Text>
            <Text fontWeight="bold" fontSize="lg" color={data.default ? '#ee6924' : 'initial'}>
              {data.formOfPayment === 'FREE'
                ? 'Grátis'
                : ` Valor: ${convertToBrazilianCurrency(data.purchasePrice)} (Até ${
                    data.installments
                  }x)`}
            </Text>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
}
