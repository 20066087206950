import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import CourseAPI from '../../../api/Course';
import ActiveGrid from '../../../assets/icons/active-grid.png';
import ActiveList from '../../../assets/icons/active-list.png';
import DisableGrid from '../../../assets/icons/disable-grid.png';
import DisableList from '../../../assets/icons/disable-list.png';
import CoursesCard from '../../../components/CoursesCard/CoursesCard';
import CoursesCardList from '../../../components/CoursesCardList/CoursesCardList';
import { Heading } from '../../../components/Heading';
import DotsFalling from '../../../components/Loadings/DotsFalling';
import Paginate from '../../../components/Paginate';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import './Course.css';
import SearchInput from './SearchInput';
import { PRODUCTS_FILTER } from '../../../helpers/LocalStorageHelper';

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';

const PER_PAGE = 10;

export default function Courses() {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  localStorage.setItem(PRODUCTS_FILTER, urlSearchParams.toString());

  const statusQuery = urlSearchParams.get('status');
  const searchQuery = urlSearchParams.get('search') ?? '';
  const orderByQuery = urlSearchParams.get('orderBy');
  const currentPage = urlSearchParams.get('page') || 1;

  const [filter, setFilter] = useState({
    search: searchQuery ?? '',
    status: statusQuery ?? '',
    orderBy: orderByQuery ?? 'most-recent',
  });

  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [viewMode, setViewMode] = useState('list');
  const [isLoading, setIsLoading] = useState(true);

  const getCourses = useCallback(
    async (page, per_page) => {
      if (!page) return;

      setIsLoading(true);

      try {
        const coursesResponse = await CourseAPI.products({
          page,
          per_page,
          search: filter.search,
          status: filter.status,
          orderBy: filter.orderBy,
        });

        const coursesData = coursesResponse?.data;

        if (coursesData?.data) {
          setCourses(coursesData?.data);
          setFilteredCourses(coursesData?.data);
          setPageCount(coursesData?.total / coursesData?.per_page);
        } else {
          setFilteredCourses([]);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [filter.orderBy, filter.search, filter.status]
  );

  useEffect(() => {
    getCourses(currentPage, PER_PAGE);
  }, [currentPage, urlSearchParams, getCourses]);

  function handlePageChange(selectedItem) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    history.push({
      search: urlSearchParams.toString(),
    });
  }

  function handleFilterChange(event) {
    const { name, value } = event.target;

    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));

    urlSearchParams.set(name, value);
    urlSearchParams.set('page', '1');

    // Remove queris aquelas cujos valores são vazios ou nulos
    urlSearchParams.forEach((paramValue, paramName) => {
      if (!paramValue) {
        urlSearchParams.delete(paramName);
      }
    });

    history.push({
      pathname: '/products',
      search: urlSearchParams.toString(),
    });
  }

  const handleSearchChange = useCallback(
    search => {
      setFilter(prevFilter => ({ ...prevFilter, search }));

      urlSearchParams.set('page', '1');

      if (search) {
        urlSearchParams.set('search', search);
      } else {
        urlSearchParams.delete('search');
      }

      history.push({
        pathname: '/products',
        search: urlSearchParams.toString(),
      });
    },

    [history, urlSearchParams]
  );

  function onDelete(id) {
    setFilteredCourses(prevCourses =>
      prevCourses.filter(course => course.productId !== parseInt(id))
    );
  }

  function redirectToNewProductPage() {
    history.push('/courses/new');
  }

  function toggleViewMode() {
    setViewMode(prevMode => (prevMode === 'list' ? 'grid' : 'list'));
  }

  return (
    <>
      <Flex align="center" justify="space-between" flexDirection={{ base: 'column', lg: 'row' }}>
        <Heading
          my={{ base: 0, md: 2, lg: 2 }}
          fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
          display="flex"
          w="100%"
        >
          Produtos Online
        </Heading>
        <Button
          onClick={redirectToNewProductPage}
          leftIcon={<BiPlus size="1rem" />}
          variant="solid"
          colorScheme="orange"
          width={{ base: '100%', lg: 44 }}
          mt={{ base: 8, lg: 0 }}
          size="sm"
          fontFamily="Montserrat"
        >
          Criar Produto
        </Button>
      </Flex>

      {courses?.length <= 0 && !isLoading && (
        <Flex w="full" justifyContent="center" my={10}>
          <Heading fontSize="18px" color="#20212350">
            Aqui você pode criar, editar e gerenciar seus produtos, de forma eficiente e objetiva,
            em um só lugar! Clique no botão “criar produto” e inicie seu sucesso no online com um
            produto completo.
          </Heading>
        </Flex>
      )}

      {!!courses?.length && (
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ base: 'column-reverse', lg: 'row' }}
          mt={{ base: 0, lg: 8 }}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 2, lg: 3 }}
            mt={{ base: 4, lg: 0 }}
          >
            <Text
              w={{ base: '100%', lg: 'auto' }}
              alignSelf="center"
              textAlign={{ base: 'start', lg: 'center' }}
              fontWeight={600}
            >
              Filtros:
            </Text>

            <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 2, md: 3 }}>
              <Select
                size="sm"
                _focus={{ borderColor: 'orange.400' }}
                w={{ base: 'full', lg: 'auto' }}
                borderRadius={6}
                placeholder="Todos os Status"
                color="#20212350"
                borderColor="#E2E8F0"
                name="status"
                id="status"
                onChange={handleFilterChange}
                value={filter.status}
                isDisabled={isLoading}
              >
                <option value="ATIVO">Ativo</option>
                <option value="EM_EDICAO">Em edição</option>
                <option value="INATIVO">Inativo</option>
                <option value="NAO_LISTADO">Não Listado</option>
              </Select>
              <Select
                size="sm"
                _focus={{ borderColor: 'orange.400' }}
                w={{ base: 'full', lg: 'auto' }}
                borderRadius={6}
                placeholder="Ordem"
                color="#20212350"
                borderColor="#E2E8F0"
                name="orderBy"
                id="orderBy"
                onChange={handleFilterChange}
                value={filter.orderBy}
                my={{ base: 1, md: 0, lg: 0 }}
                isDisabled={isLoading}
              >
                <option value="most-recent">Mais Recentes</option>
                <option value="older">Mais Antigos</option>
                <option value="alphabetical-a-z">Alfabética A-Z</option>
                <option value="alphabetical-z-a">Alfabética Z-A</option>
              </Select>
            </Stack>
          </Stack>

          <Flex
            align="center"
            direction={{ base: 'row-reverse', lg: 'row' }}
            mt={{ base: 8, lg: 0 }}
            gap={5}
          >
            <HStack spacing={4} cursor={isLoading ? 'not-allowed' : 'default'}>
              <Box onClick={toggleViewMode} cursor="pointer">
                {viewMode === 'list' ? (
                  <Image src={ActiveList} w={7} />
                ) : (
                  <Image src={DisableList} w={7} />
                )}
              </Box>
              <Box onClick={toggleViewMode} cursor="pointer">
                {viewMode === 'grid' ? (
                  <Image src={ActiveGrid} w={7} />
                ) : (
                  <Image src={DisableGrid} w={7} />
                )}
              </Box>
            </HStack>

            <SearchInput value={searchQuery} onChange={handleSearchChange} />
          </Flex>
        </Box>
      )}

      {isLoading && (
        <Flex mt="10" flexDirection="column" alignItems="center">
          <DotsFalling />
        </Flex>
      )}

      {filteredCourses?.length && !isLoading ? (
        <>
          {viewMode === 'grid' ? (
            <Grid
              mt={8}
              mb={2}
              templateColumns={{
                base: 'repeat(2, 1fr)',
                sm: 'repeat(3, 1fr)',
                xl: 'repeat(4, 1fr)',
              }}
              gap={{ base: 3, lg: 6 }}
            >
              {!isLoading &&
                filteredCourses.map(course => (
                  <GridItem key={nanoid()}>
                    <CoursesCard
                      title={course.productName}
                      image={course.productThumbnail}
                      status={course.productStatus}
                      id={course.productId}
                      onDelete={onDelete}
                    />
                  </GridItem>
                ))}
            </Grid>
          ) : (
            filteredCourses.map(course => (
              <CoursesCardList
                key={nanoid()}
                title={course.productName}
                image={course.productThumbnail}
                status={course.productStatus}
                id={course.productId}
                onDelete={onDelete}
              />
            ))
          )}

          <Box mt={4}>
            <Paginate
              pageCount={pageCount}
              initialPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Box>
        </>
      ) : (
        <>
          {!isLoading && !!courses?.length && (
            <Box display="flex" w="100%" justifyContent="center" my={10}>
              <Heading fontSize="lg">Nenhum produto encontrado.</Heading>
            </Box>
          )}
        </>
      )}
    </>
  );
}
