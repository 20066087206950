import React from 'react';

class ContentEstrutura extends React.Component {
  render() {
    return (
      <div className={`col-lg-${this.props.col}  ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default ContentEstrutura;
