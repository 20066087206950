import styles from './styles.module.css';

function NoData({ message }) {
  return (
    <div className={styles.noDataContainer}>
      <h3>{message}</h3>
    </div>
  );
}

export default NoData;
