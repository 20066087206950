import { Box, Button, Stack, TabPanel, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaPlus } from 'react-icons/fa';
import FullPageLoading from '../../../../../components/FullPageLoading';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import reorder from '../../../Courses/CourseManager/ModulesManager/utils/reorder';
import { CreateShowcaseModal } from './CreateShowcaseModal';
import Showcase from './Showcase';

export interface IProduct {
  showcaseCourseId: number;
  order: number;
  statusName: string;
  courseThumbnail: string;
  hasCourseThumbnailNetflix: boolean;
  courseId: number;
  courseName: string;
  contContent: number;
}

export interface IShowcase {
  showcaseId: number;
  name: string;
  order: number;
  statusName: string;
  products: IProduct[];
}

export default function Showcases() {
  const [showcasesData, setShowcasesData] = useState<IShowcase[]>([]);
  const [reorderShowcases, setReorderShowcases] = useState(false);

  const {
    isOpen: isOpenCreateShowcaseModal,
    onOpen: onOpenCreateShowcaseModal,
    onClose: onCloseCreateShowcaseModal,
  } = useDisclosure();

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<IShowcase[]>>({
    method: 'get',
    url: '/showcase',
    authenticated: true,
    autoFetch: true,
  });

  const showcases = response?.data;

  const hasShowcases = showcasesData?.length;

  const showcaseOrders = showcasesData.map(showcase => {
    return {
      showcaseId: showcase.showcaseId,
      order: showcase.order,
    };
  });

  const payloadNewOrder = {
    showcaseOrders: showcaseOrders,
  };

  const { handleSubmit: updateShowcasesOrder } = useHandleSubmit({
    method: 'patch',
    data: payloadNewOrder,
    url: `/showcase/order-showcase/new-order`,
    authenticated: true,
    onSuccess: {
      message: 'Vitrines ordenadas com sucesso!',
    },
  });

  useEffect(() => {
    if (reorderShowcases && showcasesData.length) {
      updateShowcasesOrder();
      setReorderShowcases(false);
    }
  }, [reorderShowcases, showcasesData.length, updateShowcasesOrder]);

  useEffect(() => {
    if (showcases?.length) {
      setShowcasesData(showcases);
    }
  }, [showcases]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const updatedShowcases = reorder(showcasesData, result.source.index, result.destination.index);

    setShowcasesData(updatedShowcases);
    setReorderShowcases(true);
  }

  return (
    <TabPanel marginTop="30px">
      <Stack spacing="30px">
        <Text hidden={!!hasShowcases} color="#0000007D" fontSize="14px" fontWeight={400}>
          Crie vitrine com produtos para a sua área de membro.
        </Text>

        <Box>
          <Button
            leftIcon={<FaPlus />}
            size="sm"
            colorScheme="primary"
            color="secondary.500"
            onClick={onOpenCreateShowcaseModal}
          >
            Adicionar vitrine
          </Button>
        </Box>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="showcases">
            {provided => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                hidden={!hasShowcases}
                spacing="30px"
              >
                {showcasesData?.map((showcase, index) => {
                  return (
                    <Draggable
                      key={String(showcase.showcaseId)}
                      draggableId={String(showcase.showcaseId)}
                      index={index}
                      isDragDisabled={showcasesData?.length < 2}
                    >
                      {provided => (
                        <Stack
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Showcase
                            showcase={showcase}
                            showcases={showcasesData}
                            setShowcases={setShowcasesData}
                            showcaseCount={showcasesData?.length}
                          />
                        </Stack>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>

      <FullPageLoading isLoading={loading} />

      <CreateShowcaseModal
        isOpen={isOpenCreateShowcaseModal}
        onClose={onCloseCreateShowcaseModal}
        showcases={showcasesData}
        setShowcases={setShowcasesData}
      />
    </TabPanel>
  );
}
