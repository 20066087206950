import { Image, Stack, useBreakpointValue } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Location } from 'history';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { axiosBackend } from '../../api/Configuration';
import FacebookIntegration from '../../api/FacebookIntegration';
import CookieBanner from '../../components/CookieBanner';
import FullPageLoading from '../../components/FullPageLoading';
import { OrderBump } from '../../components/OrderBump';
import Toast from '../../components/Toast';
import { useAuth } from '../../contexts/AuthContext';
import { UTMData } from '../../contexts/UTMContect';
import ErrorResponse from '../../helpers/ErrorResponse';
import facebookPixelHelper from '../../helpers/FacebookPixel';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../helpers/ga-tag-custom';
import {
  IS_COOKIES_ACCEPTED,
  SHOPPING_CART,
  TOKEN_LOCAL_STORAGE,
  TOKEN_PURCHASE,
  UTM_LOCAL_STORAGE,
} from '../../helpers/LocalStorageHelper';
import useFetch from '../../hooks/useFetch';
import useHotjarScript from '../../hooks/useHotjarScript';
import useQuery from '../../hooks/useQuery';
import useShoppingCart, {
  IPayment as IInstallment,
  IShoppingCart,
} from '../../hooks/useShoppingCart';
import StepBox from './Components/StepBox';
import Container from './Container';
import Payment from './Payment';
import { IPayment, MethodPaymentType } from './Payment/types';
import PurchaseSummary from './PurchaseSummary';
import PurchaseSummaryMobile from './PurchaseSummaryMobile';
import Register from './Register';
import { RegisterSchemaType } from './Register/schemaValidator';
import CheckoutSkeleton from './Skeleton';

const IS_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development';

type CheckoutType = 'LAUNCH' | 'DEFAULT';
type DaysThatCanGenerateBoletoType = '1' | '2' | '3' | '4' | '5' | '6' | '7';
export type UserFormForPurchaseType = 'END' | 'TEL' | 'INS';

interface ICourseCheckoutSettingsResponse {
  checkoutType: CheckoutType;
  paymentMethod: MethodPaymentType[];
  daysThatCanGenerateBoleto: DaysThatCanGenerateBoletoType[];
  userFormForPurchase: UserFormForPurchaseType[];
  countdown: number;
  desktopTopImage: string;
  mobileTopImage: string;
  desktopBottomImage: string;
  mobileBottomImage: string;
  useTopDesktopImageOnMobile: boolean;
  useBottomDesktopImageOnMobile: boolean;
  thankPageType: string;
  thankPageForApprovedPurchases: string;
  thankPageForPurchasesAwaitingPayment: string;
  thankPageForPurchasesAwaitingAnalysis: string;
}
export interface IUserResponse {
  fullName: string;
  documentNumber: string;
  email: string;
  instagram: string;
  ddi: string;
  ddd: string;
  phone: string;
  address: {
    country: string;
    zipCode: string;
    streetAddress: string;
    streetNumber: string;
    neighborhood: string;
    state: string;
    city: string;
    complementary: string;
    alternateAddress: string;
  };
}

interface ICartAbandonment {
  courseId: number;
  totalPrice: number;
  paymentMethodType: string;
  lineItems: { product_name: string; quantity: number; price: number }[];
}

const BRAZILIAN_COUNTRIES = ['br', 'BR', 'Brazil', 'Brasil'];

const initialState: UTMData = {
  utm_id: null,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_term: null,
  utm_content: null,
};

export default function LaunchCheckout() {
  const hostname = window.location.hostname;
  const isAmandiokaPlatform = hostname.includes('amandioka.proluno.com.br');

  const [shoppingCartData, setShoppingCartData] = useState<IShoppingCart>({} as IShoppingCart);
  const [personalData, setPersonalData] = useState<Partial<RegisterSchemaType>>();
  const [isLoading, setIsLoading] = useState(true);
  const [isFreePurchase, setIsFreePurchase] = useState(false);
  const [standardPaymentValue, setStandardPaymentValue] = useState(0);
  const [isPurchaseByPixConfirmed, setIsPurchaseByPixConfirmed] = useState(false);
  const [country, setCountry] = useState(isAmandiokaPlatform ? 'au' : 'br');
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const utmDataLocalStorage = localStorage.getItem(UTM_LOCAL_STORAGE);
  const [utmData, setUtmData] = useState<UTMData>(initialState);
  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
  const tokenForPurchase = localStorage.getItem(TOKEN_PURCHASE);
  const [installment, setInstallment] = useState(1);
  const [mainProductId, setMainProductId] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [ddi, setDDI] = useState(isAmandiokaPlatform ? '61' : '55');

  useHotjarScript([{ domain: 'vorticeconcursos.com.br', hjid: 3773309, hjsv: 6 }]);

  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const isDesktopScreen = useBreakpointValue({ base: false, md: false, lg: true, xl: true });

  const query = useQuery();
  const { productId }: { productId: string } = useParams();

  const affiliation = query.get('affiliation');

  const {
    addToShoppingCart,
    getShoppingCartFromLocalStorage,
    clearShoppingCart,
    updateShoppingCartInLocalStorage,
  } = useShoppingCart();

  const shoppingCartInLocalStorage = getShoppingCartFromLocalStorage();
  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: courseCheckoutSettingsResponse,
    error: courseCheckoutSettingsError,
    fetchData: getCourseCheckoutSettings,
  } = useFetch<UnificadaFront.ResponseJSON<ICourseCheckoutSettingsResponse>>({
    url: `/course-checkout-settings/${productId}/show`,
    method: 'get',
    autoFetch: false,
  });

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const head = document.getElementsByTagName('head').item(0);

    async function insertPixels() {
      const shoppingCartFromLocalStorage = getShoppingCartFromLocalStorage();
      const products = shoppingCartFromLocalStorage?.products.map(product => product.id);

      if (!products?.length) return;

      const { data: pixelData } = await FacebookIntegration.findMany(
        products,
        shoppingCartFromLocalStorage?.affiliation
      );

      if (pixelData?.length) {
        let currentPixel: HTMLElement;
        let script: string;

        if (!IS_DEVELOPMENT_ENV) {
          for (let pixel of pixelData) {
            currentPixel = document.createElement('script');
            currentPixel.id = pixel.identifierPixel;
            currentPixel.setAttribute('prolunofbp', pixel.courseId);

            script = facebookPixelHelper.baseCode(
              currentPixel.id,
              facebookPixelHelper.initiateCheckout(pixel.courseId)
            );

            const pixelExists = head.querySelectorAll(
              `[id="${pixel.identifier}"][prolunofbp="${pixel.courseId}"]`
            );

            if (!pixelExists.length) {
              currentPixel.innerHTML = script;

              head?.appendChild(currentPixel);
            }
          }
        }
      }
    }

    insertPixels();

    return () => {
      const pixels = document.querySelectorAll('[prolunofbp]');

      if (pixels.length) {
        for (let pixel of Array.from(pixels)) {
          head?.removeChild(pixel);
        }
      }
    };
  }, [
    isAuthenticated,
    getShoppingCartFromLocalStorage,
    shoppingCartData.productCart?.length,
    isCookiesAccepted,
  ]);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const idsGTM = googleTagManagerIds();

    let cartProducts = [];

    if (shoppingCartData?.productCart?.length) {
      cartProducts = shoppingCartData?.productCart;

      for (const id of idsGTM) {
        newEventGTM({
          id,
          data: {
            currency: 'BRL',
            event: 'addToCart',
            items: cartProducts.map(product => ({
              item_id: product.productId,
              item_name: product.productName,
              value: product.productPrice,
              quantity: 1,
            })),
            total_value: cartProducts.reduce((total, product) => total + product.productPrice, 0),
          },
        });

        removeEventGTM(id, 'addToCart');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCookiesAccepted, shoppingCartData?.productCart?.length]);

  useEffect(() => {
    const handleBeforeUnload = async () => {
      localStorage.removeItem(SHOPPING_CART);
    };

    // Adiciona o listener do evento beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove o listener ao desmontar o componente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (productId && courseCheckoutSettingsResponse?.data) {
      try {
        const shoppingCartFromLocalStorage = getShoppingCartFromLocalStorage();

        if (shoppingCartFromLocalStorage?.products?.length) {
          const mainProduct = shoppingCartFromLocalStorage.products.find(
            product => !product.orderBumpId
          );

          setMainProductId(mainProduct?.id);

          const shouldClearShoppingCart = mainProduct.id !== parseInt(productId);

          if (shouldClearShoppingCart) {
            clearShoppingCart();
          }
        }

        const methodPaymentType = courseCheckoutSettingsResponse?.data?.paymentMethod?.sort(
          (a, b) => {
            const order = ['credit_card', 'boleto', 'pix'];
            return order.indexOf(a) - order.indexOf(b);
          }
        )?.[0];

        const item = { id: Number(productId), methodPaymentType };

        if (affiliation) {
          const shoppingCartFromLocalStorage = getShoppingCartFromLocalStorage();

          clearShoppingCart();
          item['affiliation'] = affiliation;
          item['coupon'] = shoppingCartFromLocalStorage?.coupon;
          item['methodPaymentType'] =
            shoppingCartFromLocalStorage?.methodPaymentType as Partial<MethodPaymentType>;
          item['installments'] = shoppingCartFromLocalStorage?.installments;
        }

        updateShoppingCartInLocalStorage(item);
      } catch (error) {
        Toast(error.message, 'error');
      }
    }
  }, [
    affiliation,
    productId,
    clearShoppingCart,
    getShoppingCartFromLocalStorage,
    updateShoppingCartInLocalStorage,
    courseCheckoutSettingsResponse?.data,
  ]);

  // Registro de início de checkout
  useFetch({
    url: `/conversions/${productId}/begin-checkout`,
    method: 'post',
    autoFetch: !!token || !!tokenForPurchase,
    authenticated: true,
    token: token || tokenForPurchase,
  });

  const onChangeShoppingCart = useCallback(async () => {
    const shoppingCartInLocalStorage = getShoppingCartFromLocalStorage();

    if (shoppingCartInLocalStorage && courseCheckoutSettingsResponse?.data) {
      try {
        setIsLoading(true);

        const response = await addToShoppingCart('LAUNCH');

        setShoppingCartData(response);

        return response;
      } catch (error) {
        if (!error?.response?.data?.message.includes('Cupom')) {
          Toast(ErrorResponse(error), 'error');
        }

        if (shoppingCartInLocalStorage.coupon && error?.response?.data?.message.includes('Cupom')) {
          const response = await addToShoppingCart('LAUNCH');

          setShoppingCartData(response);
          return;
        }
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  }, [addToShoppingCart, courseCheckoutSettingsResponse?.data, getShoppingCartFromLocalStorage]);

  function onPoleChange(productId: number, poleId: number) {
    setShoppingCartData(prevShoppingCartData => {
      const productCart = prevShoppingCartData.productCart.map(product => {
        if (product.productId === productId) {
          // Se for informado poleId com zero, então é deletado da lista
          if (!poleId) {
            delete product.poleId;
            return product;
          }
          return { ...product, poleId };
        }

        return product;
      });

      return {
        ...prevShoppingCartData,
        productCart,
      };
    });
  }

  function onIsFreePurchaseChange(value: boolean) {
    setIsFreePurchase(value);
  }

  function onStandardPaymentValueChange(value: number) {
    setStandardPaymentValue(value);
  }

  function onIsLoadingChange(value: boolean) {
    setIsLoading(value);
  }

  function onPurchaseByPixConfirmedChange(value: boolean) {
    setIsPurchaseByPixConfirmed(value);
  }

  useEffect(() => {
    onChangeShoppingCart();
  }, [onChangeShoppingCart]);

  const loginOrRegisterToCheckoutWithProducts = useCallback(async () => {
    const shoppingCart = localStorage.getItem(SHOPPING_CART);
    const authenticationCheckout = urlSearchParams.get('added-to-cart-authentication');

    if (!shoppingCart || !authenticationCheckout) return;

    const parsedShoppingCart = JSON.parse(shoppingCart);

    if (parsedShoppingCart.products.length) {
      for (const product of parsedShoppingCart.products) {
        await axiosBackend().post(`/conversions/${product.id}/add-card`);
      }
    }

    urlSearchParams.delete('added-to-cart-authentication');
    history.push({ search: urlSearchParams.toString() });
  }, [history, urlSearchParams]);

  useEffect(() => {
    loginOrRegisterToCheckoutWithProducts();
  }, [loginOrRegisterToCheckoutWithProducts]);

  useEffect(() => {
    if (!isAuthenticated && !tokenForPurchase) {
      return history.push({
        pathname: `/launch-checkout/${productId}/personal-data`,
        search: window.location.search,
      });
    }
    if (!productId) {
      return history.push({
        pathname: '/',
        search: window.location.search,
      });
    }
  }, [history, isAuthenticated, location.pathname, productId, tokenForPurchase]);

  useEffect(() => {
    if (!utmDataLocalStorage) return;

    const utmData: {
      utm_id: string;
      utm_source: string;
      utm_medium: string;
      utm_campaign: string;
      utm_term: string;
      utm_content: string;
    } = JSON.parse(utmDataLocalStorage);

    setUtmData(utmData);
  }, [utmDataLocalStorage]);

  const {
    data: userResponse,
    fetchData: getUser,
    error,
  } = useFetch<UnificadaFront.ResponseJSON<IUserResponse>>({
    url: '/students/checkout',
    method: 'get',
    autoFetch: false,
    authenticated: true,
    token: token || tokenForPurchase,
  });

  useEffect(() => {
    if (token || tokenForPurchase) {
      getUser();
    }
  }, [getUser, token, tokenForPurchase]);

  useEffect(() => {
    if (error) {
      localStorage.removeItem(TOKEN_PURCHASE);
    }
  }, [error]);

  const productsInCart = shoppingCartData?.productCart;

  useEffect(() => {
    if (productId) {
      getCourseCheckoutSettings();
    }
    if (courseCheckoutSettingsError) {
      return history.push({
        pathname: '/',
      });
    }
  }, [getCourseCheckoutSettings, courseCheckoutSettingsError, history, productId]);

  const isSkeletonVisible = isLoading && !productsInCart?.length;
  const isOrderBumpVisible = location.pathname.includes('payment');
  const payments = shoppingCartData?.payments;
  const availablePaymentMethods = courseCheckoutSettingsResponse?.data?.paymentMethod;
  const useTopDesktopImageOnMobile =
    courseCheckoutSettingsResponse?.data?.useTopDesktopImageOnMobile;
  const useBottomDesktopImageOnMobile =
    courseCheckoutSettingsResponse?.data?.useBottomDesktopImageOnMobile;
  const bannerTopDesktop = courseCheckoutSettingsResponse?.data?.desktopTopImage;
  const bannerBottomDesktop = courseCheckoutSettingsResponse?.data?.desktopBottomImage;
  const bannerTopMobile = courseCheckoutSettingsResponse?.data?.mobileTopImage;
  const bannerBottomMobile = courseCheckoutSettingsResponse?.data?.mobileBottomImage;
  const bannerTopMobileVisible = bannerTopMobile && !isDesktopScreen && !useTopDesktopImageOnMobile;
  const bannerBottomMobileVisible =
    bannerBottomMobile && !isDesktopScreen && !useBottomDesktopImageOnMobile;
  const userFormForPurchase = courseCheckoutSettingsResponse?.data?.userFormForPurchase;
  const thankPageType = courseCheckoutSettingsResponse?.data?.thankPageType;
  const thankPageForApprovedPurchases =
    courseCheckoutSettingsResponse?.data?.thankPageForApprovedPurchases;
  const thankPageForPurchasesAwaitingPayment =
    courseCheckoutSettingsResponse?.data?.thankPageForPurchasesAwaitingPayment;
  const thankPageForPurchasesAwaitingAnalysis =
    courseCheckoutSettingsResponse?.data?.thankPageForPurchasesAwaitingAnalysis;

  const dayOfWeek = dayjs().day() + 1;
  const daysThatCanGenerateBoleto = courseCheckoutSettingsResponse?.data?.daysThatCanGenerateBoleto;

  const shouldFilterPaymentMethods = daysThatCanGenerateBoleto?.includes(
    dayOfWeek.toString() as DaysThatCanGenerateBoletoType
  );

  const availablePaymentMethodsFilter = !shouldFilterPaymentMethods
    ? availablePaymentMethods?.filter(availablePaymentMethod => availablePaymentMethod !== 'boleto')
    : availablePaymentMethods;

  const paymentPage = location.pathname.includes('payment');

  const disableChangeCountry =
    (paymentMethod !== 'boleto' && paymentPage) ||
    (paymentMethod === 'boleto' && paymentPage && BRAZILIAN_COUNTRIES.includes(country)) ||
    isAmandiokaPlatform;

  const isInternational = country && !BRAZILIAN_COUNTRIES.includes(country);
  const hidePixQRCode = !!(thankPageType && thankPageForPurchasesAwaitingAnalysis);

  const methodPaymentTypeInLocalStorage =
    shoppingCartInLocalStorage?.methodPaymentType as IPayment['methodPaymentType'];

  const totalOnePayment: IInstallment = payments?.find(payment => payment.installment === 1);

  const sendInfoCartAbandonmentPayload: ICartAbandonment = {
    courseId: parseInt(productId),
    totalPrice: totalOnePayment?.total,
    paymentMethodType: methodPaymentTypeInLocalStorage,
    lineItems: productsInCart?.map(product => {
      return {
        product_name: product.productName,
        quantity: product.productAmount,
        price: product.productPrice,
      };
    }),
  };

  const { fetchData: sendInfoCartAbandonment } = useFetch({
    data: sendInfoCartAbandonmentPayload,
    method: 'post',
    url: '/purchases/shopping-cart-abandonment',
    autoFetch: false,
    authenticated: true,
    token: token || tokenForPurchase,
  });

  const addressCountry = userResponse?.data?.address?.country;
  const addressVisible = userFormForPurchase?.includes('END');

  useEffect(() => {
    if (addressCountry && addressVisible) {
      if (isAmandiokaPlatform) {
        return setCountry('au');
      }

      setCountry(addressCountry);
    }
  }, [addressCountry, addressVisible, isAmandiokaPlatform]);

  useEffect(() => {
    if (
      (token || tokenForPurchase) &&
      methodPaymentTypeInLocalStorage &&
      !!productsInCart &&
      !!totalOnePayment
    ) {
      sendInfoCartAbandonment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInCart, token, tokenForPurchase, totalOnePayment]);

  return (
    <Container>
      <Stack spacing="30px">
        {bannerTopMobileVisible ? (
          <Image
            width="full"
            height="500px"
            objectFit="cover"
            loading="lazy"
            src={bannerTopMobile}
          />
        ) : (
          <Image
            width="1010px"
            minH="1px"
            objectFit="cover"
            loading="lazy"
            src={bannerTopDesktop}
          />
        )}

        <Stack
          width="full"
          direction={{ base: 'column-reverse', md: 'column-reverse', lg: 'row', xl: 'row' }}
          alignItems="self-start"
          justifyContent="center"
          spacing="21px"
        >
          <Stack width="full" px={{ base: 4, md: 0, lg: 0, xl: 0 }}>
            <StepBox
              country={country}
              setCountry={setCountry}
              setDDI={setDDI}
              disableChangeCountry={disableChangeCountry}
            />
            <Switch>
              <Route
                path="/launch-checkout/:productId/personal-data"
                location={{ search: window.location.search } as Location}
              >
                {isSkeletonVisible ? (
                  <CheckoutSkeleton />
                ) : (
                  <Register
                    ddi={ddi}
                    token={token}
                    country={country}
                    isInternational={isInternational}
                    userData={userResponse?.data}
                    userFormForPurchase={userFormForPurchase}
                    products={productsInCart}
                    payments={payments}
                    shoppingCartData={shoppingCartData}
                    setPersonalData={setPersonalData}
                  />
                )}
              </Route>
              <Route
                path="/launch-checkout/:productId/payment"
                location={{ search: window.location.search } as Location}
              >
                {isSkeletonVisible ? (
                  <CheckoutSkeleton />
                ) : (
                  <Payment
                    products={productsInCart}
                    payments={payments}
                    country={country}
                    userData={userResponse?.data}
                    availablePaymentMethods={availablePaymentMethodsFilter}
                    standardPaymentValue={standardPaymentValue}
                    isFreePurchase={isFreePurchase}
                    isLoading={isLoading}
                    isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
                    onChangeShoppingCart={onChangeShoppingCart}
                    onIsLoadingChange={onIsLoadingChange}
                    onPurchaseByPixConfirmedChange={onPurchaseByPixConfirmedChange}
                    utmData={utmData}
                    thankPageType={thankPageType}
                    thankPageForApprovedPurchases={thankPageForApprovedPurchases}
                    thankPageForPurchasesAwaitingPayment={thankPageForPurchasesAwaitingPayment}
                    thankPageForPurchasesAwaitingAnalysis={thankPageForPurchasesAwaitingAnalysis}
                    isInternational={isInternational}
                    setPaymentMethod={setPaymentMethod}
                    hidePixQRCode={hidePixQRCode}
                    setInstallment={setInstallment}
                    shoppingCartData={shoppingCartData}
                    personalData={personalData}
                  />
                )}
              </Route>

              <Route path="/launch-checkout/:productId" render={() => <Redirect to="/" />} />
            </Switch>
          </Stack>

          <Stack width="full">
            {isDesktopScreen ? (
              <>
                <PurchaseSummary
                  products={productsInCart}
                  isLoading={isLoading}
                  payments={payments}
                  installment={installment}
                  mainProductId={mainProductId}
                  isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
                  onChangeShoppingCart={onChangeShoppingCart}
                  onPoleChange={onPoleChange}
                  onIsFreePurchaseChange={onIsFreePurchaseChange}
                  onStandardPaymentValueChange={onStandardPaymentValueChange}
                />

                {isOrderBumpVisible && (
                  <OrderBump
                    orderBumps={shoppingCartData?.orderBumps}
                    updateShoppingCartInLocalStorage={updateShoppingCartInLocalStorage}
                    onChangeShoppingCart={onChangeShoppingCart}
                  />
                )}
              </>
            ) : (
              <Stack width="full" spacing={0} marginTop="-30px">
                <PurchaseSummaryMobile
                  products={productsInCart}
                  isLoading={isLoading}
                  payments={payments}
                  installment={installment}
                  mainProductId={mainProductId}
                  isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
                  onChangeShoppingCart={onChangeShoppingCart}
                  onPoleChange={onPoleChange}
                  onIsFreePurchaseChange={onIsFreePurchaseChange}
                  onStandardPaymentValueChange={onStandardPaymentValueChange}
                  isOrderBumpVisible={isOrderBumpVisible}
                  orderBumps={shoppingCartData?.orderBumps}
                  updateShoppingCartInLocalStorage={updateShoppingCartInLocalStorage}
                />
              </Stack>
            )}
          </Stack>
        </Stack>

        {bannerBottomMobileVisible ? (
          <Image
            width="full"
            height="500px"
            objectFit="cover"
            loading="lazy"
            src={bannerBottomMobile}
          />
        ) : (
          <Image
            width="1010px"
            minH="1px"
            objectFit="cover"
            loading="lazy"
            src={bannerBottomDesktop}
          />
        )}
      </Stack>

      <FullPageLoading isLoading={isLoading} />

      <CookieBanner />
    </Container>
  );
}
