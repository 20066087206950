import Badge from '../../../Badge';
import { Link } from 'react-router-dom';
import convert from '../../../../../../helpers/convertToBrazilianCurrency';
import dayjs from 'dayjs';
import formatCPF from '../../../../../../helpers/MaskCpf';
import styles from './styles.module.css';
import { translatePurchaseStatus } from '../../../helpers';
import { IS_REACT_APP_HOSTNAME } from '../../../../../../App';

function TransactionItem({ transaction, modalReleaseAccess }) {
  function translatePaymentMethod(transaction) {
    if (transaction === 'credit_card') {
      return 'Cartão de Crédito';
    }

    if (transaction === 'boleto') {
      return 'Boleto';
    }

    if (transaction === 'pix') {
      return 'Pix';
    }

    if (transaction === 'free') {
      return 'Grátis';
    }

    if (transaction === 'external') {
      return 'Externo';
    }
  }

  function TransactionId() {
    if (
      !transaction.transactionId &&
      !transaction.purchasePrice &&
      transaction.paymentMethod !== 'external'
    ) {
      return 'Grátis';
    } else if (transaction.transactionId && transaction.purchasePrice) {
      return (
        <Link to={`financial/${transaction.id}`} className={styles.transactionId}>
          <span>#{transaction.transactionId}</span>
        </Link>
      );
    }

    return '-';
  }

  const hostname = window.location.hostname;

  function ReleaseAccess({ purchaseStatus, modalReleaseAccess }) {
    if (!IS_REACT_APP_HOSTNAME(hostname) || purchaseStatus === 'paid') return '';

    if (purchaseStatus !== 'waiting_payment') return '';

    return (
      <td>
        <button
          className={`btn btn-outline-gray border border-gray rounded ${styles.cursor}`}
          onClick={() => modalReleaseAccess(transaction.id)}
        >
          Liberar acesso
        </button>
      </td>
    );
  }

  return (
    <tr key={transaction.id}>
      <td>
        <TransactionId />
      </td>
      <td title={transaction.products.map(item => item.product)} className={styles.td}>
        {transaction.products.length
          ? transaction.products.length === 1
            ? transaction?.products[0].product
            : transaction.products[0]?.product.concat(` + ${transaction.products.length - 1}`)
          : ''}
      </td>

      <td className={styles.td}>
        {dayjs(transaction.createdAt, { utc: true }).format('DD/MM/YYYY - HH:mm')}
      </td>
      <td className={styles.td} title={transaction.buyer.fullName}>
        {transaction.buyer.fullName}
      </td>
      <td className={styles.td}>{formatCPF(transaction.buyer.documentNumber)}</td>
      <td style={{ maxWidth: '10rem' }}>{transaction.buyer.email}</td>
      <td>{translatePaymentMethod(transaction.paymentMethod)}</td>
      <td>{transaction.purchasePrice ? convert(transaction.purchasePrice) : '-'}</td>

      <td className={styles.status}>
        <Badge status={transaction.purchaseStatus}>
          {translatePurchaseStatus(transaction.purchaseStatus)}
        </Badge>
      </td>
      <ReleaseAccess
        purchaseStatus={transaction.purchaseStatus}
        modalReleaseAccess={modalReleaseAccess}
      />
    </tr>
  );
}

export default TransactionItem;
