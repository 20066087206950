import './FrequentlyQuestions.css';

import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';

import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { Heading } from '../../../../../../components/Heading';
import FrequentlyQuestionApi from '../../../../../../api/FrequentlyQuestion';
import { Modal } from 'react-bootstrap';
import { useCourse } from '../../../../../../contexts/CourseContext';

function FrequentlyQuestions() {
  const { course } = useCourse();

  const [existingQuestions, setExistingQuestions] = useState([]);
  const [question, setQuestion] = useState('');
  const [questionAnswer, setQuestionAnswer] = useState('');

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModal2, setIsShowModal2] = useState(false);

  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [questionEdit, setQuestionEdit] = useState('');
  const [questionAnswerEdit, setQuestionAnswerEdit] = useState('');
  const [questionId, setQuestionId] = useState('');

  useEffect(() => {
    getQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getQuestion() {
    const questions = await FrequentlyQuestionApi.index(course.id);
    setExistingQuestions(questions);
  }

  async function deleteQuestion(event) {
    const { id, value } = event.target;
    if (value === 'Excluir') {
      await FrequentlyQuestionApi.delete(course.id, id);
      const newResponse = await FrequentlyQuestionApi.index(course.id);
      setExistingQuestions(newResponse);
      setMessage('Sua pergunta foi excluida com sucesso');
      setTypeMessage('sucesso');
      setIsShowModal2(true);
    }
  }

  async function updateQuestion() {
    if (
      questionEdit.length >= 6 &&
      questionEdit.length <= 255 &&
      questionAnswerEdit.length >= 6 &&
      questionAnswerEdit.length <= 255
    ) {
      await FrequentlyQuestionApi.update(course.id, questionId, questionEdit, questionAnswerEdit);
      const newResponse = await FrequentlyQuestionApi.index(course.id);
      setExistingQuestions(newResponse);
      setIsShowModal(false);
      setMessage('Sua pergunta foi atualizada com sucesso');
      setTypeMessage('sucesso');
      setIsShowModal2(true);
    } else {
      setMessage(
        'Você precisa informar campos válidos. Vale ressaltar que tanto campo de pergunta, quanto o campo de resposta suportam entre 6 e 255 caracteres'
      );
      setTypeMessage('erro');
      setIsShowModal2(true);
    }
  }

  function ListQuestions({ questions }) {
    return questions.map(({ id, question, questionAnswer }) => {
      return (
        <ul className="space-ul d-flex justify-content-between w-100  " key={id}>
          <div className="d-flex justify-content-between w-100">
            <div className="column big-text mb-2 mr-2">
              <p className="paragraph">{question}</p>
              <span>{questionAnswer}</span>
            </div>
            <div className="d-flex flex-nowrap">
              <input
                value="Editar"
                type="button"
                className="btn btn-sm  styleButton"
                id={id}
                onClick={() => {
                  setQuestionId(id);
                  setQuestionEdit(question);
                  setQuestionAnswerEdit(questionAnswer);
                  setIsShowModal(true);
                }}
              />
              <input
                value="Excluir"
                type="button"
                className="btn btn-outline-danger btn-sm styleButton"
                id={id}
                onClick={deleteQuestion}
              />
            </div>
          </div>
        </ul>
      );
    });
  }

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === 'pergunta') {
      setQuestion(value);
    }
    if (name === 'resposta') {
      setQuestionAnswer(value);
    }

    if (name === 'questionEdit') {
      setQuestionEdit(value);
    }

    if (name === 'questionAnswerEdit') {
      setQuestionAnswerEdit(value);
    }
  }

  async function onSubmit(event) {
    event.preventDefault();

    try {
      if (
        question.length >= 6 &&
        question.length <= 255 &&
        questionAnswer.length >= 6 &&
        questionAnswer.length <= 255
      ) {
        await FrequentlyQuestionApi.store(course.id, question, questionAnswer);
        const newResponse = await FrequentlyQuestionApi.index(course.id);
        await setExistingQuestions(newResponse);
        setQuestion('');
        setQuestionAnswer('');
        setMessage('Sua pergunta foi cadastrada com sucesso');
        setTypeMessage('sucesso');
        setIsShowModal2(true);
      } else {
        setMessage(
          'Você precisa informar campos válidos. Vale ressaltar que tanto campo de pergunta, quanto o campo de resposta suportam entre 6 e 255 caracteres'
        );
        setTypeMessage('erro');
        setIsShowModal2(true);
      }
    } catch (error) {
      setMessage(ErrorResponse(error));
    }
  }

  return (
    <div className="row justify-content-center">
      <div className="col">
        <form onSubmit={onSubmit}>
          <div className="card shadow-sm">
            <div className="card-inner">
              <Heading as="h4" fontSize="2xl">
                Perguntas frequentes
              </Heading>
              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="title" className="form-label">
                    Digite a sua pergunta
                  </label>

                  <input
                    type="text"
                    name="pergunta"
                    placeholder="Ex: Não estou tendo acesso aos meus cursos"
                    className="fs-14px form-control"
                    value={question}
                    onChange={handleChange}
                    min="6"
                    max="350"
                  />
                </div>
                <div className="form-group col-12">
                  <label htmlFor="title" className="form-label">
                    Digite a resposta dessa pergunta
                  </label>

                  <textarea
                    onChange={handleChange}
                    className="fs-14px form-control"
                    value={questionAnswer}
                    name="resposta"
                    id=""
                    cols="25"
                    rows="10"
                    placeholder="Ex: basta clicar no menu de acesso aos clientes"
                    min="6"
                    max="350"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center mr-1 my-4">
                <button className="btn btn-primary px-5">Salvar</button>
              </div>

              {existingQuestions && <hr />}

              <div className="row mt-3">
                <div className="form-group col-12">
                  <li className="style-li">
                    {existingQuestions && <ListQuestions questions={existingQuestions} />}
                  </li>
                </div>
              </div>
            </div>
          </div>
        </form>
        <Modal
          centered
          size="lg"
          show={isShowModal}
          onHide={() => setIsShowModal(false)}
          style={{
            backdropFilter: 'blur(4px)',
          }}
        >
          <Modal.Body>
            <div className="row d-flex justify-content-center mb-1 py-4">
              <label
                htmlFor="pergunta"
                className="font-weight-bold text-title-label mb-1"
                style={{ fontSize: '16px' }}
              >
                Pergunta
              </label>
              <input
                name="questionEdit"
                type="text"
                value={questionEdit}
                className="fs-14px form-control"
                onChange={handleChange}
              />
              <label
                htmlFor="pergunta"
                className="font-weight-bold text-title-label mt-4 mb-1"
                style={{ fontSize: '16px' }}
              >
                Resposta
              </label>
              <textarea
                name="questionAnswerEdit"
                type="text"
                value={questionAnswerEdit}
                className="fs-14px form-control "
                onChange={handleChange}
                rows="10"
              />
            </div>
            <div className="row d-flex justify-content-end mb-1 ">
              <button
                onClick={() => setIsShowModal(false)}
                className="btn btn-danger "
                style={{
                  color: 'white',
                }}
              >
                Descartar alterações
              </button>
              <button
                onClick={updateQuestion}
                className="btn btn-light ml-2"
                style={{
                  backgroundColor: '#EB7129',
                  color: 'white',
                }}
              >
                Confirmar edição
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size="md"
          show={isShowModal2}
          onHide={() => setIsShowModal2(false)}
          style={{
            backdropFilter: 'blur(4px)',
          }}
        >
          <Modal.Body>
            <div className="row d-flex justify-content-center mb-1 py-4">
              {typeMessage === 'sucesso' && (
                <FaCheckCircle
                  className="d-flex justify-content-center mb-2"
                  style={{ color: 'green', height: '60px', width: '60px' }}
                />
              )}
              {typeMessage === 'erro' && (
                <FaExclamationCircle
                  className="d-flex justify-content-center mb-2"
                  style={{
                    color: 'red',
                    height: '60px',
                    width: '60px',
                  }}
                />
              )}
              <h3 className="text-justify">{message}</h3>
            </div>
            <div className="d-flex justify-content-center mb-4 ">
              <button
                onClick={() => setIsShowModal2(false)}
                className="btn btn-light"
                style={{
                  backgroundColor: '#EB7129',
                  color: 'white',
                  alignItems: 'center',
                }}
              >
                Retornar para a tela de perguntas
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default FrequentlyQuestions;
