import './style.css';

import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';

import { Box, Flex } from '@chakra-ui/react';
import LoginAsUser from '../../../../components/LoginAsUser';
import CourseProvider from '../../../../contexts/CourseContext';
import AccessCourse from './AccessCourse';
import Affiliation from './Affiliation';
import Configuration from './Configuration';
import HeaderCourseManager from './HeaderCourseManager';
import ModulesManager from './ModulesManager';
import Price from './Price';
import Publish from './Publish';
import ViewAsStudent from './ViewAsStudent';

const Manage = () => {
  const { id } = useParams();

  return (
    <CourseProvider>
      <Box bg="white">
        <Switch>
          <Route exact path="/courses/:id/course-manager*">
            <LoginAsUser />
            <HeaderCourseManager />
            <section className="border-bottom course-manager-nav d-flex flex-wrap">
              <ul
                className="d-flex justify-content-start pt-1 animate-left"
                style={{ margin: '0.5rem 0', color: '#202123', overflow: 'auto' }}
              >
                <li>
                  <Link
                    className={`nav-link nav-link-2 tab-link text-dark`}
                    to={`/courses/${id}/course-manager/course`}
                  >
                    Curso
                  </Link>
                </li>

                <li>
                  <Link
                    className="nav-item nav-link nav-link-2 tab-link"
                    to={`/courses/${id}/course-manager/configuration/basic-configuration`}
                  >
                    Configurações
                  </Link>
                </li>

                <li>
                  <Link
                    className="nav-link nav-link-2 tab-link"
                    to={`/courses/${id}/course-manager/access-course/access-duration`}
                  >
                    Acesso do produto
                  </Link>
                </li>

                <li>
                  <Link
                    className="nav-link nav-link-1 tab-link"
                    to={`/courses/${id}/course-manager/price/primary-prices`}
                  >
                    Preço
                  </Link>
                </li>

                <li>
                  <Link
                    className="nav-link nav-link-2 tab-link"
                    to={`/courses/${id}/course-manager/publication`}
                  >
                    Publicar
                  </Link>
                </li>
              </ul>

              <div>
                <ViewAsStudent productId={id} />
              </div>
            </section>
          </Route>
        </Switch>
      </Box>
      <Flex bg="white" flexDirection="column" justifyContent="center" alignItems="center">
        <Box width="full">
          <Switch>
            <Route exact path="/courses/:id/course-manager/course*" component={ModulesManager} />
            <Route
              exact
              path="/courses/:id/course-manager/configuration/*"
              component={Configuration}
            />

            <Route
              exact
              path="/courses/:id/course-manager/access-course/*"
              component={AccessCourse}
            />

            <Route exact path="/courses/:id/course-manager/price*" component={Price} />

            <Route exact path="/courses/:id/course-manager/affiliation" component={Affiliation} />

            <Route exact path="/courses/:id/course-manager/publication" component={Publish} />

            <Route exact path={`/courses/${id}/course-manager/configuration*`}>
              <Redirect to={`/courses/${id}/course-manager/configuration/basic-configuration`} />
            </Route>

            <Route exact path={`/courses/${id}/course-manager/access-course*`}>
              <Redirect to={`/courses/${id}/course-manager/access-course/access-duration`} />
            </Route>

            <Route exact path={`/courses/${id}/course-manager/price*`}>
              <Redirect to={`/courses/${id}/course-manager/price/primary-prices`} />
            </Route>

            <Route exact path={`/courses/${id}/course-manager/publication*`}>
              <Redirect to={`/courses/${id}/course-manager/publication`} />
            </Route>

            <Route exact path={`/courses/${id}/course-manager*`}>
              <Redirect to={`/courses/${id}/course-manager/course`} />
            </Route>

            <Route exact path={`/courses/${id}/*`}>
              <Redirect to={`/courses/${id}/course-manager/course`} />
            </Route>

            <Route exact path={`/courses/${id}/*`}>
              <Redirect to={`/courses/${id}/course-manager/course`} />
            </Route>
          </Switch>
        </Box>
      </Flex>
    </CourseProvider>
  );
};

export default Manage;
