import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import {
  MdAdd as AddIcon,
  MdKeyboardArrowLeft as ArrowLeftIcon,
  MdKeyboardArrowRight as ArrowRightIcon,
  MdOutlineGetApp as DownloadIcon,
  MdRemove as MinusIcon,
} from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { baseURLBackend } from '../../../../../../api/Configuration';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import { IParams } from '../../types';

const scrollbarStyle = {
  '::-webkit-scrollbar': {
    width: '0.45rem',
    height: '0.45rem',
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.300',
  },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.200',
  },
};

const documentStyle = {
  canvas: { maxWidth: 'none !important' },
  '.react-pdf__Document': {
    minHeight: 'unset',
  },
};

export default function PDFViewer({ pdfUrl, hasDownload, highlightColor }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [orientation, setOrientation] = useState({ width: null, height: null });
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });

  const { courseId, moduleId, contentId } = useParams<IParams>();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    const pdfDocument = pdfjs.getDocument(pdfUrl);
    pdfDocument.promise.then(pdf => {
      pdf.getPage(1).then(page => {
        // caso queira pegar a orientação da página
        const { width, height } = page.getViewport({ scale: 1 });

        if (isMobile) {
          return setOrientation({
            width: 330,
            height,
          });
        }
        setOrientation({ width, height });
      });
    });
  }

  const handleZoomIn = () => {
    setScale(prevScale => Math.min(prevScale + 0.1, 2)); // Limita zoom máximo em 2x
  };

  const handleZoomOut = () => {
    setScale(prevScale => Math.max(prevScale - 0.1, 0.1)); // Limita zoom mínimo em 0.1x
  };

  function handlePrevPage() {
    setScale(1);
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  }

  function handleNextPage() {
    setScale(1);
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  }

  const containerRef = useRef(null);
  // const pageWidth = containerRef.current?.clientWidth;
  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
  const linkToDownload = `${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${token}`;

  return (
    <Stack spacing={{ base: '9px', md: '13px', lg: '13px' }}>
      <HStack
        width="full"
        height="40px"
        padding={{ base: '5px', md: '12px', lg: '12px' }}
        boxShadow="0px 0px 10px 0px #0000001A"
        borderRadius="base"
        bgColor="#37393D"
        justify="space-between"
        position="relative"
        spacing={0}
      >
        <HStack spacing={{ base: 0.25, xl: 2 }}>
          <IconButton
            aria-label="Diminuir zoom"
            onClick={handleZoomOut}
            icon={<MinusIcon size={8.75} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
            isDisabled={scale === 1}
          />

          <Box
            borderRadius="6px"
            border="1px solid rgba(255, 255, 255, 0.52)"
            background="#37393D"
            padding={{ base: '4px 10px', md: '4px 12px', lg: '4px 12px' }}
            color="#FFFFFF"
            fontSize={{ base: '0.6875rem', xl: '13px' }}
          >
            {(scale * 100).toFixed(0)}%
          </Box>

          <IconButton
            aria-label="Aumentar zoom"
            onClick={handleZoomIn}
            icon={<AddIcon size={15} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
            isDisabled={scale === 2}
          />
        </HStack>

        <HStack
          justify="center"
          spacing={{ base: 0.5, xl: 4 }}
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
        >
          <IconButton
            aria-label="Página anterior"
            onClick={handlePrevPage}
            icon={<ArrowLeftIcon size={26} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
          />

          {/* Mobile */}
          <Text fontSize="xs" color="#FFFFFF" display={{ base: 'block', xl: 'none' }}>
            {pageNumber} de {numPages}
          </Text>

          {/* Desktop */}
          <Text fontSize="xs" color="#FFFFFF" display={{ base: 'none', xl: 'block' }}>
            Página {pageNumber} de {numPages}
          </Text>

          <IconButton
            aria-label="Próxima página"
            onClick={handleNextPage}
            icon={<ArrowRightIcon size={26} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
          />
        </HStack>

        {hasDownload && (
          <Box>
            {/* Mobile */}
            <Link
              hidden={!isMobile}
              href={linkToDownload}
              download
              target="_blank"
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                variant="ghost"
                size="sm"
                fontWeight="normal"
                _hover={{ bgColor: 'transparent' }}
                color="#FFFFFF"
              >
                <DownloadIcon />
              </Button>
            </Link>

            {/* Desktop */}
            <Link
              hidden={isMobile}
              href={linkToDownload}
              download
              target="_blank"
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                _hover={{ bgColor: 'transparent' }}
                color="#FFFFFF"
                variant="ghost"
                size="sm"
                fontSize="13px"
                fontWeight="normal"
                rightIcon={<DownloadIcon />}
              >
                Download
              </Button>
            </Link>
          </Box>
        )}
      </HStack>

      <VStack
        width="full"
        bgColor={{ base: 'transparent', md: '#37393D', lg: '#37393D' }}
        padding={{ base: '12px', md: '0', lg: '0' }}
        overflow="auto"
        sx={scrollbarStyle}
      >
        <VStack ref={containerRef} sx={documentStyle}>
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <Center height="auto" bgColor="#37393D">
                <Spinner size="xl" color={highlightColor} />
              </Center>
            }
          >
            <Page pageNumber={pageNumber} scale={scale} width={orientation.width} />
          </Document>
        </VStack>
      </VStack>
    </Stack>
  );
}
