import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  TabPanel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import Pagination from '../../../../../components/Pagination';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import { CardInstitution } from '../components/CardInstitution';
import { ModalAddOrEditInstitution } from '../components/ModalAddOrEditInstitution';
import { ModalDeleteInstitution } from '../components/ModalDeleteInstitution';
import { institutionSchemaValidator } from '../schemaValidator';

export interface IInstitutionDataResult {
  data: {
    id: number;
    name: string;
  }[];
  total: number;
  page: number;
  per_page: number;
}

export interface IInstitutionData {
  id: number;
  name: string;
}

interface IProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  tabIndex: number;
}

export function TabPanelInstitution({ isOpen, onOpen, onClose, tabIndex }: IProps) {
  const {
    isOpen: isOpenModalDeleteInstitution,
    onOpen: onOpenModalDeleteInstitution,
    onClose: onCloseModalDeleteInstitution,
  } = useDisclosure();

  const [timer, setTimer] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [searchFiltered, setSearchFiltered] = useState<string>('');
  const [institutionData, setInstitutionData] = useState<IInstitutionData[]>();
  const [institutionDataFiltered, setInstitutionDataFiltered] = useState<IInstitutionData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState(0);
  const [institutionId, setInstitutionId] = useState<number>(0);
  const [institutionName, setInstitutionName] = useState<string>('');

  const {
    data: getInstitutionData,
    loading: isLoadingInstitutionData,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<IInstitutionDataResult>>({
    method: 'get',
    url: `/filter/question-library-institution?page=${currentPage}&perPage=${24}${
      search ? `&search=${search}` : ''
    }`,
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    if (getInstitutionData?.data) {
      setInstitutionData(getInstitutionData?.data.data);
      setInstitutionDataFiltered(getInstitutionData?.data.data);
      setPageCount(getInstitutionData?.data?.total / getInstitutionData?.data?.per_page);
    } else {
      setInstitutionDataFiltered([]);
    }
  }, [getInstitutionData?.data]);

  useEffect(() => {
    if (tabIndex === 3) {
      fetchData();
    }
  }, [fetchData, tabIndex]);

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit: handleSubmitInstitution,
  } = useHandleSubmit({
    data: { name: institutionName },
    url: '/filter/question-library-institution',
    method: 'post',
    authenticated: true,
    schemaValidator: institutionSchemaValidator,
    onSuccess: {
      message: 'Banca criada com sucesso!',
      callback: () => {
        fetchData();
        setInstitutionId(0);
        setInstitutionName('');
      },
    },
  });

  const {
    isLoading: isEditingInstitution,
    formValidation: formValidationEditInstitution,
    handleSubmit: handleSubmitEditInstitution,
  } = useHandleSubmit({
    data: { name: institutionName },
    url: `/filter/${institutionId}/question-library-institution`,
    method: 'patch',
    schemaValidator: institutionSchemaValidator,
    authenticated: true,
    onSuccess: {
      message: 'Banca atualizada com sucesso!',
      callback: () => {
        fetchData();
        setInstitutionId(0);
        setInstitutionName('');
      },
    },
  });

  const { isLoading: isDeleting, handleSubmit: handleSubmitDeleteInstitution } = useHandleSubmit({
    url: `/filter/${institutionId}/question-library-institution`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Banca deletada com sucesso!',
      callback: () => {
        setInstitutionDataFiltered(
          institutionDataFiltered.filter(institution => institution.id !== institutionId)
        );
        setInstitutionId(0);
        setInstitutionName('');
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    let result: any;

    if (institutionId) {
      result = await handleSubmitEditInstitution();
    } else {
      result = await handleSubmitInstitution();
    }

    if (result) {
      onClose();
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        setSearch(searchFiltered);

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchFiltered, setSearch, timer]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchFiltered(value);
    setTimer(2);
  }

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  return (
    <TabPanel padding={0}>
      <Box>
        {!institutionData?.length && (
          <Text fontWeight={400} fontSize="14px" color="#20212380" marginY={5}>
            Cadastre suas bancas, isso facilitará a filtragem de questões no biblioteca de questões.
          </Text>
        )}

        <InputGroup size="sm" marginY={5} hidden={!institutionData?.length}>
          <InputLeftElement pointerEvents="none">
            <BiSearch color="gray" size={15} />
          </InputLeftElement>
          <Input
            width={{ base: 'full', md: '320px', lg: '320px' }}
            placeholder="Buscar por título"
            borderRadius={6}
            focusBorderColor="orange.500"
            color="#20212380"
            value={searchFiltered}
            onChange={handleChangeSearch}
          />
        </InputGroup>

        <Stack
          direction="row"
          width="full"
          justifyContent="center"
          margin={10}
          hidden={!isLoadingInstitutionData}
        >
          <Spinner color="orange.500" size="lg" />
        </Stack>

        {!institutionDataFiltered.length && (
          <Heading
            fontWeight={400}
            padding={10}
            fontSize="lg"
            color="#20212380"
            marginY={5}
            textAlign="center"
            hidden={!institutionData?.length}
          >
            Nenhum resultado encontrado para "{search}"
          </Heading>
        )}

        <Box
          display="flex"
          width="full"
          flexDirection="row"
          flexWrap="wrap"
          flex={1}
          gap={5}
          hidden={isLoadingInstitutionData || !institutionDataFiltered.length}
        >
          {institutionDataFiltered?.map(institution => (
            <CardInstitution
              key={institution.id}
              institution={institution}
              setInstitutionId={setInstitutionId}
              setInstitutionName={setInstitutionName}
              onOpenModalAddOrEditInstitution={onOpen}
              onOpenModalDeleteInstitution={onOpenModalDeleteInstitution}
            />
          ))}
        </Box>

        {institutionDataFiltered.length !== 0 && getInstitutionData?.data?.total > 24 && (
          <Stack w="100%" justifyContent="center" alignItems="center" my={10}>
            <Pagination
              pageCount={pageCount}
              onPageChange={handlePageChange}
              onPageActive={currentPage - 1}
            />
          </Stack>
        )}
      </Box>

      <ModalAddOrEditInstitution
        id={institutionId}
        setInstitutionId={setInstitutionId}
        isOpen={isOpen}
        onClose={onClose}
        institutionName={institutionName}
        setInstitutionName={setInstitutionName}
        isSubmitting={isSubmitting}
        isEditingInstitution={isEditingInstitution}
        onSubmit={onSubmit}
        formValidation={formValidation || formValidationEditInstitution}
      />

      <ModalDeleteInstitution
        isOpenModalDeleteInstitution={isOpenModalDeleteInstitution}
        onCloseModalDeleteInstitution={onCloseModalDeleteInstitution}
        handleSubmitDeleteInstitution={handleSubmitDeleteInstitution}
        setInstitutionId={setInstitutionId}
        institutionName={institutionName}
        setInstitutionName={setInstitutionName}
        isDeleting={isDeleting}
      />
    </TabPanel>
  );
}
