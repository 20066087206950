import { Search2Icon } from '@chakra-ui/icons';
import {
  Accordion,
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom';
import imgNoData from '../../../../assets/images/empty.svg';
import Header from '../../../../components/Header/Header';
import DotFalling from '../../../../components/Loadings/DotsFalling';
import MenuDropdown from '../../../../components/MenuDropdown/MenuDropdown';
import NoData from '../../../../components/NoData';
import { useStudentCourse } from '../../../../contexts/CourseStudentContext';
import ProgressContextProvider from '../../../../contexts/ProgressContext';
import { useTheme } from '../../../../contexts/ThemeContext';
import scrollIntoView from '../../../../helpers/scrollIntoView';
import { sortByOrder } from '../../../../helpers/SortHelper';
import { Container } from '../../Main/Container';
import NoContentView from '../NoContentView';
import ProgressBar from './components/ProgressBar';
import ModuleCertificate from './ModuleCertificate';
import Module from './ModulesList/Module';
import styles from './styles.module.css';
import ViewAudio from './ViewAudio';
import ViewCertificate from './ViewCertificate';
import ViewDownload from './ViewDownload';
import ViewLiveYouTube from './ViewLiveYouTube';
import ViewLiveZoom from './ViewLiveZoom';
import ViewMultimedia from './ViewMultimedia';
import ViewPdf from './ViewPdf';
import ViewQuestions from './ViewQuestions';
import ViewTask from './ViewTask';
import ViewText from './ViewText';
import ViewVideo from './ViewVideo';

export const scrollbarStyle = {
  '::-webkit-scrollbar': {
    width: '0.5rem',
    height: '0.5rem',
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.600',
  },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.400',
  },
};

interface IModule {
  id: number;
  name: string;
  order: number;
  contents: {
    id: number;
    contentType: string;
    order: number;
    status: string;
    title: string;
    content: string;
    markAsDone: boolean;
    link: string;
    download: string;
  }[];
  releaseDateModule?: string;
}

function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function filterModulesAndContentByName(search: string, modules: any[]) {
  if (search === '') {
    return modules;
  }

  const normalizedSearch = removeAccents(search).toLowerCase();

  const filteredModules = modules
    ?.map(module => {
      const normalizedModuleName = removeAccents(module.name).toLowerCase();

      // Filtrar conteúdos pelo título que corresponda à pesquisa
      const filteredContents = module.contents.filter(content => {
        const normalizedContentTitle = removeAccents(content.title).toLowerCase();
        return normalizedContentTitle.includes(normalizedSearch);
      });

      // Se algum conteúdo corresponder à pesquisa, mantenha o módulo
      if (filteredContents.length > 0) {
        return { ...module, contents: filteredContents };
      }

      // Se nenhum conteúdo corresponder, verifique se o nome do módulo corresponde à pesquisa
      if (normalizedModuleName.includes(normalizedSearch)) {
        return module;
      }

      // Se não houver correspondências, exclua o módulo
      return null;
    })
    .filter(Boolean); // Remove módulos nulos

  return filteredModules;
}

function ContentView() {
  const [modulesFilters, setModulesFilters] = useState<IModule[]>([]);
  const [search, setSearch] = useState('');
  const [timer, setTimer] = useState(null);
  const [contentIdSelect, setContentIdSelect] = useState(null);

  const { id } = useParams<{ id: string }>();
  const { studentCourse, errorMessage, externalProduct, isLoading } = useStudentCourse();
  const { themeMarketplace } = useTheme();
  const history = useHistory();
  const { setColorMode } = useColorMode();

  const modules = studentCourse?.modules;

  useEffect(() => {
    if (modules) {
      setModulesFilters(modules);
    }
  }, [modules]);

  useEffect(() => {
    setColorMode('light');
  }, [setColorMode]);

  useEffect(() => {
    scrollIntoView('#containerContentsView', true);
  }, []);

  useEffect(() => {
    if (studentCourse && studentCourse.termsOfUse && studentCourse.acceptTermsUser === false) {
      const timer = setTimeout(() => {
        history.push(
          `/members-area/v1/accept-term/${studentCourse.id}/course/${studentCourse.courseUserId}/course-user-id`
        );
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [history, studentCourse]);

  const handleInputChange = event => {
    const { value } = event.target;
    setSearch(value);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimeout = setTimeout(() => {
      const filteredModules = filterModulesAndContentByName(value, modules);
      setModulesFilters(filteredModules);
    }, 500);

    setTimer(newTimeout);
  };

  const headerElement = document.querySelector('.nk-header-fixed') as HTMLElement | null;
  const headerHeight = headerElement?.offsetHeight;

  const sectionElement = document.querySelector('section:nth-child(1)') as HTMLElement | null;
  const firstSectionHeight = sectionElement?.offsetHeight;

  return (
    <ProgressContextProvider>
      <Container className="nk-header-fixed" background={themeMarketplace?.primaryColor}>
        <div id="containerContentsView" className="container-fluid">
          <div className="nk-header-wrap d-flex justify-content-between flex-nowrap header-size">
            <div className="order-sm-0 order-lg-1">
              <Header visibility={true}>
                <MenuDropdown />
              </Header>
            </div>

            <div className="nk-header-brand order-0">
              <Link to="/" className="logo-link mx-0">
                <img src={themeMarketplace.logo} className="brand" alt="logo" />
              </Link>
            </div>
            <div>
              <div
                style={{
                  width: '32px',
                  height: '32px',
                  background: `${themeMarketplace.primaryColor}`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </Container>

      {externalProduct && (
        <div className={styles.thanksContainer}>
          <h1>
            <span>Parabéns</span>
            <br /> por sua compra!
          </h1>
          <div>
            Você já pode aproveitar todas as vantagens do produto
            <span className={styles.courseName}> {externalProduct.productName}</span>. Você receberá
            um e-mail com instruções de acesso.
          </div>
          <Link to="/members-area/v1/products">
            <button>Ver meus produtos</button>
          </Link>
          <div className={styles.support}>
            <p>Tem alguma dúvida? Fale com a gente:</p>
            <p>{externalProduct.emailSupport}</p>
          </div>
        </div>
      )}

      {errorMessage && (
        <Box
          bg="white"
          className="my-4 d-flex justify-content-center flex-column align-items-center"
        >
          <NoData className="animate-top mt-4" svg={imgNoData} width="100%" height="300px" />
          <div>
            <h3>{errorMessage}</h3>
          </div>

          <Link className="btn btn-primary mt-3" to="/members-area/v1/products">
            Voltar
          </Link>
        </Box>
      )}

      {!errorMessage && !externalProduct && (
        <Box bg="white" className={styles.container_geral}>
          <section className={styles.section}>
            <div className="row justify-content-between">
              <div className={styles.container_name_course}>
                <Link className={`nav-link nav-link-2 tab-link`} to={`/members-area/v1/products`}>
                  <BsArrowLeft size="20px" />
                </Link>
                <Text color="#202123" fontSize="20px" fontWeight="600" lineHeight="normal">
                  {studentCourse?.name}
                </Text>
              </div>
            </div>
          </section>

          <section>
            <Flex
              w="full"
              mt={{ base: 2, md: 'initial' }}
              overflowY={{ base: 'scroll', md: 'unset', lg: 'unset' }}
              flexDirection={{ base: 'column-reverse', md: 'row', lg: 'row' }}
              h={{ md: `calc(100vh - ${headerHeight}px - ${firstSectionHeight}px)` }}
            >
              <Flex direction="column" borderRight="1px solid" borderColor="#8091a7" h="full">
                <Box margin="18px" width={{ base: '90%', md: '250px' }}>
                  <ProgressBar />
                </Box>

                <Flex
                  width={{ base: 'full', md: '290px' }}
                  flexDirection="column"
                  overflowY="auto"
                  overflowX="hidden"
                  sx={scrollbarStyle}
                >
                  <InputGroup margin="5px 18px" width={{ base: 'full', md: '250px' }}>
                    <InputLeftElement pointerEvents="none">
                      <Search2Icon fontSize="20px" color="#2021235C" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      width={{ base: '90%', md: '250px', lg: '250px' }}
                      focusBorderColor="primary.500"
                      borderRadius="6px"
                      value={search}
                      onChange={handleInputChange}
                      placeholder="Buscar módulo/conteúdo"
                    />
                  </InputGroup>

                  <Accordion allowMultiple>
                    {modulesFilters?.length > 0 &&
                      modulesFilters
                        ?.sort(sortByOrder)
                        .map(module => (
                          <Module
                            courseId={studentCourse.id}
                            moduleId={module.id}
                            key={nanoid()}
                            title={module.name}
                            contents={module.contents}
                            contentIdSelect={contentIdSelect}
                            setContentIdSelect={setContentIdSelect}
                            releaseDate={module.releaseDateModule}
                          />
                        ))}

                    {!modulesFilters?.length && (
                      <Text
                        margin="5px 18px"
                        color="#20212380"
                        fontWeight={600}
                        width={{ base: 'full', md: '250px', lg: '250px' }}
                      >
                        Nenhum resultado encontrado...
                      </Text>
                    )}

                    {studentCourse?.certificate && studentCourse.certificate.enable && (
                      <ModuleCertificate />
                    )}
                  </Accordion>
                </Flex>
              </Flex>

              {isLoading ? (
                <div className="container d-flex justify-content-center mt-5">
                  <DotFalling />
                </div>
              ) : (
                <Switch>
                  {/* CONTEÚDOS */}
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-download"
                    component={ViewDownload}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-multimedia"
                    component={ViewMultimedia}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-pdf"
                    component={ViewPdf}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-text"
                    component={ViewText}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-video"
                    component={ViewVideo}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-live-zoom"
                    component={ViewLiveZoom}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-live-youtube"
                    component={ViewLiveYouTube}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-audio"
                    component={ViewAudio}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-questions"
                    component={ViewQuestions}
                  />

                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-question-library"
                    component={ViewQuestions}
                  />

                  <Route
                    path="/members-area/v1/products/:courseId/content-view/module/:moduleId/content/:contentId/view-task"
                    component={ViewTask}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view/certificate"
                    component={() => <ViewCertificate courseId={id} />}
                  />
                  <Route
                    path="/members-area/v1/products/:courseId/content-view*"
                    component={NoContentView}
                  />
                </Switch>
              )}
            </Flex>
          </section>
        </Box>
      )}
    </ProgressContextProvider>
  );
}

export default ContentView;
