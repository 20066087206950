import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Hide,
  HStack,
  Input,
  Link,
  Select,
  Skeleton,
  Stack,
  Text,
  Toast,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  ChangeEvent,
  FormEvent,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import ProductAPI from '../../../api/Course';
import UserAPI from '../../../api/User';
import zipCodeAPI from '../../../api/ZipCode';
import { useAuth } from '../../../contexts/AuthContext';
import ErrorResponse from '../../../helpers/ErrorResponse';
import {
  EMAIL_LOCAL_STORAGE,
  NAME_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
} from '../../../helpers/LocalStorageHelper';
import useFetch from '../../../hooks/useFetch';
import useHandleChange, { onlyNumber, TargetType } from '../../../hooks/useHandleChange';
import useHandleSubmit, { ResponseJSON } from '../../../hooks/useHandleSubmit';
import useShoppingCart, {
  IPayment as IInstallment,
  IProductCart,
  IShoppingCart,
} from '../../../hooks/useShoppingCart';
import { FORM_STYLES, INPUT_STYLES } from '../constants';
import CouponBannerApplied from './CouponBannerApplied';
import CouponIcon from './CouponIcon';
import DocumentInput from './DocumentInput';
import Loading from './Loading';
import PaymentMethodsTabs from './PaymentMethods/PaymentMethodsTabs';
import { Status } from './PaymentMethods/Pix/AlertMessage';
import schemaValidator from './schemaValidator';
import SectionTitle from './SectionTitle';
import { IPayment, MethodPaymentType, ProductType, User, UserCard } from './types';
import useLocales from './useLocales';
import findState from './utils/findState';
import getBuyButtonText from './utils/getBuyButtonText';
import getDocumentType from './utils/getDocumentType';
import redirectToThanksPage from './utils/redirectToThanksPage';
import scrollToInvalidInput from '../../../utils/scrollToInvalidInput';
import ZipcodeInput from './ZipcodeInput';
import { GTMEventType } from '../../../ExternalScripts';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../../helpers/ga-tag-custom';
const CountrySelect = lazy(() => import('../../../components/CountrySelect'));

const BRAZILIAN_COUNTRIES = ['br', 'BR', 'Brazil', 'Brasil'];

const origin = window.location.origin;
const termOfUseLink = `${origin}/term-of-use`;

function hasDiscount(product: ProductType) {
  return product.couponAccepted;
}

function isCouponAllowedInSomeProduct(products: Partial<ProductType>[]) {
  return products?.some(hasDiscount);
}

interface PaymentProps {
  standardPaymentValue?: number;
  products: IProductCart[];
  payments: IInstallment[];
  isFreePurchase?: boolean;
  isLoading?: boolean;
  isPurchaseByPixConfirmed?: boolean;
  onIsLoadingChange?: (isLoading: boolean) => void;
  onChangeShoppingCart: () => Promise<IShoppingCart>;
  onPurchaseByPixConfirmedChange?: (isPurchaseByPixConfirmed: boolean) => void;
  utmData?: {
    utm_id?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
  };
  shoppingCartData: IShoppingCart;
}

export default function Payment({
  products,
  payments,
  standardPaymentValue,
  isLoading,
  isFreePurchase,
  isPurchaseByPixConfirmed,
  onPurchaseByPixConfirmedChange,
  onIsLoadingChange,
  onChangeShoppingCart,
  utmData,
  shoppingCartData,
}: PaymentProps) {
  const [country, setCountry] = useState('');
  const [coupon, setCoupon] = useState('');
  const [couponErrorMessage, setCouponErrorMessage] = useState('' || 'Cupom inválido');
  const [pixCode, setPixCode] = useState('');
  const [purchaseId, setPurchaseId] = useState('');
  const [status, setStatus] = useState<Status>('waiting_payment');
  const [userCreditCardsList, setUserCreditCardsList] = useState<UserCard[]>([]);
  const [isAddressFetchedByAPI, setIsAddressFetchedByAPI] = useState(false);
  const [isFetchingZipCode, setIsLoadingZipCode] = useState(false);
  const [isCouponInvalid, setIsCouponInvalid] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);
  const fullName = localStorage.getItem(NAME_LOCAL_STORAGE);
  const email = localStorage.getItem(EMAIL_LOCAL_STORAGE);

  const toast = useToast();

  const {
    updateShoppingCartInLocalStorage,
    clearShoppingCart,
    deleteCouponFromLocalStorage,
    getShoppingCartFromLocalStorage,
  } = useShoppingCart();
  const shoppingCartInLocalStorage = getShoppingCartFromLocalStorage();

  const methodPaymentTypeInLocalStorage =
    shoppingCartInLocalStorage?.methodPaymentType as IPayment['methodPaymentType'];

  const { onUpdateTotalItemsInShoppingCart } = useAuth();
  const history = useHistory();

  const { data: userResponse } = useFetch<UnificadaFront.ResponseJSON<User>>({
    url: '/students/checkout',
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const initialForm: Partial<IPayment> = useMemo(
    () => ({
      document: {
        type: 'cpf',
        number: '',
      },
      address: {
        streetAddress: '',
        neighborhood: '',
        city: '',
        state: '',
        zipCode: '',
        streetNumber: '',
        country: 'br',
        complementary: '',
        alternateAddress: '',
      },
      UTMData: {
        utm_id: utmData?.utm_id,
        utm_source: utmData?.utm_source,
        utm_medium: utmData?.utm_medium,
        utm_campaign: utmData?.utm_campaign,
        utm_term: utmData?.utm_term,
        utm_content: utmData?.utm_content,
      },
      methodPaymentType: methodPaymentTypeInLocalStorage,
    }),
    [
      methodPaymentTypeInLocalStorage,
      utmData?.utm_campaign,
      utmData?.utm_content,
      utmData?.utm_id,
      utmData?.utm_medium,
      utmData?.utm_source,
      utmData?.utm_term,
    ]
  );

  const { form, setForm, handleChange } = useHandleChange<Partial<IPayment>>(
    initialForm,
    userResponse?.data && {
      document: {
        type: getDocumentType(country, userResponse.data.documentNumber),
        number: userResponse?.data?.documentNumber || '',
      },
      address: {
        state: userResponse?.data?.address?.state || '',
        city: userResponse?.data?.address?.city || '',
        neighborhood: userResponse?.data?.address?.neighborhood || '',
        streetAddress: userResponse?.data?.address?.streetAddress || '',
        streetNumber: userResponse?.data?.address?.streetNumber || '',
        zipCode: userResponse?.data?.address?.zipCode || '',
        complementary: userResponse?.data?.address?.complementary || '',
        alternateAddress: userResponse?.data?.address?.alternateAddress || '',
      },
      saveCreditCardForFuturePurchases: false,
      installments: 1,
      creditCard: {
        cardNumber: '',
        cardHolderName: '',
        cardExpirationDate: '',
        cardCVV: '',
        month: '',
        year: '',
      },
    }
  );

  useEffect(() => {
    setForm(prevForm => ({
      ...prevForm,
      coupon: shoppingCartInLocalStorage?.coupon,
      products: products.map(product => ({
        id: Number(product.productId),
        amount: 1,
        poleId: product?.poleId,
        orderBumpId: product?.orderBumpId,
      })),
      affiliation: shoppingCartInLocalStorage?.affiliation,
    }));
  }, [
    products,
    setForm,
    shoppingCartInLocalStorage?.affiliation,
    shoppingCartInLocalStorage?.coupon,
  ]);

  useEffect(() => {
    const registeredCountry = userResponse?.data?.address?.country;

    if (!registeredCountry || BRAZILIAN_COUNTRIES.includes(registeredCountry)) {
      setCountry('br');
      return;
    }

    setCountry(registeredCountry);
  }, [userResponse?.data?.address?.country]);

  useEffect(() => {
    if (!form?.address?.zipCode) {
      setIsAddressFetchedByAPI(false);
    }
  }, [form.address.zipCode]);

  useEffect(() => {
    (async () => {
      try {
        const { data: creditCardData } = await UserAPI.creditCard();
        setUserCreditCardsList(creditCardData);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    })();
  }, []);

  const updateField = useCallback(
    (name: string, value: string) => {
      const event = {
        target: {
          name,
          value,
        },
      };

      handleChange(event as unknown as ChangeEvent<TargetType>);
    },
    [handleChange]
  );

  const handleDocumentNumberChange = useCallback(
    (value: string) => {
      updateField('document.number', value);
    },
    [updateField]
  );

  const GTMEventName = useCallback(
    async (event: GTMEventType, transaction_id?: number) => {
      const idsGTM = googleTagManagerIds();

      let cartProducts = [];

      if (shoppingCartData?.productCart?.length) {
        cartProducts = shoppingCartData?.productCart;

        for (const id of idsGTM) {
          newEventGTM({
            id,
            data: {
              currency: 'BRL',
              event,
              transaction_id,
              name: localStorage.getItem(NAME_LOCAL_STORAGE),
              email: localStorage.getItem(EMAIL_LOCAL_STORAGE),
              zip_code: form.address?.zipCode ?? null,
              payment_method: form.methodPaymentType,
              items: cartProducts.map(product => ({
                item_id: product.productId,
                item_name: product.productName,
                value: product.productPrice,
                quantity: 1,
              })),
              total_value: cartProducts.reduce((total, product) => total + product.productPrice, 0),
            },
          });

          removeEventGTM(id, event);
        }
      }
    },
    [form.address?.zipCode, form.methodPaymentType, shoppingCartData?.productCart]
  );

  async function getAddressByZipCode(zipCode: string) {
    try {
      setIsLoadingZipCode(true);
      const { data: response } = await zipCodeAPI.index(zipCode);

      if (response) {
        setForm({
          ...form,
          address: {
            ...form.address,
            state: response.state,
            city: response.city,
            neighborhood: response.neighborhood,
            streetAddress: response.street,
            zipCode,
            country: 'br',
          },
        });
      }

      if (formValidation?.address?.zipCode) {
        formValidation.address.zipCode.isInvalid = false;
        formValidation.address.zipCode.message = '';
      }
    } catch (error) {
      toast({
        title: 'Não foi possível localizar seu endereço...',
        description: 'Por favor, preencha todos os campos manualmente.',
        status: 'info',
        position: 'bottom-right',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAddressFetchedByAPI(true);
      setIsLoadingZipCode(false);
    }
  }

  const payload = {
    ...form,
    typeCheckout: 'DEFAULT',
    methodPaymentType: isFreePurchase ? 'free' : form.methodPaymentType,
    document: {
      type: getDocumentType(country, form.document.number),
      number: form.document.number,
    },
    address: {
      ...form.address,
      country: country,
      state: form.address.state.toLowerCase(),
    },
    creditCard: {
      ...form.creditCard,
      cardExpirationDate: `${form?.creditCard?.month}${form?.creditCard?.year}`,
    },
    installments: Number(form.installments),
    useSavedCreditCard: !!form.creditCard?.cardId,
  };

  if (payload.methodPaymentType !== 'credit_card') {
    delete payload.creditCard;
  }

  if (
    payload?.creditCard &&
    Object.hasOwn(payload?.creditCard, 'cardId') &&
    !payload.creditCard.cardId
  ) {
    delete payload.creditCard.cardId;
  }

  if (payload?.creditCard?.cardId && payload?.saveCreditCardForFuturePurchases) {
    payload.saveCreditCardForFuturePurchases = false;
  }

  if (payload.useSavedCreditCard) {
    delete payload.creditCard?.cardCVV;
    delete payload.creditCard?.cardExpirationDate;
    delete payload.creditCard?.cardHolderName;
    delete payload.creditCard?.cardNumber;
    delete payload.creditCard?.month;
    delete payload.creditCard?.year;
  }

  const {
    handleSubmit,
    formValidation,
    isLoading: isSubmitting,
  } = useHandleSubmit({
    schemaValidator: schemaValidator,
    data: payload,
    method: 'post',
    url: '/v2/purchases',
    authenticated: true,
    onSuccess: {
      callback: (response: any) => {
        if (payload.methodPaymentType === 'credit_card') {
          GTMEventName('purchase', response?.data?.data?.purchaseId);
        }

        if (payload.methodPaymentType === 'boleto') {
          GTMEventName('billet_payment', response?.data?.data?.purchaseId);
        }

        if (payload.methodPaymentType === 'pix') {
          GTMEventName('pending_payment', response?.data?.data?.purchaseId);
        }

        clearShoppingCart();
        onUpdateTotalItemsInShoppingCart(0);
        window.scrollTo({ top: 0 });

        if (payload.methodPaymentType === 'pix') {
          onPurchaseByPixConfirmedChange(true);
          setPixCode(response.data.data.pixQrCode);
          setPurchaseId(response.data.data.purchaseId);
          return;
        }

        if (payload.methodPaymentType === 'free') {
          history.push('/members-area/v1/products');
          return;
        }

        redirectToThanksPage(
          {
            standardPaymentValue,
            paymentMethod: form.methodPaymentType,
            products: response?.data?.data ? response?.data?.data.products : [],
            memberArea: response?.data?.data?.memberArea,
            emailSupport: response?.data?.data?.emailSupport,
            affiliation: form.affiliation,
            coupon: form.coupon,
            purchaseId: response?.data?.data?.purchaseId,
            fullName,
            email,
            documentNumber: form?.document.number,
            documentType: form?.document.type,
            address: {
              alternateAddress: form?.address?.alternateAddress,
              city: form?.address?.city,
              complementary: form?.address?.complementary,
              country: form?.address?.country,
              neighborhood: form?.address?.neighborhood,
              state: form?.address?.state,
              streetAddress: form?.address?.streetAddress,
              streetNumber: form?.address?.streetNumber,
              zipCode: form?.address?.zipCode,
            },
            utm: utmData,
          },
          history
        );
      },
    },
  });

  const isDisabled = isLoading || isSubmitting || isLoadingStatus;

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      onIsLoadingChange(true);
      scrollToInvalidInput();
      try {
        GTMEventName('processing_payment');
        await handleSubmit<ResponseJSON<any>>();
      } catch (error) {
        GTMEventName('cancelled_payment');
      }
      onIsLoadingChange(false);
    },
    [GTMEventName, handleSubmit, onIsLoadingChange]
  );

  const handleCountryChange = useCallback(
    (value: string) => {
      setCountry(value);
      setForm(prevForm => ({ ...prevForm, ...initialForm }));
      delete formValidation?.address;
    },
    [formValidation?.address, initialForm, setForm]
  );

  function handleZipCodeChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value.length === 8) getAddressByZipCode(value);
    updateField('address.zipCode', value);
  }

  function handleStateChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      address: {
        ...prevForm.address,
        state: value,
      },
    }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleCouponChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setIsCouponInvalid(false);
    setCoupon(value);
  }, []);

  async function redeemCoupon() {
    if (!coupon?.trim()) return;

    updateShoppingCartInLocalStorage({
      coupon: coupon,
    });

    try {
      const response = await onChangeShoppingCart();
      if (!isCouponAllowedInSomeProduct(response?.productCart)) {
        setIsCouponInvalid(true);
        setCoupon('');
      }
    } catch (error) {
      setIsCouponInvalid(true);
      setCouponErrorMessage(error?.response?.data?.message || 'Cupom inválido');
    }
  }

  async function clearCouponInput() {
    deleteCouponFromLocalStorage();
    await onChangeShoppingCart();
    setCoupon('');
  }

  async function verifyPaymentStatus() {
    try {
      setIsLoadingStatus(true);

      window.scrollTo({ top: 0, behavior: 'smooth' });

      const purchaseData = await ProductAPI.verifyPurchaseStatus(purchaseId);

      if (purchaseData) {
        setStatus(purchaseData.purchaseStatus);

        if (purchaseData.purchaseStatus === 'paid') {
          redirectToThanksPage(
            {
              standardPaymentValue,
              paymentMethod: form.methodPaymentType,
              products: products,
              memberArea: purchaseData?.memberArea,
              emailSupport: purchaseData?.emailSupport,
              affiliation: form.affiliation,
              coupon: form.coupon,
              purchaseId: purchaseData?.purchaseId,
              fullName,
              email,
              documentNumber: form?.document.number,
              documentType: form?.document.type,
              address: {
                alternateAddress: form?.address?.alternateAddress,
                city: form?.address?.city,
                complementary: form?.address?.complementary,
                country: form?.address?.country,
                neighborhood: form?.address?.neighborhood,
                state: form?.address?.state,
                streetAddress: form?.address?.streetAddress,
                streetNumber: form?.address?.streetNumber,
                zipCode: form?.address?.zipCode,
              },
              utm: utmData,
            },
            history
          );
        }
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingStatus(false);
    }
  }

  const handleInstallmentChange = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      updateField('installments', value);

      updateShoppingCartInLocalStorage({
        installments: Number(value),
      });

      await onChangeShoppingCart();
    },
    [onChangeShoppingCart, updateField, updateShoppingCartInLocalStorage]
  );

  const handlePaymentMethodChange = useCallback(
    async (value: MethodPaymentType) => {
      setForm({
        ...form,
        methodPaymentType: value,
        installments: 1,
      });

      updateShoppingCartInLocalStorage({
        installments: 1,
        methodPaymentType: value,
      });

      await onChangeShoppingCart();
    },
    [form, onChangeShoppingCart, setForm, updateShoppingCartInLocalStorage]
  );

  const { cities, states } = useLocales(form?.address?.state);
  const couponInLocalStorage = shoppingCartInLocalStorage?.coupon;
  const hasZipCodeRegistered = userResponse?.data?.address?.zipCode;
  const isInternational = country && !BRAZILIAN_COUNTRIES.includes(country);
  const hasZipCodeValidLength = form?.address?.zipCode?.length === 8;
  const shouldDisplayAlert = purchaseId && !isLoading && !isLoadingStatus;

  const isAddressFormShow =
    !isInternational && hasZipCodeValidLength && (hasZipCodeRegistered || isAddressFetchedByAPI);

  return (
    <VStack
      align="center"
      w="full"
      as="form"
      autoComplete="off"
      spacing={8}
      mt={{ base: 8, xl: 'initial' }}
      position="relative"
      onSubmit={onSubmit}
      noValidate
    >
      {/* 1. INFORMAÇÕES DE PAGAMENTO */}

      {!isPurchaseByPixConfirmed && (
        <Box as="section" w="full" sx={FORM_STYLES}>
          <SectionTitle number={1} title="Informações de pagamento" />

          <FormControl isInvalid={formValidation?.address?.country.isInvalid}>
            <FormLabel mt={6} htmlFor="country">
              País
            </FormLabel>
            <Suspense fallback={<Skeleton w="full" h={10} />}>
              <CountrySelect
                value={country}
                onChange={handleCountryChange}
                isInvalid={formValidation?.address?.country.isInvalid}
              />
            </Suspense>
            <FormErrorMessage>{formValidation?.address?.country.message}</FormErrorMessage>
          </FormControl>

          {!isInternational ? (
            <>
              <Stack direction={{ base: 'column', xl: 'row' }} spacing={5} my={8}>
                <FormControl isInvalid={formValidation?.document?.number.isInvalid}>
                  <FormLabel>CPF/CNPJ</FormLabel>
                  <DocumentInput
                    value={form?.document?.number}
                    onChange={handleDocumentNumberChange}
                    {...INPUT_STYLES}
                  />
                  <FormErrorMessage>{formValidation?.document?.number.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={formValidation?.address?.zipCode.isInvalid}>
                  <FormLabel>CEP</FormLabel>
                  <ZipcodeInput
                    name="address.zipCode"
                    value={form.address.zipCode}
                    onChange={handleZipCodeChange}
                    {...INPUT_STYLES}
                  />

                  <FormErrorMessage>{formValidation?.address?.zipCode.message}</FormErrorMessage>
                </FormControl>
              </Stack>

              {!isAddressFormShow && (
                <Link
                  href="https://www.correios.com.br/"
                  color=" #759AED"
                  textDecoration="underline"
                  isExternal
                >
                  Não sabe o CEP e deseja preencher o endereço?
                </Link>
              )}

              {isAddressFormShow && (
                <VStack my={8} spacing={8}>
                  <Stack spacing={5} direction={{ base: 'column', xl: 'row' }}>
                    <FormControl
                      isInvalid={formValidation?.address?.streetAddress.isInvalid}
                      w="full"
                    >
                      <FormLabel>Rua</FormLabel>
                      <Input
                        name="address.streetAddress"
                        value={form?.address?.streetAddress}
                        onChange={handleChange}
                        {...INPUT_STYLES}
                      />
                      <FormErrorMessage>
                        {formValidation?.address?.streetAddress.message}
                      </FormErrorMessage>
                    </FormControl>

                    <HStack spacing={5} w="full" align="start">
                      <FormControl
                        isInvalid={formValidation?.address?.streetNumber.isInvalid}
                        maxWidth="31%"
                      >
                        <FormLabel>Nº</FormLabel>
                        <Input
                          name="address.streetNumber"
                          value={form.address.streetNumber}
                          onChange={handleChange}
                          onInput={onlyNumber}
                          inputMode="numeric"
                          placeholder="XXX"
                          {...INPUT_STYLES}
                        />
                        <FormErrorMessage>
                          {formValidation?.address?.streetNumber.message}
                        </FormErrorMessage>
                      </FormControl>

                      <Hide above="xl">
                        <FormControl flex={1}>
                          <FormLabel>
                            Complemento{' '}
                            <Text as="span" color="#20212380">
                              {'(Opcional)'}
                            </Text>
                          </FormLabel>
                          <Input
                            name="address.complementary"
                            value={form.address.complementary}
                            onChange={handleChange}
                            placeholder="XXX"
                            {...INPUT_STYLES}
                          />
                        </FormControl>
                      </Hide>

                      <Hide below="xl">
                        <FormControl isInvalid={formValidation?.address?.neighborhood.isInvalid}>
                          <FormLabel>Bairro</FormLabel>
                          <Input
                            name="address.neighborhood"
                            value={form.address.neighborhood}
                            onChange={handleChange}
                            {...INPUT_STYLES}
                          />
                          <FormErrorMessage>
                            {formValidation?.address?.neighborhood.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Hide>
                    </HStack>
                  </Stack>

                  <Stack spacing={5} direction={{ base: 'column', xl: 'row' }} w="full">
                    <Hide below="xl">
                      <FormControl flex={1.125}>
                        <FormLabel>
                          Complemento{' '}
                          <Text as="span" color="#20212380">
                            {'(Opcional)'}
                          </Text>
                        </FormLabel>
                        <Input placeholder="XXX" {...INPUT_STYLES} />
                      </FormControl>
                    </Hide>

                    <Hide above="xl">
                      <FormControl
                        isInvalid={formValidation?.address?.neighborhood.isInvalid}
                        flex={1}
                      >
                        <FormLabel>Bairro</FormLabel>
                        <Input
                          name="address.neighborhood"
                          value={form.address.neighborhood}
                          onChange={handleChange}
                          {...INPUT_STYLES}
                        />
                        <FormErrorMessage>
                          {formValidation?.address?.neighborhood.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Hide>

                    <FormControl isInvalid={formValidation?.address?.city.isInvalid} flex={1}>
                      <FormLabel>Cidade</FormLabel>
                      <Select
                        name="address.city"
                        value={form?.address?.city}
                        onChange={handleChange}
                        {...INPUT_STYLES}
                      >
                        <option value="" hidden>
                          Selecione
                        </option>
                        {cities.map(city => (
                          <option key={city.id}>{city.nome}</option>
                        ))}
                      </Select>
                      <FormErrorMessage>{formValidation?.address?.city.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={formValidation?.address?.state.isInvalid} flex={1}>
                      <FormLabel>Estado</FormLabel>
                      <Select
                        name="address.state"
                        value={findState(form?.address?.state, states)}
                        onChange={handleStateChange}
                        {...INPUT_STYLES}
                      >
                        <option value="" hidden>
                          Selecione
                        </option>
                        {states.map(state => (
                          <option key={state.id} value={state.sigla}>
                            {state.nome}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{formValidation?.address?.state.message}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                </VStack>
              )}
            </>
          ) : (
            <VStack spacing={8} my={8}>
              <FormControl isInvalid={formValidation?.document?.number.isInvalid}>
                <FormLabel>Documento</FormLabel>
                <Input
                  name="document.number"
                  value={form.document.number}
                  onChange={handleChange}
                  placeholder="Digite o seu documento"
                  maxLength={30}
                  {...INPUT_STYLES}
                />

                {!form.document.number && !formValidation?.document?.number.isInvalid && (
                  <FormHelperText>
                    Você pode digitar qualquer documento com letras, número e caracteres especiais.
                  </FormHelperText>
                )}

                <FormErrorMessage>{formValidation?.document.number.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formValidation?.address?.alternateAddress.isInvalid}>
                <FormLabel>Endereço</FormLabel>
                <Input
                  name="address.alternateAddress"
                  value={form.address.alternateAddress}
                  onChange={handleChange}
                  placeholder="Digite o seu documento"
                  {...INPUT_STYLES}
                />

                {!form.address.alternateAddress &&
                  !formValidation?.address?.alternateAddress.isInvalid && (
                    <FormHelperText>
                      Você pode digitar letras, número e caracteres especiais.
                    </FormHelperText>
                  )}

                <FormErrorMessage>
                  {formValidation?.address?.alternateAddress.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          )}
        </Box>
      )}

      {/* 2. DESCONTO */}

      {!isFreePurchase && !isPurchaseByPixConfirmed && (
        <Box as="section" w="full" sx={FORM_STYLES}>
          <SectionTitle number={2} title="Desconto" />

          {isCouponAllowedInSomeProduct(products) ? (
            <CouponBannerApplied code={couponInLocalStorage || coupon} onClose={clearCouponInput} />
          ) : (
            <HStack align={isCouponInvalid ? 'center' : 'end'} mt={{ base: 6, xl: 7 }} spacing={5}>
              <FormControl isInvalid={isCouponInvalid}>
                <FormLabel>Cupom de desconto</FormLabel>
                <Input
                  name="coupon"
                  value={coupon}
                  onChange={handleCouponChange}
                  placeholder="Digite aqui"
                  {...INPUT_STYLES}
                />
                <FormErrorMessage>{couponErrorMessage}</FormErrorMessage>
              </FormControl>

              <Box>
                <Button
                  onClick={redeemCoupon}
                  colorScheme="default"
                  borderColor="default.500"
                  color="default.500"
                  variant="outline"
                  fontSize="md"
                  fontWeight="semibold"
                  px={7}
                  rightIcon={<CouponIcon ml={1} />}
                >
                  Aplicar
                </Button>
              </Box>
            </HStack>
          )}
        </Box>
      )}

      {/* 3. FORMA DE PAGAMENTO */}
      {!isFreePurchase && (
        <Box as="section" w="full" sx={FORM_STYLES}>
          {!isPurchaseByPixConfirmed && <SectionTitle number={3} title="Forma de Pagamento" />}

          <PaymentMethodsTabs
            payments={payments}
            isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
            pixCode={pixCode}
            status={status}
            purchaseId={purchaseId}
            saveCreditCardForFuturePurchases={form.saveCreditCardForFuturePurchases}
            value={form.methodPaymentType}
            creditCard={form.creditCard}
            formValidation={formValidation}
            installments={form.installments}
            isInternational={isInternational}
            shouldDisplayAlert={shouldDisplayAlert}
            userCreditCardsList={userCreditCardsList}
            onCreditCardChange={handleChange}
            onPaymentMethodChange={handlePaymentMethodChange}
            onInstallmentChange={handleInstallmentChange}
          />
        </Box>
      )}

      <VStack w="full" spacing={1} mt={8} align="start">
        {role && role !== 'ALUNO' && (
          <Text fontWeight="bold" textAlign="center" color="red.500">
            Seu perfil de usuário não permite a compra de produtos, para continuar, acesse como
            Aluno.
          </Text>
        )}
        <Button
          isDisabled={role !== 'ALUNO' || isDisabled}
          onClick={isPurchaseByPixConfirmed ? verifyPaymentStatus : null}
          type={isPurchaseByPixConfirmed ? 'button' : 'submit'}
          colorScheme="default"
          px="4.5rem"
          fontSize="md"
          fontWeight="semibold"
          w="full"
        >
          {getBuyButtonText(form?.methodPaymentType, isPurchaseByPixConfirmed)}
        </Button>
        <Text as="small" fontSize="xs" color="#202123">
          Ao concluir a compra você concorda com estes{' '}
          <Text
            as="a"
            href={termOfUseLink}
            color="default.500"
            target="_blank"
            rel="noopener noreferrer"
            textDecoration="underline"
            _hover={{ textDecoration: 'underline' }}
          >
            Termos de Serviços
          </Text>
          .
        </Text>
      </VStack>
      <Loading isLoading={isFetchingZipCode} />
    </VStack>
  );
}
