import Joi from 'joi';
import { Webhook } from '../types';

const INVALID_URI_MESSAGE = 'Por favor, forneça uma URL válida. Exemplo: https://www.example.com';

const eventsWebhook = [
  { description: 'Adicionou no carrinho', name: 'ADD_TO_CART' },
  { description: 'Visitou página de vendas', name: 'PAGE_VIEW' },
  { description: 'Inscrição gratuita', name: 'FREE_SIGN_UP' },
  { description: 'Emissão de Boleto', name: 'ISSUANCE_BOLETO' },
  { description: 'Emissão de Pix', name: 'ISSUANCE_PIX' },
  { description: 'Compra Aprovada', name: 'PURCHASE_APPROVED' },
  { description: 'Pagamento Recusado', name: 'PAYMENT_REFUSED' },
  { description: 'Estorno', name: 'REFUNDED' },
  { description: 'Pagamento Estornado', name: 'CHARGEDBACK' },
];

const eventsNameWebhook = eventsWebhook.map(event => event.name);

export const webhookSchemaValidator = Joi.object<Webhook>().keys({
  title: Joi.string().min(3).max(255).required().messages({
    'string.empty': 'Nome do webhook não pode ficar vazio.',
    'string.min': 'Deve ter pelo menos 3 caracteres.',
  }),
  events: Joi.array()
    .items(
      Joi.string()
        .valid(...eventsNameWebhook)
        .required()
    )
    .min(1)
    .required()
    .messages({
      'array.includesRequiredUnknowns': 'Selecione pelo menos 1 evento.',
    }),
  courseId: Joi.number()
    .required()
    .allow(null)
    .messages({ 'any.required': 'Selecione um produto.' }),
  webhookUrl: Joi.string().uri().required().messages({
    'string.uri': INVALID_URI_MESSAGE,
    'string.empty': 'URL não pode ficar vazia.',
    'any.required': 'URL não pode ficar vazia.',
  }),
  status: Joi.string(),
});
