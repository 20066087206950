import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import ErrorResponse from '../helpers/ErrorResponse';
import StudentAPI from '../api/Student';
import Toast from '../components/Toast';
import { useLocation, useParams } from 'react-router-dom';

const StudentCourseContext = createContext();

export default function StudentCourseProvider({ children }) {
  const [studentCourse, setStudentCourse] = useState();
  const [currentContent, setCurrentContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [externalProduct, setExternalProduct] = useState(null);
  const [currentCourseId, setCurrentCourseId] = useState(null);

  const params = useParams();

  const location = useLocation();

  const getCurrentContent = useCallback(
    (moduleId, contentId) => {
      const currentModule = studentCourse?.modules?.find(
        module => module.id === parseInt(moduleId)
      );
      const content = currentModule?.contents?.find(content => content.id === parseInt(contentId));
      if (content) {
        setCurrentContent(content);
      }
    },
    [studentCourse?.modules]
  );

  async function getStudentCourse(id) {
    try {
      if (!id) return;

      setIsLoading(true);

      const studentCourseResponse = await StudentAPI.show(id);

      const { data: studentCourseData } = studentCourseResponse;

      if (studentCourseData) {
        if (studentCourseData?.memberArea === 'cademi') {
          setExternalProduct(studentCourseData);
          return;
        }

        setStudentCourse(studentCourseData);
        setExternalProduct(null);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const [routeWithParams] = Object.values(params);

    const [, route, courseId] = routeWithParams.split('/');

    if (
      location.pathname.includes('members-area') &&
      /\d+/g.test(courseId) &&
      parseInt(courseId) !== currentCourseId &&
      route === 'products'
    ) {
      setCurrentCourseId(parseInt(courseId));
      getStudentCourse(courseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  function onUpdateCurrentContent(moduleId, contentId, newContent) {
    const currentModule = studentCourse?.modules?.find(module => module.id === parseInt(moduleId));
    const content = currentModule?.contents?.find(content => content.id === parseInt(contentId));

    if (content) {
      const updatedModules = studentCourse.modules.map(module => {
        if (module.id !== Number(moduleId)) {
          return module;
        }

        const updatedContents = module.contents.map(moduleContent => {
          if (moduleContent.id === Number(contentId)) {
            return { ...moduleContent, markAsDone: newContent.markAsDone };
          }

          return moduleContent;
        });

        return { ...module, contents: updatedContents };
      });

      setStudentCourse(prevStudentCourse => ({ ...prevStudentCourse, modules: updatedModules }));
    }
  }

  function clearError() {
    setErrorMessage('');
  }

  function getContent(studentCourse, moduleId, contentId) {
    if (!studentCourse || !moduleId || !contentId) return;

    const currentModule = studentCourse.modules.find(module => parseInt(moduleId) === module.id);

    if (!currentModule) return;

    return currentModule.contents.find(content => parseInt(contentId) === content.id);
  }

  return (
    <StudentCourseContext.Provider
      value={{
        studentCourse,
        getStudentCourse,
        getCurrentContent,
        onUpdateCurrentContent,
        currentContent,
        setCurrentContent,
        isLoading,
        errorMessage,
        clearError,
        externalProduct,
        getContent,
      }}
    >
      {children}
    </StudentCourseContext.Provider>
  );
}

export function useStudentCourse() {
  const context = useContext(StudentCourseContext);

  if (!context)
    throw new Error('useStudentCourse deve ser usado dentro de um StudentCourseContext.Provider.');

  return {
    studentCourse: context.studentCourse,
    getStudentCourse: context.getStudentCourse,
    getCurrentContent: context.getCurrentContent,
    onUpdateCurrentContent: context.onUpdateCurrentContent,
    currentContent: context.currentContent,
    setCurrentContent: context.setCurrentContent,
    isLoading: context.isLoading,
    errorMessage: context.errorMessage,
    clearError: context.clearError,
    externalProduct: context.externalProduct,
    getContent: context.getContent,
  };
}
