import { BsArrowRight } from 'react-icons/bs';
import { VscChromeClose } from 'react-icons/vsc';
import { Link, useParams } from 'react-router-dom';
import { Heading } from '../../../../../components/Heading';
import DotFalling from '../../../../../components/Loadings/DotsFalling';
import { useCourse } from '../../../../../contexts/CourseContext';
import { useTheme } from '../../../../../contexts/ThemeContext';
import { PRODUCTS_FILTER } from '../../../../../helpers/LocalStorageHelper';

export default function HeaderCourseManager() {
  const { course } = useCourse();
  const { id } = useParams<{ id: string }>();
  const { themeMarketplace } = useTheme();

  const previousProductsFilters = localStorage.getItem(PRODUCTS_FILTER);

  return (
    <div className="shadow-sm animate-top" style={{ background: themeMarketplace.primaryColor }}>
      <div className="d-flex justify-content-between align-items-center py-3 pl-5">
        <Link to={`/courses?${previousProductsFilters}`}>
          <VscChromeClose
            fill={themeMarketplace.textColor}
            size="2em"
            style={{ cursor: 'pointer' }}
          />
        </Link>

        <div>
          <Heading as="h5" fontSize="2xl" color={themeMarketplace.textColor} id="title-course">
            {course.name ? course.name : <DotFalling />}
          </Heading>
        </div>
        <Link to={`/product/${id}`} className="hover-box-white">
          <h5 style={{ color: themeMarketplace.textColor }}>
            Visualizar Página de Venda <BsArrowRight />
          </h5>
        </Link>
      </div>
    </div>
  );
}
