import { useToast } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { axiosBackend } from '../../../../api/Configuration';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useFetch from '../../../../hooks/useFetch';
import hasFilterParams from '../../../../utils/hasFilterParams';
import { WebhookView } from './types';

interface WebhooksResponse {
  data: WebhookView[];
  page: number;
  per_page: number;
  total: number;
}

export function useWebhook() {
  const [isDeleting, setIsDeleting] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const toast = useToast();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { data: response, loading: isFetching } = useFetch<
    UnificadaFront.ResponseJSON<WebhooksResponse>
  >({
    method: 'get',
    url: `/webhook-proluno?${urlSearchParams.toString()}`,
    authenticated: true,
    autoFetch: true,
  });

  const handleSearchChange = useCallback(
    (search: string) => {
      if (!search) return history.push('/integrations/webhook');

      urlSearchParams.set('page', '1');
      urlSearchParams.set('search', search);

      return history.push({
        pathname: '/integrations/webhook',
        search: urlSearchParams.toString(),
      });
    },
    [history, urlSearchParams]
  );

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  async function handleDeleteWebhook(webhookId: number) {
    try {
      setIsDeleting(true);

      await axiosBackend().delete(`/webhook-proluno/${webhookId}/webhook`);

      toast({
        title: 'Webhook removido com sucesso!',
        status: 'success',
        position: 'top',
      });

      history.push({
        pathname: location.pathname,
        search: location.pathname,
      });
    } catch (error) {
      toast({
        status: 'error',
        title: ErrorResponse(error),
      });
    } finally {
      setIsDeleting(false);
    }
  }

  const webhooks = response?.data?.data;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const isFiltering = hasFilterParams(urlSearchParams);
  const isLoading = isFetching || isDeleting;
  const isEmpty = !isLoading && !webhooks?.length && !isFiltering;
  const isNotFoundVisible = false;
  const isWebhookListVisible = !isLoading && !!webhooks?.length;
  const isPaginationVisible = !isLoading && pageCount > 1;

  return {
    webhooks,
    urlSearchParams,
    isNotFoundVisible,
    isLoading,
    isPaginationVisible,
    isWebhookListVisible,
    isEmpty,
    currentPage,
    pageCount,
    handleSearchChange,
    handlePageChange,
    handleDeleteWebhook,
  };
}
