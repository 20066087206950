import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../components/Layout';
import NotAccess from '../components/NotAccess';
import SuspenseDefault from '../components/SuspenseDefault';
import WithoutAccess from '../components/WithoutAccess';
import StudentCourseProvider from '../contexts/CourseStudentContext';
import { SubscriptionProvider } from '../contexts/SubscriptionContext';
import InternalManager from '../pages/Admin/InternalManager';
import BankDetails from '../pages/BankDetails';
import BankDetailsConfig from '../pages/BankDetails/BankDetailsConfig';
import ComingSoon from '../pages/ComingSoon';
import Affiliations from '../pages/DashboardAdmin/Affiliations';
import Affiliates from '../pages/DashboardAdmin/Affiliations/Affiliates';
import Affiliation from '../pages/DashboardAdmin/Affiliations/Affiliation';
import MyAffiliations from '../pages/DashboardAdmin/Affiliations/MyAffiliations';
import MyAffiliationsDetails from '../pages/DashboardAdmin/Affiliations/MyAffiliations/MyAffiliationsDetails';
import Blog from '../pages/DashboardAdmin/Blog';
import PostBlog from '../pages/DashboardAdmin/Blog/PostBlog';
import Categories from '../pages/DashboardAdmin/Categories';
import Category from '../pages/DashboardAdmin/Categories/Category';
import CheckoutSettings from '../pages/DashboardAdmin/CheckoutSettings';
import CheckoutSettingsEdit from '../pages/DashboardAdmin/CheckoutSettings/CheckoutSettingsEdit';
import Coupons from '../pages/DashboardAdmin/Coupons';
import CourseManager from '../pages/DashboardAdmin/Courses/CourseManager';
import CertificatePreview from '../pages/DashboardAdmin/Courses/CourseManager/Configuration/Certificate/CertificatePreview';
import CorrectionTask from '../pages/DashboardAdmin/Courses/CourseManager/CorrectionTask';
import Courses from '../pages/DashboardAdmin/Courses/Courses';
import NewCourse from '../pages/DashboardAdmin/Courses/New';
import EditProfile from '../pages/DashboardAdmin/EditProfile';
import RevenueSplit from '../pages/DashboardAdmin/Financial/RevenueSplit';
import EditSplitDefault from '../pages/DashboardAdmin/Financial/RevenueSplit/EditRevenueSplit/EditSplitDefault';
import Transaction from '../pages/DashboardAdmin/Financial/Transaction';
import TransactionDetail from '../pages/DashboardAdmin/Financial/TransactionDetail';
import Home from '../pages/DashboardAdmin/Home';
import Instructors from '../pages/DashboardAdmin/Instructors';
import EditInstructor from '../pages/DashboardAdmin/Instructors/EditInstructor';
import NewInstructor from '../pages/DashboardAdmin/Instructors/NewInstructor';
import LeadBase from '../pages/DashboardAdmin/LeadBase';
import Conversions from '../pages/DashboardAdmin/LeadBase/Conversions';
import MembersAreaManagement from '../pages/DashboardAdmin/MembersAreaManagement';
import Netflix from '../pages/DashboardAdmin/MembersAreaManagement/Netflix';
import OrderBump from '../pages/DashboardAdmin/OrderBump';
import OrderBumpManager from '../pages/DashboardAdmin/OrderBump/OrderBumpManager';
import PageBuilder from '../pages/DashboardAdmin/PageBuilder';
import PlatformSettings from '../pages/DashboardAdmin/PlatformSettings';
import PoleManagement from '../pages/DashboardAdmin/PoleManagement';
import Preview from '../pages/DashboardAdmin/Preview';
import Promotions from '../pages/DashboardAdmin/Promotions';
import EditPromotion from '../pages/DashboardAdmin/Promotions/EditPromotion';
import NewPromotion from '../pages/DashboardAdmin/Promotions/NewPromotion';
import { Segmentation } from '../pages/DashboardAdmin/Segmentation';
import { SegmentationCreateOrEdit } from '../pages/DashboardAdmin/Segmentation/CreateOrEdit';
import { LeadList } from '../pages/DashboardAdmin/Segmentation/LeadList';
import PaymentHistory from '../pages/DashboardAdmin/Signatures/PaymentHistory';
import Subscribers from '../pages/DashboardAdmin/Signatures/Subscribers';
import ExternalQuestion from '../pages/DashboardAdmin/Tools/ExternalQuestion';
import AddQuestions from '../pages/DashboardAdmin/Tools/ExternalQuestion/AddQuestions';
import Filter from '../pages/DashboardAdmin/Tools/Filters';
import QuestionLibrary from '../pages/DashboardAdmin/Tools/QuestionLibrary';
import QuestionStatistics from '../pages/DashboardAdmin/Tools/QuestionStatistics';
import QuestionStatisticsPage from '../pages/DashboardAdmin/Tools/QuestionStatistics/QuestionStatisticsPage';
import UserBase from '../pages/DashboardAdmin/UserBase';
import VideoLibrary from '../pages/DashboardAdmin/VideoLibrary';
import Integrations from '../pages/Integrations';
import ModulesEdit from '../pages/DashboardAdmin/MembersAreaManagement/Netflix/ModulesEdit';
import EditOrNewCovers from '../pages/DashboardAdmin/MembersAreaManagement/Netflix/Covers/EditOrNewCovers';
import UtmGenerator from '../pages/DashboardAdmin/UtmGenerator';
import NewUtm from '../pages/DashboardAdmin/UtmGenerator/NewUtm';
import UtmDetails from '../pages/DashboardAdmin/UtmGenerator/UtmDetails';
const Dashboard = lazy(() => import('../pages/DashboardAdmin/Dashboard'));
const Extract = lazy(() => import('../pages/DashboardAdmin/Extract'));
const StudentBase = lazy(() => import('../pages/DashboardAdmin/StudentBase'));
const StudentsReport = lazy(
  () => import('../pages/DashboardAdmin/Courses/CourseManager/StudentsReport')
);
const Reporting = lazy(() => import('../pages/DashboardAdmin/Courses/CourseManager/Reporting'));
const Signatures = lazy(() => import('../pages/DashboardAdmin/Signatures'));

export const routesWithoutSidebar = [
  '/tools/question-library',
  '/course-manager',
  '/checkout-settings/edit',
  '/members-area-management/netflix',
];

export const routesWithoutHeaderAndFooter = ['/launch-checkout'];

function Routes() {
  return (
    <StudentCourseProvider>
      <SubscriptionProvider>
        <Layout>
          <Switch>
            {/* Rota de redirecionamento quando o plano não tem acesso a algum recurso */}
            <Route exact path="/not-access" component={() => <NotAccess />} />
            {/* Rota de redirecionamento quando o usuário não possui acesso a determinado recurso */}
            <Route exact path="/user-without-access" component={() => <WithoutAccess />} />
            <Route exact path="/coming-soon" component={() => <ComingSoon />} />
            <Route
              path="/dashboard/:chartType"
              exact
              component={() => <SuspenseDefault showLogo={true} element={<Dashboard />} />}
            />
            <Route path="/dashboard*">
              <Redirect to="/dashboard/invoicing" />
            </Route>

            <Route path="/profile/user" exact component={EditProfile} />
            <Route path="/profile/bank-accounts" exact component={BankDetails} />
            <Route path="/profile/bank-accounts/config" component={BankDetailsConfig} />

            {/* Gerenciamento de Produtos */}
            <Route exact path="/courses/new" component={NewCourse} />
            <Route
              exact
              path="/courses/product-type"
              component={() => <Redirect to="/courses" />}
            />
            <Route exact path="/courses" component={Courses} />
            <Route exact path="/products" component={Courses} />
            <Route
              exact
              path="/students-report/:id/course*"
              component={() => <SuspenseDefault showLogo={true} element={<StudentsReport />} />}
            />
            <Route
              exact
              path="/reporting/:id/course"
              component={() => <SuspenseDefault showLogo={true} element={<Reporting />} />}
            />
            <Route
              exact
              path="/reporting"
              component={() => <SuspenseDefault showLogo={true} element={<Reporting />} />}
            />
            <Route exact path="/courses/:id/correction-task" component={CorrectionTask} />
            <Route exact path="/courses/:id/course-manager*" component={CourseManager} />
            <Route exact path="/courses/:id*" component={() => <Redirect to="/courses" />} />
            <Route exact path="/courses/*" component={() => <Redirect to="/courses" />} />

            <Route exact path="/checkout-settings" component={CheckoutSettings} />
            <Route path="/checkout-settings/edit/:id" component={CheckoutSettingsEdit} />

            {/* Gerador de UTM */}
            <Route exact path="/utm-generator" component={UtmGenerator} />
            <Route path="/utm-generator/new" component={NewUtm} />
            <Route exact path="/utm-generator/:utmId" component={UtmDetails} />

            {/* Minhas afiliações - Assinante */}
            <Route exact path="/affiliations/products" component={Affiliations} />
            <Route exact path="/affiliations/products/new" component={Affiliation} />
            <Route exact path="/affiliations/products/:id/edit" component={Affiliation} />
            <Route exact path="/affiliations/products/:id/*" component={Affiliates} />
            {/* Minhas afiliações - Afiliado */}
            <Route exact path="/affiliations/affiliates" component={Affiliates} />
            <Route exact path="/affiliations/my-affiliations" component={MyAffiliations} />
            <Route
              exact
              path="/affiliations/my-affiliations/:id"
              component={MyAffiliationsDetails}
            />

            {/* Area de Membros */}
            <Route exact path="/members-area-management" component={MembersAreaManagement} />

            <Route exact path="/members-area-management/netflix" component={Netflix} />

            <Route
              exact
              path="/members-area-management/netflix/:showcaseId/showcase/:showcaseCourseId/showcase-course/modules"
              component={ModulesEdit}
            />

            <Route
              exact
              path="/members-area-management/netflix/new-cover"
              component={EditOrNewCovers}
            />

            <Route
              exact
              path="/members-area-management/netflix/:coverId/edit-cover"
              component={EditOrNewCovers}
            />

            {/* Ferramentas */}
            <Route exact path="/tools/question-library" component={QuestionLibrary} />
            <Route exact path="/tools/filters" component={Filter} />
            <Route exact path="/tools/external-question" component={ExternalQuestion} />
            <Route exact path="/tools/external-question/add-questions" component={AddQuestions} />
            <Route exact path="/tools/question-statistics" component={QuestionStatistics} />
            <Route path="/tools/question-statistics/questions" component={QuestionStatisticsPage} />
            {/* Biblioteca de vídeos */}
            <Route path="/video-library*" component={VideoLibrary} />
            {/* Categorias */}
            <Route exact path="/categories" component={Categories} />
            <Route exact path="/categories/new" component={Category} />
            <Route exact path="/categories/:id/edit" component={Category} />
            {/* Assinaturas */}
            <Route
              exact
              path="/signatures"
              component={() => <SuspenseDefault showLogo={true} element={<Signatures />} />}
            />
            <Route exact path="/signatures/:id/subscribers" component={Subscribers} />
            <Route
              exact
              path="/signatures/:id/payment-history/:subscription_id/subscription"
              component={PaymentHistory}
            />
            {/* Tipo de usuários */}
            <Route exact path="/user-base" component={UserBase} />
            {/* Base de alunos */}
            <Route
              exact
              path="/student-base"
              component={() => <SuspenseDefault showLogo={true} element={<StudentBase />} />}
            />
            <Route
              exact
              path="/lead-base"
              component={() => <SuspenseDefault showLogo={true} element={<LeadBase />} />}
            />
            <Route
              exact
              path="/lead-base/:id/conversions"
              component={() => <SuspenseDefault showLogo={true} element={<Conversions />} />}
            />

            {/* Instrutores */}
            <Route
              exact
              path="/instructors"
              component={() => <SuspenseDefault showLogo={true} element={<Instructors />} />}
            />
            <Route
              path="/instructors/new"
              component={() => <SuspenseDefault showLogo={true} element={<NewInstructor />} />}
            />
            <Route
              path="/instructors/edit/:id"
              component={() => <SuspenseDefault showLogo={true} element={<EditInstructor />} />}
            />

            {/* Página de início */}
            <Route path="/home" exact component={Home} />
            {/* Crie seu site */}
            <Route path="/page-builder*" exact component={PageBuilder} />
            {/* Cupons */}
            <Route path="/coupons*" exact component={Coupons} />
            <Route path="/order-bump" exact component={OrderBump} />
            <Route path="/order-bump/new" exact component={OrderBumpManager} />
            <Route path="/order-bump/edit/:id" exact component={OrderBumpManager} />
            {/* Order Bump */}
            <Route path="/order-bump" exact component={OrderBump} />
            <Route path="/order-bump/new" exact component={OrderBumpManager} />
            <Route path="/order-bump/edit/:id" exact component={OrderBumpManager} />
            {/* Segmentações */}
            <Route path="/segmentation" exact component={Segmentation} />
            <Route path="/segmentation/new" exact component={SegmentationCreateOrEdit} />
            <Route path="/segmentation/:id/edit" exact component={SegmentationCreateOrEdit} />
            <Route path="/segmentation/:id/lead-list" exact component={LeadList} />
            {/* Promoções */}
            <Route path="/promotions" exact component={Promotions} />
            <Route path="/promotions/new" component={NewPromotion} />
            <Route path="/promotions/edit/:id" component={EditPromotion} />
            {/* Plataformas */}
            <Route path="/internal-manager*" exact component={InternalManager} />
            <Route path="/financial" exact component={Transaction} />
            <Route path="/financial/:id" exact component={TransactionDetail} />
            <Route path="/revenue-split/:type" exact component={RevenueSplit} />
            <Route path="/revenue-split/:type/:id" exact component={EditSplitDefault} />
            <Route
              path="/extract"
              exact
              component={() => <SuspenseDefault showLogo={true} element={<Extract />} />}
            />
            <Route exact path="/internal-blog" component={Blog} />
            <Route path="/internal-blog/post/new" component={PostBlog} />
            <Route path="/internal-blog/post/:id/edit" component={PostBlog} />
            <Route path="/settings" component={PlatformSettings} />
            {/* Visualização dos conteúdos */}
            <Route path="/members-area/products/:id*" component={Preview} />
            <Route exact path="/certificate-preview" component={CertificatePreview} />
            <Route exact path="/pole-management" component={PoleManagement} />
            <Route path="/integrations" component={Integrations} />
            <Route path="*" component={() => <Redirect to="/" />} />
          </Switch>
        </Layout>
      </SubscriptionProvider>
    </StudentCourseProvider>
  );
}
export default Routes;
