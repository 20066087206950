import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { FiEye } from 'react-icons/fi';
import { MdArrowBackIos } from 'react-icons/md';
import { Link, useHistory, useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import useHandleSubmit, { ResponseJSON } from '../../../../hooks/useHandleSubmit';
import { ConditionsRegistrationCard } from '../components/ConditionsRegistrationCard';
import {
  createOrUpdateSegmentationSchemaValidator,
  segmentationAnalyzing,
} from './segmentationSchemaValidator';

type ConversionEventType =
  | 'ADD_TO_CART'
  | 'PAGE_VIEW'
  | 'BEGIN_CHECKOUT'
  | 'PURCHASE'
  | 'FREE_SIGN_UP'
  | 'EXTERNAL_FORM'
  | 'REGISTER_DEFAULT'
  | 'REGISTER_CHECKOUT'
  | 'REGISTER_QUESTION';

export interface IConversionEvents {
  id: number;
  name: string;
  description: string;
}

export interface IRules {
  id?: number;
  conversionEvent: ConversionEventType;
  courseId?: number;
  isDenied?: boolean;
  eventTriggerDateStart?: string;
  eventTriggerDateEnd?: string;
}

export interface ISegmentation {
  id?: number;
  name?: string;
  operator?: string;
  rules?: IRules[];
  runOrStatistics?: string;
  runLeadSegmentationAnalysis?: boolean;
}
export interface ISegmentationAnalysis {
  percentage: number;
  total: number;
}

interface IParams {
  id: string;
}
export interface ICourses {
  id: number;
  name: string;
}

const initialState = {
  name: '',
  operator: 'AND',
  rules: [],
};

export const CONVERSION_EVENTS_REGISTER = [
  'REGISTER_DEFAULT',
  'REGISTER_CHECKOUT',
  'REGISTER_QUESTION',
];

const OTHER_CONVERSION_EVENTS = ['ADD_TO_CART', 'PAGE_VIEW', 'BEGIN_CHECKOUT'];

function removeNullEmptyAndEmptyArrayFields(obj) {
  if (obj === null || obj === '') {
    return null;
  }

  if (Array.isArray(obj)) {
    const filteredArray = obj
      .map(item => removeNullEmptyAndEmptyArrayFields(item))
      .filter(item => item !== null);

    return filteredArray.length === 0 ? null : filteredArray;
  }

  if (typeof obj === 'object') {
    const newObj = {};
    for (let key in obj) {
      const value = removeNullEmptyAndEmptyArrayFields(obj[key]);
      if (value !== null) {
        newObj[key] = value;
      }
    }
    return Object.keys(newObj).length === 0 ? null : newObj;
  }

  return obj;
}

export function SegmentationCreateOrEdit() {
  const { id } = useParams<IParams>();
  const history = useHistory();

  const [segmentation, setSegmentation] = useState<ISegmentation>(initialState);
  const [courses, setCourses] = useState<ICourses[]>([]);
  const [discardChanges, setDiscardChanges] = useState(initialState);
  const [hasChanges, setHasChanges] = useState(false);
  const [payloadError, setPayloadError] = useState(false);
  const [segmentationAnalysis, setSegmentationAnalysis] = useState<ISegmentationAnalysis | null>(
    null
  );

  const {
    data: segmentationEdit,
    loading: segmentationEditLoading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<any>>({
    method: 'get',
    url: `/segmentation/${id}`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (segmentationEdit?.data) {
      const segmentationData = removeNullEmptyAndEmptyArrayFields(segmentationEdit?.data);
      setSegmentation(segmentationData);
      setDiscardChanges(segmentationData);
    }
  }, [segmentationEdit?.data, id, fetchData]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [fetchData, id]);

  const { data: response, loading: coursesLoading } = useFetch<UnificadaFront.ResponseJSON<any[]>>({
    method: 'get',
    url: '/courses/list?status=ATIVO_E_NLISTADO',
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    if (response?.data) {
      setCourses(response?.data);
    }
  }, [response?.data]);

  function discardAllChanges() {
    const discardRulesChanges = [...discardChanges.rules];
    setSegmentation({ ...discardChanges, rules: discardRulesChanges });
    setHasChanges(false);
    setPayloadError(false);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;
    setSegmentation({ ...segmentation, [name]: value });
    setHasChanges(true);
    setPayloadError(false);
    setSegmentationAnalysis(null);
  }

  function handleChangeRules(event: ChangeEvent<HTMLSelectElement>, id: number) {
    const { name, value } = event.target;
    const rule = segmentation.rules.find(rule => rule.id === id);
    setSegmentationAnalysis(null);

    if (name === 'event') {
      switch (value) {
        case 'CONVERTED':
          rule.isDenied = false;
          rule.conversionEvent = !OTHER_CONVERSION_EVENTS.includes(rule.conversionEvent)
            ? rule.conversionEvent
            : null;
          break;
        case 'NOT_CONVERTED':
          rule.isDenied = true;
          rule.conversionEvent = !OTHER_CONVERSION_EVENTS.includes(rule.conversionEvent)
            ? rule.conversionEvent
            : null;
          break;
        case 'ADD_TO_CART':
          rule.isDenied = false;
          rule.conversionEvent = 'ADD_TO_CART';
          break;
        case 'NOT_ADD_TO_CART':
          rule.isDenied = true;
          rule.conversionEvent = 'ADD_TO_CART';
          break;
        case 'BEGIN_CHECKOUT':
          rule.isDenied = false;
          rule.conversionEvent = 'BEGIN_CHECKOUT';
          break;
        case 'NOT_BEGIN_CHECKOUT':
          rule.isDenied = true;
          rule.conversionEvent = 'BEGIN_CHECKOUT';
          break;
        case 'PAGE_VIEW':
          rule.isDenied = false;
          rule.conversionEvent = 'PAGE_VIEW';
          break;
        case 'NOT_PAGE_VIEW':
          rule.isDenied = true;
          rule.conversionEvent = 'PAGE_VIEW';
          break;
        case 'REGISTER_DEFAULT':
          rule.conversionEvent = 'REGISTER_DEFAULT';
          delete rule.courseId;
          break;
        case 'REGISTER_CHECKOUT':
          rule.conversionEvent = 'REGISTER_CHECKOUT';
          delete rule.courseId;
          break;
        case 'REGISTER_QUESTION':
          rule.conversionEvent = 'REGISTER_QUESTION';
          delete rule.courseId;
          break;
        default:
          rule.conversionEvent = value as ConversionEventType;
          break;
      }
    } else if (name === 'courseId') {
      rule.courseId = parseInt(value);
    }

    setSegmentation({ ...segmentation, rules: [...segmentation.rules] });
    setHasChanges(true);
  }

  function deleteCondition(id: number) {
    const segmentationFilter = segmentation.rules.filter(rule => rule.id !== id);
    setSegmentation({ ...segmentation, rules: segmentationFilter });
    setHasChanges(true);
    setSegmentationAnalysis(null);
  }

  function addCondition() {
    let maxId: number = 0;

    const allIds = segmentation?.rules.map(rule => rule.id);

    if (allIds.length) {
      maxId = Math.max(...allIds);
    }

    const rules = {
      id: maxId + 1,
      conversionEvent: '' as ConversionEventType,
    };

    const newSegmentation = [...segmentation.rules, rules];

    setSegmentation({ ...segmentation, rules: newSegmentation });
    setPayloadError(false);
    setHasChanges(true);
    setSegmentationAnalysis(null);
  }

  const payload = {
    ...segmentation,
    runLeadSegmentationAnalysis: true,
  };

  if (id) {
    payload.id = parseInt(id);
  }

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit<ISegmentation, ResponseJSON<ISegmentation>>({
    data: payload,
    schemaValidator: createOrUpdateSegmentationSchemaValidator,
    url: '/segmentation',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: `Segmentação ${id ? 'atualizada' : 'criada'} com sucesso!`,
      callback(response) {
        setHasChanges(false);
        const newSegmentationId = response?.data?.data?.id;
        if (newSegmentationId) {
          history.push(`/segmentation`);
        }
      },
    },
  });

  const payloadAnalyzing = {
    runOrStatistics: 'statistics',
    operator: payload.operator,
    rules: segmentation?.rules,
  };

  const { isLoading: isAnalyzing, handleSubmit: handleAnalyzing } = useHandleSubmit<
    ISegmentation,
    ResponseJSON<ISegmentationAnalysis>
  >({
    data: payloadAnalyzing,
    schemaValidator: segmentationAnalyzing,
    url: '/segmentation/lead-segmentation-analysis',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: `Análise de leads concluída com sucesso!`,
      callback(response) {
        setSegmentationAnalysis(response?.data?.data);
      },
    },
  });

  const hideButtonSegmentationAnalysis = isAnalyzing || !!segmentationAnalysis;

  const hideTextAnalysis = isAnalyzing || !segmentationAnalysis;

  function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    if (!payload.rules.length) {
      setPayloadError(true);
      return;
    }

    handleSubmit();
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box as={Link} to="/segmentation">
          <MdArrowBackIos size={15} />
        </Box>
        <Heading fontSize={{ base: '20px', md: '24px', lg: '24px' }} noOfLines={1} fontWeight={600}>
          {id ? 'Editar segmentação' : 'Nova segmentação'}
        </Heading>
      </Stack>

      <Stack paddingY={5} spacing={1} as="form" onSubmit={onSubmit}>
        <FormControl paddingY="25px" isInvalid={formValidation?.name?.isInvalid}>
          <FormLabel fontSize="14px">Nome</FormLabel>
          <FormHelperText fontSize="14px" paddingBottom="8px" color="rgba(32, 33, 35, 0.50)">
            Defina um nome para a sua segmentação
          </FormHelperText>
          <Input
            placeholder="Digite aqui"
            size="sm"
            name="name"
            borderRadius={5}
            focusBorderColor="orange.500"
            value={segmentation.name}
            onChange={handleChange}
          />

          <FormErrorMessage>{formValidation?.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.operator?.isInvalid}>
          <FormLabel fontSize="20px" fontWeight={500}>
            Condições
          </FormLabel>
          <FormHelperText fontSize="14px" color="#202123" paddingY="12px" fontWeight={500}>
            Os leads devem atender:
          </FormHelperText>
          <Select
            size="sm"
            borderRadius={5}
            name="operator"
            focusBorderColor="orange.500"
            color="rgba(32, 33, 35, 0.50)"
            value={segmentation.operator}
            onChange={handleChange}
          >
            <option value="AND">A todas as condições</option>
            <option value="OR">A uma condição</option>
          </Select>
          <FormErrorMessage>{formValidation?.operator?.message}</FormErrorMessage>
          <FormHelperText
            fontSize="14px"
            paddingTop="5px"
            color="rgba(32, 33, 35, 0.50)"
            fontWeight={500}
          >
            Ao selecionar{' '}
            <Text as="span" fontWeight={600}>
              “A todas as condições”
            </Text>{' '}
            a letra “E” irá aparecer entre as condições, simbolizando que o lead deve atender a
            “Condição 1 E Condição 2...” para entrar na segmentação. Ao selecionar{' '}
            <Text as="span" fontWeight={600}>
              “A uma condição”
            </Text>{' '}
            as letras “OU” irão aparecer entre as condições, simbolizando que o lead deve atender a
            “Condição 1 OU Condição 2...”, todos os leads que atendem as condições informadas serão
            selecionados.
          </FormHelperText>
        </FormControl>

        <Box
          display="flex"
          flexDirection="row"
          width="full"
          justifyContent="center"
          hidden={!segmentationEditLoading}
        >
          <Spinner size="lg" color="orange.500" />
        </Box>

        {!segmentationEditLoading &&
          segmentation.rules.length > 0 &&
          segmentation.rules.map((rule, index) => (
            <Box>
              <Text fontSize="16px" hidden={segmentation.rules.length <= 1 || index === 0}>
                {segmentation.operator === 'AND' ? 'E' : 'OU'}
              </Text>

              <ConditionsRegistrationCard
                key={rule.id}
                rule={rule}
                courses={courses}
                coursesLoading={coursesLoading}
                handleChangeRules={handleChangeRules}
                deleteCondition={deleteCondition}
                setHasChanges={setHasChanges}
                setSegmentationAnalysis={setSegmentationAnalysis}
              />
            </Box>
          ))}

        <Box paddingTop={!segmentation.rules.length ? 5 : 0}>
          <Button
            colorScheme="orange"
            variant="outline"
            size="sm"
            width="full"
            onClick={addCondition}
          >
            <Stack direction="row" alignItems="center">
              <BiPlus size={15} />
              <Text>Adicionar condição</Text>
            </Stack>
          </Button>
        </Box>

        <Text color="red.500" marginY={5}>
          {payloadError ? 'Você precisa adicionar uma condição' : ''}
        </Text>

        <Stack
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
          alignItems="center"
          width="full"
          paddingY={5}
          spacing={5}
        >
          <Stack direction="row" color="rgba(32, 33, 35, 0.50)">
            <Box
              as={Button}
              variant="unstyled"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="2px"
              onClick={handleAnalyzing}
              hidden={
                hideButtonSegmentationAnalysis ||
                segmentation.rules.length === 0 ||
                segmentation.rules.some(
                  rule =>
                    (!CONVERSION_EVENTS_REGISTER.includes(rule.conversionEvent) &&
                      !rule.courseId) ||
                    Object.values(rule).some(value => value === null || value === '')
                )
              }
            >
              <FiEye size="14px" />
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="20px"
                textDecorationLine="underline"
              >
                Ver estimativa de leads
              </Text>
            </Box>
            <Box hidden={!isAnalyzing}>
              <Spinner size="sm" color="orange.500" />
            </Box>
            <Text
              hidden={hideTextAnalysis}
              fontFamily="Inter"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="20px"
            >
              Essa segmentação representa{' '}
              <Text as="span" fontWeight={600}>
                {segmentationAnalysis?.percentage}%
              </Text>{' '}
              da sua base de leads. Aproximadamente{' '}
              <Text as="span" fontWeight={600}>
                {segmentationAnalysis?.total}
              </Text>{' '}
              leads.
            </Text>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row', lg: 'row' }}
            justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
            alignItems="center"
            width={{ base: 'full', md: 'auto', lg: 'auto' }}
          >
            <Button
              width={{ base: 'full', md: 'auto', lg: 'auto' }}
              size="sm"
              isDisabled={!hasChanges}
              onClick={discardAllChanges}
            >
              Cancelar
            </Button>
            <Button
              size="sm"
              width={{ base: 'full', md: 'auto', lg: 'auto' }}
              colorScheme="orange"
              isDisabled={isSubmitting || !hasChanges}
              isLoading={isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
