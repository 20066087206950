import { useEffect, useState } from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { MdContentCopy } from 'react-icons/md';
import Toast from '../../../../../../components/Toast';
import { Heading } from '../../../../../../components/Heading';
import styles from './styles.module.css';
import { useCourse } from '../../../../../../contexts/CourseContext';
import { Text } from '@chakra-ui/react';

type InitialStateType = {
  isExternalLinkSale: boolean;
  externalSalePageLink: string;
};

function getCheckoutLink(courseId: number) {
  const location = window.location;

  return `${location.origin}/student-checkout/v3?productId=${courseId}`;
}

function getCheckoutLinkInRegister(courseId: number) {
  const location = window.location;

  return `${location.origin}/student-checkout/v3/register?productId=${courseId}`;
}

function ExternalSalesPage() {
  const [isExternalLinkSale, setIsExternalLinkSale] = useState(false);
  const [externalSalePageLink, setExternalSalePageLink] = useState('');

  const [hasChanged, setHasChanged] = useState(false);

  const [initialState, setInitialState] = useState<InitialStateType>();

  const { course, updateCourse, loading } = useCourse();

  useEffect(() => {
    if (!loading && course) {
      setIsExternalLinkSale(!!course.externalLinkSale);
      setExternalSalePageLink(course?.externalLinkSale || '');

      setInitialState({
        isExternalLinkSale: !!course.externalLinkSale,
        externalSalePageLink: course.externalLinkSale,
      });
    }
  }, [course, loading]);

  function handleCopy(_, result: boolean) {
    if (result) Toast(`Link copiado com sucesso`);
  }

  function handleChange(event) {
    const { value } = event.target;

    if (value === 'prolunoSalePage') {
      setIsExternalLinkSale(false);
      setExternalSalePageLink('');
    } else {
      setIsExternalLinkSale(true);
      setExternalSalePageLink(initialState.externalSalePageLink);
    }

    setHasChanged(true);
  }

  function handleChangeExternalSalePageLink(event) {
    const { value } = event.target;

    setExternalSalePageLink(value);
    setHasChanged(true);
  }

  function handleDiscard() {
    setIsExternalLinkSale(initialState.isExternalLinkSale);
    setExternalSalePageLink(initialState.externalSalePageLink);
    setHasChanged(false);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      await updateCourse({ externalLinkSale: externalSalePageLink, isExternalLinkSale });

      Toast('Curso atualizado com sucesso.');
      setHasChanged(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return (
    <div className="row justify-content-center px-3 my-3">
      <div className="col-lg-8 col-xl-9">
        <form onSubmit={handleSubmit} className="card shadow-sm">
          <div className="card shadow-sm">
            <div className="card-inner">
              <Heading as="h4" fontSize="2xl">
                Tipo de página de vendas
              </Heading>
              <div className="mt-5">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="salePage"
                    id="prolunoSalePage"
                    value="prolunoSalePage"
                    onChange={handleChange}
                    checked={!isExternalLinkSale}
                  />
                  <label
                    className="custom-control-label text-title-label font-weight-bold"
                    htmlFor="prolunoSalePage"
                  >
                    Página de vendas Proluno
                  </label>
                </div>

                <p>
                  Opção para você que deseja vender seus produtos usando a páginas de vendas interna
                  de sua plataforma. Ao selecionar essa opção você precisará cadastrar as
                  informações da página de vendas, como a descrição e o banner principal.
                </p>

                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="salePage"
                    id="externalSalePage"
                    value="externalSalePage"
                    onChange={handleChange}
                    checked={isExternalLinkSale}
                  />

                  <label
                    className="custom-control-label text-title-label font-weight-bold"
                    htmlFor="externalSalePage"
                  >
                    Página de vendas externa
                  </label>
                </div>
                <p>
                  Opção para você que deseja vender seus produtos por uma página externa, como por
                  exemplo uma Landing Page. Você também pode usar o link do checkout de compras para
                  realizar vendas diretas, sem que seus clientes passem por uma página de vendas,
                  basta enviar o link para ele.
                </p>

                {isExternalLinkSale && (
                  <div>
                    <p>Cole aqui o link de sua página de vendas externa.</p>
                    <div className="mb-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <input
                            className="form-control"
                            name="externalSalePageLink"
                            value={externalSalePageLink}
                            type="url"
                            onChange={handleChangeExternalSalePageLink}
                            placeholder="Link da página externa"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row">
                        <div className="col-lg-12">
                          <Text fontWeight="500">
                            Esse é o link do seu checkout de compra. Você pode adicionar esse link
                            no botão de compra de sua página externa ou envia-los para os seus
                            clientes realizarem as comprar do produto.
                          </Text>

                          <Text marginTop={4}>Redirecionamento para o Login do Checkout:</Text>
                          <div className={styles.copyInput}>
                            <div>
                              <p>{getCheckoutLink(course.id)}</p>
                            </div>

                            <CopyToClipboard text={getCheckoutLink(course.id)} onCopy={handleCopy}>
                              <button type="button" className={`${styles.copyButton}`}>
                                <MdContentCopy size={14} />
                                Copiar URL
                              </button>
                            </CopyToClipboard>
                          </div>

                          <Text>Redirecionamento para o Cadastro do Checkout:</Text>
                          <div className={styles.copyInput}>
                            <div>
                              <p>{getCheckoutLinkInRegister(course.id)}</p>
                            </div>

                            <CopyToClipboard
                              text={getCheckoutLinkInRegister(course.id)}
                              onCopy={handleCopy}
                            >
                              <button type="button" className={`${styles.copyButton}`}>
                                <MdContentCopy size={14} />
                                Copiar URL
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-end align-items-center my-4">
                <button type="button" className="btn btn-light px-5 mr-2" onClick={handleDiscard}>
                  Cancelar
                </button>
                <button className="btn btn-primary px-5" disabled={!hasChanged}>
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ExternalSalesPage;
