import { AccordionButton, AccordionIcon, AccordionItem, Flex, Text } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import IconContainer from '../../Icons/IconContainer';
import SubmenuItem, { ISubmenuItemProps } from './SubmenuItem';

export interface IMenuItemProps {
  key?: string;
  name: string;
  icon?: string | any;
  path?: string;
  href?: string;
  submenuItems?: ISubmenuItemProps[];
}

function SubitemList({
  path,
  submenuItems,
}: {
  path?: string;
  submenuItems?: ISubmenuItemProps[];
}) {
  if (!path && submenuItems?.length) {
    return (
      <>
        {submenuItems.map((submenuItem, index) => (
          <SubmenuItem
            key={`${submenuItem.name}${index}`}
            name={submenuItem.name}
            path={submenuItem.path}
          />
        ))}
      </>
    );
  }

  return '';
}

export default function MenuItem({ name, path, href, submenuItems, icon }: IMenuItemProps) {
  const history = useHistory();
  const { pathname } = useLocation();

  const isDisabled = !path && !href && !submenuItems?.length;

  function handleClick() {
    path && history.push(path);
    href && window.open(href, '_blank');
  }

  const isActive = pathname.includes(path);

  return (
    <AccordionItem
      border="none"
      isDisabled={isDisabled}
      color="secondary.500"
      _hover={{
        filter: 'none',
        bg: 'transparent',
      }}
    >
      <AccordionButton
        display="flex"
        justifyContent="space-between"
        onClick={handleClick}
        opacity={isActive ? 1 : 0.64}
        bg={isActive && 'blackAlpha.50'}
        pl={{ base: 0, lg: 7 }}
        py={4}
        _focus={{ outline: 'none' }}
        _hover={{ opacity: 1 }}
        _expanded={{ opacity: 1, bg: 'blackAlpha.50' }}
      >
        <Flex gap="18px" align="center" textAlign="left">
          {icon && <IconContainer name={icon} />}

          <Text as="span" fontSize="sm" fontWeight="semibold">
            {name}
          </Text>
        </Flex>
        <AccordionIcon display={submenuItems?.length ? 'flex' : 'none'} />
      </AccordionButton>
      {SubitemList({ path, submenuItems })}
    </AccordionItem>
  );
}
