import { Center, Flex, Heading, Spinner } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ROLE_LOCAL_STORAGE } from '../../helpers/LocalStorageHelper';
import useFetch from '../../hooks/useFetch';
import IntegrationsCategorySection from './IntegrationsCategorySection';
import IntegrationsFilter from './IntegrationsFilter';
import IntegrationsHeader from './IntegrationsHeader';
import WebhookBanner from './WebhookBanner';
import integrations from './integrations';
import ActiveCampaign from './pages/ActiveCampaign';
import Cademi from './pages/Cademi';
import Facebook from './pages/Facebook';
import Pixel from './pages/Facebook/Pixel';
import GoogleTagManager from './pages/GoogleTagManager';
import JivoChat from './pages/JivoChat';
import Proluno from './pages/Proluno';
import RDStation from './pages/RDStation';
import Webhook from './pages/Webhook';
import EditWebhook from './pages/Webhook/EditWebhook';
import NewWebhook from './pages/Webhook/NewWebhook';
import Zoom from './pages/Zoom';
import ENotas from './pages/eNotas';

type IntegrationStatus = 'ACTIVE' | 'DISABLED';
export interface Integration {
  id: number;
  logo: string;
  category: string;
  categoryTitle: string;
  title: string;
  description: string;
  isImplemented: boolean;
  path: string;
  status?: IntegrationStatus;
}

function booleanToStatusString(value: boolean): IntegrationStatus {
  return !!value ? 'ACTIVE' : 'DISABLED';
}

export default function Integrations() {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const searchQuery = queryParams.get('search') ?? '';
  const statusQuery = queryParams.get('status') ?? '';

  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { data: statusResponse, loading: isLoading } = useFetch<UnificadaFront.ResponseJSON<any>>({
    method: 'get',
    url: '/platforms/integrations-status',
    autoFetch: true,
    authenticated: true,
  });

  const integrationsListWithStatus = integrations.map(integration => ({
    ...integration,
    status: statusResponse && booleanToStatusString(statusResponse.data[integration.name]),
  }));

  const filteredIntegrations = integrationsListWithStatus.filter(integration => {
    return (
      integration.title.toLowerCase().includes(searchQuery?.toLowerCase()) &&
      integration.status?.includes(statusQuery)
    );
  });

  const groupedByCategory = filteredIntegrations.reduce((result, item) => {
    const category = item.category;
    result[category] = result[category] || [];
    result[category].push(item);

    return result;
  }, {});

  const categoryList = Object.keys(groupedByCategory).map(category => {
    const categoryTitle = groupedByCategory[category][0]?.categoryTitle || category;

    const sortedIntegrations = groupedByCategory[category].sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    return {
      title: categoryTitle,
      name: category,
      integrations: sortedIntegrations,
    };
  });

  const isNotFoundVisible = !isLoading && !filteredIntegrations?.length;
  const isIntegrationsListVisible = !isLoading && !!categoryList?.length;

  return (
    <Flex direction="column">
      <Switch>
        <Route exact path="/integrations">
          <IntegrationsHeader />

          <IntegrationsFilter mt={8} />

          <WebhookBanner />

          {isLoading && (
            <Center my={20}>
              <Spinner size="xl" color="primary.500" />
            </Center>
          )}

          {isNotFoundVisible && (
            <Center mt={16}>
              <Heading size="md" fontWeight="semibold" color="gray.300">
                Nenhum resultado encontrado...
              </Heading>
            </Center>
          )}

          {isIntegrationsListVisible &&
            categoryList.map(category => (
              <IntegrationsCategorySection
                key={category.name}
                category={category.title}
                integrations={category.integrations.filter(integration =>
                  role === 'AFILIADO' ? integration.path === 'facebook' : true
                )}
              />
            ))}
        </Route>

        <Route path="/integrations/zoom">
          <Zoom />
        </Route>

        <Route path="/integrations/rd-station">
          <RDStation />
        </Route>

        <Route path="/integrations/e-notas">
          <ENotas />
        </Route>

        <Route path="/integrations/cademi">
          <Cademi />
        </Route>

        <Route exact path="/integrations/proluno" component={Proluno} />
        <Route exact path="/integrations/jivo-chat" component={JivoChat} />
        <Route exact path="/integrations/facebook" component={Facebook} />
        <Route exact path="/integrations/facebook/new-pixel" component={Pixel} />
        <Route exact path="/integrations/facebook/:id/edit-pixel" component={Pixel} />
        <Route exact path="/integrations/google-tag-manager" component={GoogleTagManager} />
        <Route exact path="/integrations/google-tag-manager/:id/gtm" component={GoogleTagManager} />
        <Route exact path="/integrations/webhook" component={Webhook} />
        <Route path="/integrations/webhook/new" component={NewWebhook} />
        <Route path="/integrations/webhook/edit/:id" component={EditWebhook} />
        <Route path="/integrations/active-campaign" component={ActiveCampaign}></Route>
      </Switch>
    </Flex>
  );
}
