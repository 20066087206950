import {
  Box,
  Button,
  Container,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Signatures from '../../../api/Signatures';
import Toast from '../../../components/Toast';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useFetch from '../../../hooks/useFetch';
import useQuery from '../../../hooks/useQuery';
import BootStrapModal from './components/Modal';
import Status from './components/Status';
import StatusPayment from './components/StatusPayment';
import styles from './styles.module.css';
import getPaymentMethod from './utils/getPaymentMethod';

interface IPayment {
  id: number;
  products: {
    id: number;
    name: string;
    price: string;
    amount: number;
  }[];
  value: number;
  paymentMethod: string;
  installments: number;
  date: Date;
  status: string;
  link: string;
  pixQrCode: string;
}

export type IItemType = {
  id: number;
  name: string;
  price: string;
  amount: number;
};

type CourseListType = {
  courseName: string;
  pagarmeIdentifier: number;
};

type TransactionsStudentsDataResult = {
  id: number;
  planName: string;
  amount: number;
  boletoExpirationDate: string;
  boletoUrl: string;
  createdAt: string;
  paymentMethod: string;
  status: string;
  pagarmeIdentifier: number;
  paymentOrder: number;
};

function Payments() {
  const [transactionsStudents, setTransactionsStudents] = useState<
    TransactionsStudentsDataResult[]
  >([]);
  const [paymentList, setPaymentList] = useState<IPayment[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [pixCode, setPixCode] = useState('');
  const [transactionFiltered, setTransactionFiltered] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCancellation,
    onOpen: onOpenCancellation,
    onClose: onCloseCancellation,
  } = useDisclosure();

  const query = useQuery();
  const history = useHistory();

  let paymentOrder = 0;

  const {
    data: transactionsStudentsData,
    loading: isLoadingTransactionsStudents,
    fetchData: fetchDataTransactionsStudents,
  } = useFetch<UnificadaFront.ResponseJSON<TransactionsStudentsDataResult[]>>({
    method: 'get',
    url: `/course-subscription/${subscriptionId}/transactions-student`,
    authenticated: true,
    autoFetch: false,
  });

  const { data: courseSubscriptionListData, loading: courseSubscriptionListLoading } = useFetch<
    UnificadaFront.ResponseJSON<CourseListType[]>
  >({
    method: 'get',
    url: `/course-subscription-list`,
    authenticated: true,
    autoFetch: true,
  });

  const { data: paymentListData, loading: paymentListLoading } = useFetch<
    UnificadaFront.ResponseJSON<IPayment[]>
  >({
    method: 'get',
    url: `/students/purchases-historic`,
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    if (transactionsStudentsData?.data) {
      setTransactionsStudents(transactionsStudentsData?.data);
    }
  }, [transactionsStudentsData?.data]);

  useEffect(() => {
    if (subscriptionId) {
      fetchDataTransactionsStudents();
    }
  }, [fetchDataTransactionsStudents, subscriptionId]);

  useEffect(() => {
    if (paymentListData?.data) {
      setPaymentList(paymentListData?.data);
    }
  }, [paymentListData?.data]);

  useEffect(() => {
    const popState = query.get('popstate');

    if (popState) {
      window.addEventListener('popstate', () => history.go(1));
    }
  });

  function toggleModal(pixQrCode: string) {
    if (pixQrCode) setPixCode(pixQrCode);

    setIsShowModal(prevState => !prevState);
  }

  function handleChangeSelectedProduct(event) {
    const { value } = event.target;

    if (value) {
      setSubscriptionId(parseInt(value));
    } else {
      setSubscriptionId(null);
    }
  }

  function filterTransactionId(products: IItemType[]) {
    setTransactionFiltered(products);
    onOpen();
  }

  async function cancelSubscription(subscriptionId: number) {
    try {
      if (subscriptionId) {
        const cancelSubscription = await Signatures.studentCancelSubscription({
          subscriptionId: subscriptionId,
        });

        if (cancelSubscription.data) {
          setSubscriptionStatus(cancelSubscription.data.subscriptionStatus);
        }

        Toast('Assinatura cancelada com sucesso!', 'success');
        onCloseCancellation();
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return (
    <div className={styles.paymentsContainer}>
      {paymentListLoading ? (
        <Box width="full" display="flex" justifyContent="center" alignItems="center" padding={10}>
          <Spinner size="xl" color="orange.500" />
        </Box>
      ) : (
        <Container maxW="1130px">
          <Tabs colorScheme="orange" isLazy>
            <TabList>
              <Tab>Produtos Online</Tab>
              <Tab>Assinaturas</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box>
                  <TableContainer>
                    <Table variant="simple" colorScheme="orange">
                      <Thead>
                        <Tr>
                          <Th>N° do Pedido</Th>
                          <Th>Valor</Th>
                          <Th>Forma de pagamento</Th>
                          <Th>Parcelas</Th>
                          <Th>Data</Th>
                          <Th>Status</Th>
                        </Tr>
                      </Thead>

                      {paymentList.length > 0 &&
                        paymentList.map(payment => (
                          <>
                            <Tbody>
                              <Tr key={payment.id} cursor="pointer">
                                <Td onClick={() => filterTransactionId(payment.products)}>
                                  <Box color="orange.400" fontWeight="semibold">
                                    #{payment.id}
                                  </Box>
                                </Td>
                                <Td onClick={() => filterTransactionId(payment.products)}>
                                  {convertToBrazilianCurrency(payment.value)}
                                </Td>
                                <Td onClick={() => filterTransactionId(payment.products)}>
                                  {getPaymentMethod(payment.paymentMethod)}
                                </Td>
                                <Td onClick={() => filterTransactionId(payment.products)}>
                                  {payment.installments === 1 ? 'À vista' : payment.installments}
                                </Td>
                                <Td onClick={() => filterTransactionId(payment.products)}>
                                  {dayjs(payment.date).format('DD/MM/YYYY')}
                                </Td>
                                <Td>
                                  <Status
                                    paymentMethod={payment.paymentMethod}
                                    paymentStatus={payment.status}
                                    link={payment.link}
                                    onClick={() => toggleModal(payment.pixQrCode)}
                                  />
                                </Td>
                              </Tr>
                            </Tbody>
                          </>
                        ))}
                    </Table>
                  </TableContainer>
                </Box>
              </TabPanel>
              <TabPanel>
                <Stack width="full" spacing={2} marginY={5}>
                  <Heading fontSize="md">Histórico de Pagamento</Heading>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Text fontSize="md" fontWeight={500}>
                        Escolha o produto:
                      </Text>
                      <Select
                        size="sm"
                        borderRadius={5}
                        width={350}
                        placeholder="Selecione"
                        focusBorderColor="#D86826"
                        value={subscriptionId}
                        onChange={handleChangeSelectedProduct}
                        isDisabled={courseSubscriptionListLoading}
                      >
                        {courseSubscriptionListData &&
                          courseSubscriptionListData?.data.map(product => (
                            <option key={nanoid()} value={product.pagarmeIdentifier}>
                              {product.courseName}
                            </option>
                          ))}
                      </Select>
                    </Stack>

                    <Button
                      hidden={!subscriptionId}
                      style={{ outline: 'none', boxShadow: 'none' }}
                      size="sm"
                      isDisabled={subscriptionStatus === 'canceled'}
                      variant={subscriptionStatus === 'canceled' ? 'solid' : 'outline'}
                      colorScheme="red"
                      onClick={onOpenCancellation}
                    >
                      {subscriptionStatus === 'canceled'
                        ? 'Assinatura cancelada'
                        : 'Cancelar assinatura'}
                    </Button>
                  </Stack>
                </Stack>

                <Box hidden={!transactionsStudents.length || !subscriptionId}>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Pagamento</th>
                        <th scope="col">Nome do Plano</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Data Venc.</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    {transactionsStudents.map((productFiltered, index) => (
                      <tbody>
                        <tr key={index}>
                          <td data-label="Pagamento">
                            {transactionsStudents.length - paymentOrder++}
                          </td>
                          <td data-label="Nome do Plano">{productFiltered.planName}</td>
                          <td data-label="Tipo">
                            {getPaymentMethod(productFiltered.paymentMethod)}
                          </td>
                          <td data-label="Data">
                            {dayjs(productFiltered.boletoExpirationDate).format('DD/MM/YYYY')}
                          </td>
                          <td data-label="Valor">
                            {convertToBrazilianCurrency(productFiltered.amount / 100)}
                          </td>
                          <td data-label="Status">
                            <StatusPayment
                              paymentMethod={productFiltered.paymentMethod}
                              paymentStatus={productFiltered.status}
                              link={productFiltered.boletoUrl}
                              onClick={() => toggleModal(null)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </Box>

                {isLoadingTransactionsStudents && (
                  <Box
                    width="full"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding={10}
                  >
                    <Spinner size="xl" color="orange.500" />
                  </Box>
                )}

                {!transactionsStudents.length &&
                  !isLoadingTransactionsStudents &&
                  subscriptionId && (
                    <Box width="full" display="flex" justifyContent="center" alignItems="center">
                      <Heading fontSize="md">Você não possui assinaturas.</Heading>
                    </Box>
                  )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalHeader>Resumo da Compra</ModalHeader>
          <ModalCloseButton style={{ outline: 'none', boxShadow: 'none' }} />
          <ModalBody>
            <TableContainer>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Produto</Th>
                    <Th>Valor</Th>
                    <Th>Quantidade</Th>
                  </Tr>
                </Thead>
                {transactionFiltered.length > 0 &&
                  transactionFiltered?.map(item => (
                    <Tbody>
                      <Tr>
                        <Td>{item?.name}</Td>
                        <Td> {convertToBrazilianCurrency(item?.price)}</Td>
                        <Td>{item?.amount}</Td>
                      </Tr>
                    </Tbody>
                  ))}
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenCancellation} onClose={onCloseCancellation} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent padding={5}>
          <ModalHeader textAlign="center" fontWeight={700}>
            Tem certeza que deseja cancelar essa assinatura?
          </ModalHeader>
          <ModalCloseButton />

          <ModalFooter justifyContent="center">
            <Stack direction="row" spacing={5}>
              <Button colorScheme="red" onClick={onCloseCancellation} size="sm" width={150}>
                Não
              </Button>
              <Button onClick={() => cancelSubscription(subscriptionId)} size="sm" width={150}>
                Sim
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <BootStrapModal isShow={isShowModal} pixCode={pixCode} onHide={toggleModal} />
    </div>
  );
}

export default Payments;
