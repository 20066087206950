import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GoArrowRight } from 'react-icons/go';
import { HiDotsHorizontal } from 'react-icons/hi';
import { MdDragIndicator, MdOutlineImageNotSupported } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { getStatusColorByName } from '..';
import { IProduct, IShowcase } from '../..';
import { axiosBackend } from '../../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import useHandleSubmit from '../../../../../../../hooks/useHandleSubmit';
import reorder from '../../../../../Courses/CourseManager/ModulesManager/utils/reorder';

interface IProps {
  products: IProduct[];
  showcase: IShowcase;
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
  onOpenCreateBannerModal: () => void;
  onOpenDeleteProductModal: () => void;
  onOpenDeleteProductBannerModal: () => void;
  showcaseCourseId: number;
  setShowcaseCourseId: (id: number) => void;
  setShowcaseSelected: (showcaseInfo: { id: number; name: string; status: string }) => void;
}

export default function TableShowcase({
  products,
  showcase,
  showcases,
  setShowcases,
  onOpenCreateBannerModal,
  onOpenDeleteProductModal,
  onOpenDeleteProductBannerModal,
  setShowcaseSelected,
  showcaseCourseId,
  setShowcaseCourseId,
}: IProps) {
  const history = useHistory();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const toast = useToast();

  const [reorderProductsInShowcases, setReorderProductsInShowcases] = useState(false);

  const productsOrders = products.map(product => {
    return {
      showcaseCourseId: product.showcaseCourseId,
      order: product.order,
    };
  });

  const payloadNewOrder = {
    showcaseCourseOrders: productsOrders,
  };

  const { handleSubmit: updateShowcaseProductsOrder } = useHandleSubmit({
    method: 'patch',
    data: payloadNewOrder,
    url: `/showcase-course/${showcase.showcaseId}/showcase/order-showcase-course`,
    authenticated: true,
    onSuccess: {
      message: 'Produtos da vitrine ordenados com sucesso!',
    },
  });

  useEffect(() => {
    if (reorderProductsInShowcases && showcaseCourseId) {
      updateShowcaseProductsOrder();
      setReorderProductsInShowcases(false);
    }
  }, [showcaseCourseId, reorderProductsInShowcases, updateShowcaseProductsOrder]);

  async function handleStatusChange(event: ChangeEvent<HTMLSelectElement>, productId: number) {
    const { value } = event.target;

    if (!value) return;

    const payload = {
      status: value,
    };

    try {
      const showcaseIndex = showcases.findIndex(
        showcaseData => showcaseData.showcaseId === showcase.showcaseId
      );

      if (showcaseIndex === -1) return;

      const updatedShowcases = [...showcases];

      const showcaseCourse = updatedShowcases[showcaseIndex].products.find(
        product => product.showcaseCourseId === productId
      );

      await axiosBackend().patch(`/showcase-course/${showcaseCourse.showcaseCourseId}`, payload);

      showcaseCourse.statusName = value;

      setShowcases(updatedShowcases);

      toast({
        title: 'O status do produto foi atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const updatedProductsInShowcases = reorder(
      products,
      result.source.index,
      result.destination.index
    );

    const showcaseIndex = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === showcase.showcaseId
    );

    if (showcaseIndex === -1) return;

    const updatedShowcases = [...showcases];

    updatedShowcases[showcaseIndex].products = updatedProductsInShowcases;

    setShowcases(updatedShowcases);

    setReorderProductsInShowcases(true);
  }

  function addBanner(showcaseCourseId: number) {
    setShowcaseCourseId(showcaseCourseId);
    onOpenCreateBannerModal();
  }

  function removeBanner(showcaseCourseId: number) {
    setShowcaseCourseId(showcaseCourseId);
    onOpenDeleteProductBannerModal();
  }

  function deleteProduct(showcaseCourseId: number, showcase: IShowcase) {
    setShowcaseCourseId(showcaseCourseId);
    setShowcaseSelected({
      id: showcase.showcaseId,
      name: showcase.name,
      status: showcase.statusName,
    });
    onOpenDeleteProductModal();
  }

  function editModules(productId: number, showcaseId: number, showcaseCourseId: number) {
    return history.push({
      pathname: `/members-area-management/netflix/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/modules`,
      search: `productId=${productId}`,
    });
  }

  function SelectStatus({ product }) {
    return (
      <Select
        fontSize="14px"
        fontWeight={400}
        focusBorderColor="transparent"
        border="none"
        width={product.statusName === 'ATIVO' ? '75px' : '90px'}
        size="xs"
        ml={-2}
        color={getStatusColorByName(product.statusName)}
        value={product.statusName}
        onChange={event => handleStatusChange(event, product.showcaseCourseId)}
      >
        <option value="ATIVO">Ativo</option>
        <option value="INATIVO">Inativo</option>
      </Select>
    );
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="products">
        {provided => (
          <Box hidden={!products.length} {...provided.droppableProps} ref={provided.innerRef}>
            {products?.map((product, index) => {
              const hiddenRemoveBanner =
                !product.hasCourseThumbnailNetflix || !product.courseThumbnail;

              const bgColor = index % 2 === 0 ? '#20212310' : 'auto';
              return (
                <Draggable
                  key={String(product.showcaseCourseId)}
                  draggableId={String(product.showcaseCourseId)}
                  index={index}
                  isDragDisabled={products?.length < 2}
                >
                  {provided => (
                    <Box
                      padding={{ base: '24px 20px 24px 20px', lg: '24px 30px 24px 30px' }}
                      height="67px"
                      display="flex"
                      gap={{ base: '10px', lg: '30px' }}
                      bgColor={bgColor}
                      alignItems="center"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onMouseEnter={() => setShowcaseCourseId(product.showcaseCourseId)}
                    >
                      <Box width={isMobile ? '80px' : '135px'}>
                        <MdDragIndicator
                          fontSize="24px"
                          color={products.length <= 1 ? 'transparent' : '#202123'}
                        />
                      </Box>
                      <Box width="150px" padding={0} margin={0}>
                        <Box
                          hidden={!!product.courseThumbnail}
                          width="49px"
                          height="62px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          bgColor="#D9D9D9"
                        >
                          <MdOutlineImageNotSupported color="#757475" fontSize="20px" />
                        </Box>

                        {product.courseThumbnail && (
                          <Image
                            width="49px"
                            height="62px"
                            alt="Banner"
                            src={product.courseThumbnail}
                          />
                        )}
                      </Box>
                      <Stack width="full" alignItems="flex-start" spacing={0} textAlign="start">
                        <Text width={{ base: '150px', lg: 'full' }} isTruncated fontSize="14px">
                          {product.courseName}
                        </Text>
                        <Box hidden={!isMobile}>
                          <SelectStatus product={product} />
                        </Box>
                      </Stack>
                      <Box width="full" hidden={isMobile}>
                        {product.contContent > 1
                          ? `${product.contContent} conteúdos`
                          : `${product.contContent} conteúdo`}
                      </Box>
                      <Box width="400px" hidden={isMobile}>
                        <SelectStatus product={product} />
                      </Box>
                      <Box width={isMobile ? '80px' : '60px'}>
                        <Menu>
                          <MenuButton
                            w="27px"
                            h="24px"
                            borderRadius={5}
                            bgColor="#20212312"
                            _hover={{ bgColor: '#20212330' }}
                            _active={{ bgColor: '#20212330' }}
                          >
                            <Stack justifyContent="center" alignItems="center">
                              <HiDotsHorizontal size="18px" color="#00000080" />
                            </Stack>
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              color="#979CA6"
                              fontWeight={500}
                              onClick={() => addBanner(product.showcaseCourseId)}
                            >
                              Adicionar banner
                            </MenuItem>
                            <MenuItem
                              color="#979CA6"
                              fontWeight={500}
                              hidden={hiddenRemoveBanner}
                              onClick={() => removeBanner(product.showcaseCourseId)}
                            >
                              Remover banner
                            </MenuItem>
                            <MenuItem
                              color="#979CA6"
                              fontWeight={500}
                              onClick={() =>
                                editModules(
                                  product.courseId,
                                  showcase.showcaseId,
                                  product.showcaseCourseId
                                )
                              }
                            >
                              Editar módulos
                            </MenuItem>

                            <MenuItem
                              color="#BB2124"
                              fontWeight={500}
                              onClick={() => deleteProduct(product.showcaseCourseId, showcase)}
                            >
                              Remover da vitrine
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Box>
                      <Box width={isMobile ? '80px' : '60px'}>
                        <GoArrowRight
                          fontSize="24px"
                          color="#20212380"
                          cursor="pointer"
                          onClick={() =>
                            editModules(
                              product.courseId,
                              showcase.showcaseId,
                              product.showcaseCourseId
                            )
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}
