import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link as ChakraLink,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IoMdTrophy } from 'react-icons/io';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import useFetch from '../../../../hooks/useFetch';
import useQuery from '../../../../hooks/useQuery';

type CurrentLeadType = {
  id: number;
  fullName: string;
  email: string;
  phone: string;
};

type Conversion = {
  id: number;
  name: string;
  type: string;
  createdAt: string;
  product: string;
};

export default function Conversions() {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const query = useQuery();

  const [conversions, setConversions] = useState<Conversion[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { data, loading } = useFetch<
    UnificadaFront.ResponseJSON<{
      user: CurrentLeadType;
      per_page: number;
      total: number;
      page: number;
      data: Conversion[];
    }>
  >({
    url: `/conversions/${id}/user?page=${currentPage}`,
    method: 'get',
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    let page = query.get('page');

    if (page) {
      try {
        if (Number(page) < 1) {
          return history.push(`/lead-base/${id}/conversions?page=${currentPage}`);
        }

        setCurrentPage(Number(page));
      } catch (error) {
        history.push(`/lead-base/${id}/conversions?page=${currentPage}`);
      }
    }
  }, [history, query, id, currentPage]);

  useEffect(() => {
    if (data?.data) {
      setConversions(data?.data?.data);
      setPageCount(data?.data?.total / data?.data?.per_page);
    }
  }, [data?.data]);

  function handlePageChange({ selected: selectedPage }) {
    history.push(`/lead-base/${id}/conversions?page=${selectedPage + 1}`);
  }

  if (loading && !data) {
    return (
      <Stack width="full" justifyContent="center" alignItems="center" my={2}>
        <Spinner size="lg" color="#EB7129" />
      </Stack>
    );
  }

  return (
    <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} justifyContent="space-between">
      <Stack direction="column" spacing={5}>
        <HStack spacing={2}>
          <IconButton
            onClick={() => history.goBack()}
            icon={<ChevronLeftIcon size="1.75rem" />}
            aria-label="Voltar"
            variant="ghost"
            colorScheme="gray"
            size="sm"
          />

          <Heading fontSize="36px" fontWeight={600}>
            {data?.data?.user.fullName}
          </Heading>
        </HStack>

        <HStack>
          <Text fontSize="16px" color="#20212350" fontWeight="bold">
            E-mail:
          </Text>
          <Text fontSize="16px" color="#20212350">
            {data?.data?.user.email}
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="#20212350" fontWeight="bold">
            Telefone:
          </Text>
          <Text fontSize="16px" color="#20212350">
            {data?.data?.user?.phone || '-'}
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="#20212350" fontWeight="bold">
            Conversões:
          </Text>
          <Text fontSize="16px" color="#20212350">
            {data?.data?.total}
          </Text>
        </HStack>
      </Stack>
      <VStack>
        <List spacing={8} marginTop={8}>
          {conversions.map(lead => (
            <ListItem
              key={lead.id}
              display="flex"
              flexDirection="column"
              sx={{ marginTop: '0!important' }}
            >
              <Flex justifyContent="space-between" width="full" flexDirection="column">
                <HStack width="full" alignItems="center">
                  <Box rounded="full" backgroundColor="gray.100" padding={5}>
                    <IoMdTrophy color="#ce8f62" size={28} />
                  </Box>
                  <VStack alignItems="flex-start" width="full">
                    <Text fontWeight="bold" color="blackAlpha.600">
                      Evento:
                    </Text>
                    <Stack
                      justifyContent="space-between"
                      width="full"
                      flexDirection={{ base: 'column', md: 'row' }}
                    >
                      <Box padding={1} backgroundColor="gray.200" rounded="base">
                        <Text fontWeight="medium" color="blackAlpha.600">
                          {lead.type === 'EXTERNAL_FORM' &&
                          lead.name.match(/Formulário Externos - \[(.*)\]/g) ? (
                            <HStack>
                              <Text>Formulário Externos -</Text>
                              <ChakraLink
                                color="blue.500"
                                href={lead.name.replace(/(.*)\[(.*)\]/, '$2')}
                              >
                                {lead.name.replace(/(.*)\[(.*)\]/, '$2')}
                              </ChakraLink>
                            </HStack>
                          ) : (
                            lead.name
                          )}
                        </Text>
                      </Box>
                      <Box alignContent="flex-end">
                        <Text>{dayjs(lead.createdAt).format('DD/MM/YYYY hh:mm [(GMT] Z[)]')}</Text>
                      </Box>
                    </Stack>
                  </VStack>
                </HStack>
                <Flex padding={0}>
                  <Box padding={4} borderRightWidth={1} borderRightColor="gray.500"></Box>
                  <Box padding={4} borderLeftWidth={1} borderLeftColor="gray.500"></Box>
                </Flex>
              </Flex>
            </ListItem>
          ))}
        </List>
        {conversions.length !== 0 && (
          <Stack width="full" justifyContent="center" alignItems="center" my={2}>
            <Pagination
              pageCount={pageCount}
              onPageChange={handlePageChange}
              onPageActive={currentPage - 1}
            />
          </Stack>
        )}
      </VStack>
    </Stack>
  );
}
