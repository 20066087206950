import { Button, Link, Tooltip } from '@chakra-ui/react';

import { MdOutlineRemoveRedEye as EyeIcon } from 'react-icons/md';
import { useCourse } from '../../../../../contexts/CourseContext';
import useFetch from '../../../../../hooks/useFetch';
import { getContentType } from '../../../../NetflixMemberArea/utils/getContentType';

export type MemberArea = 'proluno' | 'netflix';

interface MemberAreaResponse {
  membersArea: MemberArea;
  lastUpdate: string;
  hasMembersAreaNetflix: boolean;
}

interface ViewAsStudentProps {
  productId: string;
}

export default function ViewAsStudent({ productId }: ViewAsStudentProps) {
  const { course } = useCourse();

  const { data: response, loading: isFetching } = useFetch<
    UnificadaFront.ResponseJSON<MemberAreaResponse>
  >({
    method: 'get',
    url: '/platform/members-area/show',
    autoFetch: true,
    authenticated: true,
  });

  const hasModule = !!course.modules?.length;
  const firstModule = course?.modules?.[0];
  const firstContent = firstModule?.contents?.[0];
  const contentType = getContentType(firstContent?.contentType);
  const hasNetflixMemberArea = response?.data.membersArea === 'netflix';

  const viewAsStudentUrl = hasNetflixMemberArea
    ? `/members-area/v2/course/${productId}/module/${firstModule?.id}/content/${firstContent?.id}/content-view/${contentType}`
    : `/members-area/v1/products/${productId}/content-view`;

  return (
    <Tooltip
      isDisabled={hasModule}
      label="Você não adicionou nenhum módulo"
      hasArrow
      placement="left"
    >
      <Button
        as={Link}
        href={hasModule ? viewAsStudentUrl : null}
        isExternal
        leftIcon={<EyeIcon size={20} />}
        variant="outline"
        colorScheme="blackAlpha"
        marginRight={2}
        textTransform="uppercase"
        isLoading={isFetching}
        isDisabled={!hasModule}
      >
        Ver como Aluno
      </Button>
    </Tooltip>
  );
}
