import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '../../../../../components/ContainerSite/ContainerSite';
import FullPageLoading from '../../../../../components/FullPageLoading';
import useFetch from '../../../../../hooks/useFetch';
import useWindowSize from '../../../../../hooks/useWindowSize';
import HeaderDesktop from './HeaderDesktop';
import HeaderDesktopContainer from './HeaderDesktopContainer';
import HeaderMobile from './HeaderMobile';
import HeaderMobileContainer from './HeaderMobileContainer';
import ReactSlider from './ReactSlider';
import SocialNetworkCard from './SocialNetworkCard';

export interface ISocialNetwork {
  type: string;
  url: string;
}
interface IInstructorResponse {
  id: number;
  fullName: string;
  biography: string;
  photo: string;
  totalProducts: number;
  totalStudents: number;
  socialNetwork: ISocialNetwork[];
  coverBanner: string;
  disciplines: [];
}
interface IInstructorCourse {
  id: number;
  name: string;
  url: string;
  instructor: string;
  description: string;
  thumbnail: string;
  bannerToShow: number;
  banners: [];
  createAt: string;
}
interface IInstructorCourseResponse {
  courses: IInstructorCourse[];
  limit: number;
  total: number;
}

export function InstructorProfile() {
  const { id } = useParams<{ id: string }>();
  const { width } = useWindowSize();
  const [page, setPage] = useState<number>(1);
  const [loadedCourses, setLoadedCourses] = useState([]);
  const [hiddenButton, setHiddenButton] = useState(false);

  const hostname = window.location.hostname;
  const isVorticePlatform = hostname.includes('vorticeconcursos.com.br');

  const instructorId = id;
  const headerMobileVisible = width <= 1024;

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<IInstructorResponse>>({
    method: 'get',
    url: `/users/${instructorId}/instructor`,
    autoFetch: true,
    authenticated: false,
  });

  const { data: courseResponse, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<IInstructorCourseResponse>
  >({
    method: 'get',
    url: `/courses/${instructorId}/instructor?page=${page}`,
    autoFetch: true,
    authenticated: false,
  });

  const courses = courseResponse?.data?.courses;
  const total = courseResponse?.data?.total;

  useEffect(() => {
    if (courses?.length > 0) {
      setLoadedCourses(prevStateCourses => [...prevStateCourses, ...courses]);
    }

    if (!courses?.length || total <= 8) {
      setHiddenButton(true);
    } else {
      setHiddenButton(false);
    }
  }, [courses, total]);

  const instructor = response?.data;

  const coverBanner = instructor?.coverBanner;

  const loadMoreCourses = () => {
    setPage(page + 1);
  };

  return (
    <Container>
      <HeaderDesktopContainer hidden={headerMobileVisible} coverBanner={coverBanner}>
        <HeaderDesktop instructor={instructor} isVorticePlatform={isVorticePlatform} />
      </HeaderDesktopContainer>

      <HeaderMobileContainer isVisible={headerMobileVisible} coverBanner={coverBanner}>
        <HeaderMobile instructor={instructor} isVorticePlatform={isVorticePlatform} />
      </HeaderMobileContainer>

      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        spacing="35px"
        marginX={{ base: '15px', md: '135px', lg: '135px' }}
        marginTop={headerMobileVisible ? '220px' : '35px'}
        marginBottom={{ base: '35px', md: '35px', lg: '35px' }}
      >
        <Box
          dangerouslySetInnerHTML={{ __html: instructor?.biography }}
          hidden={!instructor?.biography}
          width={{ base: 'full', md: '867px', lg: '867px' }}
        />

        <SocialNetworkCard
          socialNetworks={instructor?.socialNetwork}
          hidden={!instructor?.socialNetwork?.filter(social => social.url !== '')?.length}
        />
      </Stack>

      <Stack
        marginX={{ base: '15px', md: '135px', lg: '135px' }}
        spacing="32px"
        marginBottom="30px"
        hidden={!loadedCourses?.length}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Heading
            lineHeight="40px"
            fontWeight={600}
            fontSize={{ base: '16px', md: '32px', lg: '32px' }}
          >
            Produtos
          </Heading>

          <Text
            color="#759AED"
            fontSize={{ base: '12px', md: '18px', lg: '18px' }}
            fontWeight="400"
            textDecorationLine="underline"
            onClick={loadMoreCourses}
            cursor="pointer"
            hidden={hiddenButton}
          >
            Carregar mais
          </Text>
        </Stack>

        <Box width={{ base: 'full', md: 'auto', lg: 'auto' }}>
          <ReactSlider courses={loadedCourses} isLoading={isLoading} />
        </Box>
      </Stack>

      <Heading
        hidden={loadedCourses?.length > 0}
        textAlign="center"
        color="#20212380"
        fontSize="lg"
      >
        Nenhum curso encontrado!
      </Heading>

      <FullPageLoading isLoading={loading} />
    </Container>
  );
}
