import { Link, Route, Switch, useParams } from 'react-router-dom';

import ContentEstrutura from '../../../../../components/ContentEstrutura/ContentEstrutura';
import SidebarEstrutura from '../../../../../components/SidebarEstrutura/SidebarEstrutura';
import BasicSettings from './BasicSettings/BasicSettings';
import Certificate from './Certificate';
import ExternalSalesPage from './ExternalSalesPage';
import FrequentlyQuestions from './FrequentlyQuestions/FrequentlyQuestions';
import ImageDescription from './ImageDescription';
import MembersArea from './MembersArea';
import styles from './styles.module.css';

export default function Configuration() {
  const { id } = useParams();

  return (
    <div className="d-flex px-3 h-100">
      <SidebarEstrutura col={4}>
        <ul
          className={`nav nav-tabs d-flex flex-column ${styles.nav_links}`}
          style={{ borderBottom: 'none' }}
        >
          <li className="nav-item mt-3">
            <Link
              to={`/courses/${id}/course-manager/configuration/basic-configuration`}
              className="nav-link tab-link"
            >
              Configurações básicas
            </Link>
          </li>

          <li className="nav-item tab-link">
            <Link
              to={`/courses/${id}/course-manager/configuration/image-and-description`}
              className="nav-link tab-link"
            >
              Imagens e descrição do curso
            </Link>
          </li>

          <li className="nav-item tab-link">
            <Link
              to={`/courses/${id}/course-manager/configuration/frequently-asked-questions`}
              className="nav-link tab-link"
            >
              Perguntas frequentes
            </Link>
          </li>

          <li className="nav-item tab-link">
            <Link
              to={`/courses/${id}/course-manager/configuration/external-sales-page`}
              className="nav-link tab-link"
            >
              Tipo de página de vendas
            </Link>
          </li>
          <li className="nav-item tab-link">
            <Link
              to={`/courses/${id}/course-manager/configuration/members-area`}
              className="nav-link tab-link"
            >
              Área de membros
            </Link>
          </li>
          <li className="nav-item tab-link">
            <Link
              to={`/courses/${id}/course-manager/configuration/certificate`}
              className="nav-link tab-link"
            >
              Certificado
            </Link>
          </li>
        </ul>
      </SidebarEstrutura>

      <ContentEstrutura col={8} className="mt-4 ml-2">
        <Switch>
          <Route
            exact
            path="/courses/:id/course-manager/configuration/basic-configuration"
            component={BasicSettings}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/image-and-description"
            component={ImageDescription}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/frequently-asked-questions"
            component={FrequentlyQuestions}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/external-sales-page"
            component={ExternalSalesPage}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/members-area"
            component={MembersArea}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/certificate"
            component={Certificate}
          />
        </Switch>
      </ContentEstrutura>
    </div>
  );
}
