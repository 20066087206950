import { Flex, Box, keyframes } from '@chakra-ui/react';

const boxAnimationKeyframes = keyframes`
  0%, 25% { top: 0px;}
  35% { top: calc(0% - 30px); }
  40% { top: calc(0% - 30px); }
  45% { top: calc(0% - 30px); }
  50%, 70% { top: calc(0% - 30px); }
  80%, 100% { top: 100%; }
`;

const animation = `${boxAnimationKeyframes} 2s ease-in-out infinite`;

export default function AnimatedBoxesLoading() {
  return (
    <Flex position="relative" left={{ base: '-20%', md: '-10%', lg: 'unset' }}>
      <Box
        position="absolute"
        width="1rem"
        height="1rem"
        background="#eb7129"
        left={0}
        animation={animation}
        sx={{ animationDelay: '0.5s' }}
      />
      <Box
        position="absolute"
        width="1rem"
        height="1rem"
        background="#759AED"
        left="26px"
        animation={animation}
        sx={{ animationDelay: '0.8s' }}
      />
      <Box
        position="absolute"
        width="1rem"
        height="1rem"
        background="#81BCB7"
        left="52px"
        animation={animation}
        sx={{ animationDelay: '1.1s' }}
      />
      <Box
        position="absolute"
        width="1rem"
        height="1rem"
        background="#EFB8E8"
        left="78px"
        animation={animation}
        sx={{ animationDelay: '1.4s' }}
      />
    </Flex>
  );
}
