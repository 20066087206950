import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { IShowcase } from '..';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  showcaseSelected: {
    id: number;
    name: string;
    status: string;
  };
  showcaseCourseId: number;
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
}

export function DeleteProductModal({
  isOpen,
  onClose,
  showcaseSelected,
  showcaseCourseId,
  showcases,
  setShowcases,
}: IProps) {
  function afterRemoveProductConfirm() {
    const showcaseIndex = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === showcaseSelected.id
    );

    if (showcaseIndex === -1) {
      return onClose();
    }

    const currentShowcases = [...showcases];

    currentShowcases[showcaseIndex].products.filter(
      product => product.showcaseCourseId !== showcaseCourseId
    );

    const productsUpdated = currentShowcases[showcaseIndex].products.filter(
      product => product.showcaseCourseId !== showcaseCourseId
    );

    currentShowcases[showcaseIndex].products = productsUpdated;

    setShowcases(currentShowcases);

    onClose();
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    url: `/showcase-course/${showcaseCourseId}`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Produto removido com sucesso!',
      callback: afterRemoveProductConfirm,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'sm', lg: 'lg' }} isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit} textAlign="center" padding="15px" margin="15px">
        <ModalCloseButton />

        <Box width="full" display="flex" justifyContent="center" color="red.500">
          <IoAlertCircleOutline fontSize="80px" />
        </Box>

        <ModalHeader fontSize="18px" fontWeight={600}>
          Exclusão de produto
        </ModalHeader>

        <ModalBody fontSize="14px" fontWeight={500}>
          Tem certeza que deseja excluir o produto da vitrine?
        </ModalBody>

        <ModalFooter>
          <Stack
            width="full"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button colorScheme="red" width="150px" onClick={onClose}>
              Não
            </Button>
            <Button
              colorScheme="gray"
              width="150px"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Sim
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
