import {
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { BiPlus, BiSearch } from 'react-icons/bi';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { FaThList } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { platformsWithoutUploadVideo } from '../../../../App';
import VideoLibraryAPI from '../../../../api/VideoLibrary';
import emptyVideos from '../../../../assets/images/empty-video-library.svg';
import AlertModal from '../../../../components/AlertModal';
import { Heading } from '../../../../components/Heading';
import Paginate from '../../../../components/Paginate';
import Toast from '../../../../components/Toast';
import { useVideoLibrary } from '../../../../contexts/VideoLibraryContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useQuery from '../../../../hooks/useQuery';
import useWindowSize from '../../../../hooks/useWindowSize';
import { CardVideoLibraryMobileGridView } from './CardVideoLibraryMobileGridView';
import { CardVideoLibraryMobileListView } from './CardVideoLibraryMobileListView';
import { CardVideoLibraryView } from './CardVideoLibraryView';
import ModalVideoUpload from './ModalVideoUpload';
import TableVideoLibrary from './TableVideoLibrary';
import './videolist.css';

const axiosUpload = axios.create();

export const MAX_LENGTH_VIDEO_FILE = 1073741824;
const PLATFORMS_WITH_UPLOAD_LIMITATION = [
  { url: 'educacionalonline.com.br', limit: 100 },
  { url: 'cursopreparar.com.br', limit: 250 },
  { url: 'academiadodinheiro.proluno.com.br', limit: 20 },
];

const initialStateFilter = {
  nameVideo: '',
  activeStatus: true,
  archiveStatus: true,
  filteredVideos: [],
};

export type FIlteredVideosType = {
  archive: boolean;
  description: string;
  download: boolean;
  downloadLink: string;
  duration: number;
  id: number;
  link: string;
  name: string;
  player: string;
  size: number;
  thumbnail: string;
  transcode: string;
  uploadAt: string;
  uri: string;
};

type FilterType = {
  nameVideo: string;
  activeStatus: boolean;
  archiveStatus: boolean;
  filteredVideos: FIlteredVideosType[];
};

export interface VideoView {
  id: number;
  name: string;
  description?: string;
  uri: string;
  link: string;
  player: string;
  archive?: boolean;
  download?: boolean;
  thumbnail: string;
  transcode: string;
  duration: number;
  size: number;
  uploadAt?: string;
  downloadLink?: string;
  upload_link?: string;
}

export interface VideoLibraryItem {
  id?: number;
  name: string;
  thumbnail: string;
  duration: number;
  size: number;
  transcode: string;
  archive: boolean;
  uploadAt: string;
}

export type ListItemFileType = {
  identifier: string;
  file: File;
  videoLibraryItem?: VideoLibraryItem;
  upload: {
    progress: number;
    inProgress?: boolean;
    isComplete?: boolean;
    fail: {
      isError: boolean;
      message: string;
    };
  };
};

type StateType = 'add' | 'upload' | 'remove' | 'update' | 'clear';

export type ActionType = {
  type: StateType;
  listItemFile?: ListItemFileType;
};

export type UpdateStatusTranscodeType = {
  id: number;
  transcode: string;
  thumbnail: string;
  duration?: number;
  name?: string;
};

export type Video = {
  id: number;
  archive: boolean;
  description: string;
  download: boolean;
  downloadLink: string;
  duration: number;
  link: string;
  name: string;
  player: string;
  size: number;
  thumbnail: string;
  transcode: string;
  uploadAt: string;
  uri: string;
};

const reducer = (state: ListItemFileType[], action: ActionType): ListItemFileType[] => {
  switch (action.type) {
    case 'add':
      const fileExists = state.find(
        listItem => listItem.file.name === action.listItemFile.file.name
      );

      const listItemFileUpdated = [...state];

      if (!fileExists) {
        listItemFileUpdated.push({
          ...action.listItemFile,
          upload: {
            ...action.listItemFile.upload,
          },
        });

        return listItemFileUpdated;
      }

      return state;
    case 'upload':
      const listItemFilesToUpload: ListItemFileType[] = [];

      for (const listItemFile of state) {
        if (
          listItemFile.file.name === action.listItemFile.file.name &&
          !listItemFile.upload.inProgress &&
          !listItemFile.upload.isComplete
        ) {
          const currentListItemFile = action.listItemFile;

          listItemFilesToUpload.push({
            ...currentListItemFile,
            upload: {
              ...currentListItemFile.upload,
              inProgress: true,
            },
          });

          continue;
        } else {
          listItemFilesToUpload.push(listItemFile);
        }
      }

      return listItemFilesToUpload;
    case 'update':
      const listItemFilesUploaded: ListItemFileType[] = [];

      for (const listItemFile of state) {
        if (listItemFile.file.name === action.listItemFile.file.name) {
          listItemFilesUploaded.push(action.listItemFile);
        } else {
          listItemFilesUploaded.push(listItemFile);
        }
      }

      return listItemFilesUploaded;
    case 'remove':
      const listItemFileFiltered = state.filter(
        listItemFile => listItemFile.identifier !== action.listItemFile.identifier
      );

      return listItemFileFiltered;
    case 'clear':
      const listItemFilesUploadInProgress = state.filter(
        listItemFile => !listItemFile.upload.isComplete
      );
      return listItemFilesUploadInProgress;
    default:
      throw new Error('Action.type inválido.');
  }
};

function getArchiveStatusModal(search) {
  const query = new URLSearchParams(search);

  const status = query.get('status') || '';

  if (status.match(/^false$/gi)) return 'Desarquivar';
  if (status.match(/^true$/gi)) return 'Arquivar';

  return '...';
}

function getArchiveStatusToast(status) {
  if (status.match(/^false$/gi)) return 'Desarquivado';
  if (status.match(/^true$/gi)) return 'Arquivado';

  return '...';
}

export default function VideoList() {
  const [getLinkLoading, setGetLinkLoading] = useState(false);
  const [listItemFiles, dispatch] = useReducer(reducer, []);
  const [viewMode, setViewMode] = useState('list');
  const [filter, setFilter] = useState<FilterType>(initialStateFilter);
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [search, setSearchVideo] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowSize();
  const query = useQuery();
  const history = useHistory();
  const hostname = window.location.hostname;

  const {
    getVideos,
    total,
    videoLibrary,
    isLoadingVideoLibrary,
    messageVideoLibrary,
    deleteVideoLibrary,
    editVideoLibrary,
  } = useVideoLibrary();

  const {
    isOpen: isOpenModalVideoUpload,
    onOpen: onOpenModalVideoUpload,
    onClose: onCloseModalVideoUpload,
  } = useDisclosure();

  const perPage = 10;
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [currentPage, setCurrentPage] = useState(Number(urlSearchParams.get('page')) || 1);
  const [pageCount, setPageCount] = useState(Math.ceil(Number(total) / perPage));
  const shouldShowPagination = !isLoading && pageCount > 0;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentVideo, setCurrentVideo] = useState(null);

  const listItemFilesIsComplete = listItemFiles.map(item => item.upload.isComplete);

  useEffect(() => {
    if (!isOpen) {
      setCurrentVideo(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!!currentVideo) {
      onOpen();
    } else {
      onClose();
    }
  }, [currentVideo, onClose, onOpen]);

  useEffect(() => {
    if (listItemFilesIsComplete.length && !listItemFilesIsComplete.includes(false)) {
      setIsLoading(false);
      dispatch({ type: 'clear' });
      onCloseModalVideoUpload();
    }
  }, [listItemFilesIsComplete, onCloseModalVideoUpload]);

  useEffect(() => {
    async function fetchVideosByName() {
      try {
        setIsLoading(true);

        const { data: videoSearchData } = await VideoLibraryAPI.indexByName({
          search,
          page: currentPage,
          per_page: 10,
          orderBy: orderBy ? orderBy : 'desc',
        });

        if (videoSearchData) {
          setPageCount(videoSearchData.total / 10);
          setFilter(prevFilter => ({ ...prevFilter, filteredVideos: videoSearchData.videos }));
        } else {
          setFilter(prevFilter => ({ ...prevFilter, filteredVideos: [] }));
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    }

    const timer = setTimeout(() => {
      if (search || orderBy) {
        fetchVideosByName();
      } else {
        setPageCount(total / 10);
        setFilter(prevFilter => ({ ...prevFilter, filteredVideos: videoLibrary }));
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentPage, orderBy, search, total, videoLibrary]);

  useEffect(() => {
    if (!isLoadingVideoLibrary) {
      setPageCount(total / 10);
      setFilter(prevFilter => ({ ...prevFilter, filteredVideos: videoLibrary }));
    }
  }, [isLoadingVideoLibrary, total, videoLibrary]);

  useEffect(() => {
    const action = query.get('action');

    setShowModalArchive(action === 'archive');
    setShowModalDelete(action === 'delete');
  }, [query]);

  function onOpenPreviewVideo(id: number) {
    const video = filter.filteredVideos.find(video => video.id === id);

    if (video) {
      setCurrentVideo(video);
    }
  }

  function toggleViewMode() {
    setViewMode(prevMode => (prevMode === 'list' ? 'grid' : 'list'));
  }

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    const newSearch = urlSearchParams.toString();

    setCurrentPage(newPage);

    history.push(`?${newSearch}`);
  }

  function handleChange(event) {
    const { name, value, checked, type } = event.target;
    const newFilter = { ...filter };

    if (type === 'text') {
      setSearchVideo(value);

      setPageCount(0);
      setCurrentPage(1);
      history.push(`/video-library?page=1`);
    }

    if (type === 'checkbox') {
      newFilter[name] = checked;
    }

    if (name === 'orderBy') {
      setOrderBy(value);
    }

    setFilter({ ...filter, ...newFilter });
  }

  async function onArchive() {
    const id = query.get('id');
    const status = query.get('status').match('true') ? true : false;

    const video = videoLibrary.find(video => video.id === parseInt(id));

    try {
      await editVideoLibrary(id, { ...video, archive: status }, video.thumbnail);
      Toast(`Vídeo ${getArchiveStatusToast(status)} com sucesso`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setShowModalDelete(false);
      onHideModalArchive();
    }
  }

  async function onDelete() {
    setIsLoading(true);

    const id = query.get('id');

    try {
      await deleteVideoLibrary(id);
      Toast('Vídeo excluído com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
      onHideModalDelete();
    }
  }

  function onHideModalArchive() {
    setShowModalDelete(false);
    onCloseModal();
  }

  function onHideModalDelete() {
    setShowModalDelete(false);
    onCloseModal();
  }

  function onCloseModal() {
    history.push('/video-library');
  }

  useEffect(() => {
    if (!search) {
      getVideos({ page: currentPage });
      window.scrollTo(0, 0);
      setIsLoading(false);
    }
  }, [currentPage, getVideos, search]);

  function onAddVideoList(video) {
    setFilter(prevState => {
      const videosAdded = [video, ...prevState.filteredVideos];
      return { ...filter, filteredVideos: videosAdded };
    });
  }

  function updateStatusTranscode({ id, transcode, thumbnail }) {
    const videosAdded = filter.filteredVideos.map(video => {
      if (id === video.id) {
        return { ...video, transcode, thumbnail };
      }

      return video;
    });

    setFilter({ ...filter, filteredVideos: videosAdded });
  }

  function onDeleteVideoList(id) {
    const videosFiltered = filter.filteredVideos.filter(video => video.id !== id);
    setFilter({ ...filter, filteredVideos: videosFiltered });
  }

  function onSubmitFiles() {
    for (const listItemFile of listItemFiles) {
      if (listItemFile.upload.isComplete || listItemFile.upload.inProgress) continue;

      const file = listItemFile.file;

      dispatch({
        listItemFile: {
          ...listItemFile,
          upload: {
            ...listItemFile.upload,
            inProgress: true,
          },
        },
        type: 'update',
      });

      setIsLoading(true);

      VideoLibraryAPI.getUploadLink({
        name: encodeURI(listItemFile.file.name),
      })
        .then(({ data: videoWithUploadLink }) => {
          axiosUpload
            .put(videoWithUploadLink.url, file, {
              onUploadProgress: progressEvent => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                dispatch({
                  listItemFile: {
                    ...listItemFile,
                    upload: {
                      ...listItemFile.upload,
                      inProgress: true,
                      progress,
                    },
                  },
                  type: 'update',
                });
              },
              headers: {
                'Content-Type': 'video/mp4',
              },
            })
            .then(() => {
              VideoLibraryAPI.uploadVideo({
                name: encodeURI(listItemFile.file.name),
                size: listItemFile.file.size,
                link: videoWithUploadLink.url,
              })
                .then(({ data: video }: { data: VideoView }) => {
                  dispatch({
                    listItemFile: {
                      ...listItemFile,
                      videoLibraryItem: {
                        id: video.id,
                        name: video.name,
                        thumbnail: video.thumbnail,
                        uploadAt: video.uploadAt,
                        archive: false,
                        duration: video.duration,
                        size: video.size,
                        transcode: video.transcode,
                      },
                      upload: {
                        ...listItemFile.upload,
                        inProgress: false,
                        isComplete: true,
                      },
                    },
                    type: 'update',
                  });

                  onAddVideoList({
                    name: video.name,
                    thumbnail: video.thumbnail,
                    uploadAt: video.uploadAt,
                    archive: false,
                    duration: video.duration,
                    size: video.size,
                    transcode: video.transcode,
                    id: video.id,
                  });
                })
                .catch(() => {
                  dispatch({
                    listItemFile: {
                      ...listItemFile,
                      upload: {
                        ...listItemFile.upload,
                        fail: {
                          isError: true,
                          message: 'Falha no Upload',
                        },
                      },
                    },
                    type: 'update',
                  });
                });
            })
            .catch(error => {
              Toast(ErrorResponse(error), 'error');
            });
        })
        .catch(() => {
          Toast('Falha ao gerar link de Upload', 'error');
        });
    }
  }

  async function getPlatformStorage() {
    try {
      setIsLoading(true);
      const response = await VideoLibraryAPI.getPlatformStorage();

      return response.data;
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleAddVideoButtonClick() {
    const uploadLimitPlatform = PLATFORMS_WITH_UPLOAD_LIMITATION.find(
      platforms => platforms.url === hostname
    );

    if (uploadLimitPlatform) {
      const { sizeUsage } = await getPlatformStorage();

      if (sizeUsage > uploadLimitPlatform.limit) {
        Toast('Você atingiu o limite máximo de upload de vídeos da sua plataforma.', 'error');
        return;
      }
    }

    onOpenModalVideoUpload();
  }

  return (
    <>
      <HStack width="full" display="flex" justifyContent="space-between">
        <Heading fontSize={{ base: '24px', md: '36px', lg: '36px' }}>Biblioteca de vídeos</Heading>

        <Button
          colorScheme="primary"
          color="secondary.500"
          size="sm"
          onClick={handleAddVideoButtonClick}
          hidden={platformsWithoutUploadVideo.includes(hostname) || width < 700}
          isDisabled={isLoading}
        >
          <BiPlus />

          <Text marginLeft={1}>Adicionar vídeo</Text>
        </Button>
      </HStack>

      <Text
        fontSize={{ base: '15px', md: '18px', lg: '18px' }}
        fontWeight={400}
        color="#20212380"
        marginY={{ base: '10px', md: '20px', lg: '20px' }}
      >
        A biblioteca de vídeos é por onde você irá realizar o upload dos seus vídeos para utilizar
        em seus produtos. Todos os vídeos enviados diretamente no cadastro de seus produtos também
        ficaram armazenados aqui.
      </Text>

      <Button
        colorScheme="primary"
        color="secondary.500"
        size="sm"
        onClick={onOpenModalVideoUpload}
        isDisabled={isLoading}
        hidden={
          platformsWithoutUploadVideo.includes(hostname) ||
          width > 700 ||
          filter.filteredVideos.length === 0
        }
      >
        <BiPlus />
        <Text marginLeft={1}>Adicionar vídeo</Text>
      </Button>

      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        width="full"
        spacing="10px"
        marginTop="10px"
        cursor={isLoading ? 'not-allowed' : 'default'}
      >
        <InputGroup size="sm">
          <InputLeftElement pointerEvents="none">
            <BiSearch color="#20212350" size={20} />
          </InputLeftElement>
          <Input
            type="text"
            name="nameVideo"
            width={{ base: 'full', md: '320px', lg: '320px' }}
            size="sm"
            borderRadius={6}
            focusBorderColor="primary.500"
            placeholder="Digite o nome do vídeo"
            value={search}
            onChange={handleChange}
          />
        </InputGroup>

        <Stack
          spacing="13px"
          alignItems="center"
          justifyContent={{ base: 'space-between', md: 'center', lg: 'center' }}
          cursor={isLoading ? 'not-allowed' : 'default'}
          direction={{ base: 'row-reverse', md: 'row', lg: 'row' }}
        >
          <Box display="flex" gap="13px" marginX={{ base: 0, md: 0, lg: 5 }}>
            <Icon
              as={FaThList}
              boxSize="20px"
              onClick={toggleViewMode}
              cursor="pointer"
              color={viewMode === 'list' ? 'orange.500' : '#202123'}
            />
            <Icon
              as={BsFillGrid3X3GapFill}
              boxSize="20px"
              onClick={toggleViewMode}
              cursor="pointer"
              color={viewMode === 'grid' ? 'orange.500' : '#202123'}
            />
          </Box>

          <Select
            width="179px"
            size="sm"
            focusBorderColor="orange.500"
            borderRadius={6}
            color="#20212380"
            fontSize="14px"
            fontWeight={400}
            name="orderBy"
            value={orderBy}
            onChange={handleChange}
          >
            <option value="" hidden>
              Filtrar por
            </option>
            <option value="asc">Mais antigos</option>
            <option value="desc">Mais recentes</option>
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
          </Select>
        </Stack>
      </Stack>

      {!isLoadingVideoLibrary ? (
        <>
          {viewMode === 'list' && width > 700 && (
            <TableVideoLibrary
              onDeleteVideoList={onDeleteVideoList}
              updateStatusTranscode={updateStatusTranscode}
              videos={filter.filteredVideos}
              onOpenPreviewVideo={onOpenPreviewVideo}
            />
          )}

          {viewMode === 'grid' && width > 700 && (
            <CardVideoLibraryView
              onDeleteVideoList={onDeleteVideoList}
              updateStatusTranscode={updateStatusTranscode}
              videos={filter.filteredVideos}
              onOpenPreviewVideo={onOpenPreviewVideo}
            />
          )}

          {viewMode === 'list' && width < 700 && (
            <CardVideoLibraryMobileListView
              onDeleteVideoList={onDeleteVideoList}
              updateStatusTranscode={updateStatusTranscode}
              videos={filter.filteredVideos}
              onOpenPreviewVideo={onOpenPreviewVideo}
            />
          )}

          {viewMode === 'grid' && width < 700 && (
            <CardVideoLibraryMobileGridView
              onDeleteVideoList={onDeleteVideoList}
              updateStatusTranscode={updateStatusTranscode}
              videos={filter.filteredVideos}
              onOpenPreviewVideo={onOpenPreviewVideo}
            />
          )}

          {shouldShowPagination && (
            <Stack padding={5} justifyContent="center" alignItems="center">
              <Paginate
                pageCount={pageCount}
                onPageChange={handlePageChange}
                initialPage={currentPage}
              />
            </Stack>
          )}
        </>
      ) : (
        <Box width="full" textAlign="center" justifyContent="center" marginY="100px">
          <Spinner size="xl" colorScheme="primary" />
        </Box>
      )}

      {!filter.filteredVideos.length && !isLoadingVideoLibrary && (
        <Box display="flex" width="full" alignItems="center" justifyContent="center" marginY={10}>
          <Image
            width={{ base: '300px', md: '400px', lg: '450px' }}
            src={emptyVideos}
            alt="Sem vídeos"
          />
        </Box>
      )}

      <Button
        colorScheme="primary"
        color="secondary.500"
        size="sm"
        onClick={onOpenModalVideoUpload}
        isDisabled={isLoading}
        hidden={
          platformsWithoutUploadVideo.includes(hostname) ||
          width > 700 ||
          filter.filteredVideos.length > 0
        }
      >
        <BiPlus />
        <Text marginLeft={1}>Adicionar vídeo</Text>
      </Button>

      {messageVideoLibrary && (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h3 className="mt-4">{messageVideoLibrary}</h3>
        </div>
      )}

      <AlertModal
        showModal={showModalDelete}
        onHideModal={onHideModalDelete}
        title="Tem certeza que deseja excluir este vídeo?"
        actionButton={{ nameActionButton: 'Sim', onActionButton: onDelete }}
        cancelableButton={{
          nameCancelableButton: 'Não',
          onCancelableButton: onHideModalDelete,
        }}
        message=""
        disabled={isLoading}
      />

      <AlertModal
        showModal={showModalArchive}
        onHideModal={onHideModalArchive}
        title={`Tem certeza que deseja ${getArchiveStatusModal(query.get('status'))} este vídeo?`}
        actionButton={{ nameActionButton: 'Sim', onActionButton: onArchive }}
        cancelableButton={{
          nameCancelableButton: 'Não',
          onCancelableButton: onHideModalArchive,
        }}
        message=""
        disabled={isLoadingVideoLibrary}
      />

      <ModalVideoUpload
        isLoading={isLoading}
        isOpen={isOpenModalVideoUpload}
        onClose={onCloseModalVideoUpload}
        getLinkLoading={getLinkLoading}
        setGetLinkLoading={setGetLinkLoading}
        dispatch={dispatch}
        listItemFiles={listItemFiles}
        onSubmitFiles={onSubmitFiles}
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'xs', md: 'md', lg: 'lg' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalHeader textAlign="center" fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>
            {currentVideo?.name}
          </ModalHeader>

          <ModalBody>
            <Box
              as="iframe"
              width="full"
              height={{ base: 150, md: 250, lg: 300 }}
              src={currentVideo?.player}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={currentVideo?.name}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
