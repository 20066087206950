import {
  Box,
  Container,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';
import useQuery from '../../../../hooks/useQuery';
import Covers from './Covers';
import Header from './Header';
import Showcases from './Showcases';

const TAB_STYLE = {
  color: '#20212380',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  _active: { color: 'primary.500' },
  _selected: { color: 'primary.500' },
};

export const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.4rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.4rem',
    paddingLeft: '0.4rem',
    borderRadius: 'md',
    backgroundColor: 'primary.300',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'primary.400',
  },
};

export default function Netflix() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const query = useQuery();

  const tabIndex = parseInt(query.get('tab')) || 0;

  return (
    <Box maxH="100vh">
      <Header />

      <Container
        as="main"
        w="full"
        maxW="container.xl"
        padding={isMobile ? '32px 0px 40px 0px' : '56px 0px 40px 0px'}
      >
        <Tabs defaultIndex={tabIndex} isLazy>
          <TabList>
            <Tab sx={TAB_STYLE}>Vitrines</Tab>
            <Tab sx={TAB_STYLE}>Capas</Tab>
          </TabList>

          <TabIndicator mt="-1.5px" height="3px" bg="primary.500" borderRadius="2px" />

          <TabPanels>
            <Showcases />

            <Covers />
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  );
}
