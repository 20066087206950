import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Icon,
} from '@chakra-ui/react';
import Joi from 'joi';
import { FormEvent, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useHandleChange from '../../../../../../../hooks/useHandleChange';
import useHandleSubmit, { ResponseJSON } from '../../../../../../../hooks/useHandleSubmit';
import CoursePriceSchema, {
  CoursePriceType,
  FormOfPaymentType,
} from '../../../../../../../validation/CoursePriceSchema';
import { EntitySchema } from '../../../../../../../validation/EntitySchema';
import PurchasePrice from './PurchasePrice';
import { useCourse } from '../../../../../../../contexts/CourseContext';
import { RiArrowLeftSLine } from 'react-icons/ri';
import VariedPrices from './VariedPrices';

function getTabIndexByFormOfPayment(formOfPayment: FormOfPaymentType) {
  switch (formOfPayment) {
    case 'FREE':
      return 1;
    case 'INSTALLMENTS_WITH_FEE':
      return 2;
    case 'INSTALLMENTS_WITHOUT_FEE':
      return 3;
    default:
      return 0;
  }
}

const createPurchasePriceSchema: Partial<EntitySchema<CoursePriceType>> = {
  default: CoursePriceSchema.default,
  formOfPayment: CoursePriceSchema.formOfPayment.required().messages({
    'any.required': 'Selecione uma forma de pagamento.',
  }),
  purchasePrice: Joi.when('formOfPayment', {
    is: 'FREE',
    then: CoursePriceSchema.purchasePrice,
    otherwise: CoursePriceSchema.purchasePrice.required().messages({
      'any.required': 'Digite um valor.',
    }),
  }),
  installments: Joi.when('formOfPayment', {
    is: 'FREE',
    then: CoursePriceSchema.installments,
    otherwise: CoursePriceSchema.installments.required().messages({
      'any.required': 'Digite a quantidade de parcelas.',
    }),
  }),
  name: CoursePriceSchema.name.required().messages({
    'any.required': 'Informe um nome para o preço ou oferta',
  }),
  pricesVariedStatus: CoursePriceSchema.pricesVariedStatus,
  purchasePriceBoleto: Joi.when('pricesVariedStatus', {
    is: true,
    then: CoursePriceSchema.purchasePriceBoleto.required(),
    otherwise: CoursePriceSchema.purchasePriceBoleto,
  }),
  purchasePricePix: Joi.when('pricesVariedStatus', {
    is: true,
    then: CoursePriceSchema.purchasePricePix.required(),
    otherwise: CoursePriceSchema.purchasePricePix,
  }),
};

const updatePurchasePriceSchema: Partial<EntitySchema<CoursePriceType>> = {
  default: CoursePriceSchema.default,
  formOfPayment: CoursePriceSchema.formOfPayment,
  purchasePrice: Joi.when('formOfPayment', {
    is: 'FREE',
    then: Joi.valid('0'),
    otherwise: CoursePriceSchema.purchasePrice.required(),
  }),
  installments: Joi.when('formOfPayment', {
    is: 'FREE',
    then: CoursePriceSchema.installments,
    otherwise: CoursePriceSchema.installments,
  }),
  name: CoursePriceSchema.name,
  pricesVariedStatus: CoursePriceSchema.pricesVariedStatus,
  purchasePriceBoleto: Joi.when('pricesVariedStatus', {
    is: true,
    then: CoursePriceSchema.purchasePriceBoleto.required(),
    otherwise: CoursePriceSchema.purchasePriceBoleto,
  }),
  purchasePricePix: Joi.when('pricesVariedStatus', {
    is: true,
    then: CoursePriceSchema.purchasePricePix.required(),
    otherwise: CoursePriceSchema.purchasePricePix,
  }),
};

export default function EditPrice() {
  const { id, coursePriceId } = useParams<{ id: string; coursePriceId: string }>();

  const [coursePrice, setCoursePrice] = useState<CoursePriceType>(null);

  const { course, coursePrices: currentCoursePrices, setCoursePrices } = useCourse();

  useEffect(() => {
    if (coursePriceId) {
      const coursePrice = currentCoursePrices?.find(
        (coursePrice: CoursePriceType) => coursePrice.id === Number(coursePriceId)
      );

      if (coursePrice) {
        setCoursePrice(coursePrice);
      }
    }
  }, [course.coursePrices, coursePriceId, currentCoursePrices]);

  const { form, handleChange, onChanged, handleCancel } = useHandleChange<CoursePriceType>(
    {
      name: null,
      purchasePrice: null,
      formOfPayment: null,
      installments: null,
      pricesVariedStatus: null,
      purchasePriceBoleto: null,
      purchasePricePix: null,
    },
    coursePrice
      ? ({
          name: coursePrice?.name,
          purchasePrice: coursePrice?.purchasePrice,
          formOfPayment: coursePrice?.formOfPayment,
          installments: coursePrice?.installments,
          pricesVariedStatus: coursePrice?.pricesVariedStatus,
          purchasePriceBoleto: coursePrice?.purchasePriceBoleto,
          purchasePricePix: coursePrice?.purchasePricePix,
        } as CoursePriceType)
      : null
  );

  const { formValidation, handleSubmit, isLoading } = useHandleSubmit<
    Partial<CoursePriceType>,
    ResponseJSON<{ id: number; default: boolean }>
  >({
    data: form,
    method: coursePriceId ? 'patch' : 'post',
    url: coursePriceId
      ? `/courses/${coursePriceId}/course-price`
      : `/courses/${id}/course/course-price`,
    onSuccess: {
      message: `Preço ${coursePriceId ? 'atualizado' : 'cadastrado'} com sucesso!`,
      redirect: {
        to: `/courses/${id}/course-manager/price/primary-prices`,
      },
      callback: response => {
        const { data: CoursePrice } = response.data;

        if (coursePriceId) {
          const coursePrices = currentCoursePrices.map((coursePrice: CoursePriceType) => {
            if (coursePrice.id === parseInt(coursePriceId)) {
              return {
                ...coursePrice,
                ...form,
              };
            }

            return coursePrice;
          });

          setCoursePrices(coursePrices);
          return;
        }

        setCoursePrices([
          ...currentCoursePrices,
          { ...form, id: CoursePrice.id, default: CoursePrice.default },
        ]);
      },
    },
    schemaValidator: Joi.object(
      coursePriceId ? updatePurchasePriceSchema : createPurchasePriceSchema
    ),
    authenticated: true,
  });

  function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    handleSubmit();
  }

  return (
    <Box width="full" paddingBottom="36px">
      <Link to={`/courses/${id}/course-manager/price/primary-prices`}>
        <HStack>
          <Icon as={RiArrowLeftSLine} fontSize="3xl" />
          <Heading as="h2" fontWeight="medium" fontSize="30px">
            {coursePriceId ? 'Editar' : 'Cadastrar'} preço ou oferta:
          </Heading>
        </HStack>
      </Link>
      <Card
        as="form"
        onSubmit={onSubmit}
        width="full"
        marginTop="10px"
        shadow="lg"
        padding="4px"
        border="1px solid #00000050"
      >
        <CardHeader paddingTop={5} paddingBottom={3}>
          <Text fontWeight={500} fontSize="20px">
            Informações principais
          </Text>
        </CardHeader>
        <CardBody paddingY={1}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'flex-end' }}
            justifyContent={{ base: 'space-between', md: 'space-between' }}
          >
            <VStack alignItems="flex-start" width="full">
              <FormControl isInvalid={formValidation?.name?.isInvalid}>
                <FormLabel fontSize="14px">Nome</FormLabel>
                <Input
                  isDisabled={isLoading}
                  name="name"
                  value={form.name}
                  placeholder="Qual nome você deseja dar a esse preço?"
                  type="text"
                  onChange={handleChange}
                  color="#20212380"
                  focusBorderColor="primary.500"
                />
                <FormErrorMessage>{formValidation?.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formValidation?.formOfPayment?.isInvalid} paddingTop={6}>
                <FormLabel fontSize="14px">Forma de pagamento</FormLabel>
                <FormHelperText fontSize="14px" color="#20212380">
                  Você pode escolher entre três formas de pagamento: gratuito, parcelado sem tarifas
                  (o responsável pelas tarifas, é você) e parcelado com tarifas (seu cliente é quem
                  paga as tarifas).
                </FormHelperText>
                <Select
                  isDisabled={isLoading}
                  marginTop={2}
                  value={form.formOfPayment || ''}
                  name="formOfPayment"
                  onChange={event => {
                    handleChange(event);
                  }}
                  color="#20212380"
                  placeholder="Selecione uma forma de pagamento"
                  focusBorderColor="primary.500"
                >
                  <option value="FREE">Gratuito</option>
                  <option value="INSTALLMENTS_WITHOUT_FEE">Parcelado sem tarifas</option>
                  <option value="INSTALLMENTS_WITH_FEE">Parcelado com tarifas</option>
                </Select>
                <FormErrorMessage>{formValidation?.formOfPayment?.message}</FormErrorMessage>
              </FormControl>
              <Tabs
                variant="unstyled"
                index={getTabIndexByFormOfPayment(form.formOfPayment)}
                width="full"
              >
                <TabPanels width="full">
                  <TabPanel></TabPanel>
                  <TabPanel padding={0}>
                    <Text as="i">
                      Seu produto será oferecido de forma gratuita, os usuários só precisarão
                      estarem cadastrados para adquiri-lo.
                    </Text>
                  </TabPanel>
                  <TabPanel padding={0}>
                    <Text as="i">Seu cliente é quem paga as tarifas.</Text>
                    <PurchasePrice
                      form={{
                        installments: form.installments,
                        purchasePrice: form.purchasePrice,
                        formOfPayment: form.formOfPayment,
                      }}
                      formValidation={{
                        installments: formValidation?.installments,
                        purchasePrice: formValidation?.purchasePrice,
                      }}
                      handleChange={handleChange}
                    />
                    <VariedPrices
                      form={{
                        pricesVariedStatus: form.pricesVariedStatus,
                        purchasePricePix: form.purchasePricePix,
                        purchasePriceBoleto: form.purchasePriceBoleto,
                      }}
                      formValidation={{
                        pricesVariedStatus: formValidation?.pricesVariedStatus,
                        purchasePricePix: formValidation?.purchasePricePix,
                        purchasePriceBoleto: formValidation?.purchasePriceBoleto,
                      }}
                      onChange={handleChange}
                    />
                  </TabPanel>
                  <TabPanel padding={0} width="full">
                    <Text as="i">O responsável pelas tarifas, é você.</Text>
                    <PurchasePrice
                      form={{
                        installments: form.installments,
                        formOfPayment: form.formOfPayment,
                        purchasePrice: form.purchasePrice,
                      }}
                      formValidation={{
                        installments: formValidation?.installments,
                        purchasePrice: formValidation?.purchasePrice,
                      }}
                      handleChange={handleChange}
                    />

                    <VariedPrices
                      form={{
                        pricesVariedStatus: form.pricesVariedStatus,
                        purchasePricePix: form.purchasePricePix,
                        purchasePriceBoleto: form.purchasePriceBoleto,
                      }}
                      formValidation={{
                        pricesVariedStatus: formValidation?.pricesVariedStatus,
                        purchasePricePix: formValidation?.purchasePricePix,
                        purchasePriceBoleto: formValidation?.purchasePriceBoleto,
                      }}
                      onChange={handleChange}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </Stack>
        </CardBody>
        <CardFooter width="full" paddingTop={!form.formOfPayment || !onChanged ? 0 : 'auto'}>
          <HStack width="full" justifyContent="flex-end">
            <Button size="sm" isDisabled={!onChanged} onClick={handleCancel}>
              Cancelar
            </Button>
            <Button
              size="sm"
              type="submit"
              isLoading={isLoading}
              colorScheme="primary"
              isDisabled={!onChanged}
              color="secondary.500"
            >
              {coursePriceId ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </HStack>
        </CardFooter>
      </Card>
    </Box>
  );
}
