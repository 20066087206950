import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdInsertDriveFile as BoletoIcon, MdCreditCard as CreditCardIcon } from 'react-icons/md';
import { ReactComponent as PixIcon } from '../../../../../assets/icons/pixIcon.svg';
import { FormValidation } from '../../../../../hooks/useHandleSubmit';
import { IPayment as IInstallment } from '../../../../../hooks/useShoppingCart';
import { CreditCardType, IPayment, UserCard } from '../../types';

import Boleto from '../Boleto';
import CreditCard from '../CreditCard';
import Pix from '../Pix';
import { Status } from '../Pix/AlertMessage';

type PaymentMethod = {
  id: number;
  name: string;
  icon: JSX.Element;
  value: string;
  component?: JSX.Element;
};

interface PaymentTabProps {
  value: string;
  creditCard: CreditCardType;
  formValidation?: FormValidation<Partial<IPayment>>;
  installments: number;
  payments: IInstallment[];
  pixCode: string;
  purchaseId: string;
  status: Status;
  shouldDisplayAlert: boolean;
  isPurchaseByPixConfirmed: boolean;
  saveCreditCardForFuturePurchases: boolean;
  isInternational: boolean;
  isLoadingStatus?: boolean;
  userCreditCardsList: UserCard[];
  onPaymentMethodChange: (value: string) => void;
  onCreditCardChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onInstallmentChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function findPaymentMethodByValue(value: string, paymentMethods: PaymentMethod[]) {
  return paymentMethods.findIndex(option => option.value === value);
}

function findPaymentMethodByIndex(index: number, paymentMethods: PaymentMethod[]) {
  return paymentMethods[index]?.value;
}

export default function PaymentTabs({
  value,
  creditCard,
  formValidation,
  payments,
  installments,
  saveCreditCardForFuturePurchases,
  isInternational,
  shouldDisplayAlert,
  pixCode,
  purchaseId,
  status,
  isPurchaseByPixConfirmed,
  isLoadingStatus,
  userCreditCardsList,
  onPaymentMethodChange,
  onCreditCardChange,
  onInstallmentChange,
}: PaymentTabProps) {
  const isMobile = useBreakpointValue({ base: true, xl: false });

  const paymentMethods = [
    {
      id: 0,
      name: 'Cartão de crédito',
      icon: <CreditCardIcon />,
      value: 'credit_card',
      component: (
        <CreditCard
          creditCard={creditCard}
          formValidation={formValidation?.creditCard}
          payments={payments}
          installments={installments}
          saveCreditCardForFuturePurchases={saveCreditCardForFuturePurchases}
          userCreditCardsList={userCreditCardsList}
          onChange={onCreditCardChange}
          onInstallmentChange={onInstallmentChange}
        />
      ),
    },
    !isInternational && {
      id: 1,
      name: 'Boleto bancário',
      icon: <BoletoIcon />,
      value: 'boleto',
      component: <Boleto onPaymentMethodChange={onPaymentMethodChange} />,
    },
    {
      id: 2,
      name: 'Pix',
      icon: <PixIcon />,
      value: 'pix',
      component: (
        <Pix
          shouldDisplayAlert={shouldDisplayAlert}
          pixCode={pixCode}
          purchaseId={purchaseId}
          isLoadingStatus={isLoadingStatus}
          isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
          status={status}
        />
      ),
    },
  ].filter(Boolean); // Remove os itens que são false

  const selectedPaymentMethod = findPaymentMethodByValue(value, paymentMethods);

  // Faz o scroll do tab selecionado ficar no centro da tela no mobile
  useEffect(() => {
    if (isMobile) {
      const activeTabButton = document.querySelector(`[aria-selected="true"]`);
      activeTabButton?.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }, [isMobile, value]);

  function handelPaymentMethodChange(index: number) {
    onPaymentMethodChange(findPaymentMethodByIndex(index, paymentMethods));
  }

  return (
    <Tabs
      index={selectedPaymentMethod}
      onChange={handelPaymentMethodChange}
      overflowX="auto"
      variant="unstyled"
      isLazy
      p={0}
      mt={7}
    >
      {!isPurchaseByPixConfirmed && (
        <TabList
          gap={{ base: 3, xl: 5 }}
          scrollSnapType="x mandatory"
          overflowX="auto"
          sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        >
          {paymentMethods.map((option, index) => (
            <Tab
              as={Button}
              key={option?.id}
              p={0}
              scrollSnapAlign="start"
              variant="outline"
              onClick={() => handelPaymentMethodChange(index)}
              isActive={selectedPaymentMethod === index}
              pointerEvents={selectedPaymentMethod === index ? 'none' : 'auto'}
              whiteSpace="normal"
              fontWeight="normal"
              fontSize={{ base: '0.625rem', xl: 'sm' }}
              w={{ base: '6.5rem', xl: '8rem' }}
              maxW="8rem"
              px={{ base: 3, xl: 3.5 }}
              py={3}
              minH="3.5rem"
              justifyContent="center"
              border="0.84px solid #20212340"
              color="#20212380"
              _active={{
                bg: 'transparent',
                color: 'default.500',
                borderColor: 'default.500',
              }}
              textAlign="start"
              leftIcon={option?.icon}
              sx={{ svg: { width: { base: '18px', xl: '20.25px' } } }}
              flexShrink={0}
            >
              {option?.name}
            </Tab>
          ))}
        </TabList>
      )}

      <TabPanels>
        {paymentMethods.map(option => (
          <TabPanel key={option?.id} px={0.5} pt={isPurchaseByPixConfirmed ? 0 : 8}>
            {option?.component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
