import { AccordionPanel, Button, Center, Circle, List, ListItem, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCourseStudent } from '../../../../../../../../contexts/CourseStudentContextV2';
import getRouteToShow from '../../../../../../../../helpers/getRouteToShow';
import { IContent } from '../../../../../ContentsView/types';
import CheckIcon from './CheckIcon';
import { axiosBackend } from '../../../../../../../../api/Configuration';
import Toast from '../../../../../../../../components/Toast';
import ErrorResponse from '../../../../../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE } from '../../../../../../../../helpers/LocalStorageHelper';

export default function ModuleSubitemList({
  courseId,
  moduleId,
  contents,
  highlightColor,
}: {
  courseId: string | number;
  moduleId: string | number;
  contents: IContent[];
  highlightColor?: string;
}) {
  const { currentContent } = useCourseStudent();
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const [isActive, setIsActive] = useState(currentContent?.id);

  const history = useHistory();

  useEffect(() => {
    if (currentContent?.id) {
      setIsActive(currentContent?.id);
    }
  }, [currentContent?.id]);

  function onClick(contentId: number) {
    const currentContentItem = contents.map(content => content.id);

    const currentContentId = currentContentItem.find(content => content === contentId);

    setIsActive(currentContentId);
  }

  async function handleContentClick(content: IContent) {
    const baseURL = `/members-area/v2/course/${courseId}/module/${moduleId}/content/${content.id}/content-view`;
    const path = getRouteToShow(content.contentType);

    try {
      if (role === 'ALUNO') {
        await axiosBackend().patch(
          `/students/${courseId}/course/${moduleId}/module/${content.id}/content/save-last-content`
        );
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      history.push(`${baseURL}/${path}`);
    }
  }

  const sortedContents = contents?.sort((a, b) => a.order - b.order);

  if (!sortedContents?.length) return null;

  return (
    <AccordionPanel paddingBottom={0}>
      <List spacing="20px">
        {sortedContents.map((content: IContent, index) => (
          <ListItem
            onClick={() => onClick(content.id)}
            position="relative"
            key={`listItem-${content.id}`}
            marginRight="0.5rem"
            cursor="pointer"
            _before={
              index !== sortedContents.length - 1 && {
                content: "''",
                width: '2px',
                height: '130%',
                bg: content.markAsDone ? highlightColor : '#979CA6',
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateX(-50%)',
              }
            }
          >
            <Center
              boxSize="1.125rem"
              position="absolute"
              top="50%"
              left={0}
              transform="translate(-50%, -50%)"
            >
              {content.markAsDone ? (
                <Circle
                  size="16px"
                  bg="white"
                  border="1px solid"
                  bgColor={highlightColor ?? '#EB7129'}
                  borderColor={highlightColor ?? '#EB7129'}
                >
                  <CheckIcon color="#FFF" boxSize="10px" />
                </Circle>
              ) : (
                <Circle
                  size="16px"
                  bg="white"
                  border="1px solid"
                  bgColor="#202123"
                  borderColor={isActive === content.id ? highlightColor : '#979CA6'}
                />
              )}
            </Center>

            <Button
              onClick={() => handleContentClick(content)}
              whiteSpace="normal"
              textAlign="start"
              variant="ghost"
              color={isActive === content.id ? highlightColor : '#FFF'}
              colorScheme="default"
              _hover={{
                bg: 'transparent',
                color: highlightColor,
              }}
            >
              <Text noOfLines={2} fontSize="14px" fontWeight="500">
                {content.title}
              </Text>
            </Button>
          </ListItem>
        ))}
      </List>
    </AccordionPanel>
  );
}
