import { Stack, Avatar, VStack, Heading, Text } from '@chakra-ui/react';
import { ISocialNetwork } from '..';

interface IInstructorProp {
  instructor: {
    id: number;
    fullName: string;
    biography: string;
    photo: string;
    totalProducts: number;
    totalStudents: number;
    socialNetwork: ISocialNetwork[];
  };
  isVorticePlatform: boolean;
}

export default function HeaderDesktop({ instructor, isVorticePlatform }: IInstructorProp) {
  return (
    <Stack direction="row" spacing="35px" alignItems="center" margin="35px 135px">
      <Avatar
        boxSize="200px"
        bgColor="#202123"
        color="#FFF"
        size="2xl"
        name={instructor?.fullName}
        src={instructor?.photo}
      />

      <Stack spacing="35px">
        <VStack alignItems="flex-start" color="#FFF" fontSize="14px" lineHeight="20px" zIndex={9}>
          <Text fontWeight="600">Instrutor(a)</Text>
          <Heading fontSize="30px" fontWeight="700">
            {instructor?.fullName}
          </Heading>
        </VStack>

        {!isVorticePlatform && (
          <Stack direction="row" spacing="30px">
            <VStack alignItems="flex-start" color="#FFF" lineHeight="20px" zIndex={9}>
              <Text fontSize="14px" fontWeight="700">
                Total de alunos
              </Text>
              <Text fontSize="24px" fontWeight="600">
                {instructor?.totalStudents}
              </Text>
            </VStack>

            <VStack alignItems="flex-start" color="#FFF" lineHeight="20px" zIndex={9}>
              <Text fontSize="14px" fontWeight="700">
                Total de produtos
              </Text>
              <Text fontSize="24px" fontWeight="600">
                {instructor?.totalProducts}
              </Text>
            </VStack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
