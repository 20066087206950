import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import ErrorResponse from '../../../../../../../../helpers/ErrorResponse';
import useFetch from '../../../../../../../../hooks/useFetch';
import { FormValidation } from '../../../../../../../../hooks/useHandleSubmit';
import { CoursePriceType } from '../../../../../../../../validation/CoursePriceSchema';

import AmountInstallments from '../AmountInstallments';
import { TargetType } from '../../../../../../../../hooks/useHandleChange';
import Utils from '../../../../../../../../helpers/Utils';
import { useState, useEffect } from 'react';

type FormOfPaymentProps = {
  form: Pick<CoursePriceType, 'purchasePrice' | 'installments' | 'formOfPayment'>;
  formValidation?: Pick<FormValidation<Partial<CoursePriceType>>, 'purchasePrice' | 'installments'>;
  handleChange: (event: React.ChangeEvent<TargetType>) => void;
};

export default function PurchasePrice({ form, formValidation, handleChange }: FormOfPaymentProps) {
  const { data, loading, error, fetchData } = useFetch<AxiosResponse>({
    url: `/courses/calculate-installments-amount?amount=${Utils.Number.convertFloatToUnitPrice(
      form.purchasePrice
    )}&installments=${form.installments}&installmentWithTariff=${
      form.formOfPayment === 'INSTALLMENTS_WITH_FEE' ? 'true' : 'false'
    }`,
    method: 'get',
    autoFetch: false,
  });

  const [installmentsAmountParamsChanged, setInstallmentsAmountParamsChanged] = useState(false);

  useEffect(() => {
    setInstallmentsAmountParamsChanged(true);
  }, [form.purchasePrice, form.installments, form.formOfPayment]);

  return (
    <Box marginTop={4}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems={!formValidation?.purchasePrice?.isInvalid ? 'flex-end' : 'center'}
        width="full"
        spacing={4}
      >
        <FormControl
          flex={6}
          isInvalid={
            formValidation?.purchasePrice?.isInvalid || formValidation?.installments?.isInvalid
          }
        >
          <FormLabel>Preço e parcelas</FormLabel>
          <InputGroup>
            <InputLeftAddon children="R$" />
            <Input
              name="purchasePrice"
              value={form.purchasePrice || ''}
              placeholder="Informe o preço do produto"
              type="number"
              onChange={handleChange}
              focusBorderColor="primary.500"
            />
          </InputGroup>
          <FormErrorMessage>
            {formValidation?.purchasePrice?.message || formValidation?.installments?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          flex={2}
          alignSelf={formValidation?.installments?.isInvalid ? 'center' : 'initial'}
          isInvalid={formValidation?.installments?.isInvalid}
        >
          <Select
            marginTop={
              formValidation?.purchasePrice?.isInvalid || formValidation?.installments?.isInvalid
                ? 1
                : 0
            }
            value={form.installments || ''}
            name="installments"
            onChange={handleChange}
            placeholder="Parcelas"
            focusBorderColor="primary.500"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </Select>
        </FormControl>
        <FormControl
          height={10}
          display="flex"
          flex={1}
          alignItems="center"
          isInvalid={formValidation?.installments?.isInvalid}
          alignSelf={formValidation?.installments?.isInvalid ? 'center' : 'initial'}
        >
          <Button
            size="sm"
            flex={{ base: '', md: 1 }}
            marginTop={
              formValidation?.purchasePrice?.isInvalid || formValidation?.installments?.isInvalid
                ? 1
                : 0
            }
            variant="outline"
            colorScheme="primary"
            onClick={() => {
              fetchData();
              setInstallmentsAmountParamsChanged(false);
            }}
            isLoading={loading}
            isDisabled={
              loading ||
              !form.purchasePrice ||
              !form.installments ||
              !installmentsAmountParamsChanged
            }
          >
            Simular parcelas
          </Button>
        </FormControl>
      </Stack>
      <AmountInstallments
        data={data?.data}
        error={error && ErrorResponse(error)}
        loading={loading}
        formOfPaymentType={form.formOfPayment}
      />
    </Box>
  );
}
