import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { useState, ChangeEvent, FormEvent, useEffect } from 'react';

import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { Heading } from '../../../../../../components/Heading';
import { Modal } from 'react-bootstrap';
import { useCourse } from '../../../../../../contexts/CourseContext';

function PreviewControl() {
  const { course, updateCourse, loading } = useCourse();

  const [hasLimitedContent, setHasLimitedContent] = useState(false);
  const [limitingContentView, setLimitingContentView] = useState(3);

  const [isShowModal, setIsShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (!loading) {
      setHasLimitedContent(course.hasLimitedContent);
      setLimitingContentView(course.limitingContentView);
    }
  }, [course.hasLimitedContent, course.limitingContentView, loading]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    if (name === 'hasLimitedContent') {
      return setHasLimitedContent(!hasLimitedContent);
    }

    setLimitingContentView(parseInt(value));
  }

  function toggleModal() {
    setIsShowModal(!isShowModal);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      await updateCourse({ hasLimitedContent, limitingContentView });

      setMessage('Curso alterado com sucesso !');
      setTypeMessage('sucesso');
      setIsShowModal(true);
    } catch (error) {
      setMessage(ErrorResponse(error));
      setTypeMessage('erro');
      setIsShowModal(true);
    }
  }

  return (
    <div className="row justify-content-center px-3 my-3">
      <div className="col-lg-8 col-xl-9">
        <form onSubmit={handleSubmit} className="card shadow-sm">
          <div className="card shadow-sm">
            <div className="card-inner">
              <Heading as="h4" fontSize="2xl">
                Controle de visualizações
              </Heading>
              <div className="mt-5">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="hasLimitedContent"
                    id="freeAccess"
                    checked={!hasLimitedContent}
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label text-title-label font-weight-bold"
                    htmlFor="freeAccess"
                  >
                    Livre
                  </label>
                </div>
                <p className="mt-1">Os alunos terão acesso ilimitados dos seus vídeos</p>

                <div className="custom-control custom-radio mt-4">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="hasLimitedContent"
                    id="limitedAccess"
                    checked={hasLimitedContent}
                    onChange={handleChange}
                  />

                  <label
                    className="custom-control-label text-title-label font-weight-bold"
                    htmlFor="limitedAccess"
                  >
                    Acesso limitado
                  </label>
                </div>
                <p className="mt-1">
                  Os vídeos poderão ser visualizados por padrão até{' '}
                  <b style={{ fontWeight: 600 }}>3 vezes</b>. Será computada como uma visualização
                  quando o aluno ultrapassar a visualização de{' '}
                  <b style={{ fontWeight: 600 }}>80%</b> do vídeo.
                </p>

                {hasLimitedContent && (
                  <div className="col-lg-2 px-0 mt-2">
                    <input
                      className="form-control px-1"
                      onChange={handleChange}
                      name="limitingContentView"
                      value={limitingContentView}
                      type="number"
                      required
                      min={1}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center mr-4 mb-4">
              <button className="btn btn-primary px-5">Salvar</button>
            </div>
          </div>
        </form>
        <Modal
          centered
          size="sm"
          show={isShowModal}
          onHide={toggleModal}
          style={{
            backdropFilter: 'blur(4px)',
          }}
        >
          <Modal.Body>
            <div className="row d-flex justify-content-center mb-1 py-4">
              {typeMessage === 'sucesso' && (
                <FaCheckCircle
                  className="d-flex justify-content-center mb-2"
                  style={{ color: 'green', height: '50px', width: '50px' }}
                />
              )}
              {typeMessage === 'aviso' && (
                <FaExclamationCircle
                  className="d-flex justify-content-center mb-2"
                  style={{
                    color: '#EB7129',
                    height: '50px',
                    width: '50px',
                  }}
                />
              )}
              {typeMessage === 'erro' && (
                <FaExclamationCircle
                  className="d-flex justify-content-center mb-2"
                  style={{
                    color: 'red',
                    height: '50px',
                    width: '50px',
                  }}
                />
              )}
              <h3 className="text-center">{message}</h3>
            </div>
            <div className="d-flex justify-content-center mb-4 ">
              <button
                onClick={toggleModal}
                className="btn btn-light"
                style={{
                  backgroundColor: '#EB7129',
                  color: 'white',
                  alignItems: 'center',
                }}
              >
                Voltar ao controle de visualização
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default PreviewControl;
