import { axiosBackend } from './Configuration';

const RevenueSplit = {
  store: async revenueSplit => {
    try {
      await axiosBackend().post('/revenue-split/revenue-default', revenueSplit);
    } catch (error) {
      throw error;
    }
  },

  storePole: async revenueSplit => {
    try {
      await axiosBackend().post('/revenue-split/revenue-pole', revenueSplit);
    } catch (error) {
      throw error;
    }
  },

  indexDefault: async ({ page = 1, search = '' }) => {
    try {
      const { data: revenueSplitData } = await axiosBackend().get(
        `/revenue-split?page=${page}${search && `&search=${search}`}`
      );

      return revenueSplitData;
    } catch (error) {
      throw error;
    }
  },

  indexPole: async ({ page = 1, per_page = 10, search = '', status }) => {
    try {
      const { data: revenueSplitPoleData } = await axiosBackend().get(
        `/revenue-split-pole?page=${page}${per_page ? `&per_page=${per_page}` : ''}${
          search && `&search=${search}`
        } ${status ? `&status=${status}  ` : ''}`
      );

      return revenueSplitPoleData;
    } catch (error) {
      throw error;
    }
  },

  getRevenueSplitsByGroup: async groupId => {
    try {
      const { data: revenueSplitsByGroupData } = await axiosBackend().get(
        `/revenue-split/revenue-split-by-group/${groupId}`
      );

      return revenueSplitsByGroupData;
    } catch (error) {
      throw error;
    }
  },

  update: async (id, revenueSplit) => {
    try {
      await axiosBackend().patch(`/revenue-split/${id}`, revenueSplit);
    } catch (error) {
      throw error;
    }
  },

  delete: async id => {
    try {
      await axiosBackend().delete(`/revenue-split/${id}`);
    } catch (error) {
      throw error;
    }
  },

  updateStatusGroup: async (id, status) => {
    try {
      await axiosBackend().patch(`/revenue-split-group/${id}`, status);
    } catch (error) {
      throw error;
    }
  },

  deleteGroup: async id => {
    try {
      await axiosBackend().delete(`/revenue-split-group/${id}`);
    } catch (error) {
      throw error;
    }
  },

  percentages: {
    index: async (courseId, revenueType) => {
      try {
        const { data: percentagesData } = await axiosBackend().get(
          `/revenue-split/percentage-available-by-course/${courseId}/course?revenueType=${revenueType}`
        );

        return percentagesData;
      } catch (error) {
        throw error;
      }
    },

    percentagesPerGroups: async groupId => {
      try {
        const { data: revenueSplitsByGroupData } = await axiosBackend().get(
          `/revenue-split/percentage-available-by-group/${groupId}/revenue-split-group`
        );

        return revenueSplitsByGroupData;
      } catch (error) {
        throw error;
      }
    },
  },

  revenueSplitCourseList: async () => {
    try {
      const { data: revenueSplitCourseListData } = await axiosBackend().get(
        `/revenue-split/course-list`
      );

      return revenueSplitCourseListData;
    } catch (error) {
      throw error;
    }
  },

  checkProductsSplit: async payload => {
    try {
      const { data: checkProductsSplitData } = await axiosBackend().post(
        `/revenue-split/check-products-split`,
        payload
      );

      return checkProductsSplitData;
    } catch (error) {
      throw error;
    }
  },
};

export default RevenueSplit;
