import { useToast } from '@chakra-ui/react';
import process from 'process/browser';
import { ChangeEvent, FormEvent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import useHandleChange from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../utils/scrollToInvalidInput';
import schemaValidator from './schemaValidator';
import { ICheckoutSettingsEditPageThanks } from './types';

const isDevelopment = process?.env?.NODE_ENV === 'development';
const protocol = isDevelopment ? 'http://' : 'https://';
const port = isDevelopment ? ':3000' : '';
const boletoSrcWidget = `"${protocol}${window.location.hostname}${port}/assets/js/boleto-script.js"`;
const boletoScript = `<iframe id="proluno-boleto-iframe"></iframe><script src=${boletoSrcWidget}></script>`;
const pixSrcWidget = `"${protocol}${window.location.hostname}${port}/assets/js/pix-script.js"`;
const pixScript = `<iframe id="proluno-pix-iframe"></iframe><script src=${pixSrcWidget}></script>`;

export interface UseCheckoutSettingsEditPageThanksProps {
  onIsLoadingChange: (isLoading: boolean) => void;
}

export default function useCheckoutSettingsEditPageThanks({
  onIsLoadingChange,
}: UseCheckoutSettingsEditPageThanksProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const toast = useToast();

  const { data: response, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<ICheckoutSettingsEditPageThanks>
  >({
    method: 'get',
    url: `/course-checkout-settings/${id}/page-thanks`,
    authenticated: true,
    autoFetch: true,
  });

  const registeredPageThanksSettings = response?.data;

  useEffect(() => {
    onIsLoadingChange(isLoading);
  }, [isLoading, onIsLoadingChange]);

  const {
    form,
    setForm,
    onChanged: hasChange,
    setOnChanged: setHasChange,
    handleCancel,
    handleChange,
  } = useHandleChange<ICheckoutSettingsEditPageThanks>(
    {},
    registeredPageThanksSettings && {
      thankPageType: registeredPageThanksSettings.thankPageType ?? 'DEFAULT',
      thankPageForApprovedPurchases:
        registeredPageThanksSettings.thankPageForApprovedPurchases ?? '',
      thankPageForPurchasesAwaitingPayment:
        registeredPageThanksSettings.thankPageForPurchasesAwaitingPayment ?? '',
      thankPageForPurchasesAwaitingAnalysis:
        registeredPageThanksSettings.thankPageForPurchasesAwaitingAnalysis ?? '',
    }
  );

  function handleThankPageTypeChange(event: ChangeEvent<HTMLSelectElement>) {
    setHasChange(true);

    const { value } = event.target;

    const newValue = value as ICheckoutSettingsEditPageThanks['thankPageType'];

    if (value === 'DEFAULT') {
      setForm(prevForm => ({ ...prevForm, thankPageType: newValue }));

      delete form.thankPageForApprovedPurchases;
      delete form.thankPageForPurchasesAwaitingAnalysis;
      delete form.thankPageForPurchasesAwaitingPayment;

      return;
    }

    setForm(prevForm => ({
      ...prevForm,
      ...registeredPageThanksSettings,
      thankPageType: newValue,
    }));
  }

  function handleCancelButtonClick() {
    if (!hasChange) {
      return history.push(`/checkout-settings/edit/${id}/customization`);
    }

    handleCancel();
  }

  function handleCopy(_text: string, result: boolean) {
    if (result) {
      toast({ title: 'Script copiado com sucesso!', status: 'success', position: 'top' });
    }
  }

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: form,
    url: `/course-checkout-settings/${id}/page-thanks`,
    method: 'patch',
    authenticated: true,
    schemaValidator,
    onSuccess: {
      callback: () => history.push(`/checkout-settings/edit/${id}/customization`),
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    isSubmitting,
    formValidation,
    hasChange,
    boletoScript,
    pixScript,
    handleThankPageTypeChange,
    handleCopy,
    handleChange,
    handleCancelButtonClick,
    onSubmit,
  };
}
