import { Input, InputGroup, InputLeftAddon, InputProps } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';

interface CurrencyInputProps extends Omit<InputProps, 'onChange'> {
  onChange: (value: number) => void;
  value: string | number | readonly string[];
}

export default function CurrencyInput({ value, onChange, ...rest }: CurrencyInputProps) {
  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    if (typeof value === 'number') {
      setDisplayValue(formatValue(value.toFixed(2)));
    } else if (typeof value === 'string') {
      setDisplayValue(formatValue(value));
    } else if (Array.isArray(value) && value.length > 0) {
      setDisplayValue(formatValue(value[0]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const rawValue = event.target.value.replace(/[^\d]/g, '').replace(/^0*/, '');

    // Se não houver valor após limpeza, exibe 0,00
    if (rawValue === '') {
      setDisplayValue('0,00');
      onChange(0.0);
      return;
    }

    // Separa o valor em parte inteira e decimal
    let integerPart = rawValue.slice(0, -2) || '0';
    let decimalPart = rawValue.slice(-2);

    // Adiciona separadores de milhar
    integerPart = addThousandSeparators(integerPart);

    // Ajusta o valor decimal para sempre ter dois dígitos
    if (decimalPart.length === 1) {
      decimalPart = '0' + decimalPart;
    } else if (decimalPart === '') {
      decimalPart = '00';
    }

    // Formata o valor final para exibição
    const formattedDisplayValue = `${integerPart},${decimalPart}`;
    setDisplayValue(formattedDisplayValue);

    // Formata o valor para salvar
    const formattedValue = `${integerPart.replace(/\./g, '')}.${decimalPart}`;
    onChange(Number(formattedValue));
  }

  // Função para adicionar separadores de milhar
  function addThousandSeparators(value: string) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  // Função para formatar o valor inicial
  function formatValue(value: string) {
    if (!value) return '';

    const parts = value.split('.');
    const integerPart = parts[0] || '0';
    const decimalPart = parts[1] || '00';

    const formattedIntegerPart = addThousandSeparators(integerPart);
    return `${formattedIntegerPart},${decimalPart.padEnd(2, '0')}`;
  }

  return (
    <InputGroup>
      <InputLeftAddon>R$</InputLeftAddon>
      <Input
        value={displayValue}
        onChange={handleChange}
        inputMode="numeric"
        placeholder="0,00"
        {...rest}
      />
    </InputGroup>
  );
}
