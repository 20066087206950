import { useParams, Switch, Route, NavLink } from 'react-router-dom';

import SidebarEstrutura from '../../../../../components/SidebarEstrutura/SidebarEstrutura';
import ContentEstrutura from '../../../../../components/ContentEstrutura/ContentEstrutura';
import AccessDuration from './AccessDuration';
import PreviewControl from './PreviewControl';

export default function AccessCourse() {
  const { id } = useParams();

  return (
    <div className="d-flex px-3 h-100">
      <SidebarEstrutura col={4} className="h-100">
        <ul className="nav nav-tabs d-flex flex-column" style={{ borderBottom: 'none' }}>
          <li className="nav-item">
            <NavLink
              to={`/courses/${id}/course-manager/access-course/access-duration`}
              className="nav-link nav-link-2 tab-link"
              activeClassName="active"
            >
              Duração de acesso
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`/courses/${id}/course-manager/access-course/preview-control`}
              className="nav-link nav-link-2 tab-link"
              activeClassName="active"
            >
              Controle de visualização
            </NavLink>
          </li>
        </ul>
      </SidebarEstrutura>
      <ContentEstrutura col={8}>
        <Switch>
          <Route
            exact
            path="/courses/:id/course-manager/access-course/access-duration"
            component={AccessDuration}
          />

          <Route
            exact
            path="/courses/:id/course-manager/access-course/preview-control"
            component={PreviewControl}
          />
        </Switch>
      </ContentEstrutura>
    </div>
  );
}
