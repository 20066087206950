import {
  Button,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Link, useHistory, useParams } from 'react-router-dom';
import RevenueSplitAPI from '../../../../../api/RevenueSplit';
import UserAPI from '../../../../../api/User';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';

const initialState = {
  user: '',
  percentage: '',
};

function EditSplitDefault() {
  const [coProducers, setCoProducers] = useState([]);
  const [revenueSplitsByGroupId, setRevenueSplitsByGroupId] = useState([]);

  const [revenueSplit, setRevenueSplit] = useState(initialState);

  const [isLoadingDeleteSplit, setIsLoadingDeleteSplit] = useState(false);
  const [isLoadingNewSplit, setIsLoadingNewSplit] = useState(false);
  const [isLoadingEditSplit, setIsLoadingEditSplit] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingSplits, setIsLoadingSplits] = useState(false);
  const [isLoadingPercentages, setIsLoadingPercentages] = useState(false);

  const [currentUser, setCurrentUser] = useState('');
  const [availablePercentagePreview, setAvailablePercentagePreview] = useState(null);
  const [percentageAvailable, setPercentageAvailable] = useState();

  const { id } = useParams();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenModalEditPercentage,
    onOpen: onOpenModalEditPercentage,
    onClose: onCloseModalEditPercentage,
  } = useDisclosure();

  useEffect(() => {
    getRevenueSplitsByGroup(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function getRevenueSplitsByGroup() {
    try {
      setIsLoadingSplits(true);
      const { data: revenueSplitsByGroupData } = await RevenueSplitAPI.getRevenueSplitsByGroup(id);

      if (revenueSplitsByGroupData) {
        setRevenueSplitsByGroupId(revenueSplitsByGroupData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingSplits(false);
    }
  }

  async function percentageAvailableByGroup() {
    try {
      setIsLoadingPercentages(true);
      const { data: percentageAvailableByCourseData } =
        await RevenueSplitAPI.percentages.percentagesPerGroups(id);

      if (percentageAvailableByCourseData) {
        setPercentageAvailable(percentageAvailableByCourseData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingPercentages(false);
    }
  }

  async function getCoProducers() {
    try {
      setIsLoadingUsers(true);
      const { data: coProducersData } = await UserAPI.coProducers.index();

      if (coProducersData) {
        setCoProducers(coProducersData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingUsers(false);
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;

    const values = {
      ...revenueSplit,
      [name]: value,
    };

    if (name === 'percentage') {
      setAvailablePercentagePreview(
        parseFloat(percentageAvailable?.availablePercentage) - parseFloat(value)
      );

      if (value === '') {
        setAvailablePercentagePreview(null);
      }
    }

    setRevenueSplit(values);
  }

  async function handleSubmitEdit(event) {
    event.preventDefault();

    try {
      setIsLoadingEditSplit(true);
      const payload = {
        percentage: parseFloat(revenueSplit.percentage),
      };

      await RevenueSplitAPI.update(currentUser, payload);
      Toast('Compartilhamento atualizado com sucesso!', 'success');
      onCloseModalEditPercentage();
      getRevenueSplitsByGroup(id);
      setCurrentUser('');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingEditSplit(false);
    }
  }

  async function deleteSplit(splitId) {
    try {
      setIsLoadingDeleteSplit(true);

      if (splitId) {
        await RevenueSplitAPI.delete(splitId);

        Toast('Compartilhamento excluído com sucesso!', 'success');

        getRevenueSplitsByGroup();
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingDeleteSplit(false);
    }
  }

  async function handleSubmitNewSplit(event) {
    event.preventDefault();

    try {
      setIsLoadingNewSplit(true);

      const payload = {
        user_id: parseInt(revenueSplit.user),
        course_id: parseInt(revenueSplitsByGroupId.courseId),
        percentage: parseFloat(revenueSplit.percentage),
      };

      await RevenueSplitAPI.store(payload);

      setRevenueSplit('');
      Toast('Compartilhamento adicionado!', 'success');
      onClose();
      getRevenueSplitsByGroup(id);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingNewSplit(false);
    }
  }

  function goBack(event) {
    event.preventDefault();

    history.goBack();
  }

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        w="100%"
        justifyContent="space-between"
        my={5}
        alignItems="center"
      >
        <Stack
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          alignItems="center"
          textAlign={{ base: 'center', md: 'start', lg: 'start' }}
        >
          <Link style={{ margin: 0 }} onClick={goBack}>
            <AiOutlineLeft size="20px" />
          </Link>
          <Heading w="100%" fontWeight={600} fontSize={{ base: 'xl', md: '2xl', lg: '2xl' }}>
            {revenueSplitsByGroupId?.uniqueRevenueSplit
              ? `${revenueSplitsByGroupId.poleName} (${revenueSplitsByGroupId.courseName})`
              : revenueSplitsByGroupId.courseName}
          </Heading>
        </Stack>
      </Stack>

      <TableContainer>
        <Table size="lg" colorScheme="gray" w="100%" variant="striped">
          <Thead>
            <Tr>
              <Th>Usuário</Th>
              <Th>Porcentagem</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>

          <Tbody>
            {revenueSplitsByGroupId.revenueSplit?.map(revenueSplit => (
              <Tr key={nanoid()}>
                <Td>
                  {isLoadingSplits ? (
                    <Spinner color="orange.400" />
                  ) : (
                    <Text fontWeight="400">{revenueSplit?.name}</Text>
                  )}
                </Td>
                <Td>
                  {isLoadingSplits ? (
                    <Spinner color="orange.400" />
                  ) : (
                    <Text fontWeight="400">{revenueSplit?.percentage}%</Text>
                  )}
                </Td>
                <Td>
                  {!revenueSplit.isOwner && (
                    <Text mx={2} color="#20212380">
                      <Menu>
                        <MenuButton
                          style={{ outline: 'none', boxShadow: 'none' }}
                          bgColor="#20212320"
                          p="2px"
                          borderRadius={5}
                          color="rgba(32, 33, 35, 0.5)"
                        >
                          <HiDotsHorizontal size="18px" />
                        </MenuButton>

                        <MenuList>
                          <MenuItem
                            style={{ outline: 'none', boxShadow: 'none' }}
                            fontWeight="semibold"
                            onClick={() => {
                              onOpenModalEditPercentage();
                              percentageAvailableByGroup();
                              setCurrentUser(revenueSplit.id);
                            }}
                          >
                            Editar
                          </MenuItem>
                          {isLoadingDeleteSplit ? (
                            <MenuItem
                              hidden={revenueSplitsByGroupId?.uniqueRevenueSplit}
                              style={{ outline: 'none', boxShadow: 'none' }}
                            >
                              <Spinner color="red.500" />
                            </MenuItem>
                          ) : (
                            <MenuItem
                              hidden={revenueSplitsByGroupId?.uniqueRevenueSplit}
                              style={{ outline: 'none', boxShadow: 'none' }}
                              fontWeight="semibold"
                              color="red.500"
                              onClick={() => deleteSplit(revenueSplit.id)}
                            >
                              Excluir
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    </Text>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Heading w="100%" fontWeight={500} color="#20212350" fontSize="lg" my={5}>
        * O restante da porcentagem pertence a Proluno.
      </Heading>

      <Stack w="100%" direction="row" justifyContent="flex-end">
        <Button
          hidden={!isLoadingSplits && revenueSplitsByGroupId?.uniqueRevenueSplit}
          px={3}
          w={{ base: '100%', md: 180, lg: 180 }}
          size="md"
          colorScheme="orange"
          style={{ outline: 'none', boxShadow: 'none' }}
          onClick={() => {
            onOpen();
            percentageAvailableByGroup();
            getCoProducers();
          }}
        >
          + Adicionar usuário
        </Button>
      </Stack>

      <Modal
        isOpen={isOpenModalEditPercentage}
        onClose={() => {
          setRevenueSplit(initialState);
          setAvailablePercentagePreview('');
          onCloseModalEditPercentage();
        }}
        size="2xl"
        isCentered
      >
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent>
          <ModalHeader fontSize={{ base: 'md', md: '2xl', lg: '2xl' }}>
            Editar porcentagem
          </ModalHeader>
          <form onSubmit={handleSubmitEdit}>
            <ModalCloseButton style={{ outline: 'none', boxShadow: 'none' }} />
            <ModalBody>
              <Stack direction="column" w="100%" justifyContent="center" spacing={2}>
                <Text
                  htmlFor="percentage"
                  fontWeight="semibold"
                  fontSize={{ base: 'md', md: 'xl', lg: 'xl' }}
                >
                  Porcentagem:
                </Text>
                <Input
                  type="number"
                  name="percentage"
                  id="percentage"
                  focusBorderColor="orange.400"
                  placeholder="Digite a porcentagem"
                  value={revenueSplit.percentage}
                  onChange={handleChange}
                  step="0.01"
                  min="0.01"
                  max="99.99"
                  isRequired
                />

                <Stack direction="row" w="100%" justifyContent="flex-start">
                  <Stack direction="column" my={5}>
                    <Text>
                      Porcentagem da proluno:{' '}
                      <Text as="line" fontWeight={600}>
                        {!isLoadingPercentages && percentageAvailable?.prolunoPercentage
                          ? `${parseFloat(percentageAvailable.prolunoPercentage).toFixed(2)}%`
                          : '-'}
                      </Text>
                    </Text>
                    <Text>
                      Porcentagem disponível:{' '}
                      <Text as="line" fontWeight={600}>
                        {!isLoadingPercentages && percentageAvailable?.availablePercentage
                          ? `${
                              typeof availablePercentagePreview === 'number'
                                ? parseFloat(availablePercentagePreview).toFixed(2)
                                : parseFloat(percentageAvailable?.availablePercentage).toFixed(2)
                            }%`
                          : '-'}
                      </Text>
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Stack direction="row" w="100%" justifyContent="center">
                <Button
                  colorScheme="gray"
                  mr={3}
                  onClick={() => {
                    setRevenueSplit(initialState);
                    setAvailablePercentagePreview('');
                    onCloseModalEditPercentage();
                  }}
                  style={{ outline: 'none', boxShadow: 'none' }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="solid"
                  colorScheme="orange"
                  type="submit"
                  style={{ outline: 'none', boxShadow: 'none' }}
                >
                  {isLoadingEditSplit ? <Spinner color="orange.400" /> : 'Salvar'}
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setRevenueSplit(initialState);
          setAvailablePercentagePreview('');
          onClose();
        }}
        size="2xl"
        isCentered
      >
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent>
          <ModalHeader fontSize={{ base: 'md', md: '2xl', lg: '2xl' }}>
            Novo compartilhamento de receita
          </ModalHeader>
          <form onSubmit={handleSubmitNewSplit}>
            <ModalCloseButton
              style={{ outline: 'none', boxShadow: 'none' }}
              onClick={() => {
                setRevenueSplit(initialState);
                setAvailablePercentagePreview('');
                onClose();
              }}
            />
            <ModalBody>
              <Stack direction="column" w="100%" justifyContent="center" spacing={2}>
                <Text
                  htmlFor="user"
                  fontWeight="semibold"
                  fontSize={{ base: 'md', md: 'xl', lg: 'xl' }}
                >
                  Usuário:
                </Text>
                {isLoadingUsers ? (
                  <Spinner color="orange.400" />
                ) : !isLoadingUsers && !revenueSplitsByGroupId?.uniqueRevenueSplit ? (
                  <Select
                    name="user"
                    id="user"
                    onChange={handleChange}
                    focusBorderColor="orange.400"
                  >
                    <option hidden>Escolha o usuário</option>
                    {coProducers.length ? (
                      coProducers.map(({ id, fullName }) => (
                        <option key={id} value={id}>
                          {fullName}
                        </option>
                      ))
                    ) : (
                      <option value="">Você não possui usuários.</option>
                    )}
                  </Select>
                ) : (
                  ''
                )}

                <Text
                  htmlFor="percentage"
                  fontWeight="semibold"
                  fontSize={{ base: 'md', md: 'xl', lg: 'xl' }}
                >
                  Porcentagem:
                </Text>
                <Input
                  type="number"
                  name="percentage"
                  id="percentage"
                  focusBorderColor="orange.400"
                  placeholder="Digite a porcentagem"
                  value={revenueSplit.percentage}
                  onChange={handleChange}
                  step="0.01"
                  min="0.01"
                  max="99.99"
                  isRequired
                />

                <Stack direction="row" w="100%" justifyContent="flex-start">
                  <Stack direction="column" my={5}>
                    <Text>
                      Porcentagem da proluno:{' '}
                      <Text as="line" fontWeight={600}>
                        {!isLoadingPercentages && percentageAvailable?.prolunoPercentage
                          ? `${percentageAvailable.prolunoPercentage}%`
                          : '-'}
                      </Text>
                    </Text>
                    <Text>
                      Porcentagem disponível:{' '}
                      <Text as="line" fontWeight={600}>
                        {!isLoadingPercentages && percentageAvailable?.availablePercentage
                          ? `${
                              typeof availablePercentagePreview === 'number'
                                ? availablePercentagePreview
                                : percentageAvailable?.availablePercentage
                            }%`
                          : '-'}
                      </Text>
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Stack direction="row" w="100%" justifyContent="center">
                <Button
                  colorScheme="gray"
                  mr={3}
                  onClick={() => {
                    setRevenueSplit(initialState);
                    setAvailablePercentagePreview('');
                    onClose();
                  }}
                  style={{ outline: 'none', boxShadow: 'none' }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="solid"
                  colorScheme="orange"
                  type="submit"
                  style={{ outline: 'none', boxShadow: 'none' }}
                >
                  {isLoadingNewSplit ? <Spinner color="orange.400" /> : 'Salvar'}
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditSplitDefault;
