import { Avatar, Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import getRouteToShow from '../../../../helpers/getRouteToShow';
import { getLastContent } from '../../../../helpers/LocalStorageHelper';

function ExtendAccess({ product }) {
  if (product.statusCourse !== 'EXPIRADO') return '';

  return (
    <Flex direction="column" p={1} align="center" textAlign="center" gap={2}>
      <Text fontSize="xs">{`Expirado em  ${dayjs(product.expiresIn).format('DD/MM/YYYY')}`}</Text>

      <Button
        width="80%"
        size="xs"
        as={Link}
        to={`/product/${product.id}/extend`}
        colorScheme="primary"
        color="secondary.500"
      >
        Estender Acesso
      </Button>
    </Flex>
  );
}

function ProductCard({ product }) {
  let lastContent = getLastContent(product.id);

  if (!lastContent) {
    lastContent = {
      contentId: product.lastContentId,
      moduleId: product.lastModuleId,
      contentType: product.lastContentType,
    };
  }

  let courseCurrent = `/members-area/v1/products/${product.id}/content-view${
    lastContent?.moduleId && lastContent?.contentId
      ? `/module/${lastContent?.moduleId}/content/${lastContent?.contentId}/${getRouteToShow(
          lastContent?.contentType
        )}`
      : ''
  }`;

  if (product?.termsOfUse && product?.acceptTermsUse === false) {
    courseCurrent = `/members-area/v1/accept-term/${product.id}/course/${product.courseUserId}/course-user-id`;
  }

  return (
    <Box
      as={Link}
      to={courseCurrent}
      borderRadius={10}
      boxShadow="4px 4px 4px rgba(0, 0, 0, 0.25)"
      zIndex={10}
      overflow="hidden"
      width={{ base: '100%', md: '48%', lg: '30%', xl: '23%' }}
    >
      <Box w="full" h="16rem">
        {!product.thumbnail ? (
          <Avatar
            name={product.name}
            bg="primary.500"
            size="2xl"
            w="full"
            h="full"
            fontWeight={600}
            fontFamily="montserrat"
            color="secondary.500"
            borderRadius={10}
          />
        ) : (
          <Image
            src={product.thumbnail}
            objectFit="cover"
            h="full"
            w="full"
            style={{
              filter: product.statusCourse === 'EXPIRADO' && 'grayscale(100%)',
            }}
            alt="Imagem Curso"
          />
        )}
      </Box>

      <Text width="90%" fontSize="16px" paddingY={5} paddingX={2} fontWeight={600} isTruncated>
        {product?.name}
      </Text>

      <Box hidden={product.statusCourse !== 'EXPIRADO'}>
        <ExtendAccess product={product} />
      </Box>
    </Box>
  );
}

export default memo(ProductCard);
