import { CloseButton, Flex, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';

type Instructor = {
  name: string;
  id: number;
};

interface InstructorsSelectProps {
  isLoading: boolean;
  selectedInstructors: Instructor[];
  allInstructors: Instructor[];
  onInstructorSelect: (instructor: Instructor) => void;
  onInstructorRemove: (instructorId: number) => void;
}

export default function InstructorsSelect({
  isLoading,
  selectedInstructors,
  allInstructors,
  onInstructorSelect,
  onInstructorRemove,
}: InstructorsSelectProps) {
  function handleInstructorSelect(newValue) {
    onInstructorSelect(newValue[0]);
  }

  function handleInstructorRemove(instructorId: number) {
    onInstructorRemove(instructorId);
  }

  const availableInstructors = allInstructors
    ?.filter(instructor => !selectedInstructors?.some(selected => selected?.id === instructor?.id))
    .map(instructor => ({
      value: instructor.id,
      label: instructor.name,
    }));

  return (
    <FormControl>
      <FormLabel htmlFor="name" fontSize="sm" fontWeight="medium" lineHeight="5" color="#202123">
        Instrutores
      </FormLabel>

      <Text as="span" color="#0000007D">
        Selecione os instrutores que fazem parte desse curso.
      </Text>

      <MultiSelect
        menuPlacement="bottom"
        placeholder="Selecione"
        isMulti
        focusBorderColor="primary.500"
        hasStickyGroupHeaders
        selectedOptionColor="primary"
        noOptionsMessage={() => 'Nenhum instrutor encontrado.'}
        value={[]}
        onChange={handleInstructorSelect}
        options={availableInstructors}
        isLoading={isLoading}
        isDisabled={isLoading}
        chakraStyles={{
          container: provided => ({
            ...provided,
            width: '100%',
            color: '#20212380',
            fontSize: 'sm',
            _focusWithin: {
              zIndex: 1,
              borderColor: 'primary.500',
              boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
              borderRadius: 'md',
            },
          }),
          placeholder: provided => ({
            ...provided,
            color: '#20212380',
            fontSize: 'sm',
          }),
          dropdownIndicator: provided => ({
            ...provided,
            bg: 'transparent',
            color: '#202123',
            px: 2.5,
          }),
          indicatorSeparator: provided => ({
            ...provided,
            display: 'none',
          }),
          option: provided => ({
            ...provided,
            color: '#20212380',
            fontSize: 'sm',
          }),
          valueContainer: provided => ({
            ...provided,
            px: 3,
            color: '#20212380',
            fontSize: 'sm',
          }),
        }}
      />

      <HStack
        align="center"
        flexWrap="wrap"
        flex={1}
        marginTop={1}
        spacing={1}
        gap={1}
        cursor={isLoading ? 'not-allowed' : 'default'}
      >
        {selectedInstructors?.map(instructor => (
          <Flex
            key={instructor?.id}
            justify="center"
            align="center"
            paddingX={2}
            paddingY={0.5}
            gap={1}
            maxWidth="13rem"
            backgroundColor="#20212325"
            borderRadius={6}
          >
            <Text fontWeight="medium" fontSize="xs" color="#202123" isTruncated>
              {instructor?.name}
            </Text>

            <CloseButton
              onClick={() => handleInstructorRemove(instructor.id)}
              size="sm"
              sx={{ svg: { boxSize: '6.36px' } }}
              boxSize={4}
            />
          </Flex>
        ))}
      </HStack>
    </FormControl>
  );
}
