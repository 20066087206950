import buildYourWebsite from '../../../../../assets/images/redirection/build-your-website.svg';
import CreateBlog from '../../../../../assets/images/redirection/create-blog.svg';
import createProduct from '../../../../../assets/images/redirection/create-product.svg';
import InviteUsers from '../../../../../assets/images/redirection/invite-users.svg';
import RevenueSplit from '../../../../../assets/images/redirection/revenue-split.svg';
import UpMyClasses from '../../../../../assets/images/redirection/up-my-classes.svg';

const paths = [
  {
    title: 'Criar um Produto',
    image: createProduct,
    path: '/courses/new',
    description: 'Crie o seu primeiro produto!',
  },
  {
    title: 'Construir o Seu Site',
    image: buildYourWebsite,
    path: '/page-builder',
    description: 'Inicie a criação da página inicial do seu site!',
  },
  {
    title: 'Subir minhas aulas',
    image: UpMyClasses,
    path: '/video-library',
    description: 'Faça o upload das suas aulas ou dos seus arquivos digitais. ',
  },
  {
    title: 'Convidar Usuários',
    image: InviteUsers,
    path: '/user-base',
    description: 'Envie convites para os usuários que irão gerenciar sua plataforma.',
  },
  {
    title: 'Compartilhar Receita',
    image: RevenueSplit,
    path: '/revenue-split/default-type',
    description: 'Compartilhe seus ganhos com outros usuários.',
  },
  {
    title: 'Criar blog',
    image: CreateBlog,
    path: '/internal-blog',
    description: 'Cadastre a sua primeira publicação no blog.',
  },
];

export default paths;
