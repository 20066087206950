import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  CardProps,
  Flex,
  HStack,
  Skeleton,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import useShoppingCart, { IProductCart, IShoppingCart } from '../../../hooks/useShoppingCart';
import Product from './Product';

interface PurchaseSummaryMobileProps extends CardProps {
  products: IProductCart[];
  isLoading: boolean;
  isPurchaseByPixConfirmed?: boolean;
  onChangeShoppingCart: () => Promise<IShoppingCart>;
  onPoleChange: (productId: number, poleId: number) => void;
  onIsFreePurchaseChange: (value: boolean) => void;
  onStandardPaymentValueChange: (value: number) => void;
}

export default function PurchaseSummaryMobile({
  products,
  isLoading,
  isPurchaseByPixConfirmed,
  onChangeShoppingCart,
  onPoleChange,
  onIsFreePurchaseChange,
  onStandardPaymentValueChange,
}: PurchaseSummaryMobileProps) {
  const { deleteProductFromLocalStorage, getShoppingCartFromLocalStorage } = useShoppingCart();
  const { onUpdateTotalItemsInShoppingCart } = useAuth();
  const history = useHistory();

  const purchaseSummaryRef = useRef<HTMLDivElement>(null);

  async function deleteProduct(productId: number) {
    deleteProductFromLocalStorage(productId);

    const hasProductsInShoppingCart = !!getShoppingCartFromLocalStorage();

    if (!hasProductsInShoppingCart) {
      onUpdateTotalItemsInShoppingCart(0);
      return history.push('/all-products');
    }

    await onChangeShoppingCart();
  }

  const subtotal = useMemo(
    () =>
      products?.reduce(
        (total, { productPrice, couponDiscount }) => total + productPrice + couponDiscount,
        0
      ),
    [products]
  );

  const total = useMemo(
    () =>
      convertToBrazilianCurrency(products?.reduce((acc, product) => acc + product.productPrice, 0)),
    [products]
  );

  const discount = useMemo(() => {
    return convertToBrazilianCurrency(
      products?.reduce((acc, product) => acc + product.couponDiscount, 0)
    );
  }, [products]);

  useEffect(() => {
    const header = document.querySelector('#mobileHeader');
    const headerHeight = header?.clientHeight;
    const marginTop = headerHeight;

    purchaseSummaryRef.current.style.top = marginTop + 'px';
    purchaseSummaryRef.current.style.height = headerHeight + 'px';
  }, []);

  useEffect(() => {
    onIsFreePurchaseChange(!!total ? false : true);
    onStandardPaymentValueChange(subtotal);
  }, [onIsFreePurchaseChange, onStandardPaymentValueChange, subtotal, total]);

  const firstProduct = products?.[0];
  const totalItems = products?.length;

  return (
    <Accordion
      allowToggle
      w="full"
      position="fixed"
      inset={0}
      ref={purchaseSummaryRef}
      zIndex={1}
      color="#202123"
      bg="#ffffff"
    >
      <AccordionItem bg="#ffffff" boxShadow="0px 4px 4px 0px #00000040" borderBottomRadius="base">
        <AccordionButton>
          <HStack justify="space-between" flex={1}>
            <Box position="relative">
              <Avatar
                src={firstProduct?.productThumbnail}
                name={firstProduct?.productName}
                borderRadius="base"
                background="default.500"
                color="white"
                size="sm"
              />

              <Flex
                align="center"
                justify="center"
                boxSize={4}
                borderRadius="full"
                boxShadow="0 2px 5px rgba(50,50,93,.1),0 1px 1px rgba(0,0,0,.07)"
                bg="#ffffff"
                position="absolute"
                top="64%"
                left="64%"
              >
                <Text fontSize="10px">{totalItems}</Text>
              </Flex>
            </Box>

            <Skeleton w="full" isLoaded={!isLoading} maxH={5}>
              <Text fontSize="sm" fontWeight="semibold">
                {total}
              </Text>
            </Skeleton>

            <AccordionIcon />
          </HStack>
        </AccordionButton>

        <AccordionPanel pt={4}>
          <VStack spacing={4} divider={<StackDivider borderColor="gray.300" />}>
            {products?.map(product => (
              <Product
                key={product.productId}
                product={product}
                isLoading={isLoading}
                onDelete={deleteProduct}
                onPoleChange={onPoleChange}
                isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
              />
            ))}
          </VStack>

          <Skeleton w="full" isLoaded={!isLoading} maxH={5} mt={8}>
            <HStack w="full" justify="space-between" fontSize="xs">
              <Text as="span">Subtotal:</Text>
              <Text as="strong" fontWeight="semibold">
                {convertToBrazilianCurrency(subtotal)}
              </Text>
            </HStack>
          </Skeleton>

          <Skeleton w="full" isLoaded={!isLoading} maxH={5} mt={{ base: '0.422rem', xl: 3 }}>
            <HStack w="full" justify="space-between" fontSize="xs">
              <Text as="span">Cupom de desconto:</Text>
              <Text as="strong" fontWeight="semibold">
                {discount}
              </Text>
            </HStack>
          </Skeleton>

          <Skeleton
            w="full"
            isLoaded={!isLoading}
            startColor="default.400"
            endColor="default.400"
            maxH={8}
            mt={7}
          >
            <HStack w="full" justify="space-between" fontSize="xs" color="#EB7129">
              <Text as="span" fontWeight="medium">
                Total:
              </Text>
              <Text as="strong">{total}</Text>
            </HStack>
          </Skeleton>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
