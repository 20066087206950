import Joi from 'joi';

export type CreateShowcaseSchemaType = {
  name: string;
  productsIds: number[];
};

export const createShowcaseSchemaValidator = Joi.object<CreateShowcaseSchemaType>().keys({
  name: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages({
      'string.min': 'Deve ser no mínimo 3 dígitos.',
      'string.max': 'Deve ser no máximo 255 dígitos.',
      'any.required': 'O nome da vitrine é obrigatório.',
      'string.empty': 'O nome da vitrine é obrigatório.',
    } as JoiMessages.LanguageMessages),
  productsIds: Joi.array().items(
    Joi.number()
      .integer()
      .positive()
      .required()
      .messages({
        'number.base': 'O ID do produto deve ser um número.',
        'number.integer': 'O ID do produto deve ser um número inteiro.',
        'number.positive': 'O ID do produto deve ser um número positivo.',
        'any.required': 'O ID do produto é obrigatório.',
      } as JoiMessages.LanguageMessages)
  ),
});
