import { axiosBackend } from './Configuration';

const TransactionAPI = {
  index: async ({
    page = 1,
    search = '',
    startDate = '',
    endDate = '',
    filterPaymentMethod,
    filterPurchaseStatus,
  }) => {
    try {
      const { data: transactionData } = await axiosBackend().get(
        `/purchases?page=${page}${search && `&search=${search}`}${
          startDate && `&startDate=${startDate}`
        }${endDate && `&endDate=${endDate}`}${
          filterPaymentMethod.length > 0 ? `&filterPaymentMethod=${filterPaymentMethod}` : ''
        }${filterPurchaseStatus.length > 0 ? `&filterPurchaseStatus=${filterPurchaseStatus}` : ''}`
      );
      return transactionData;
    } catch (error) {
      throw error;
    }
  },
  show: async purchase_id => {
    try {
      const { data: transactionData } = await axiosBackend().get(`/purchases/${purchase_id}`);
      return transactionData;
    } catch (error) {
      throw error;
    }
  },

  showPurchasesCompany: async () => {
    try {
      const { data: purchasesData } = await axiosBackend().get('/purchases/company');

      return purchasesData;
    } catch (error) {
      throw error;
    }
  },

  createWithdrawal: async data => {
    try {
      const { data: purchasesData } = await axiosBackend().post('/purchases/transfer', data);

      return purchasesData;
    } catch (error) {
      throw error;
    }
  },
};

export default TransactionAPI;
