import {
  Center,
  HStack,
  IconButton,
  Input,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';

import {
  MdKeyboardArrowLeft as ArrowLeftIcon,
  MdKeyboardArrowRight as ArrowRightIcon,
  MdResetTv as ResetZoomIcon,
  MdZoomIn as ZoomInIcon,
  MdZoomOut as ZoomOutIcon,
} from 'react-icons/md';

export default function PDFViewer({ pdfUrl }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handleZoomIn() {
    setScale(prevScale => Math.min(prevScale + 0.1, 2)); // Limita zoom máximo em 2x
  }

  function handleZoomOut() {
    setScale(prevScale => Math.max(prevScale - 0.1, 1)); // Limita zoom mínimo em 1x
  }

  function handlePrevPage() {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  }

  function handleNextPage() {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  }

  function handlePageChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    const newPageNumber = Number(value);

    if (newPageNumber > 0 && newPageNumber <= numPages) {
      setPageNumber(newPageNumber);
    }
  }

  function handleResetZoom() {
    setScale(1);
  }

  const containerRef = useRef(null);
  const pageWidth = containerRef.current?.clientWidth;

  return (
    <>
      <HStack
        spacing={2}
        my={4}
        justify="center"
        color="gray.500"
        maxW={{ base: '90%', md: '720px', lg: '1280px' }}
        marginX="auto"
      >
        <Tooltip
          label="Diminuir zoom"
          aria-label="Diminuir zoom"
          borderRadius="md"
          fontSize="0.5rem"
        >
          <IconButton
            aria-label="Diminuir zoom"
            onClick={handleZoomOut}
            icon={<ZoomOutIcon size={26} />}
            size="xs"
            variant="outline"
            color="gray.500"
          />
        </Tooltip>

        <Tooltip
          label="Aumentar zoom"
          aria-label="Aumentar zoom"
          borderRadius="md"
          fontSize="0.5rem"
        >
          <IconButton
            aria-label="Aumentar zoom"
            onClick={handleZoomIn}
            icon={<ZoomInIcon size={26} />}
            size="xs"
            variant="outline"
            color="gray.500"
          />
        </Tooltip>

        <Tooltip label="Zoom padrão" aria-label="Zoom padrão" borderRadius="md" fontSize="0.5rem">
          <IconButton
            aria-label="Zoom padrão"
            onClick={handleResetZoom}
            icon={<ResetZoomIcon size={16} />}
            size="xs"
            variant="outline"
            color="gray.500"
          />
        </Tooltip>

        <Tooltip
          label="Página anterior"
          aria-label="Página anterior"
          borderRadius="md"
          fontSize="0.5rem"
        >
          <IconButton
            aria-label="Página anterior"
            onClick={handlePrevPage}
            icon={<ArrowLeftIcon size={26} />}
            size="xs"
            variant="outline"
            color="gray.500"
          />
        </Tooltip>

        <Tooltip
          label="Próxima página"
          aria-label="Próxima página"
          borderRadius="md"
          fontSize="0.5rem"
        >
          <IconButton
            aria-label="Próxima página"
            onClick={handleNextPage}
            icon={<ArrowRightIcon size={26} />}
            size="xs"
            variant="outline"
            color="gray.500"
          />
        </Tooltip>

        <Input
          value={pageNumber}
          onChange={handlePageChange}
          size="xs"
          maxW={10}
          borderRadius="md"
          textAlign="center"
          fontWeight="medium"
        />

        <Text fontWeight="medium">de</Text>
        <Input
          size="xs"
          maxW={10}
          value={numPages}
          borderRadius="md"
          readOnly
          textAlign="center"
          fontWeight="medium"
        />
      </HStack>

      <VStack
        maxW={{ base: '90%', md: '720px', '2xl': '1280px' }}
        maxH={{ base: '64vh' }}
        margin="0 auto"
        overflow="auto"
        shadow="base"
        ref={containerRef}
        sx={{
          canvas: {
            maxWidth: 'none !important',
          },
        }}
      >
        <Document
          file={pdfUrl}
          loading={
            <Center h="480px">
              <Spinner size="xl" color="secondary.500" />
            </Center>
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={scale} width={pageWidth} />
        </Document>
      </VStack>

      <HStack justify="center" my={4}>
        <IconButton
          aria-label="Página anterior"
          onClick={handlePrevPage}
          icon={<ArrowLeftIcon size={26} />}
          size="xs"
          variant="ghost"
          color="gray.500"
        />

        <Text fontSize="xs">
          Página {pageNumber} de {numPages}
        </Text>

        <IconButton
          aria-label="Próxima página"
          onClick={handleNextPage}
          icon={<ArrowRightIcon size={26} />}
          size="xs"
          variant="ghost"
          color="gray.500"
        />
      </HStack>
    </>
  );
}
