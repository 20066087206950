import { Stack } from '@chakra-ui/react';
import { useEffect } from 'react';

import FilterSelect from './FilterSelect';
import useFilters from './useFilters';

type Filter = {
  questionLibraryAreaId: number;
  questionLibraryDisciplineId: number;
  questionLibrarySubjectId: number;
};

interface FiltersListProps {
  area: number;
  discipline: number;
  subject: number;
  onChange: (filter: Filter) => void;
}

export default function FiltersList({ area, discipline, subject, onChange }: FiltersListProps) {
  const {
    filtersList,
    disciplinesOptions,
    subjectsOptions,
    selectedArea,
    selectedDiscipline,
    selectedSubject,
    isFetching,
    handleAreaChange,
    handleDisciplineChange,
    handleSubjectChange,
  } = useFilters();

  useEffect(() => {
    onChange({
      questionLibraryAreaId: selectedArea?.id,
      questionLibraryDisciplineId: selectedDiscipline?.id,
      questionLibrarySubjectId: selectedSubject?.id,
    });
  }, [selectedArea, selectedDiscipline, selectedSubject, onChange]);

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      width="full"
      alignItems="center"
      spacing={5}
      sx={{ label: { color: '#202123', fontSize: 'sm' } }}
    >
      <FilterSelect
        value={area || selectedArea?.id}
        label="Área"
        name="areaId"
        options={filtersList.areas}
        isDisabled={isFetching}
        onChange={handleAreaChange}
      />

      <FilterSelect
        value={discipline || selectedDiscipline?.id}
        label="Disciplina"
        name="disciplineId"
        options={disciplinesOptions}
        isDisabled={isFetching}
        onChange={handleDisciplineChange}
      />

      <FilterSelect
        value={subject || selectedSubject?.id}
        label="Assunto"
        name="subjectId"
        options={subjectsOptions}
        isDisabled={isFetching}
        onChange={handleSubjectChange}
      />
    </Stack>
  );
}
