import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { FormEvent, useState } from 'react';
import { IShowcase } from '..';
import { axiosBackend } from '../../../../../../api/Configuration';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import ImagePreview from './ImagePreview';
import ImageUploadLoading from './ImageUploadLoading';

const FIVE_MEGABYTES = 5 * 1024 * 1024;

interface ImageFile {
  preview: string;
  file?: File;
}
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  showcase: IShowcase;
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
  showcaseCourseId: number | null;
}

export function CreateBannerModal({
  isOpen,
  onClose,
  showcase,
  showcases,
  setShowcases,
  showcaseCourseId,
}: IProps) {
  const toast = useToast();
  const [isUploading, setIsUploading] = useState(false);
  const [bannerImage, setBannerImage] = useState<ImageFile>(null);

  const {
    form,
    setForm,
    setOnChanged: setHasChange,
  } = useHandleChange<{ banner: string | null }>({ banner: null });

  async function handleDropFile(acceptedFiles) {
    try {
      setHasChange(true);

      const [file] = acceptedFiles;

      if (file.size > FIVE_MEGABYTES) {
        return toast({
          title: 'Imagem muito grande!',
          description: 'O tamanho máximo deve ser igual ou inferior a 5 MB.',
          status: 'error',
          position: 'top',
        });
      }

      const preview = URL.createObjectURL(file);

      setBannerImage({ preview, file });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
      });
    }
  }

  function handleSelectNewImage() {
    setHasChange(true);

    setForm({ banner: '' });
    setBannerImage(null);
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  async function afterUploadingImage() {
    try {
      const showcaseIndex = showcases.findIndex(
        showcaseData => showcaseData.showcaseId === showcase.showcaseId
      );

      if (showcaseIndex === -1) {
        return onClose();
      }

      const updatedShowcases = [...showcases];

      const showcaseCourse = updatedShowcases[showcaseIndex].products.find(
        product => product.showcaseCourseId === showcaseCourseId
      );

      showcaseCourse.courseThumbnail = bannerImage.preview;
      showcaseCourse.hasCourseThumbnailNetflix = true;

      setShowcases(updatedShowcases);

      toast({
        title: 'O banner do produto foi atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });

      setForm({ banner: '' });
      setBannerImage(null);

      onClose();
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    try {
      setIsUploading(true);

      const { newFileName } = await UploadHelper.upload(bannerImage.file, 'course');

      setForm({ banner: newFileName });

      const payload = { banner: newFileName };

      await axiosBackend().patch(`/showcase-course/${showcaseCourseId}/add-banner`, payload);

      afterUploadingImage();
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Adicionar banner do curso</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Text color="rgba(0, 0, 0, 0.49)" fontSize="14px" fontWeight="400">
              Adicione o banner que será a capa do seu curso na área de membros.
            </Text>

            {!bannerImage?.preview && !form.banner ? (
              <Box width="full" display="flex" justifyContent="center">
                <Box width="260px" bgColor="red.200" height="325px" flexShrink="0">
                  <CustomDropzone
                    file={bannerImage?.file}
                    height="325px"
                    isDisabled={isUploading}
                    accept={['image/jpg', 'image/png', 'image/jpeg']}
                    dimensions="1080x1350"
                    onDrop={handleDropFile}
                    onDropRejected={handleDropRejected}
                    extensions="JPG, PNG, JPEG e WEBP"
                  />
                  <ImageUploadLoading position="absolute" isLoading={isUploading} />
                </Box>
              </Box>
            ) : (
              <VStack width="full" display="flex" justifyContent="center">
                <Box width="260px" height="325px">
                  <ImagePreview
                    imageField="banner"
                    preview={bannerImage?.preview ?? form.banner}
                    onSelectNewImage={handleSelectNewImage}
                  />
                </Box>

                <Button
                  display={{ base: 'flex', xl: 'none' }}
                  onClick={handleSelectNewImage}
                  w="full"
                  variant="outline"
                  colorScheme="primary"
                  size="sm"
                  zIndex="2"
                >
                  Trocar Imagem
                </Button>
              </VStack>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button size="sm" onClick={onClose} colorScheme="gray">
              Cancelar
            </Button>
            <Button
              isDisabled={isUploading}
              isLoading={isUploading}
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              type="submit"
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
