import { useHistory } from 'react-router-dom';
import { hostname, platformsToRemoveInstructorTransactionsMenu } from '../../../../App';
import { Heading } from '../../../../components/Heading';
import { ROLE_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import { useEffect } from 'react';

import styles from './styles.module.css';
import TableList from './TableList';

function Transaction() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const history = useHistory();

  useEffect(() => {
    if (role === 'INSTRUTOR' && platformsToRemoveInstructorTransactionsMenu.includes(hostname)) {
      history.push('/');
    }
  }, [history, role]);

  return (
    <>
      <Heading>Transações</Heading>
      <p className={`my-2 ${styles.paragraphTransactions}`}>
        Gerencie suas vendas por aqui. Nesta seção você visualiza todas as compras que ocorrem na
        sua plataforma e no marketplace da Proluno{' '}
        <b style={{ fontWeight: 600 }}>(pagas ou inscrições gratuitas)</b>. Você pode filtrar as
        transações por nome, CPF, e-mail, produto, valor, data ou período de tempo. Você ainda pode
        exportar as transações que desejar, criando planilhas.
      </p>

      <TableList />
    </>
  );
}

export default Transaction;
