import { HStack, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  FaFacebookSquare as Facebook,
  FaHeart as Heart,
  FaInstagram as Instagram,
  FaTelegramPlane as Telegram,
  FaWhatsapp as Whatsapp,
  FaYoutube as YouTube,
  FaLinkedin as Linkedin,
} from 'react-icons/fa';

import { Box, Text, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';
import styles from './styles.module.css';

function getFooter(themeMarketplace) {
  const footer = themeMarketplace?.themeSections?.find(section => section.name === 'footer');

  return footer?.themeFooter;
}

function getMenu(themeMarketplace) {
  const header = themeMarketplace?.themeSections?.find(section => section.name === 'header');
  return header?.themeHeaderMenu?.filter(menuItem => menuItem.active);
}

function getDocumentType(document) {
  return document.length > 11 ? 'CNPJ' : 'CPF';
}

function maskDocument(value) {
  return value.length > 11
    ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

const initialStateFooter = {
  title: '',
  mission: '',
  informationPhone: '',
  informationEmail: '',
  urlSocialMediaFacebook: '',
  urlSocialMediaInstagram: '',
  urlSocialMediaWhatsapp: '',
  urlSocialMediaYouTube: '',
  urlSocialMediaTelegram: '',
};

function FooterHomeAdmin() {
  const [footer, setFooter] = useState(initialStateFooter);
  const [menu, setMenu] = useState([]);

  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      const themeFooter = getFooter(themeMarketplace);
      const menu = getMenu(themeMarketplace);

      setFooter(themeFooter);
      setMenu(menu);
    }
  }, [themeMarketplace, isLoadingThemeMarketplace]);

  function Pages() {
    return (
      <ul>
        <h3 className={styles.footerTitle}>Páginas</h3>

        {menu?.length &&
          menu.map(menuItem => {
            let path = menuItem.path;

            switch (menuItem.path) {
              case '/home':
                path = '#';
                break;

              case '/about':
                path = 'about';
                break;

              case '/courses':
                path = 'all-products';
                break;

              case '/blog':
                path = 'blog';
                break;

              case '/all-instructors':
                path = 'all-instructors';
                break;

              default:
                menuItem.path = path;
            }

            menuItem.path = path;

            return (
              <li key={menuItem.id}>
                <Link to={`/${menuItem.path}`} style={{ color: '#fff', fontSize: '16px' }}>
                  {menuItem.label}
                </Link>
              </li>
            );
          })}
      </ul>
    );
  }

  return (
    <footer className={styles.footerSection}>
      <div className={styles.footerContainer}>
        <ul>
          <h3 className={styles.footerTitle}>{footer?.title || 'Título'}</h3>
          <p className={styles.footerParagraph}>{footer?.mission || 'Missão'}</p>
        </ul>

        <Pages />

        <ul>
          <h3 className={styles.footerTitle}>Contato</h3>

          <li className={styles.informations}>{footer?.informationPhone}</li>
          <li className={styles.informations}>{footer?.informationEmail}</li>
        </ul>

        <ul>
          <h3 className={styles.footerTitle}>Redes Sociais</h3>

          <li className={styles.socialNetworks}>
            {footer?.urlSocialMediaInstagram && (
              <a href={footer.urlSocialMediaInstagram} target="_blank" rel="noopener noreferrer">
                <Instagram color="#ffffff" />
              </a>
            )}

            {footer?.urlSocialMediaFacebook && (
              <a href={footer.urlSocialMediaFacebook} target="_blank" rel="noopener noreferrer">
                <Facebook />
              </a>
            )}

            {footer?.urlSocialMediaTelegram && (
              <a href={footer.urlSocialMediaTelegram} target="_blank" rel="noopener noreferrer">
                <Telegram />
              </a>
            )}

            {footer?.urlSocialMediaYouTube && (
              <a href={footer.urlSocialMediaYouTube} target="_blank" rel="noopener noreferrer">
                <YouTube color="#ffffff" />
              </a>
            )}

            {footer?.urlSocialMediaLinkedin && (
              <a href={footer.urlSocialMediaLinkedin} target="_blank" rel="noopener noreferrer">
                <Linkedin />
              </a>
            )}

            {footer?.urlSocialMediaWhatsapp && (
              <a href={footer.urlSocialMediaWhatsapp} target="_blank" rel="noopener noreferrer">
                <Whatsapp color="#ffffff" />
              </a>
            )}
          </li>
        </ul>
      </div>

      <HStack mt={10} justifySelf="start">
        <VStack color="#FFFFFF" align="start">
          {footer?.documentNumber && (
            <Box>
              <Text as="strong">{getDocumentType(footer?.documentNumber)}:</Text>{' '}
              <Text as="span">{maskDocument(footer?.documentNumber)}</Text>
            </Box>
          )}

          {footer?.termsAndPoliciesLink && (
            <ChakraLink
              as={Link}
              to="/termos-e-politicas"
              textDecoration="underline !important"
              fontWeight="medium"
              isExternal
            >
              Termos e Políticas
            </ChakraLink>
          )}
        </VStack>
      </HStack>

      <div className={styles.subFooter}>
        <a
          href="https://ensine.proluno.com.br/"
          target="_blank"
          rel="noopener noreferrer"
          role="button"
          className="d-flex"
        >
          Tecnologia
          <div className={styles.pulse}>
            <Heart size={20} color="#EB7129" />
          </div>
          Proluno - Ltda.
        </a>
      </div>
    </footer>
  );
}

export default FooterHomeAdmin;
