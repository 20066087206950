import {
  Box,
  Button,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { HiDotsHorizontal } from 'react-icons/hi';
import { MdDragIndicator } from 'react-icons/md';
import { IShowcase } from '..';
import { axiosBackend } from '../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { AddProductModal } from '../AddProductModal';
import { CreateBannerModal } from '../CreateBannerModal';
import { DeleteShowcaseModal } from '../DeleteShowcaseModal';
import { EditShowcaseModal } from '../EditShowcaseModal';
import ShowcaseList from './List';
import { DeleteProductModal } from '../DeleteProductModal';
import { RemoveProductBannerModal } from '../RemoveProductBannerModal';

interface IProps {
  showcaseCount: number;
  showcase: IShowcase;
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
}

type ActionType = 'create-banner' | 'edit' | 'duplicate' | 'delete' | 'add-products';

export function getStatusColorByName(status: string) {
  switch (status) {
    case 'ATIVO':
      return '#22BB33';
    case 'INATIVO':
      return '#BB2124';
    case 'EM_EDICAO':
      return '#E8C536';
    default:
      return '#202123';
  }
}

export default function Showcase({ showcase, showcaseCount, showcases, setShowcases }: IProps) {
  const [showcaseCourseId, setShowcaseCourseId] = useState<number | null>(null);

  const [showcaseSelected, setShowcaseSelected] = useState({
    id: null,
    name: '',
    status: '',
  });

  const toast = useToast();

  const {
    isOpen: isOpenAddProductsModal,
    onOpen: onOpenAddProductsModal,
    onClose: onCloseAddProductsModal,
  } = useDisclosure();

  const {
    isOpen: isOpenEditShowcaseModal,
    onOpen: onOpenEditShowcaseModal,
    onClose: onCloseEditShowcaseModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteShowcaseModal,
    onOpen: onOpenDeleteShowcaseModal,
    onClose: onCloseDeleteShowcaseModal,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateBannerModal,
    onOpen: onOpenCreateBannerModal,
    onClose: onCloseCreateBannerModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteProductModal,
    onOpen: onOpenDeleteProductModal,
    onClose: onCloseDeleteProductModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteProductBannerModal,
    onOpen: onOpenDeleteProductBannerModal,
    onClose: onCloseDeleteProductBannerModal,
  } = useDisclosure();

  function onClickAction(showcase: IShowcase, action: ActionType) {
    setShowcaseSelected({
      id: showcase.showcaseId,
      name: showcase.name,
      status: showcase.statusName,
    });

    switch (action) {
      case 'create-banner':
        onOpenCreateBannerModal();
        break;
      case 'add-products':
        onOpenAddProductsModal();
        break;
      case 'edit':
        onOpenEditShowcaseModal();
        break;
      case 'duplicate':
        duplicateShowcase();
        break;
      case 'delete':
        onOpenDeleteShowcaseModal();
        break;
    }
  }

  async function duplicateShowcase() {
    try {
      const response = await axiosBackend().post(`/showcase/${showcase.showcaseId}/duplicate`);

      const { data } = response.data;

      const showcaseDuplicate = data as IShowcase;

      if (!showcaseDuplicate) return;

      setShowcases([...showcases, showcaseDuplicate]);

      toast({
        title: 'A vitrine foi duplicada com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }

  async function handleStatusChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    if (!value) return;

    const payload = {
      name: showcase.name,
      status: value,
    };

    try {
      await axiosBackend().patch(`/showcase/${showcase.showcaseId}`, payload);

      const showcaseIndex = showcases.findIndex(
        showcaseData => showcaseData.showcaseId === showcase.showcaseId
      );

      if (showcaseIndex === -1) return;

      const updatedShowcases = [...showcases];

      updatedShowcases[showcaseIndex].statusName = value;

      setShowcases(updatedShowcases);

      toast({
        title: 'O status da vitrine foi atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }

  return (
    <Box
      width="full"
      display="flex"
      flexDirection="column"
      borderRadius={6}
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
    >
      <Stack
        width="full"
        direction="row"
        padding="24px 13px 24px 13px"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack>
          <MdDragIndicator fontSize="24px" color={showcaseCount <= 1 ? 'transparent' : '#202123'} />

          <Heading fontSize="18px" fontWeight={500}>
            {showcase.name}
          </Heading>
        </HStack>

        <HStack>
          <Select
            fontSize="14px"
            fontWeight={400}
            width={showcase.statusName === 'ATIVO' ? '75px' : '90px'}
            color={getStatusColorByName(showcase.statusName)}
            focusBorderColor="transparent"
            border="none"
            name="status"
            size="xs"
            value={showcase.statusName}
            onChange={handleStatusChange}
          >
            <option value="ATIVO">Ativo</option>
            <option value="INATIVO">Inativo</option>
          </Select>

          <Menu>
            <MenuButton
              w="27px"
              h="24px"
              borderRadius={5}
              bgColor="#20212312"
              _hover={{ bgColor: '#20212330' }}
              _active={{ bgColor: '#20212330' }}
            >
              <Stack justifyContent="center" alignItems="center">
                <HiDotsHorizontal size="18px" color="#00000080" />
              </Stack>
            </MenuButton>
            <MenuList>
              <MenuItem
                color="#979CA6"
                fontWeight={500}
                onClick={() => onClickAction(showcase, 'edit')}
              >
                Editar vitrine
              </MenuItem>
              <MenuItem
                color="#979CA6"
                fontWeight={500}
                onClick={() => onClickAction(showcase, 'duplicate')}
              >
                Duplicar
              </MenuItem>
              <MenuItem
                color="#BB2124"
                fontWeight={500}
                onClick={() => onClickAction(showcase, 'delete')}
              >
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Stack>

      <ShowcaseList
        products={showcase.products}
        showcase={showcase}
        showcases={showcases}
        setShowcases={setShowcases}
        showcaseCourseId={showcaseCourseId}
        setShowcaseCourseId={setShowcaseCourseId}
        onOpenCreateBannerModal={onOpenCreateBannerModal}
        onOpenDeleteProductModal={onOpenDeleteProductModal}
        onOpenDeleteProductBannerModal={onOpenDeleteProductBannerModal}
        setShowcaseSelected={setShowcaseSelected}
      />

      <Box
        width="full"
        padding="24px 13px 24px 13px"
        display="flex"
        justifyContent="center"
        hidden={!!showcase.products.length}
      >
        <Heading fontSize="14px" color="#20212380">
          Não há produtos adicionados a esse vitrine
        </Heading>
      </Box>

      <Box width="full" padding="20px" display="flex" justifyContent="flex-end">
        <Button
          leftIcon={<FaPlus />}
          size="sm"
          variant="outline"
          bgColor="#FFF"
          color="primary.500"
          borderColor="primary.500"
          _hover={{ bgColor: 'primary.100' }}
          onClick={() => onClickAction(showcase, 'add-products')}
        >
          Adicionar produtos
        </Button>
      </Box>

      <EditShowcaseModal
        isOpen={isOpenEditShowcaseModal}
        onClose={onCloseEditShowcaseModal}
        showcaseSelected={showcaseSelected}
        showcases={showcases}
        setShowcases={setShowcases}
      />

      <DeleteShowcaseModal
        isOpen={isOpenDeleteShowcaseModal}
        onClose={onCloseDeleteShowcaseModal}
        showcaseSelected={showcaseSelected}
        showcases={showcases}
        setShowcases={setShowcases}
      />

      <CreateBannerModal
        isOpen={isOpenCreateBannerModal}
        onClose={onCloseCreateBannerModal}
        showcase={showcase}
        showcases={showcases}
        setShowcases={setShowcases}
        showcaseCourseId={showcaseCourseId}
      />

      <AddProductModal
        isOpen={isOpenAddProductsModal}
        onClose={onCloseAddProductsModal}
        showcaseSelected={showcaseSelected}
        showcases={showcases}
        setShowcases={setShowcases}
      />

      <DeleteProductModal
        isOpen={isOpenDeleteProductModal}
        onClose={onCloseDeleteProductModal}
        showcaseSelected={showcaseSelected}
        showcaseCourseId={showcaseCourseId}
        showcases={showcases}
        setShowcases={setShowcases}
      />

      <RemoveProductBannerModal
        isOpen={isOpenDeleteProductBannerModal}
        onClose={onCloseDeleteProductBannerModal}
        showcaseCourseId={showcaseCourseId}
        showcase={showcase}
        showcases={showcases}
        setShowcases={setShowcases}
      />
    </Box>
  );
}
