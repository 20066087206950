import Joi from 'joi';

export type AddProductSchemaType = {
  showcaseId: number;
  productsIds: number[];
};

export const addProductSchemaValidator = Joi.object<AddProductSchemaType>().keys({
  showcaseId: Joi.number()
    .integer()
    .required()
    .messages({
      'number.base': 'O ID do showcase deve ser um número.',
      'number.integer': 'O ID do showcase deve ser um número inteiro.',
      'any.required': 'O ID do showcase é obrigatório.',
    } as JoiMessages.LanguageMessages),
  productsIds: Joi.array().items(
    Joi.number()
      .integer()
      .positive()
      .required()
      .messages({
        'number.base': 'O ID do produto deve ser um número.',
        'number.integer': 'O ID do produto deve ser um número inteiro.',
        'number.positive': 'O ID do produto deve ser um número positivo.',
        'any.required': 'O ID do produto é obrigatório.',
      } as JoiMessages.LanguageMessages)
  ),
});
