import { axiosBackend } from './Configuration';

const PurchaseAPI = {
  store: {
    purchaseFreeProduct: async course_id => {
      try {
        const { data: purchaseData } = await axiosBackend().post(
          `/purchases/${course_id}/course/free`
        );

        return purchaseData;
      } catch (error) {
        throw error;
      }
    },
  },
  purchaseRefund: async transaction_id => {
    try {
      await axiosBackend().patch(`/purchases/${transaction_id}/refund`);
    } catch (error) {
      throw error;
    }
  },
  purchaseExportTransaction: async ({
    typeFile = '',
    startDate = '',
    endDate = '',
    search = '',
    filterPaymentMethod,
    filterPurchaseStatus,
  }) => {
    const urlDownload = `/purchases/export-transaction${typeFile ? `?typeFile=${typeFile}` : ''}${
      search ? `&search=${search}` : ''
    }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${
      filterPaymentMethod.length > 0 ? `&filterPaymentMethod=${filterPaymentMethod}` : ''
    }${filterPurchaseStatus.length > 0 ? `&filterPurchaseStatus=${filterPurchaseStatus}` : ''}`;

    const axios = axiosBackend();

    const response = await axios({
      url: urlDownload,
      method: 'GET',
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', typeFile === 'CSV' ? 'transações.csv' : 'transações.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  },
  purchasePaymentInternational: async course_id => {
    try {
      await axiosBackend().post(`/purchases/${course_id}/course/purchase`);
    } catch (error) {
      throw error;
    }
  },
  purchaseFutureReleases: async (filterStartDateFutureReleases, filterEndDateFutureReleases) => {
    try {
      const { data: futureReleasesData } = await axiosBackend().get(
        `/purchases/payable-days${
          filterStartDateFutureReleases && `?startDate=${filterStartDateFutureReleases}`
        }${filterEndDateFutureReleases && `&endDate=${filterEndDateFutureReleases}`}`
      );

      return futureReleasesData;
    } catch (error) {
      throw error;
    }
  },
};

export default PurchaseAPI;
