import { cnpj, cpf } from 'cpf-cnpj-validator';
import Joi from 'joi';
import { CreditCardType, IPayment, ProductType } from './types';
import { UTMData } from '../../../contexts/UTMContect';

const CARD_ID_EMPTY = 'O creditCard.cardId está vazio.';
const FIELD_ALLOWED = 'Apenas o creditCard.cardId é permitido.';

function validateCPF(value: string, helpers) {
  if (!cpf.isValid(value)) {
    return helpers.error('any.invalid');
  }

  return value;
}

function validateCNPJ(value: string, helpers) {
  if (!cnpj.isValid(value)) {
    return helpers.error('any.invalid');
  }

  return value;
}

const creditCardSchema = Joi.object<Partial<CreditCardType>>().keys({
  cardNumber: Joi.string().regex(/^\d+$/).required().messages({
    'string.empty': 'Digite o número do cartão para continuar.',
    'string.pattern.base': 'Número de cartão inválido',
  }),
  cardHolderName: Joi.string()
    .required()
    .messages({ 'string.empty': 'Digite o titular do cartão para continuar.' }),
  month: Joi.string().required().messages({
    'string.empty': 'Selecione o mês para continuar.',
  }),
  year: Joi.string().required().messages({
    'string.empty': 'Selecione o ano para continuar.',
  }),
  cardExpirationDate: Joi.string()
    .messages({
      'string.empty': 'Selecione a data de validade para continuar.',
      'string.pattern.base': 'Data de validade inválida.',
    })
    .regex(/^\d{4}$/)
    .required(),
  cardCVV: Joi.string()
    .regex(/^\d{3,4}$/)
    .required()
    .messages({
      'string.empty': 'Digite o CVV para continuar',
      'string.pattern.base': 'CVV inválido',
    }),
  cardId: Joi.string().allow(null, ''),
});

const cardIdRequiredSchema = Joi.object<
  Partial<Omit<CreditCardType, 'cardNumber' | 'cardHolderName' | 'cardCVV' | 'cardExpirationDate'>>
>()
  .keys({
    cardId: Joi.string().required().messages({
      'any.required': CARD_ID_EMPTY,
    }),
  })
  .messages({
    'object.unknown': FIELD_ALLOWED,
  });

const productTypeSchema = Joi.object<ProductType>().keys({
  id: Joi.number().required(),
  amount: Joi.number().required(),
  poleId: Joi.number().optional(),
  orderBumpId: Joi.number().optional(),
});

const schema = Joi.object<IPayment>().keys({
  products: Joi.array().items(productTypeSchema).required(),
  document: Joi.object().keys({
    type: Joi.string().required(),
    number: Joi.when(Joi.ref('...address.country'), {
      is: 'br',
      then: Joi.when(Joi.ref('type'), {
        is: 'cpf',
        then: Joi.string().custom(validateCPF, 'validateCPF').messages({
          'string.empty': 'Digite o CPF ou CNPJ para continuar',
          'any.invalid': 'CPF inválido',
        }),
        otherwise: Joi.string().custom(validateCNPJ, 'validateCNPJ').messages({
          'string.empty': 'Digite o CPF ou CNPJ para continuar',
          'any.invalid': 'CNPJ inválido',
        }),
      }),
      otherwise: Joi.string().required().messages({
        'string.empty': 'Digite o documento para continuar',
      }),
    }),
  }),
  address: Joi.object().keys({
    country: Joi.string().required().length(2).messages({
      'string.empty': 'Selecione o país para continuar',
      'string.length': 'Selecione o país para continuar',
    }),
    zipCode: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().length(8).messages({
        'string.empty': 'Digite o CEP para continuar',
        'string.length': 'O CEP deve ter 8 números',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    streetAddress: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite o endereço para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    streetNumber: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite o Nº para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    neighborhood: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite o bairro para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    state: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Selecione o estado para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    city: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite a cidade para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    complementary: Joi.string().allow(null, ''),
    alternateAddress: Joi.when('country', {
      not: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite o endereço para continuar',
      }),
      otherwise: Joi.string().allow(null, '').optional(),
    }),
  }),
  coupon: Joi.string().optional().allow(null, ''),
  saveCreditCardForFuturePurchases: Joi.boolean(),
  installments: Joi.number().min(1).max(12),
  methodPaymentType: Joi.string().valid('credit_card', 'boleto', 'pix', 'free').required(),
  typeCheckout: Joi.string().valid('LAUNCH', 'DEFAULT').required(),
  creditCard: Joi.when('methodPaymentType', {
    is: 'credit_card',
    then: Joi.when('useSavedCreditCard', {
      is: true,
      then: cardIdRequiredSchema.required(),
      otherwise: creditCardSchema.required(),
    }).required(),
  }),
  useSavedCreditCard: Joi.boolean(),
  affiliation: Joi.string().allow(null, ''),
  UTMData: Joi.object<UTMData>().keys({
    utm_id: Joi.string().required().allow(null),
    utm_source: Joi.string().required().allow(null),
    utm_medium: Joi.string().required().allow(null),
    utm_campaign: Joi.string().required().allow(null),
    utm_term: Joi.string().required().allow(null),
    utm_content: Joi.string().required().allow(null),
  }),
});

export default schema;
