import process from 'process/browser';
import { useEffect } from 'react';
import Toast from './components/Toast';
import ErrorResponse from './helpers/ErrorResponse';
import { IS_COOKIES_ACCEPTED } from './helpers/LocalStorageHelper';
import useFetch from './hooks/useFetch';
import useHotjarScript from './hooks/useHotjarScript';

//--- Eventos GTM ----

// addToCart - O evento é disparado quando a página é carregada.
// begin_checkout - O evento é disparado quando os dados do contacto são preenchidos.
// moved_to_payment - O evento é disparado quando os dados de endereço são preenchidos.
// processing_payment - O evento é disparado quando se clica em “Pagar”.
// purchase - O evento é disparado quando a compra é aprovada.
// billet_payment - O evento é disparado quando o boleto é impresso.
// pending_payment - O evento é disparado quando o pagamento fica pendente.
// cancelled_payment - O evento é disparado quando a compra é negada.

//--- Parâmetros dos Eventos ----

// checkout - Em que fase do checkout se encontra o cliente.
// contact_email - Email encriptado do cliente ("legacy").
// currency - Moeda utilizada na compra.
// email - Email encriptado do cliente.
// event - Evento de checkout - ex: adicionar ao carrinho.
// ecommerce - Informação relativa ao ecommerce utilizado.
// infrastructure - Informação encriptada sobre a localização e acesso do cliente.
// items - Informações relativas ao produto (nome, quantidades, preço e ID).
// name - Nome do comprador.
// payment_method - Método de pagamento utilizado na venda.
// phone - Número de telemóvel do comprador.
// productQty - Quantidade de produtos.
// productTotal - Valor do produto (unitário).
// product_id - Código de identificação do produto.
// product_name - Nome do produto.
// product_total_value - Valor total do produto = valor do produto x quantidade de produto.
// product_type - Tipo de produto.
// total_value - Valor total a pagar pelo cliente.
// transaction_id - Código de identificação da transação.
// zip_code - CEP ou código postal do enderenço do comprador.

export type GTMEventType =
  | 'addToCart'
  | 'begin_checkout'
  | 'moved_to_payment'
  | 'processing_payment'
  | 'purchase'
  | 'billet_payment'
  | 'pending_payment'
  | 'cancelled_payment';

export interface IGTMParams {
  event: GTMEventType;
  checkout: string;
  transaction_id: number;
  name: string;
  contact_email: string;
  email: string;
  phone: string;
  zip_code: string;
  currency: 'BRL';
  items: { item_id: number; item_name: string; value: number; quantity: number }[];
  payment_method: string;
  productQty: string;
  productTotal: string;
  product_id: string;
  product_name: string;
  product_type: string;
  product_total_value: string;
  total_value: string;
  ecommerce: string;
  infrastructure: string;
}

const { install: GoogleTag } = require('./helpers/ga-tag-custom');

export const SCRIPTS_PROLUNO = {
  DOMINIO: 'checkout.proluno.me',
  GOOGLE_TAG: 'GTM-582BPLF',
};

type GTMType = { code: string };

function ExternalScripts() {
  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  const { data: response } = useFetch<UnificadaFront.ResponseJSON<GTMType>>({
    url: '/google-tag-manager/active-list',
    method: 'get',
    autoFetch: true,
    authenticated: false,
  });

  const activeGTM = response?.data?.code;

  useEffect(() => {
    if (isCookiesAccepted === 'false' || !isCookiesAccepted) return;

    async function writeGTMScript() {
      try {
        if (process?.env.NODE_ENV !== 'development') {
          if (activeGTM) {
            // Adiciona o script ao head
            const script = document.createElement('script');
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${activeGTM}');`;
            document.head.appendChild(script);

            // Adiciona o noscript ao body
            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = `https://www.googletagmanager.com/ns.html?id=${activeGTM}`;
            iframe.height = '0';
            iframe.width = '0';
            iframe.style.display = 'none';
            iframe.style.visibility = 'hidden';
            noscript.appendChild(iframe);
            document.body.appendChild(noscript);
          }
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }

    writeGTMScript();
  }, [activeGTM, isCookiesAccepted]);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    GoogleTag(SCRIPTS_PROLUNO.GOOGLE_TAG);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useHotjarScript([
    { domain: 'vorticeconcursos.com.br', hjid: 3773309, hjsv: 6 },
    { domain: 'professorsoares.com.br', hjid: 4942713, hjsv: 6 },
  ]);

  return <span></span>;
}

export default ExternalScripts;
