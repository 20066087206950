import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import haveNotCourse from '../../../assets/images/empty.svg';
import MainCard from '../../../components/MainCard';
import useFetch from '../../../hooks/useFetch';
import ProductCard from './ProductCard';

function Products() {
  const { data: responseProducts, loading: isLoadingProducts } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    method: 'get',
    url: '/students/courses',
    authenticated: true,
    autoFetch: true,
  });

  const { data: responseFeaturedProducts, loading: isLoadingFeaturedProducts } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    method: 'get',
    url: '/students/featured-products',
    authenticated: true,
    autoFetch: true,
  });

  const products = responseProducts?.data;
  const featuredProducts = responseFeaturedProducts?.data;

  return (
    <Container maxW="container.lg">
      {isLoadingProducts && (
        <Box display="flex" width="full" justifyContent="center" margin="30px">
          <Spinner size="lg" colorScheme="primary" />
        </Box>
      )}

      {!isLoadingProducts && products?.length > 0 && (
        <Stack as="section">
          <Heading fontSize="24px" color="#000" fontWeight="500">
            Meus produtos
          </Heading>

          <Flex mt={6} gap={6} flexWrap="wrap">
            {products?.map(product => (
              <ProductCard product={product} />
            ))}
          </Flex>
        </Stack>
      )}

      {!isLoadingProducts && !products?.length && (
        <Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
            w="full"
            alignItems="center"
          >
            <Stack
              width="full"
              direction={{ base: 'column', md: 'row', lg: 'row' }}
              alignItems="center"
              justifyContent="center"
            >
              <Text textAlign="center" fontSize="lg">
                Você ainda não possui produtos comprados. Para adquirir nossos produtos clique no
                botão ao lado.
              </Text>

              <Button
                as={Link}
                size="sm"
                width={{ base: 'full', md: 'auto', lg: 'auto' }}
                to="/all-products"
                colorScheme="primary"
                color="secondary.500"
              >
                Explorar a Proluno
              </Button>
            </Stack>
          </Box>

          <Box display="flex" justifyContent="center">
            <Image w={250} h={250} src={haveNotCourse} alt="Imagem Curso" />
          </Box>
        </Stack>
      )}

      {isLoadingFeaturedProducts && (
        <Box display="flex" width="full" justifyContent="center" margin="30px">
          <Spinner size="lg" colorScheme="primary" />
        </Box>
      )}

      {!isLoadingFeaturedProducts && (
        <Box as="section" mt={10} hidden={!featuredProducts?.length}>
          <Heading fontSize="24px" marginY="18px" color="#000" fontWeight="500">
            Produtos em destaque
          </Heading>

          <Flex
            mt={6}
            gap={6}
            flexWrap="wrap"
            sx={{
              '#main-card': {
                width: { base: '100%', md: '48%', lg: '30%', xl: '23%' },
                '.chakra-avatar': {
                  width: '100%',
                },
              },
              img: {
                width: '100%',
                objectFit: 'cover',
              },
            }}
          >
            {featuredProducts?.map(course => (
              <MainCard
                key={course.id}
                loading={isLoadingFeaturedProducts}
                course={course}
                checkoutType={course.checkoutType}
              />
            ))}
          </Flex>

          <Flex justifyContent="center" my={6}>
            <Button
              as={Link}
              size="sm"
              to="/all-products"
              colorScheme="primary"
              color="secondary.500"
            >
              Ver mais
            </Button>
          </Flex>
        </Box>
      )}
    </Container>
  );
}

export default Products;
