import { IconButton, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from '@chakra-ui/react';
import { memo } from 'react';

import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link, useRouteMatch } from 'react-router-dom';
import { WebhookView } from '../../types';
import Events from './Events';

interface WebhooksTableRowProps {
  webhook: WebhookView;
  onDeleteWebhook: (webhookId: number) => void;
}

function WebhooksTableRow({ webhook, onDeleteWebhook }: WebhooksTableRowProps) {
  const { url: currenUrl } = useRouteMatch();

  const productName =
    webhook.productName === 'all' ? 'Todos' : `${webhook.productName.toLowerCase()}`;

  return (
    <Tr color="gray.700" sx={{ td: { p: 6 } }}>
      <Td maxWidth="12.687rem" whiteSpace="normal" textTransform="capitalize">
        {productName}
      </Td>

      <Td maxWidth="13.0625rem" whiteSpace="normal">
        {webhook.name}
      </Td>

      <Td width="14.125rem" sx={{ py: '14px !important' }}>
        <Events webhookId={webhook.id} events={webhook.events} />
      </Td>

      <Td maxWidth="13.0625rem" whiteSpace="normal">
        {webhook.webhookUrl}
      </Td>

      <Td
        maxWidth="7.1875rem"
        whiteSpace="normal"
        textTransform="capitalize"
        color={webhook.status === 'ATIVO' ? '#22BB33' : '#BB2124'}
      >
        {webhook.status.toLowerCase()}
      </Td>

      <Td textAlign="center">
        <Menu size="sm" arrowPadding={0} placement="bottom-end">
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<MoreIcon size="1.125rem" />}
            w="27px"
            h="24px"
            borderRadius={5}
            bgColor="#2021231F"
            _hover={{ bgColor: '#b6b5b5' }}
            _active={{ bgColor: '#b6b5b5' }}
          />

          <MenuList
            minWidth="101px"
            boxShadow="0px 4px 4px 0px #00000040"
            sx={{
              '.chakra-menu__menuitem': {
                fontWeight: 'medium',
                fontSize: 'xs',
                color: '#20212380',
                _hover: { bgColor: '#2021230D' },
                _focus: { bgColor: '#2021230D' },
                minWidth: '101px',
              },
            }}
          >
            <MenuItem as={Link} to={`${currenUrl}/edit/${webhook.id}`}>
              Editar
            </MenuItem>

            <MenuItem onClick={() => onDeleteWebhook(webhook.id)}>
              <Text color="#BB2124">Excluir</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default memo(WebhooksTableRow);
