import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useEffect, useRef, useState } from 'react';

import { MdOutlineCheck, MdSearch as SearchIcon } from 'react-icons/md';

const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.300',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.400',
  },
};

interface FilterSelectProps {
  name: string;
  label: string;
  options: any[];
  value?: string | number;
  isDisabled?: boolean;
  color?: string;
  bgColor?: string;
  onChange?: (filterId: number) => void;
}

export default function FilterSelect({
  name,
  label,
  value,
  options,
  isDisabled,
  onChange,
}: FilterSelectProps) {
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(
    options?.find(item => item.id === Number(value))?.name
  );

  const selectedItemRef = useRef(null);

  function scrollToSelectedItem() {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({ block: 'nearest' });
    }
  }

  useEffect(() => {
    if (isMenuOpen) {
      const timeout = setTimeout(() => {
        scrollToSelectedItem();
      }, 50);

      return () => clearTimeout(timeout);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setSelectedItem(options?.find(item => item.id === Number(value))?.name);
  }, [options, name, value]);

  useEffect(() => {
    const handleSearch = setTimeout(() => {
      const filteredItems = options?.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );

      setFilteredOptions(filteredItems);
    }, 500);

    return () => clearTimeout(handleSearch);
  }, [searchText, options]);

  function handleSelectItem({ name, value }: { name: string; value: string }) {
    const filterBeingAdded = options?.find(option => option.id === Number(value));

    setSelectedItem(filterBeingAdded?.name);
    onChange(filterBeingAdded?.id);
  }

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      <Menu onOpen={() => setIsMenuOpen(true)} onClose={() => setIsMenuOpen(false)}>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              w="full"
              variant="outline"
              borderRadius="md"
              size="md"
              alignItems="center"
              fontWeight="normal"
              overflow="hidden"
              rightIcon={
                isOpen ? <ChevronUpIcon boxSize="1.25rem" /> : <ChevronDownIcon boxSize="1.25rem" />
              }
              isTruncated
              isDisabled={isDisabled}
              pr={2.5}
              _hover={{ bg: 'none', borderColor: 'gray.300' }}
              _active={{
                bg: 'none',
                zIndex: 1,
                borderColor: 'primary.500',
                boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
              }}
            >
              <Text mr={2} isTruncated textAlign="start" color="#2D374880">
                {selectedItem || 'Selecione'}
              </Text>
            </MenuButton>

            <MenuList
              p={2.5}
              border="none"
              boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
              minWidth="16rem"
            >
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={SearchIcon} color="#2021235C" boxSize={8} mt={-1} ml={3} />
                </InputLeftElement>

                <Input
                  value={searchText}
                  onChange={({ target: { value } }) => setSearchText(value)}
                  type="text"
                  placeholder={`Buscar ${label.toLowerCase()}`}
                  size="sm"
                  borderRadius="md"
                  mb={1.5}
                  focusBorderColor="primary.500"
                  borderColor="gray.200"
                  _placeholder={{ color: '#0000005C' }}
                  pl={12}
                />
              </InputGroup>

              <VStack maxHeight="12rem" overflowY="auto" color="#AFAFB0" sx={scrollbarStyle}>
                {searchText && filteredOptions?.length === 0 ? (
                  <Text>Nenhum resultado encontrado...</Text>
                ) : (
                  <>
                    {filteredOptions
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map(item => (
                        <MenuItem
                          key={name + item.id}
                          onClick={() =>
                            handleSelectItem({
                              name,
                              value: item.id,
                            })
                          }
                          ref={selectedItem === item.name ? selectedItemRef : null}
                        >
                          <Flex align="center" w={5} mr={4}>
                            {selectedItem === item.name && (
                              <Icon as={MdOutlineCheck} color="#EB7129" boxSize={7} />
                            )}
                          </Flex>

                          <Text>{item.name}</Text>
                        </MenuItem>
                      ))}
                  </>
                )}
              </VStack>
            </MenuList>
          </>
        )}
      </Menu>
    </FormControl>
  );
}
