import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  Link as ChakraLink,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import welcomeBackground from '../../../assets/images/backgrounds/welcome-background.png';
import DotFalling from '../../../components/Loadings/DotsFalling';
import MenuDropdown from '../../../components/MenuDropdown/MenuDropdown';
import { useTheme } from '../../../contexts/ThemeContext';
import { NAME_LOCAL_STORAGE } from '../../../helpers/LocalStorageHelper';
import useWindowSize from '../../../hooks/useWindowSize';
import EditPassword from '../EditPassword';
import Payments from '../Payments';
import Products from '../Products';
import Profile from '../Profile';
import QuestionStudent from '../Questions';
import StatisticsView from '../Questions/StatisticsView';
import './style.css';
import useExternalLinkHeader from '../../../useExternalLinkHeader';

export function menuNameGenerate(activeLink) {
  let menuName = 'Questões';

  switch (activeLink) {
    case '/members-area/v1/questions':
      menuName = 'Questões';
      break;
    case '/members-area/v1/statistics':
      menuName = 'Estatísticas';
      break;
    case '/members-area/v1/reports':
      menuName = 'Relatórios';
      break;
    default:
  }

  return menuName;
}

function Main() {
  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();
  const { width } = useWindowSize();
  const location = useLocation();
  const externalLink = useExternalLinkHeader();

  const { setColorMode } = useColorMode();
  const [activeLink, setActiveLink] = useState('');

  const activeColorLink = [
    '/members-area/v1/questions',
    '/members-area/v1/statistics',
    '/members-area/v1/reports',
  ];

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    setColorMode('light');
  }, [setColorMode]);

  if (isLoadingThemeMarketplace) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <DotFalling />
      </div>
    );
  }

  return (
    <>
      <Box
        width="full"
        display="flex"
        height={{ base: '70px', md: '100px', lg: '100px' }}
        alignItems="center"
        justifyContent="space-between"
        position="fixed"
        zIndex={999}
        top={0}
        padding={{ base: 2, md: 5, lg: '40px' }}
        backgroundColor={themeMarketplace.primaryColor}
      >
        {externalLink ? (
          <Box as={ChakraLink} href={externalLink} width="full">
            <Image
              width={132}
              height={50}
              objectFit="contain"
              src={themeMarketplace.logo}
              alt="logo"
            />
          </Box>
        ) : (
          <Box as={Link} to="/" width="full">
            <Image
              width={132}
              height={50}
              objectFit="contain"
              src={themeMarketplace.logo}
              alt="logo"
            />
          </Box>
        )}

        <MenuDropdown />
      </Box>

      <Box as="section" marginTop="6rem" paddingBottom="32px">
        <Box
          width="full"
          height="204px"
          backgroundImage={welcomeBackground}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          justifyContent="center"
          paddingTop="69px"
          paddingBottom="40px"
          hidden={width < 730}
        >
          <Stack spacing="20px" alignItems="center">
            <Heading fontWeight={600}>
              Seja bem-vindo(a), {localStorage.getItem(NAME_LOCAL_STORAGE)}.
            </Heading>

            <Breadcrumb
              separator={''}
              spacing="20px"
              _activeLink={{ colorScheme: 'orange' }}
              fontSize="18px"
              fontWeight={400}
              color="rgba(32, 33, 35, 0.50)"
            >
              <BreadcrumbItem>
                <BreadcrumbLink
                  _hover={{ color: themeMarketplace.primaryColor }}
                  as={Link}
                  to={`/members-area/v1/products`}
                  color={
                    activeLink === '/members-area/v1/products'
                      ? themeMarketplace.primaryColor
                      : 'rgba(32, 33, 35, 0.50)'
                  }
                  style={{ textDecoration: 'none' }}
                >
                  Meus Produtos
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink
                  _hover={{ color: themeMarketplace.primaryColor }}
                  as={Link}
                  to={`/members-area/v1/payments`}
                  color={
                    activeLink === '/members-area/v1/payments'
                      ? themeMarketplace.primaryColor
                      : 'rgba(32, 33, 35, 0.50)'
                  }
                  style={{ textDecoration: 'none' }}
                >
                  Pagamentos
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Menu isLazy>
                  <MenuButton
                    color={
                      activeLink === '/members-area/v1/questions'
                        ? themeMarketplace.primaryColor
                        : 'rgba(32, 33, 35, 0.50)'
                    }
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      color={
                        activeColorLink.includes(activeLink)
                          ? themeMarketplace.primaryColor
                          : 'rgba(32, 33, 35, 0.50)'
                      }
                    >
                      <Text>{menuNameGenerate(activeLink)}</Text>
                      <BiChevronDown />
                    </Stack>
                  </MenuButton>
                  <MenuList fontSize="16px">
                    <MenuItem>
                      <BreadcrumbLink
                        _hover={{ color: themeMarketplace.primaryColor }}
                        as={Link}
                        to={`/members-area/v1/questions`}
                        color={
                          activeLink === '/members-area/v1/questions'
                            ? themeMarketplace.primaryColor
                            : 'rgba(32, 33, 35, 0.50)'
                        }
                        style={{ textDecoration: 'none' }}
                      >
                        Questões
                      </BreadcrumbLink>
                    </MenuItem>
                    <MenuItem>
                      <BreadcrumbLink
                        _hover={{ color: themeMarketplace.primaryColor }}
                        as={Link}
                        to={`/members-area/v1/statistics`}
                        color={
                          activeLink === '/members-area/v1/statistics'
                            ? themeMarketplace.primaryColor
                            : 'rgba(32, 33, 35, 0.50)'
                        }
                        style={{ textDecoration: 'none' }}
                      >
                        Estatísticas
                      </BreadcrumbLink>
                    </MenuItem>
                    <MenuItem hidden>
                      <BreadcrumbLink
                        _hover={{ color: themeMarketplace.primaryColor }}
                        as={Link}
                        to={`/members-area/v1/reports`}
                        color={
                          activeLink === '/members-area/v1/reports'
                            ? themeMarketplace.primaryColor
                            : 'rgba(32, 33, 35, 0.50)'
                        }
                        style={{ textDecoration: 'none' }}
                      >
                        Relatórios
                      </BreadcrumbLink>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink
                  _hover={{ color: themeMarketplace.primaryColor }}
                  as={Link}
                  to={`/members-area/v1/profile`}
                  color={
                    activeLink === '/members-area/v1/profile'
                      ? themeMarketplace.primaryColor
                      : 'rgba(32, 33, 35, 0.50)'
                  }
                  style={{ textDecoration: 'none' }}
                >
                  Perfil
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Stack>
        </Box>

        <Switch>
          <Route path="/members-area/v1/products" component={Products} />
          <Route path="/members-area/v1/payments" component={Payments} />
          <Route path="/members-area/v1/profile" component={Profile} />
          <Route path="/members-area/v1/questions" component={QuestionStudent} />
          <Route path="/members-area/v1/statistics" component={StatisticsView} />
          <Route path="/members-area/v1/reports" component={() => <>olá 2</>} />
          <Route path="/members-area/v1/edit/password" component={EditPassword} />
          <Route path="*" component={() => <Redirect to="/members-area/v1/products" />} />
        </Switch>
      </Box>
    </>
  );
}

export default Main;
