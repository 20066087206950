import { Badge, Box, HStack, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import { platformsWithSubscription } from '../../../../../App';
import PrimaryPrices from './PrimaryPrices';
import SubscriptionPlan from './SubscriptionPlan';
import Plan from './SubscriptionPlan/plan';

function Price() {
  const { id } = useParams();

  const hostname = window.location.hostname;

  return (
    <Box display="flex" flexDirection={{ base: 'column', md: 'row', lg: 'row' }}>
      <Tabs colorScheme="orange" px={10}>
        <TabList>
          <Box
            w="100%"
            display="flex"
            flexDirection="column"
            alignItems={{ base: 'center', md: 'flex-start', lg: 'flex-start' }}
            pr={{ base: 0, md: 5, lg: 155 }}
            borderRight="1px"
            borderColor="blackAlpha.400"
            paddingTop="56px"
          >
            <NavLink
              to={`/courses/${id}/course-manager/price/primary-prices`}
              style={{ margin: 0, width: '100%' }}
            >
              <Tab
                _selected={{ color: '#d96826', borderColor: '#d96826' }}
                color="#4a4b4c"
                style={{
                  outline: 'none',
                  boxShadow: 'none',
                  margin: 0,
                }}
                fontWeight={700}
                fontSize={{ base: 'lg', md: 'xl', lg: 'xl' }}
                w={250}
              >
                Preços primários
              </Tab>
            </NavLink>

            {platformsWithSubscription.includes(hostname) && (
              <NavLink
                to={`/courses/${id}/course-manager/price/subscription`}
                style={{ margin: 0, width: '100%' }}
              >
                <Tab
                  _selected={{ color: 'rgb(216, 104, 38)', borderColor: 'rgb(216, 104, 38)' }}
                  color="#4a4b4c"
                  style={{ outline: 'none', boxShadow: 'none', margin: 0 }}
                  fontWeight={700}
                  fontSize={{ base: 'lg', md: 'xl', lg: 'xl' }}
                  w={250}
                >
                  <HStack>
                    <Text>Assinaturas</Text>
                    <Badge variant="outline" colorScheme="orange">
                      Beta
                    </Badge>
                  </HStack>
                </Tab>
              </NavLink>
            )}
          </Box>
        </TabList>
      </Tabs>

      <Box paddingTop="56px" width="full" paddingRight="40px" paddingLeft={{base: '40px', md: 0}}>
        <Switch>
          <Route
            exact
            path="/courses/:id/course-manager/price/primary-prices*"
            component={PrimaryPrices}
          />
          <Route
            exact
            path="/courses/:id/course-manager/price/subscription"
            component={SubscriptionPlan}
          />
          <Route
            exact
            path="/courses/:id/course-manager/price/subscription/new-plan"
            component={Plan}
          />
          <Route
            exact
            path="/courses/:id/course-manager/price/subscription/:planId/edit"
            component={Plan}
          />
        </Switch>
      </Box>
    </Box>
  );
}

export default Price;
