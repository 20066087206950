import { Heading, List, ListItem } from '@chakra-ui/react';
import { Banner, Course, Showcase } from '../../types';
import ProductsList from '../ProductsList';

interface HomeProps {
  hasProducts: boolean;
  myProducts: Course[];
  showcases: Showcase[];
  banners: Banner[];
  isPreview?: boolean;
}

export default function Home({
  hasProducts,
  myProducts,
  showcases,
  banners,
  isPreview = false,
}: HomeProps) {
  const hasBanner = !!banners?.length;

  return (
    <>
      {hasProducts && (
        <>
          <Heading
            mt={{ base: hasBanner ? 6 : '5rem', xl: hasBanner ? 6 : '7rem' }}
            ml={{ base: 4, lg: '5.5rem' }}
            mb={3}
            color="#ffffff"
            fontSize={{ base: 'xl', md: '3xl' }}
            fontWeight="medium"
          >
            Meus produtos
          </Heading>

          <ProductsList products={myProducts} showProgressBar isPreview={isPreview} />
        </>
      )}

      <List
        mt={hasProducts ? { base: 8, lg: '4rem' } : { base: 4, lg: '8rem' }}
        spacing={{ base: 8, lg: '4rem' }}
      >
        {showcases?.map(
          showcaseItem =>
            !!showcaseItem.showcaseCourse.length && (
              <ListItem key={showcaseItem.id}>
                <Heading
                  ml={{ base: 4, lg: '5.5rem' }}
                  mb={3}
                  color="#ffffff"
                  fontSize={{ base: 'xl', md: '3xl' }}
                  fontWeight="medium"
                >
                  {showcaseItem.name}
                </Heading>

                <ProductsList
                  products={showcaseItem.showcaseCourse.map(showcaseItem => showcaseItem.course)}
                  isPreview={isPreview}
                />
              </ListItem>
            )
        )}
      </List>
    </>
  );
}
