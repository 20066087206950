import {
  Button,
  Center,
  Heading,
  HStack,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

import { MdOutlineSettings as SettingsIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import AlertModal from './components/AlertModal';
import BankAccountForm from './components/BankAccountForm';
import BankDetailsCard from './components/BankDetailsCard';
import { useGetRemoteBanks } from './useGetRemoteBanks';
import { getBankNameByCode } from './utils/getBankNameByCode';
import { getBiometricsStatus } from './utils/getBioMetricStatus';

export type AccountType =
  | 'conta_corrente'
  | 'conta_poupanca'
  | 'conta_corrente_conjunta'
  | 'conta_poupanca_conjunta';

export interface BankDetails {
  id: number;
  recipientId: string;
  bankCode: string;
  agencia: string;
  agenciaDv?: string | null;
  conta: string;
  contaDv: string;
  type: AccountType;
  documentType: 'cpf' | 'cnpj';
  documentNumber: string;
  legalName: string;
  status: 'registration' | 'affiliation' | 'active' | 'inactive';
  kycDetailsStatus: 'pending' | 'partially_denied' | 'approved' | 'denied';
  kycDetailsStatusReason: any;
  statusReason?: string | null;
  url: string;
  base64: string;
  expirationDate: string;
  createdAt: string;
  ddi: string;
  ddd: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  neighborhood: string;
  streetAddress: string;
  streetNumber: string;
  complementary: string;
  zipCode: string;
  birthday: string;
  monthlyIncome: number;
  annualRevenue: number;
  professionalOccupation: string;
  companyName: string;
  tradingName: string;
}

export default function BankDetailsPage() {
  const { data: bankDetailsResponse, loading: isFetchingBankDetails } = useFetch<
    UnificadaFront.ResponseJSON<BankDetails>
  >({
    url: '/users/bank-accounts',
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const { banks: bankList, isLoading: isFetchingBankList } = useGetRemoteBanks();

  const isLoading = isFetchingBankList || isFetchingBankDetails;

  const bankDetails = bankDetailsResponse?.data;

  const biometricStatus = getBiometricsStatus(bankDetails?.status, bankDetails?.kycDetailsStatus);

  const hasBankAccountRegistered = !!bankDetailsResponse;

  const { isOpen: isAlertModalOpen, onClose } = useDisclosure({
    defaultIsOpen: biometricStatus?.name === 'pending',
  });

  if (isLoading) {
    return (
      <Center height="80vh">
        <Spinner color="primary.500" size="xl" thickness="4px" speed="1s" emptyColor="gray.200" />
      </Center>
    );
  }

  return (
    <>
      <VStack align="start" spacing={2}>
        <HStack width="100%" justify="space-between">
          <Heading
            as="h1"
            fontSize={{ base: 'xl', xl: '4xl' }}
            fontWeight="semibold"
            color="#202123"
            marginBottom={0}
          >
            Dados bancários
          </Heading>

          {!!bankDetailsResponse?.data?.conta && (
            <Button
              as={Link}
              to="/profile/bank-accounts/config"
              variant="outline"
              colorScheme="secondary"
              leftIcon={<SettingsIcon />}
              size={{ base: 'sm', xl: 'md' }}
            >
              Configurações
            </Button>
          )}
        </HStack>

        <Text color="#20212380" fontSize={{ base: 'sm', xl: '1.125rem' }} lineHeight="normal">
          O preenchimento dos dados bancários é um parte importante para o lançamento do seu
          primeiro produto online. É através dessas informações fornecidas por você que o nosso
          sistema irá realizar os repasses automáticos das suas vendas.{' '}
          <Text as="strong">Por favor, preencha-os com atenção!</Text>
        </Text>
      </VStack>

      {hasBankAccountRegistered ? (
        <>
          <BankDetailsCard
            bankName={getBankNameByCode(Number(bankDetailsResponse.data?.bankCode), bankList)}
            bankDetails={bankDetailsResponse.data}
          />

          <AlertModal isOpen={isAlertModalOpen} onClose={onClose} />
        </>
      ) : (
        <BankAccountForm
          hasBankAccountRegistered={hasBankAccountRegistered}
          bankList={bankList}
          mt={8}
        />
      )}
    </>
  );
}
