import React from 'react';
import { FcEditImage } from 'react-icons/fc';

export default function ModuleNotSelected() {
  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <FcEditImage className="fs-120px" />
      <h3>Vamos construir seu produto!</h3>
      <p className="mt-3 w-50 fs-17px">
        Aqui é onde você irá cadastrar o produto, criando todos os módulos, adicionando o conteúdo
        que irá disponibilizar em cada módulo, cadastrando as áreas e disciplinas a que ele pertence
        e definindo a duração de acesso e também o preço! Clique no botão "Adicionar Módulo" à
        esquerda para começar.
      </p>
    </div>
  );
}
