import {
  Box,
  Card,
  CloseButton,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useState } from 'react';
import { AiOutlineRight, AiOutlineUp } from 'react-icons/ai';
import DateRangePicker from '../../../../../components/DateRangePicker';
import useWindowSize from '../../../../../hooks/useWindowSize';
import {
  CONVERSION_EVENTS_REGISTER,
  ICourses,
  IRules,
  ISegmentationAnalysis,
} from '../../CreateOrEdit';

const eventsHideConversionType = ['ADD_TO_CART', 'PAGE_VIEW', 'BEGIN_CHECKOUT'];

interface IRule {
  rule: IRules;
  handleChangeRules: (event: ChangeEvent<HTMLSelectElement>, id: number) => void;
  deleteCondition: (id: number) => void;
  courses: ICourses[];
  coursesLoading: boolean;
  setHasChanges: (boolean: boolean) => void;
  setSegmentationAnalysis: (segmentationAnalysis: ISegmentationAnalysis | null) => void;
}

export function ConditionsRegistrationCard({
  rule,
  deleteCondition,
  courses,
  coursesLoading,
  handleChangeRules,
  setHasChanges,
  setSegmentationAnalysis,
}: IRule) {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showSelectRange, setShowSelectRange] = useState(false);
  const { width } = useWindowSize();
  const [startDate, setStartDate] = useState('--/--/----');
  const [endDate, setEndDate] = useState('--/--/----');

  useEffect(() => {
    if (rule?.eventTriggerDateStart && rule?.eventTriggerDateEnd) {
      setStartDate(dayjs(rule.eventTriggerDateStart).format('YYYY-MM-DD'));
      setEndDate(dayjs(rule.eventTriggerDateEnd).format('YYYY-MM-DD'));
      setShowSelectRange(true);
    }
  }, [rule.eventTriggerDateEnd, rule.eventTriggerDateStart]);

  function handleRangeChange(startDate: string, endDate: string) {
    setStartDate(dayjs(startDate).format('YYYY-MM-DD'));
    setEndDate(dayjs(endDate).format('YYYY-MM-DD'));
    rule.eventTriggerDateStart = dayjs(startDate).format('YYYY-MM-DD');
    rule.eventTriggerDateEnd = dayjs(endDate).format('YYYY-MM-DD');
    setHasChanges(true);
    setSegmentationAnalysis(null);
  }

  function removeDate() {
    delete rule.eventTriggerDateStart;
    delete rule.eventTriggerDateEnd;
    setStartDate('--/--/----');
    setEndDate('--/--/----');
    setHasChanges(true);
    setSegmentationAnalysis(null);
  }

  function getValueEvent({ name, isDenied }) {
    if (name === '' && typeof isDenied !== 'boolean') {
      return '';
    }

    const eventMap = {
      PAGE_VIEW: isDenied ? 'NOT_PAGE_VIEW' : 'PAGE_VIEW',
      ADD_TO_CART: isDenied ? 'NOT_ADD_TO_CART' : 'ADD_TO_CART',
      BEGIN_CHECKOUT: isDenied ? 'NOT_BEGIN_CHECKOUT' : 'BEGIN_CHECKOUT',
    };

    if (eventMap[name]) {
      return eventMap[name];
    }

    return isDenied ? 'NOT_CONVERTED' : 'CONVERTED';
  }

  return (
    <>
      <Card
        marginY={5}
        borderRadius="5px"
        border="1px solid rgba(32, 33, 35, 0.05)"
        bgColor="#FFF"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        position="relative"
        onMouseLeave={() => setShowCloseButton(false)}
        onMouseOut={() => setShowCloseButton(true)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="self-end"
          position="absolute"
          width="full"
          marginLeft={3}
          marginBottom={5}
          hidden={width < 412 ? false : !showCloseButton}
        >
          <Box
            display="flex"
            zIndex={9}
            color="#FFF"
            bgColor="#757475"
            borderRadius="full"
            onClick={() => {
              deleteCondition(rule.id);
              setHasChanges(true);
            }}
          >
            <CloseButton size="sm" />
          </Box>
        </Box>
        <Stack
          spacing="10px"
          padding="20px 20px 10px 20px"
          direction={{ base: 'column', md: 'row', lg: 'row' }}
        >
          <FormControl>
            <Stack spacing={0}>
              <FormLabel fontSize="14px" lineHeight="20px" fontWeight={500}>
                Evento
              </FormLabel>

              <Select
                name="event"
                width={'isDenied' in rule ? 'full' : '307px'}
                height="40px"
                borderRadius={5}
                color="#20212380"
                focusBorderColor="orange.500"
                onChange={event => handleChangeRules(event, rule.id)}
                value={getValueEvent({ name: rule.conversionEvent, isDenied: rule.isDenied })}
                isRequired
              >
                <option hidden style={{ color: '#20212380' }}>
                  Selecione
                </option>

                <optgroup label="Conversão" style={{ fontWeight: 500, color: '#202123' }}>
                  <option value="CONVERTED" style={{ color: '#20212380' }}>
                    Converteu
                  </option>
                  <option value="NOT_CONVERTED" style={{ color: '#20212380' }}>
                    Não converteu
                  </option>
                </optgroup>

                <optgroup
                  label="Monitoramento de leads"
                  style={{ fontWeight: 500, color: '#202123' }}
                >
                  <option value="PAGE_VIEW" style={{ color: '#20212380' }}>
                    Visitou a página de vendas
                  </option>
                  <option value="NOT_PAGE_VIEW" style={{ color: '#20212380' }}>
                    Não visitou a página de vendas
                  </option>
                  <option value="ADD_TO_CART" style={{ color: '#20212380' }}>
                    Adicionou um produto ao carrinho
                  </option>
                  <option value="NOT_ADD_TO_CART" style={{ color: '#20212380' }}>
                    Não adicionou um produto ao carrinho
                  </option>
                  <option value="BEGIN_CHECKOUT" style={{ color: '#20212380' }}>
                    Iniciou/Entrou no Checkout de Lançamento
                  </option>
                  <option value="NOT_BEGIN_CHECKOUT" style={{ color: '#20212380' }}>
                    Não Iniciou/Entrou no Checkout de Lançamento
                  </option>
                </optgroup>
              </Select>
            </Stack>
          </FormControl>
          <FormControl
            hidden={
              eventsHideConversionType.includes(rule.conversionEvent) || !('isDenied' in rule)
            }
          >
            <Stack spacing={0}>
              <FormLabel fontSize="14px" lineHeight="20px" fontWeight={500}>
                Tipo de conversão
              </FormLabel>

              <Select
                width="full"
                height="40px"
                name="event"
                color="#20212380"
                borderRadius={5}
                focusBorderColor="orange.500"
                isDisabled={coursesLoading}
                onChange={event => handleChangeRules(event, rule.id)}
                value={rule.conversionEvent}
                isRequired={!eventsHideConversionType.includes(rule.conversionEvent)}
              >
                <option hidden style={{ color: '#20212380' }}>
                  Selecione
                </option>
                <option value="PURCHASE">na compra do produto</option>
                <option value="ISSUANCE_BOLETO">na emissão de Boleto</option>
                <option value="ISSUANCE_PIX">na emissão de Pix</option>
                <option value="FREE_SIGN_UP">na inscrição gratuita</option>
                <option value="EXTERNAL_FORM">no formulário externo</option>
                <option value="REGISTER_DEFAULT">Cadastro Padrão</option>
                <option value="REGISTER_CHECKOUT">Cadastro via Checkout</option>
                <option value="REGISTER_QUESTION">Cadastro via Questões</option>
              </Select>
            </Stack>
          </FormControl>
          <FormControl
            hidden={
              !('isDenied' in rule) || CONVERSION_EVENTS_REGISTER.includes(rule.conversionEvent)
            }
          >
            <Stack spacing={0}>
              <FormLabel fontSize="14px" lineHeight="20px" fontWeight={500}>
                Produto
              </FormLabel>

              <Select
                width="full"
                height="40px"
                color="#20212380"
                name="courseId"
                placeholder="Selecione"
                borderRadius={5}
                focusBorderColor="orange.500"
                isDisabled={coursesLoading}
                onChange={event => handleChangeRules(event, rule.id)}
                value={rule.courseId ?? ''}
                isRequired={!CONVERSION_EVENTS_REGISTER.includes(rule.conversionEvent)}
              >
                <option hidden style={{ color: '#20212380' }}>
                  Selecione
                </option>
                {courses.length > 0 &&
                  courses.map(conversionEvent => (
                    <option key={conversionEvent.id} value={conversionEvent.id}>
                      {conversionEvent.name}
                    </option>
                  ))}
              </Select>
            </Stack>
          </FormControl>
        </Stack>

        <Box padding="0px 20px 20px 20px">
          <Stack
            direction={{ base: 'column', md: 'row', lg: 'row' }}
            alignItems={{ base: 'start', md: 'center', lg: 'center' }}
            hidden={!showSelectRange}
          >
            <Text fontSize="12px" fontWeight={500}>
              Data
            </Text>

            <Box
              width={{ base: 'full', md: 220, lg: 220 }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              border="1px solid var(--gray-200, #E2E8F0)"
              borderRadius={6}
              paddingX={3}
            >
              <Text color="rgba(45, 55, 72, 0.50)" fontSize="16px">
                {startDate}
              </Text>
              <DateRangePicker
                startDate={dayjs().format('YYYY-MM-DD')}
                endDate={dayjs().format('YYYY-MM-DD')}
                onChange={handleRangeChange}
                hideSelect
              />
            </Box>

            <Text fontSize="12px" fontWeight={400}>
              até
            </Text>
            <Box
              width={{ base: 'full', md: 220, lg: 220 }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              border="1px solid var(--gray-200, #E2E8F0)"
              borderRadius={6}
              paddingX={3}
            >
              <Text color="rgba(45, 55, 72, 0.50)" fontSize="16px">
                {endDate}
              </Text>
              <DateRangePicker
                startDate={dayjs().format('YYYY-MM-DD')}
                endDate={dayjs().format('YYYY-MM-DD')}
                onChange={handleRangeChange}
                hideSelect
              />
            </Box>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            cursor="pointer"
            width="120px"
            onClick={() => {
              setShowSelectRange(!showSelectRange);
              setHasChanges(true);
            }}
          >
            {showSelectRange ? (
              <Stack
                direction="row"
                alignItems="center"
                spacing="6px"
                fontSize="12px"
                fontWeight="500"
                lineHeight="24px"
              >
                <AiOutlineUp width="10px" height="6px" />
                <Text onClick={removeDate}>Remover data</Text>
              </Stack>
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                spacing="6px"
                fontSize="12px"
                fontWeight="500"
                lineHeight="24px"
              >
                <AiOutlineRight width="10px" height="6px" />
                <Text>Adicionar data</Text>
              </Stack>
            )}
          </Stack>
        </Box>
      </Card>
    </>
  );
}
