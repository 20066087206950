import {
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Select as SelectMulti } from 'chakra-react-select';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import useHandleChange, { TargetType } from '../../../../../../hooks/useHandleChange';
import useHandleSubmit, { Validation } from '../../../../../../hooks/useHandleSubmit';
import {
  CreateShowcaseSchemaType,
  createShowcaseSchemaValidator,
} from './createShowcaseSchemaValidator';
import { IShowcase } from '..';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
}

const initialForm = {
  name: '',
  productsIds: [],
};

const chakraStyles = {
  container: provided => ({
    ...provided,
    width: '100%',
    color: '#20212380',
    fontSize: 'sm',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#20212380',
    fontSize: 'sm',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    bg: 'transparent',
    color: '#202123',
    px: 2.5,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    color: '#20212380',
    fontSize: 'sm',
  }),
  valueContainer: provided => ({
    ...provided,
    px: 3,
    color: '#20212380',
    fontSize: 'sm',
  }),
};

export function CreateShowcaseModal({ isOpen, onClose, showcases, setShowcases }: IProps) {
  const [options, setOptions] = useState<{ value: number; label: string }[]>([]);

  const { form, handleChange, setForm } = useHandleChange<CreateShowcaseSchemaType>(initialForm);

  function afterCreateConfirm(response) {
    if (!response.data.data) return;

    setShowcases([...showcases, response.data.data]);

    setForm(initialForm);

    onClose();
  }

  const { data: courseListResponse, loading: courseListLoading } = useFetch<
    UnificadaFront.ResponseJSON<{ id: number; name: string }[]>
  >({
    method: 'get',
    url: `/showcase/courses`,
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    const courseListFiltered = courseListResponse?.data?.filter(
      course => !form.productsIds.includes(course.id)
    );

    const options = courseListFiltered?.map(course => ({
      value: course.id,
      label: course.name,
    }));

    setOptions(options);
  }, [courseListResponse?.data, form.productsIds]);

  function onChange(newValues) {
    if (!newValues || newValues.length === 0) return; // Verificar se há valores novos

    const chosenCourses = form.productsIds;

    const newArrayValue = chosenCourses.concat(newValues.map(newValue => newValue.value));

    const event = {
      target: {
        name: 'productsIds',
        value: newArrayValue,
      },
    };

    handleChange(event as unknown as ChangeEvent<TargetType>);
  }

  function onClickButton(productId) {
    const productsIds = form.productsIds?.filter(course => course !== productId);
    setForm({ ...form, productsIds });
  }

  const payload = { ...form };

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: createShowcaseSchemaValidator,
    url: '/showcase',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Vitrine criada com sucesso!',
      callback(response) {
        afterCreateConfirm(response);
      },
    },
  });

  const isInvalidSelect =
    !form.productsIds.length && (formValidation?.productsIds as unknown as Validation)?.isInvalid;

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Adicionar uma vitrine</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <FormControl isInvalid={formValidation?.name?.isInvalid}>
              <FormLabel>Nome da vitrine</FormLabel>

              <Input
                name="name"
                value={form.name}
                onChange={handleChange}
                placeholder="Digite aqui"
              />

              <FormErrorMessage>{formValidation?.name.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={(formValidation?.productsIds as unknown as Validation)?.isInvalid}
            >
              <FormLabel>Produtos</FormLabel>

              <SelectMulti
                menuPlacement="bottom"
                placeholder="Selecione os produtos"
                isMulti={true}
                focusBorderColor="primary.500"
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'Nenhum produto encontrado.'}
                options={options}
                value={[]}
                isLoading={courseListLoading}
                menuPortalTarget={document.body}
                isInvalid={isInvalidSelect}
                onChange={onChange}
                chakraStyles={chakraStyles}
                styles={{
                  menuPortal: (provided: Record<string, any>) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />

              <FormErrorMessage>
                {(formValidation?.productsIds as unknown as Validation)?.message}
              </FormErrorMessage>

              <Stack direction="row" alignItems="center" flexWrap="wrap" flex={1} marginY={2}>
                {form.productsIds.length &&
                  form.productsIds.map(productId => (
                    <Flex
                      key={productId}
                      justify="center"
                      align="center"
                      paddingX={2}
                      paddingY={0.5}
                      gap={1}
                      maxWidth="13rem"
                      backgroundColor="#20212325"
                      borderRadius={6}
                    >
                      <Text fontWeight="medium" fontSize="xs" color="#202123" isTruncated>
                        {courseListResponse?.data?.find(course => course.id === productId)?.name}
                      </Text>

                      <CloseButton
                        size="sm"
                        boxSize={4}
                        sx={{ svg: { boxSize: '6.36px' } }}
                        onClick={() => onClickButton(productId)}
                      />
                    </Flex>
                  ))}
              </Stack>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button size="sm" onClick={onClose} colorScheme="gray">
              Cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
