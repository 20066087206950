import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Heading } from '../../../../../components/Heading';
import DataLoading from '../../../../../components/Loadings/DataLoading';
import Toast from '../../../../../components/Toast';
import { useCourse } from '../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import './style.css';

function Publish() {
  const { course, setCourse, updateCourse, loading } = useCourse();
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleChange(event) {
    const { value } = event.target;
    setCourse({ ...course, status: value });
  }

  async function handlerStatus(event) {
    event.preventDefault();

    try {
      await updateCourse({ status: course.status });
      onClose();
      Toast('Curso atualizado com sucesso', 'success');
    } catch (error) {
      onClose();
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function BeforeHandleSubmit(event) {
    event.preventDefault();

    if (course.status === 'INATIVO') {
      return onOpen();
    }
    handlerStatus(event);
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-12 col-xl-8 mt-5">
        <form className="card shadow-sm mb-5" onSubmit={BeforeHandleSubmit}>
          <div className="card-inner">
            <Heading as="h4" fontSize="2xl">
              Publicar
            </Heading>
            <div className="mt-3">
              <p className="fs-16px">Status de publicação do curso</p>
            </div>
            <DataLoading className="mt-3" loading={loading}>
              <div className="mt-3 d-flex flex-column">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio1"
                    name="status"
                    className="custom-control-input"
                    value="EM_EDICAO"
                    onChange={handleChange}
                    checked={course.status === 'EM_EDICAO'}
                  />
                  <label
                    className="custom-control-label text-title-label font-weight-bold"
                    htmlFor="customRadio1"
                  >
                    Em Edição
                  </label>
                </div>
                <p className="m-2 fs-16px">
                  <strong>Os alunos não podem comprar ou se inscrever neste curso.</strong> Para os
                  alunos que já estão matriculados o acesso ainda irá permanecer.
                </p>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio3"
                    name="status"
                    className="custom-control-input"
                    value="NAO_LISTADO"
                    onChange={handleChange}
                    checked={course.status === 'NAO_LISTADO'}
                  />
                  <label
                    className="custom-control-label text-title-label font-weight-bold"
                    htmlFor="customRadio3"
                  >
                    Não Listado
                  </label>
                </div>
                <p className="m-2 fs-16px">
                  O produto não será listado na página principal e não aparecerá nas buscas. Apenas
                  quem tiver o link da página do produto poderá acessa-lo para compra/inscrição.
                </p>
                <div className="custom-control custom-radio mt-1">
                  <input
                    type="radio"
                    id="customRadio2"
                    name="status"
                    className="custom-control-input"
                    value="ATIVO"
                    onChange={handleChange}
                    checked={course.status === 'ATIVO'}
                  />
                  <label
                    className="custom-control-label text-title-label font-weight-bold"
                    htmlFor="customRadio2"
                  >
                    Ativo
                  </label>
                </div>
                <p className="mt-1 mx-2 fs-16px">
                  Os alunos podem comprar, se inscrever e acessar o conteúdo deste curso. Para os
                  alunos que estão matriculados, este curso aparecerá em seu Painel do Aluno.
                </p>
                <p className="my-1 mx-2">
                  <b style={{ color: '#D36625' }}>Importante:</b> Você só poderá publicar um produto
                  pago após cadastrar seus dados bancários.{' '}
                  <Link
                    className="font-weight-bold"
                    to="/profile/bank-accounts"
                    style={{ color: '#D36625' }}
                  >
                    Acessar dados bancários
                  </Link>
                </p>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio4"
                    name="status"
                    className="custom-control-input"
                    value="INATIVO"
                    onChange={handleChange}
                    checked={course.status === 'INATIVO'}
                  />
                  <label
                    className="custom-control-label text-title-label font-weight-bold"
                    htmlFor="customRadio4"
                  >
                    Inativo
                  </label>
                </div>
                <p className="mt-1 mx-2 fs-16px">
                  Os alunos não podem comprar ou se inscrever neste produto. Para os alunos que já
                  estão matriculados, este produto{' '}
                  <strong>não ficará mais disponível na área do aluno.</strong>
                </p>
              </div>
            </DataLoading>
            <div className="d-flex justify-content-end align-items-center mt-3 mr-2">
              <button className="btn btn-primary px-5">Salvar</button>
            </div>
          </div>
        </form>
        <>
          <Modal
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            size="lg"
            motionPreset="slideInBottom"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton style={{ outline: 'none' }} _focus="none" />
              <ModalBody>
                <HStack spacing={2} p={10} alignItems="center" justifyContent="center" w="100%">
                  <Heading fontSize="2xl">
                    Ao alterar para o status INATIVO, todos os alunos irão perder o acesso ao
                    produto, está ciente dessa decisão?
                  </Heading>
                </HStack>
              </ModalBody>
              <ModalFooter>
                <HStack spacing={2} p={3} alignItems="center" justifyContent="center" w="100%">
                  <Button
                    w={120}
                    colorScheme="gray"
                    onClick={handlerStatus}
                    style={{ outline: 'none' }}
                    isDisabled={loading}
                    _focus="none"
                  >
                    Sim
                  </Button>
                  <Button
                    w={120}
                    colorScheme="red"
                    onClick={onClose}
                    style={{ outline: 'none' }}
                    _focus="none"
                    isDisabled={loading}
                  >
                    Não
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      </div>
    </div>
  );
}

export default Publish;
