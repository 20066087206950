import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Poles from '../../../../api/Poles';
import RevenueSplitAPI from '../../../../api/RevenueSplit';
import UserAPI from '../../../../api/User';
import Users from '../../../../assets/icons/users.svg';
import { Heading } from '../../../../components/Heading';
import Pagination from '../../../../components/Pagination';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import NoData from './NoData';

const initialState = {
  id: '',
  courseId: '',
  percentage: '',
};

function getDefaultIndex(splitType) {
  return splitType === 'default-type' ? 0 : 1;
}

function RevenueSplit() {
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabIndex, setTabIndex] = useState(0);

  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [isLoadingPercentages, setIsLoadingPercentages] = useState(true);
  const [isLoadingSplitDefault, setIsLoadingSplitDefault] = useState(true);
  const [isLoadingSplitPoles, setIsLoadingSplitPoles] = useState(true);
  const [isLoadingUsersSede, setIsLoadingUsersSede] = useState(true);

  const [courses, setCourses] = useState([]);
  const [revenueSplits, setRevenueSplits] = useState([]);
  const [filterSplits, setFilterSplits] = useState([]);
  const [revenueSplitsPole, setRevenueSplitsPole] = useState([]);
  const [filterSplitsPole, setFilterSplitsPole] = useState([]);
  const [coProducers, setCoProducers] = useState([]);
  const [allPoles, setAllPoles] = useState([]);

  const [newRevenueSplit, setNewRevenueSplit] = useState(initialState);
  const [percentageAvailable, setPercentageAvailable] = useState();

  const [page, setPage] = useState(1);
  const [pagePole, setPagePole] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageCountPole, setPageCountPole] = useState(0);

  const [searchSplits, setSearchSplits] = useState('');
  const [searchStatusPole, setSearchStatusPole] = useState('');
  const [searchSplitsPole, setSearchSplitsPole] = useState('');

  const [availablePercentagePreview, setAvailablePercentagePreview] = useState(null);
  const [searchActive, setSearchActive] = useState(false);
  const [erroProduct, setErroProduct] = useState(null);

  const { type } = useParams();
  const { url } = useRouteMatch();

  const getRevenueSplitsDefault = useCallback(async ({ page, search }) => {
    try {
      setIsLoadingSplitDefault(true);
      const { data: revenueSplitsData } = await RevenueSplitAPI.indexDefault({
        page,
        search,
      });

      if (revenueSplitsData?.revenueSplitDefaultView?.length) {
        setRevenueSplits(revenueSplitsData.revenueSplitDefaultView);
        setFilterSplits(revenueSplitsData.revenueSplitDefaultView);
        setPageCount(revenueSplitsData.total / revenueSplitsData.per_page);
      } else {
        setFilterSplits([]);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingSplitDefault(false);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchSplits && !searchActive) {
        setSearchActive(true);
        handlePageChange({ selected: 0 });
      }
      if (!searchSplits && searchActive) {
        setSearchActive(false);
        handlePageChange({ selected: 0 });
      }
      getRevenueSplitsDefault({
        page: page,
        search: searchSplits,
      });
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchActive, searchSplits]);

  async function getRevenueSplitsPole({ page, search, status }) {
    try {
      setIsLoadingSplitPoles(true);
      const { data: revenueSplitsPoleData } = await RevenueSplitAPI.indexPole({
        page,
        search,
        status,
      });

      if (revenueSplitsPoleData?.revenueSplitPoleView?.length) {
        setRevenueSplitsPole(revenueSplitsPoleData.revenueSplitPoleView);
        setFilterSplitsPole(revenueSplitsPoleData.revenueSplitPoleView);
        setPageCountPole(revenueSplitsPoleData.total / revenueSplitsPoleData.per_page);
      } else {
        setFilterSplitsPole([]);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingSplitPoles(false);
    }
  }

  useEffect(() => {
    getRevenueSplitsPole({ page: pagePole });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePole]);

  useEffect(() => {
    getCoProducers();
    getCoProducersSede();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function percentageAvailableByCourse(courseId, revenueType) {
    try {
      setIsLoadingPercentages(true);
      const { data: percentageAvailableByCourseData } = await RevenueSplitAPI.percentages.index(
        courseId,
        revenueType
      );

      if (percentageAvailableByCourseData) {
        setPercentageAvailable(percentageAvailableByCourseData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingPercentages(false);
    }
  }

  async function getCourses() {
    try {
      setIsLoadingCourses(true);
      const { data: listCoursesData } = await RevenueSplitAPI.revenueSplitCourseList();

      if (listCoursesData.length) {
        setCourses(listCoursesData);
      }
    } catch (error) {
      Toast(ErrorResponse(error));
    } finally {
      setIsLoadingCourses(false);
    }
  }

  async function getCoProducers() {
    try {
      setIsLoadingUsers(true);
      const { data: coProducersData } = await UserAPI.coProducers.index();

      if (coProducersData) {
        setCoProducers(coProducersData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingUsers(false);
    }
  }

  async function getCoProducersSede() {
    try {
      setIsLoadingUsersSede(true);
      const { data: findAllPolesData } = await Poles.findAllPoles({ page: 1, perPage: 1000 });

      if (findAllPolesData.data) {
        setAllPoles(findAllPolesData.data);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingUsersSede(false);
    }
  }

  function filterPole() {
    handlePagePoleChange({ selected: 0 });
    getRevenueSplitsPole({ page: pagePole, search: searchSplitsPole, status: searchStatusPole });
  }

  function handlePageChange({ selected: selectedPage }) {
    setPage(selectedPage + 1);
  }

  function handlePagePoleChange({ selected: selectedPage }) {
    setPagePole(selectedPage + 1);
  }

  function handleChangeFilterDefault(event) {
    const { value } = event.target;
    setSearchSplits(value);
  }

  function handleChangeFilterPole(event) {
    const { name, value } = event.target;

    if (name === 'searchStatus') {
      setSearchStatusPole(value);
    } else {
      setSearchSplitsPole(value);
    }
  }

  async function handleChangeNewSplit(event) {
    const { name, value } = event.target;

    setErroProduct(null);

    setNewRevenueSplit(prevPost => ({ ...prevPost, [name]: value }));

    if (name === 'courseId') {
      await checkProductsSplit(
        parseInt(value),
        tabIndex === 0 ? 'DEFAULT' : tabIndex === 1 ? 'POLE' : 'AFFILIATE'
      );
      await percentageAvailableByCourse(
        parseInt(value),
        tabIndex === 0 ? 'DEFAULT' : tabIndex === 1 ? 'POLE' : 'AFFILIATE'
      );
    }

    if (name === 'percentage') {
      setAvailablePercentagePreview(
        parseFloat(percentageAvailable?.availablePercentage) - parseFloat(value)
      );

      if (value === '') {
        setAvailablePercentagePreview(null);
      }
    }
  }

  function handleTabsChange(index) {
    if (index === 0) {
      history.push(`/revenue-split/default-type`);
    }

    if (index === 1) {
      history.push('/revenue-split/pole-type');
    }

    setTabIndex(index);
  }

  function editSplit(id) {
    history.push(`${url}/${id}`);
  }

  async function handleRevenueSplit(tabIndex) {
    if (tabIndex === 0) {
      onOpen();
      getCourses();
      getCoProducers();
    } else {
      onOpen();
      getCourses();
      getCoProducersSede();
    }
  }

  async function checkProductsSplit(course_id, revenueType) {
    try {
      const payload = {
        course_id,
        revenueType,
      };

      if (course_id) {
        const checkSplit = await RevenueSplitAPI.checkProductsSplit(payload);

        if (checkSplit.data && tabIndex === 0) {
          setErroProduct(
            'Esse produto já possui um compartilhamento do tipo Polo criado e para adicioná-lo ao Compartilhamento Padrão é necessário que o compartilhamento anterior seja excluído.'
          );
        }

        if (checkSplit.data && tabIndex === 1) {
          setErroProduct(
            'Esse produto já possui um compartilhamento do tipo Padrão criado e para adicioná-lo ao Compartilhamento de Polo é necessário que o compartilhamento anterior seja excluído.'
          );
        }
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function updateRevenueSplitGroup(id, status) {
    try {
      let newStatus = '';

      if (status === 'ATIVO') {
        newStatus = 'INATIVO';
      } else {
        newStatus = 'ATIVO';
      }

      const payload = {
        status: newStatus,
      };

      if (id) {
        await RevenueSplitAPI.updateStatusGroup(id, payload);
        Toast('Compartilhamento atualizado!', 'success');

        getRevenueSplitsDefault({
          page: page,
          search: searchSplits,
        });

        getRevenueSplitsPole({
          page: pagePole,
          search: searchSplitsPole,
        });
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function deleteRevenueSplitGroup(id) {
    try {
      if (id) {
        await RevenueSplitAPI.deleteGroup(id);
        Toast('Compartilhamento Deletado!', 'success');

        getRevenueSplitsDefault({
          page: page,
          search: searchSplits,
        });

        getRevenueSplitsPole({
          page: pagePole,
          search: searchSplitsPole,
        });
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function newRevenueSubmit(event) {
    event.preventDefault();

    let payload = {};

    if (tabIndex === 0) {
      payload = {
        user_id: parseInt(newRevenueSplit.id),
        course_id: parseInt(newRevenueSplit.courseId),
        percentage: parseFloat(newRevenueSplit.percentage),
      };

      try {
        await RevenueSplitAPI.store(payload);
        Toast('Compartilhamento adicionado com sucesso!', 'success');
        await getRevenueSplitsDefault({
          page: page,
          search: searchSplits,
        });
        onClose();
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    } else {
      payload = {
        pole_id: parseInt(newRevenueSplit.id),
        course_id: parseInt(newRevenueSplit.courseId),
        percentage: parseFloat(newRevenueSplit.percentage),
      };

      try {
        await RevenueSplitAPI.storePole(payload);
        Toast('Compartilhamento adicionado com sucesso!', 'success');
        await getRevenueSplitsPole({ page: pagePole, search: searchSplitsPole });

        onClose();
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }
  }

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        w="100%"
        justifyContent="space-between"
        my={5}
        alignItems="center"
      >
        <Heading
          fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}
          w="100%"
          textAlign={{ base: 'center', md: 'start', lg: 'start' }}
          isTruncated
        >
          Compartilhamento de Receita
        </Heading>
        <Stack
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          w={{ base: '100%', md: 'auto', lg: 'auto' }}
        >
          <Button
            px={5}
            size="sm"
            colorScheme="orange"
            style={{ outline: 'none', boxShadow: 'none' }}
            onClick={() => handleRevenueSplit(tabIndex)}
          >
            {tabIndex === 0 ? 'Novo Compartilhamento' : 'Novo Compartilhamento Polo'}
          </Button>
        </Stack>
      </Stack>

      <Tabs colorScheme="orange" onChange={handleTabsChange} defaultIndex={getDefaultIndex(type)}>
        <TabList>
          <Tab style={{ outline: 'none', boxShadow: 'none' }}>Padrão</Tab>
          <Tab style={{ outline: 'none', boxShadow: 'none' }}>Compartilhamento de polo</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Heading
              hidden={revenueSplits.length}
              my={5}
              fontSize="lg"
              fontWeight={500}
              w="100%"
              textAlign={{ base: 'center', md: 'start', lg: 'start' }}
              color="#CBC9C9"
            >
              Compartilhe a receita dos seus produtos com quem contribuiu na construção! Adicione
              quantos você quiser, mas fique atento a porcentagem que será concedida!
            </Heading>

            {!revenueSplits.length && !isLoadingSplitDefault && (
              <NoData message="Você não possui compartilhamento de receitas." />
            )}

            {isLoadingSplitDefault && (
              <Box w="100%" h={300} display="flex" justifyContent="center" alignItems="center">
                <Spinner color="orange.400" />
              </Box>
            )}

            {!isLoadingSplitDefault && revenueSplits.length > 0 && (
              <Stack direction="row" justifyContent="flex-end" alignItems="center">
                <Stack spacing={4}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<AiOutlineSearch color="gray" size="25px" />}
                    />
                    <Input
                      h="40px"
                      hidden={isLoadingSplitDefault}
                      size="sm"
                      type="text"
                      name="search"
                      placeholder="Buscar por Produto ou Usuário"
                      onChange={handleChangeFilterDefault}
                      value={searchSplits}
                      w={{ base: '100%', md: 320, lg: 320 }}
                      focusBorderColor="orange.400"
                      borderRadius={5}
                    />
                  </InputGroup>
                </Stack>
              </Stack>
            )}

            {!filterSplits.length && !isLoadingSplitDefault && searchSplits && (
              <NoData message="Você não possui compartilhamento com esse nome ou produto." />
            )}

            <TableContainer
              hidden={
                isLoadingSplitDefault ||
                !revenueSplits.length ||
                (!filterSplits.length && searchSplits !== '')
              }
            >
              <Table
                variant="unstyled"
                style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
              >
                <Thead bgColor="#EB7129" color="white" borderRadius={5} h={45}>
                  <Tr>
                    <Th
                      fontSize="14px"
                      fontFamily="Montserrat !important"
                      textTransform="none"
                      fontWeight={400}
                      borderLeftRadius={5}
                    >
                      <Text mx={2}>Produto</Text>
                    </Th>
                    <Th
                      fontSize="14px"
                      fontFamily="Montserrat !important"
                      textTransform="none"
                      fontWeight={400}
                    >
                      <Text mx={2}>Usuários no compartilhamento</Text>
                    </Th>

                    <Th
                      fontSize="14px"
                      fontFamily="Montserrat !important"
                      textTransform="none"
                      fontWeight={400}
                      borderRightRadius={5}
                      w={20}
                    >
                      <Text mx={2}>Ações</Text>
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {!isLoadingSplitDefault &&
                    filterSplits.length > 0 &&
                    filterSplits.map(revenueSplit => (
                      <Tr bgColor="rgba(32, 33, 35, 0.05)">
                        <Td
                          borderLeftRadius={5}
                          onClick={() => editSplit(revenueSplit.idGroup)}
                          cursor="pointer"
                        >
                          <Text mx={2} color="#20212380">
                            {revenueSplit.courseName}
                          </Text>
                        </Td>

                        <Td
                          borderLeftRadius={5}
                          onClick={() => editSplit(revenueSplit.idGroup)}
                          cursor="pointer"
                        >
                          <Stack direction="row">
                            <Image src={Users}></Image>
                            <Text mx={2} color="#20212380">
                              {revenueSplit.usersCount}{' '}
                              {revenueSplit.usersCount === 1 ? 'Usuário' : 'Usuários'}
                            </Text>
                          </Stack>
                        </Td>

                        <Td borderLeftRadius={5}>
                          <Text mx={2} color="#20212380">
                            <Menu>
                              <MenuButton
                                style={{ outline: 'none', boxShadow: 'none' }}
                                bgColor="#20212320"
                                p="2px"
                                borderRadius={5}
                                color="rgba(32, 33, 35, 0.5)"
                              >
                                <HiDotsHorizontal size="18px" />
                              </MenuButton>

                              <MenuList>
                                <MenuItem
                                  onClick={() => deleteRevenueSplitGroup(revenueSplit.idGroup)}
                                  style={{ outline: 'none', boxShadow: 'none' }}
                                  fontWeight="semibold"
                                  color="red.500"
                                >
                                  Excluir
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>

            {!isLoadingSplitDefault && revenueSplits.length > 0 && (
              <Box my={5} hidden={!filterSplits.length}>
                <Pagination
                  onPageActive={page - 1}
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                />
              </Box>
            )}
          </TabPanel>

          <TabPanel>
            <Heading
              hidden={revenueSplitsPole.length}
              my={5}
              fontSize="lg"
              fontWeight={500}
              w="100%"
              textAlign={{ base: 'center', md: 'start', lg: 'start' }}
              color="#CBC9C9"
            >
              Compartilhe a receita desse produto de acordo com a origem do aluno. O seu aluno
              poderá selecionar a qual polo ele pertence, e a divisão ser realizada.
            </Heading>

            {!revenueSplitsPole.length && !isLoadingSplitPoles && (
              <NoData message="Você não possui compartilhamento de receitas do tipo polo." />
            )}

            {isLoadingSplitPoles && (
              <Box w="100%" h={300} display="flex" justifyContent="center" alignItems="center">
                <Spinner color="orange.400" />
              </Box>
            )}

            {!isLoadingSplitPoles && revenueSplitsPole.length > 0 && (
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4}>
                <Stack spacing={4}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<AiOutlineSearch color="gray" size="25px" />}
                    />
                    <Input
                      h="40px"
                      hidden={isLoadingSplitPoles}
                      type="text"
                      name="search"
                      placeholder="Buscar por Polo, Gestor ou Produto"
                      onChange={handleChangeFilterPole}
                      value={searchSplitsPole}
                      w={{ base: '100%', md: 320, lg: 320 }}
                      focusBorderColor="orange.400"
                      borderRadius={5}
                    />
                  </InputGroup>
                </Stack>

                <Select
                  name="searchStatus"
                  focusBorderColor="orange.400"
                  placeholder="Status"
                  w={150}
                  borderRadius={5}
                  onChange={handleChangeFilterPole}
                  value={searchStatusPole}
                >
                  <option value="ATIVO">Ativo</option>
                  <option value="INATIVO">Inativo</option>
                </Select>

                <Button size="sm" w={55} colorScheme="orange" onClick={() => filterPole()}>
                  Filtrar
                </Button>
              </Stack>
            )}

            {!filterSplitsPole.length && !isLoadingSplitPoles && searchSplitsPole && (
              <NoData message="Você não possui compartilhamento com esse nome, produto ou status" />
            )}

            <TableContainer
              hidden={
                isLoadingSplitPoles ||
                !revenueSplitsPole.length ||
                (!filterSplitsPole.length && searchSplitsPole !== '')
              }
            >
              <Table
                variant="unstyled"
                style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
              >
                <Thead bgColor="#EB7129" color="white" borderRadius={5} h={45}>
                  <Tr>
                    <Th
                      fontSize="14px"
                      fontFamily="Montserrat !important"
                      textTransform="none"
                      fontWeight={400}
                      borderLeftRadius={5}
                    >
                      <Text mx={2}>Nome do Polo</Text>
                    </Th>
                    <Th
                      fontSize="14px"
                      fontFamily="Montserrat !important"
                      textTransform="none"
                      fontWeight={400}
                    >
                      <Text mx={2}>Gestor de Polo</Text>
                    </Th>
                    <Th
                      fontSize="14px"
                      fontFamily="Montserrat !important"
                      textTransform="none"
                      fontWeight={400}
                    >
                      <Text mx={2}>Produto</Text>
                    </Th>
                    <Th
                      fontSize="14px"
                      fontFamily="Montserrat !important"
                      textTransform="none"
                      fontWeight={400}
                    >
                      <Text mx={2}>Status</Text>
                    </Th>
                    <Th
                      fontSize="14px"
                      fontFamily="Montserrat !important"
                      textTransform="none"
                      fontWeight={400}
                      borderRightRadius={5}
                      w={20}
                    >
                      <Text mx={2}>Ações</Text>
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {!isLoadingSplitPoles &&
                    filterSplitsPole.length > 0 &&
                    filterSplitsPole.map(revenueSplit => (
                      <Tr bgColor="rgba(32, 33, 35, 0.05)">
                        <Td
                          borderLeftRadius={5}
                          onClick={() => editSplit(revenueSplit.idGroup)}
                          cursor="pointer"
                        >
                          <Text mx={2} color="#20212380">
                            {revenueSplit.poleName}
                          </Text>
                        </Td>
                        <Td
                          borderLeftRadius={5}
                          onClick={() => editSplit(revenueSplit.idGroup)}
                          cursor="pointer"
                        >
                          <Text mx={2} color="#20212380">
                            {revenueSplit.userName}
                          </Text>
                        </Td>
                        <Td
                          borderLeftRadius={5}
                          onClick={() => editSplit(revenueSplit.idGroup)}
                          cursor="pointer"
                        >
                          <Text mx={2} color="#20212380">
                            {revenueSplit.productName}
                          </Text>
                        </Td>
                        <Td borderLeftRadius={5}>
                          <Text mx={2} color="#20212380">
                            {revenueSplit.status}
                          </Text>
                        </Td>
                        <Td borderLeftRadius={5}>
                          <Text mx={2} color="#20212380">
                            <Menu>
                              <MenuButton
                                style={{ outline: 'none', boxShadow: 'none' }}
                                bgColor="#20212320"
                                p="2px"
                                borderRadius={5}
                                color="rgba(32, 33, 35, 0.5)"
                              >
                                <HiDotsHorizontal size="18px" />
                              </MenuButton>

                              <MenuList>
                                <MenuItem
                                  style={{ outline: 'none', boxShadow: 'none' }}
                                  color="gray.500"
                                  onClick={() =>
                                    updateRevenueSplitGroup(
                                      revenueSplit.idGroup,
                                      revenueSplit.status
                                    )
                                  }
                                >
                                  {revenueSplit.status === 'INATIVO' ? 'Ativar' : 'Inativar'}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => deleteRevenueSplitGroup(revenueSplit.idGroup)}
                                  style={{ outline: 'none', boxShadow: 'none' }}
                                  fontWeight="semibold"
                                  color="red.500"
                                >
                                  Excluir
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>

              {!isLoadingSplitPoles && revenueSplitsPole.length > 0 && (
                <Box my={5} hidden={!filterSplitsPole.length}>
                  <Pagination
                    onPageActive={pagePole - 1}
                    pageCount={pageCountPole}
                    onPageChange={handlePagePoleChange}
                  />
                </Box>
              )}
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setNewRevenueSplit(initialState);
          setAvailablePercentagePreview('');
          onClose();
        }}
        size="2xl"
        isCentered
      >
        <form onSubmit={newRevenueSubmit}>
          <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
          <ModalContent>
            <ModalHeader fontWeight={800} fontSize="xl" textAlign="start">
              {tabIndex === 0 ? 'Adicionar um usuário' : 'Adicionar um Polo'}
            </ModalHeader>
            <ModalCloseButton
              style={{ outline: 'none', boxShadow: 'none' }}
              onClick={() => {
                setNewRevenueSplit(initialState);
                setAvailablePercentagePreview('');
                setErroProduct(null);
                onClose();
              }}
            />
            <ModalBody>
              <Stack direction="column" w="100%" justifyContent="center" spacing={2}>
                <Text fontWeight="semibold" fontSize="md">
                  {tabIndex === 0 ? 'Usuário' : 'Polo'}
                </Text>

                {tabIndex === 0 ? (
                  isLoadingUsers ? (
                    <Spinner color="orange.400" />
                  ) : (
                    <Select
                      isRequired={tabIndex === 0}
                      focusBorderColor="orange.400"
                      onChange={handleChangeNewSplit}
                      value={newRevenueSplit.id}
                      name="id"
                    >
                      <option value="" hidden>
                        Selecione um usuário
                      </option>
                      {!isLoadingUsers && coProducers.length ? (
                        coProducers.map(user => (
                          <option placeholder="Selecione um usuário" value={user.id}>
                            {user.fullName}
                          </option>
                        ))
                      ) : (
                        <option value="">Você não cadastrou usuários.</option>
                      )}
                    </Select>
                  )
                ) : isLoadingUsersSede ? (
                  <Spinner color="orange.400" />
                ) : (
                  <Select
                    isRequired={tabIndex === 1}
                    focusBorderColor="orange.400"
                    onChange={handleChangeNewSplit}
                    value={newRevenueSplit.id}
                    name="id"
                  >
                    <option value="" hidden>
                      Selecione um Polo
                    </option>
                    {!isLoadingUsersSede && allPoles.length ? (
                      allPoles.map(pole => (
                        <option placeholder="Selecione um Polo" value={pole.id}>
                          {pole.name}
                        </option>
                      ))
                    ) : (
                      <option value="">Você não cadastrou usuários tipo POLO/SEDE.</option>
                    )}
                  </Select>
                )}

                <Text fontWeight="medium" color="#20212350" fontSize="md">
                  OBS: Para selecionar um usuário é necessário cadastra-lo. Para isso vá no menu
                  lateral em Usuários {'>'} Usuários da conta.
                </Text>

                <Text fontWeight="semibold" fontSize="md">
                  Escolher produto
                </Text>
                {isLoadingCourses ? (
                  <Spinner color="orange.400" />
                ) : (
                  <Select
                    isRequired
                    focusBorderColor={erroProduct ? '#BB212480' : 'orange.400'}
                    onChange={handleChangeNewSplit}
                    value={newRevenueSplit.courseId}
                    name="courseId"
                    isInvalid={erroProduct}
                  >
                    <option value="" hidden>
                      Escolha um produto
                    </option>
                    {courses.length ? (
                      courses.map(course => (
                        <option placeholder="Escolha um curso" value={course.id}>
                          {course.name}
                        </option>
                      ))
                    ) : (
                      <option value="">Você não possui cursos.</option>
                    )}
                  </Select>
                )}

                {erroProduct && (
                  <Text color="#BB212480" fontSize="15px">
                    {erroProduct}
                  </Text>
                )}

                <Text fontWeight="semibold" fontSize="md">
                  Porcentagem
                </Text>

                <InputGroup size="sm">
                  <Input
                    isRequired
                    type="number"
                    name="percentage"
                    placeholder="Digite uma porcentagem"
                    focusBorderColor="orange.400"
                    borderRadius={5}
                    value={newRevenueSplit.percentage}
                    onChange={handleChangeNewSplit}
                    h={10}
                    step="0.01"
                    min="0.01"
                    max="99.99"
                  />
                  <InputRightAddon children="%" h={10} />
                </InputGroup>
              </Stack>

              <Stack direction="row" w="100%" justifyContent="flex-start">
                <Stack direction="column" my={5}>
                  <Text>
                    Porcentagem da proluno:{' '}
                    <Text as="line" fontWeight={600}>
                      {!isLoadingPercentages && percentageAvailable?.prolunoPercentage
                        ? `${parseFloat(percentageAvailable?.prolunoPercentage).toFixed(2)}%`
                        : '-'}
                    </Text>
                  </Text>
                  <Text>
                    Porcentagem disponível:{' '}
                    <Text as="line" fontWeight={600}>
                      {!isLoadingPercentages && percentageAvailable?.availablePercentage
                        ? `${
                            typeof availablePercentagePreview === 'number'
                              ? parseFloat(availablePercentagePreview).toFixed(2)
                              : parseFloat(percentageAvailable?.availablePercentage).toFixed(2)
                          }%`
                        : '-'}
                    </Text>
                  </Text>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Stack direction="row" w="100%" justifyContent="flex-end">
                <Button
                  style={{ outline: 'none', boxShadow: 'none' }}
                  colorScheme="gray"
                  mr={3}
                  onClick={() => {
                    setNewRevenueSplit(initialState);
                    setAvailablePercentagePreview('');
                    setErroProduct(null);
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="solid"
                  style={{ outline: 'none', boxShadow: 'none' }}
                  isDisabled={erroProduct}
                  colorScheme={erroProduct ? 'blackAlpha' : 'orange'}
                  type="submit"
                >
                  {isLoadingSplitDefault | isLoadingSplitPoles ? (
                    <Spinner color="orange.400" />
                  ) : (
                    'Adicionar'
                  )}
                </Button>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default RevenueSplit;
