import {
  Box,
  Button,
  Heading as HeadingChakra,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { Heading } from '../../../../../../components/Heading';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import './style.css';

const accessDurationInitialValues = {
  freeAccess: false,
  limitedAccess: false,
  accessPeriod: 7,
  accessDeadline: false,
  courseAccessDeadline: dayjs().utc().format('YYYY-MM-DD'),
};

export default function AccessCourse() {
  const { course, updateCourse, loading } = useCourse();

  const [accessDuration, setAccessDuration] = useState(accessDurationInitialValues);

  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!loading) {
      setAccessDuration({
        freeAccess: course.freeAccess,
        limitedAccess: course.limitedAccess,
        accessPeriod: course.accessPeriod || accessDurationInitialValues.accessPeriod,
        accessDeadline: course.accessDeadline,
        courseAccessDeadline:
          dayjs(course.courseAccessDeadline).utc().format('YYYY-MM-DD') ||
          accessDurationInitialValues.courseAccessDeadline,
      });
    }
  }, [course, loading]);

  function handleChange(event) {
    const { value, name, type, checked } = event.target;

    const values = {
      ...accessDuration,
    };

    if (type === 'radio') {
      values.freeAccess = false;
      values.accessDeadline = false;
      values.limitedAccess = false;

      values[value] = checked;
    } else if (type === 'date') {
      values.courseAccessDeadline = dayjs(value).utc().format('YYYY-MM-DD');
    } else {
      values[name] = value;
    }

    setAccessDuration(values);
  }

  async function onSubmit(event) {
    event.preventDefault();

    try {
      await updateCourse(accessDuration);

      setMessage('Curso alterado com sucesso !');
      setTypeMessage('sucesso');
      onOpen();
    } catch (error) {
      setMessage(ErrorResponse(error));
      setTypeMessage('erro');
      onOpen();
    }
  }

  return (
    <div className="row justify-content-center px-3 my-3">
      <div className="col-lg-8 col-xl-9">
        <form onSubmit={onSubmit} className="card shadow-sm">
          <div className="card shadow-sm">
            <div className="card-inner">
              <Heading as="h4" fontSize="2xl">
                Duração de acesso
              </Heading>

              <Stack direction="column" spacing={2} mt={5}>
                <HeadingChakra fontWeight="500" fontSize="md">
                  Selecione qual será o formato de duração de acesso dos seus alunos a esse produto.
                </HeadingChakra>
                <HeadingChakra fontWeight="300" fontSize="sm" fontStyle="italic">
                  Obs: Se você já possui alunos cadastrados no produto e realizou a mudança de
                  duração, a nova configuração só fica válida para novos alunos, os antigos
                  continuam com a configuração que eles adquiriram o produto.
                </HeadingChakra>
              </Stack>

              <Stack direction="column" my={5} spacing={6}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row">
                    <input
                      style={{
                        border: '1px solid',
                        width: '15px',
                        display: 'grid',
                        placeContent: 'center',
                        borderRadius: '50%',
                      }}
                      type="radio"
                      name="accessDuration"
                      id="freeAccess"
                      value="freeAccess"
                      checked={accessDuration.freeAccess}
                      onChange={handleChange}
                    />
                    <Text fontWeight={600} color="gray.600" fontSize="lg" htmlFor="freeAccess">
                      Livre
                    </Text>
                  </Stack>
                  <Text fontWeight={400} fontSize="sm">
                    Os alunos terão acesso permanente
                  </Text>
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Stack direction="row">
                    <input
                      style={{
                        border: '1px solid',
                        width: '15px',
                        display: 'grid',
                        placeContent: 'center',
                        borderRadius: '50%',
                      }}
                      type="radio"
                      name="accessDeadline"
                      id="limitedAccess"
                      value="limitedAccess"
                      checked={accessDuration.limitedAccess}
                      onChange={handleChange}
                    />

                    <Text fontWeight={600} color="gray.600" fontSize="lg" htmlFor="limitedAccess">
                      Acesso limitado
                    </Text>
                  </Stack>
                  <Text fontWeight={400} fontSize="sm">
                    Os alunos terão um período de acesso limitado em{' '}
                    <Text as="line" fontWeight={600}>
                      DIAS
                    </Text>{' '}
                    estipulado por você, iniciando a partir da compra do produto.
                  </Text>

                  {accessDuration.limitedAccess && (
                    <Input
                      mt={5}
                      focusBorderColor="orange.400"
                      className="form-control"
                      onChange={handleChange}
                      name="accessPeriod"
                      value={accessDuration.accessPeriod}
                      type="number"
                      placeholder="Quantidade em dias"
                    />
                  )}
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Stack direction="row">
                    <input
                      style={{
                        border: '1px solid',
                        width: '15px',
                        display: 'grid',
                        placeContent: 'center',
                        borderRadius: '50%',
                      }}
                      type="radio"
                      name="accessDuration"
                      id="accessDeadline"
                      value="accessDeadline"
                      checked={accessDuration.accessDeadline}
                      onChange={handleChange}
                    />
                    <Text fontWeight={600} color="gray.600" fontSize="lg" htmlFor="accessDeadline">
                      Limite para ficar ativo
                    </Text>
                  </Stack>

                  <Text fontWeight={400} fontSize="sm">
                    O curso terá um prazo limite para ficar ativo, após a data limite escolhida por
                    você e o curso ficará inativo
                  </Text>

                  {accessDuration.accessDeadline && (
                    <Input
                      mt={5}
                      focusBorderColor="orange.400"
                      type="date"
                      className="form-control"
                      value={dayjs(accessDuration.courseAccessDeadline).utc().format('YYYY-MM-DD')}
                      onChange={handleChange}
                    />
                  )}
                </Stack>
              </Stack>
            </div>

            <Stack direction="row" w="100%" justifyContent="flex-end" p={10}>
              <Button
                colorScheme="orange"
                type="submit"
                style={{
                  outline: 'none',
                  boxShadow: 'none',
                }}
              >
                Salvar
              </Button>
            </Stack>
          </div>
        </form>

        <Modal
          size="md"
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        >
          <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
          <ModalContent>
            <ModalCloseButton
              onClick={onClose}
              style={{
                outline: 'none',
                boxShadow: 'none',
              }}
            />
            <ModalBody w="100%" textAlign="center">
              {typeMessage === 'sucesso' && (
                <Stack w="100%" direction="column" alignItems="center">
                  <ModalHeader>Alterações realizadas com sucesso!</ModalHeader>
                  <FaCheckCircle
                    style={{
                      color: 'green',
                      height: '50px',
                      width: '50px',
                      textAlign: 'center',
                    }}
                  />
                  <Text>
                    As alterações realizadas ficarão vigentes apenas para novos aluno, os antigos
                    permanecem com as configurações anteriores.
                  </Text>
                </Stack>
              )}
              {typeMessage === 'aviso' && (
                <Stack w="100%" direction="column" alignItems="center" my={10}>
                  <FaExclamationCircle
                    style={{
                      marginBottom: 10,
                      color: '#EB7129',
                      height: '50px',
                      width: '50px',
                    }}
                  />
                  <Text>{message}</Text>
                </Stack>
              )}
              <Box>
                {typeMessage === 'erro' && (
                  <Stack w="100%" direction="column" alignItems="center" my={10}>
                    <FaExclamationCircle
                      style={{
                        marginBottom: 10,
                        color: 'red',
                        height: '50px',
                        width: '50px',
                      }}
                    />
                    <Text>{message}</Text>
                  </Stack>
                )}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
}
