import { Box, Spinner } from '@chakra-ui/react';

export default function FullPageLoading({ isLoading }: { isLoading: boolean }) {
  return (
    <Box
      id="overlay"
      inset={0}
      display={isLoading ? 'flex' : 'none'}
      position="fixed"
      zIndex={999}
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      background="hsla(0,0%,100%,0.75)"
    >
      <Spinner color="primary.500" size="xl" thickness="4px" speed="1s" emptyColor="gray.200" />
    </Box>
  );
}
