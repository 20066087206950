import { ReactComponent as FinancialIcon } from '../../../../../assets/icons/sidebar/financialIcon.svg';
import { ReactComponent as IntegrationsIcon } from '../../../../../assets/icons/sidebar/integrationsIcon.svg';
import { ReactComponent as MarketingIcon } from '../../../../../assets/icons/sidebar/marketingIcon.svg';
import { ReactComponent as MembersAreaIcon } from '../../../../../assets/icons/sidebar/membersAreaIcon.svg';
import { ReactComponent as PageBuilderIcon } from '../../../../../assets/icons/sidebar/pageBuilderIcon.svg';
import { ReactComponent as ProductManagerIcon } from '../../../../../assets/icons/sidebar/productsManagerIcon.svg';
import { ReactComponent as ProfileIcon } from '../../../../../assets/icons/sidebar/profileIcon.svg';
import { ReactComponent as ConfigIcon } from '../../../../../assets/icons/sidebar/configIcon.svg';
import { ReactComponent as SupportIcon } from '../../../../../assets/icons/sidebar/supportIcon.svg';
import { ReactComponent as TrainingIcon } from '../../../../../assets/icons/sidebar/trainingIcon.svg';
import { ReactComponent as UpgradeIcon } from '../../../../../assets/icons/sidebar/upgradeIcon.svg';
import { ReactComponent as UsersIcon } from '../../../../../assets/icons/sidebar/usersIcon.svg';
import { ReactComponent as poleManagementIcon } from '../../../../../assets/icons/sidebar/pole-management.svg';
import { ReactComponent as AccountKey } from '../../../../../assets/icons/account-key.svg';
import { ReactComponent as HomeIcon } from '../../../../../assets/icons/sidebar/homeIcon.svg';
import { ReactComponent as MyAffiliationsIcon } from '../../../../../assets/icons/sidebar/myAffiliationsIcon.svg';
import { ReactComponent as ToolsIcon } from '../../../../../assets/icons/sidebar/toolsIcon.svg';

import { Flex } from '@chakra-ui/react';

const icons = {
  pageBuilderIcon: PageBuilderIcon,
  productManagerIcon: ProductManagerIcon,
  financialIcon: FinancialIcon,
  marketingIcon: MarketingIcon,
  trainingIcon: TrainingIcon,
  upgradeIcon: UpgradeIcon,
  usersIcon: UsersIcon,
  poleManagementIcon: poleManagementIcon,
  profileIcon: ProfileIcon,
  integrationsIcon: IntegrationsIcon,
  supportIcon: SupportIcon,
  membersAreaIcon: MembersAreaIcon,
  configIcon: ConfigIcon,
  accountKey: AccountKey,
  homeIcon: HomeIcon,
  myAffiliationsIcon: MyAffiliationsIcon,
  toolsIcon: ToolsIcon,
};

const IconContainer = ({ name, ...props }) => {
  const Icon = icons[name];

  return (
    <Flex align="center" justify="center" w="24px" h="24px" flexShrink={0}>
      <Icon {...props} alt={`Ícone ${name}`} style={props} />
    </Flex>
  );
};

export default IconContainer;
